import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import {
  Color,
} from "../../systemadministration/usermanagement/CheckRole";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Str from "../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";

import PermissionsGate from "../../../utils/permissionGate";
import { emailStoppedList } from "../../../services/EmailServices";
import moment from "moment";
import HtmlParser from "react-html-parser";
import { GetCommonContent } from "../../../services/ProgrammeServices";
import hasPermission from "../../../utils/hasMultiplePermission";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../utils/Constants";
import Tablefilter from "../../common/Tablefilter";
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";

const StoppedList = () => {
  const loc = useLocation();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState([]);
  const [searchStatusCheck, setSearchStatusCheck] = useState({});
  const [role, setRole] = useState(
    loc && loc.state && loc.state.name ? [loc.state.name] : []
  );
  const [searchRoleCheck, setSearchRoleCheck] = useState(
    loc && loc.state && loc.state.role_name ? loc.state.role_name : {}
  );
  const [location, setLocation] = useState([]);
  const [stoppedData, setStoppedData] = useState({});
  const [report, setReport] = useState([]);
  const [searchReportCheck, setSearchReportCheck] = useState({});
  const [userData, setUserData] = useState([]);
  const [loading, setloading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortkey, setSortKey] = useState("id");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [reason, setReason] = useState({ arr: [], checkObj: {} });
  const [searchReasonCheck, setSearchReasonCheck] = useState({});
  const [filterData, setFilterData] = useState({reason : []});
  const [filterCheck, setFilterCheck] = useState("true");

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  // TODO Api Call

  useEffect(() => {

    let response = hasPermission({ scopes: ["bnseview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }
  }, [])

  useEffect(() => {
    setPage(1)

  }, [location]);

  useEffect(() => {
    const cancelTokenSources = [];
    const getList = async () =>{
      setloading(true);
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();      
      cancelTokenSources.push(source);

      const data = {
        page: page,
        limit: perPage,
        viaSearch: search ? search : "",
        exportStatus: false,
        key: sortkey,
        sort: sortOrder,
        viaReason: reason.arr.length > 0 ? JSON.stringify(reason.arr) : [],
        dynamicFilter: filterCheck
      };

      try {
        const res = await emailStoppedList(data, source.token);
        if(res.status==200){
          setUserData(res?.data?.data_list?.data);
          setTotalRows(res?.data?.data_list?.total)
          setloading(false);
          if(filterCheck == "true"){
            setFilterData({...res.data, reason : res.data.reasonFilter});
            setFilterCheck("false")
          }
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }
    }
    getList();
    return () => {
      cancelTokenSources.forEach(source => {
          source.cancel('Component unmounted');
      });
    };
  }, [search, page, perPage, sortkey, sortOrder, reason, searchReasonCheck]);

  // TODO Searching
  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column?.selector);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  // TODO Filtering

  const handleStatusFilter = (e) => {
    const value = e.target.value;
    let arr = status;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setStatus(arr);
    let id = e.target.id;
    setSearchStatusCheck({
      ...searchStatusCheck,
      [id]: !searchStatusCheck[id],
    });
  };

  const handleRoleFilter = (e) => {
    const value = e.target.value;
    let arr = role;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setRole(arr);
    let id = e.target.id;
    setSearchRoleCheck({ ...searchRoleCheck, [id]: !searchRoleCheck[id] });
  };

  const handleReportToFilter = (e) => {
    const value = e.target.value;
    let arr = report;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setReport(arr);
    let id = e.target.id;
    setSearchReportCheck({
      ...searchReportCheck,
      [id]: !searchReportCheck[id],
    });
  };

  // TODO Reset Filter

  const resetFilter = () => {
    setStatus([]);
    setSearchStatusCheck({});
    setRole([]);
    setSearchRoleCheck({});
    setLocation([]);
    setReport([]);
    setSearchReportCheck({});
    setSearch("");
    setReason({ arr: [], checkObj: {} });
    setSearchReasonCheck({})
  };

  // TODO Data

  const handleReturningIcon = (row) => {
    return (
      <>
        {row.isEmailActive ? (
          <>
            <i class="fal fa-envelope"></i>&nbsp;
          </>
        ) : (
          ""
        )}
        {row.isSmsActive ? (
          <>
            <i class="fal fa-comment"></i>&nbsp;
          </>
        ) : (
          ""
        )}
        {row.isPushnotificationActive ? <i class="fal fa-mobile"></i> : ""}

        {!row.isPushnotificationActive && !row.isSmsActive && !row.isEmailActive ? "-" : ""}
      </>
    );
  };

  const sortingUser = (rowA, rowB) => {
    const name1 = rowA?.sendByCourse?.log.toString() ? rowA?.sendByCourse?.log.toString() : "-";
    const name2 = rowB?.sendByCourse?.log.toString() ? rowB?.sendByCourse?.log.toString() : "-";
    return name1.localeCompare(name2);
  };

  const columns = useMemo(() => [
    {
      name: "Subject",
      selector: "subject",
      sortable: true,
      // cell: (row) => (row.subject ? row.subject : "NA"),
      cell: (row) => (row?.subject ? <p
        className="as-text-blue curser textLimit100"
        title={row?.subject}
        onClick={() => handleStoppedView(row?.id, row)}
        data-toggle="modal"
        data-target="#notifications"
      >{row?.subject}</p> : "-"),
    },

    {
      name: "Reason",
      selector: "reason",
      sortable: true,
      // maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) => (
        row?.reason ? row?.reason : "-"
      ),
    },
    {
      name: "To",
      selector: "to",
      sortable: true,
      cell: (row) => (row?.to ? row?.to : "-"),
    },
    {
      name: "Sent Via",
      selector: "sendVia",
      sortable: false,
      // maxWidth: "80px",
      // minWidth: "80px",
      cell: (row) => (
        <div className="ticket-id">
          <span className="overflow-ellipsis2">
            {row.draft_notification ? <div className="as-green-bg as-widget">{"Draft Saved On " + moment.unix(row.start).format(TABLE_DATE_TIME_FORMAT)}</div> : handleReturningIcon(row)}
          </span>
        </div>
      ),
    },
    {
      name: "Sent By",
      selector: "log",
      sortable: false,
      wrap: true,
      // sortFunction: sortingUser,
      cell: (row) =>
        row.sendByCourse && row.sendByCourse.log ?
          <span className="feature-name" title={row.sendByCourse && row.sendByCourse.log}>
            <span className="textLimit100">{row.sendByCourse && row.sendByCourse.log}</span>
          </span>
          : (
            <p>-</p>
          ),
    },
    {
      name: "Created Date",
      selector: "created_date",
      sortable: true,
      // cell: (row) => (row?.created_date ? row?.created_date : "NA"),
      cell: (row) => (row?.created_date && row?.created_date != 0 && row?.created_date != "0000-00-00 00:00:00") ? `${moment(row?.created_date).format(TABLE_DATE_FORMAT)} ${moment(row?.created_date).format(TABLE_TIME_FORMAT)}` : '-',
    },

    {
      name: "Action",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <PermissionsGate scopes={["umedit"]}>
              <button
                title="View"
                className="btn btn-primary rounded-circle"
                onClick={() => handleStoppedView(row?.id, row)}
                data-toggle="modal"
                data-target="#notifications"
              >
                <i className="fal fa-eye"></i>
              </button>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  const handleStoppedView = (ID, row) => {
    setStoppedData(row)
  };

  // TODO Excel
  // TODO Csv
  // TODO Pdf

  const exportData = (fileType, fileName) => {
    let data
    const header = ["Subject", "Reason", "To", "Sent Via", "Sent By", "Created Date"];

    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });


    emailStoppedList({
      page: page,
      limit: perPage,
      viaSearch: search ? search : "",
      exportStatus: "true"
    })
      .then((res) => {
        data = res?.data?.data_list?.map((row) => ({
          Subject: row?.subject ? row?.subject : "-",
          Reason: row?.reason ? row?.reason : "-",
          // Status: row.status ? row.status : "-",
          To: row?.to ? row?.to : "-",
          "Sent Via": row.draft_notification ? "Draft Saved On " + moment.unix(row.start).format(TABLE_DATE_TIME_FORMAT) : ((row.isEmailActive ? "Email " : "") + (row.isPushnotificationActive ? " Push" : "")),
          ["Sent By"]: row.sendByCourse && row.sendByCourse ? row.sendByCourse.log : "-",
          "Created Date": (row?.created_date && row?.created_date != 0 && row?.created_date != "0000-00-00 00:00:00") ? `${moment(row?.created_date).format(TABLE_DATE_FORMAT)} ${moment(row?.created_date).format(TABLE_TIME_FORMAT)}` : '-',
        }))

        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });

          const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

          const blobURL = window.URL.createObjectURL(blob);

          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          setTimeout(() => {
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row["Subject"],
              row["Reason"],
              // row["Status"],
              row["To"],
              row["Sent Via"],
              row["Sent By"],
              row["Created Date"],
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      });
  };

  const customSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
     // use the selector function to resolve your field names by passing the sort comparitors
     const aField = rowA[selector]?.toString().toLowerCase()
     const bField = rowB[selector]?.toString()?.toLowerCase()
   
     let comparison = 0;
   
     if (aField > bField) {
      comparison = 1;
     } else if (aField < bField) {
      comparison = -1;
     }
   
     return direction === 'desc' ? comparison * -1 : comparison;
    });
   };

  return (
    <>
      <div className="my-tickets-info-list Tickets-main-wrap">
        <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
          <div className="search-filter-div">
            <div className="search-filter-div-left">
              <div className="system-administration-table table-responsive">
                <div className="table-responsive-div">
                  <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      id="assessment-table-main_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          className=""
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={handleSearchFilter}
                          value={search}
                        />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filter-button-group">
                  <div
                    className="filter-scroll"
                  >
                    <div
                      className="filter-scroll-inner filter-custom-new"
                    >
                      <Tablefilter
                        filterName="Reason"
                        optionArr={filterData.reason}
                        state={reason}
                        setState={setReason}
                      />
                    </div>
                  </div>
                  <div className="reset-btn-group">
                    <div className="button-reset dropdown-comman">
                      <button
                        className="btn btn-primary"
                        onClick={resetFilter}
                        title="Reset"
                      >
                        <i className="fal fa-redo"></i>Reset
                      </button>
                    </div>
                    <div className="files-export-group">
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("xlsx", "Stopped Notifications");
                        }}
                        title="Export spreadsheet"
                      >
                        <i className="fal fa-file-excel icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("csv", "Stopped Notifications");
                        }}
                        title="Export CSV"
                      >
                        <i className="fal fa-file-csv icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("pdf", "Stopped Notifications");
                        }}
                        title="Export PDF"
                      >
                        <i className="fal fa-file-pdf icon"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <DataTable
              data={userData}
              defaultSortField="created_date"
              defaultSortAsc={false}
              columns={columns}
              pagination={true}
              noDataComponent={Str.noRecord}
              paginationDefaultPage={page}
              progressPending={loading}
              progressComponent={<SkeletonTicketList />}
              onSort={handleSort}
              paginationServer
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              highlightOnHover={false}
              paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
              sortFunction={customSort}
            />
        </div>
      </div>

      <div className="modal fade" id="notifications" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="modal-header modal-header-custom">
                <h5 className="modal-title">
                  <i className="fas fa-bars"></i> Generated Details
                </h5>
                <button type="button" data-dismiss="modal" className="close" title="Close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
              </div>
              {/* <div className="topic-add-modal-content text-center font-weight-bold">
                  <i className="fas fa-bars"></i> Generated Details
                </div> */}
              {/* <hr /> */}
              <div className="p-30px">
                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <div className="basic-info-box-list">
                      <ul className="d-flex flex-column p-0">
                        <li className="d-flex">
                          <span className="title mw-0">To Mail:&nbsp;</span>
                          <span>{stoppedData?.to ? stoppedData?.to : 'NA'}</span>
                        </li>
                        <li className="d-flex">
                          <span className="title mw-0">Sent:&nbsp;</span>
                          <span>{stoppedData?.sent==0 ? "Pending" : `${moment(stoppedData.sent).format('DD MMMM YYYY')}@${moment(stoppedData.sent).format('HH:mm')}`}</span>
                        </li>
                        <li className="d-flex">
                          <span className="title mw-0">Subject:&nbsp;</span>
                          <span>{stoppedData?.subject ? stoppedData?.subject : 'NA'}</span>
                        </li>
                      </ul>
                    </div>
                       
                          <span className="d-flex" style={{fontWeight:"600"}}>Content:&nbsp;</span>
                          <span className="word-break-all">{stoppedData?.body ? HtmlParser(stoppedData?.body) : 'NA'}</span>
                    <div className="basic-info-box-list">
                      <ul className="d-flex flex-column p-0">
                        <li className="d-flex">
                          <span className="">Attachment:&nbsp;</span>
                          {/* <span>{generatedData && generatedData.subject && generatedData.subject}</span> */}
                            <span>{stoppedData?.attachment && stoppedData?.attachmentArr.length ? <><a className="as-text-blue curser" target={"_blank"} href={`${IMAGE_URL}/${encodeURI(stoppedData?.attachment.replace("home/myaie/public_html/", "").replace("public/", ""))}`}>{stoppedData?.attachment?.split("/")[stoppedData?.attachment?.split("/").length-1]}</a><br/>{stoppedData.attachmentArr.map((item, index) => <React.Fragment key={index}><a className="as-text-blue curser" target={"_blank"} href={`${IMAGE_URL}/${encodeURI(item?.attachment.replace("home/myaie/public_html/", "").replace("public/", ""))}`}>{item?.attachment?.split("/")[item?.attachment?.split("/").length-1]}</a>{index < (stoppedData.attachmentArr.length - 1) ? <br/> : ""}</React.Fragment>)}</>
                              : stoppedData?.attachment ? <a className="as-text-blue curser" target={"_blank"} href={`${IMAGE_URL}/${encodeURI(stoppedData?.attachment.replace("home/myaie/public_html/", "").replace("public/", ""))}`}>{stoppedData?.attachment?.split("/")[stoppedData?.attachment?.split("/").length-1]}</a> : stoppedData?.attachmentArr?.length ? stoppedData.attachmentArr.map((item, index) => <React.Fragment key={index}><a className="as-text-blue curser" target={"_blank"} href={`${IMAGE_URL}/${encodeURI(item?.attachment.replace("home/myaie/public_html/", "").replace("public/", ""))}`}>{item?.attachment?.split("/")[item?.attachment?.split("/").length-1]}</a></React.Fragment>) : 'NA'}
                      </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="form-group form-group-save-cancel mt-3">
                  <button type="button" className="btn btn-danger" data-dismiss="modal" title="Close">
                    <i className="fal fa-times"></i>
                    Close
                  </button>
                </div>
              </div>
            </div>

            {/* <div className="modal-footer"> */}
            {/* <a href={!notificationData || !notificationData.attachment ? "" : IMAGE_URL + "/" + (notificationData.attachment).replace("/home/myaie/public_html/", "").replace("public/", "")} target="_blank" download>
                  <button className="btn btn-primary" disabled={notificationData.attachment == "" || notificationData.attachment == null || !notificationData.attachment} title="Download Document">
                    <i className="fal fa-arrow-down"></i>
                    Download Document
                  </button>
                </a> */}
            {/* </div> */}
          </div>
        </div>
      </div>

    </>
  );
};

export default StoppedList;

import userReducer from "./reducers/user";
import payerReducer from "./reducers/payer";
import subjectReducer from "./reducers/subject";
import homeTabReducer from "./reducers/homeTab";
import chatGroups from "./reducers/chatGroups";
import selectedGroupChatReducer from "./reducers/selectedGroupChatReducer";
import { combineReducers } from "redux";
import fileReducer from "./reducers/file";
import quizReducer from "./reducers/quiz";
import notificationReducer from "./reducers/notification";
import widgetReducer from "./reducers/widget";
import modalStatus from "./reducers/modalStatus";
import brandReducer from "./reducers/brand";

import imageReducer from "./reducers/image";
import permissionReducer from "./reducers/permission";
import giverPermissionReducer from "./reducers/givenPermission";
import userstatusReducer from "./reducers/userstatus";
import registerDropdownValues from './reducers/registrationDropdown';
import hoverUserReducer from "./reducers/hoverUser";
import sideBarLinksReducer from "./reducers/sideBarLinks";
import brandVisualReducer from "./reducers/brandVisuals";
import assessmentReducer from "./reducers/assessment";
import examCenterReducer from "./reducers/examCenter";
import examNameReducer from "./reducers/examName";
import examPeriodReducer from "./reducers/examPeriod";
import hoverTopicReducer from "./reducers/hoverTopic";
import hoverKeywordReducer from "./reducers/hoverKeyword";
import brand_override_reducer from "./reducers/brand_override";
import course_type_reducer from "./reducers/courseType";
import file_system from "./reducers/file_system";

const rootReducer = combineReducers({
  user: userReducer,
  payer: payerReducer,
  tabs: homeTabReducer,
  selectedGroup: selectedGroupChatReducer,
  subject: subjectReducer,
  chat: chatGroups,
  file: fileReducer,
  quiz: quizReducer,
  notification: notificationReducer,
  widget: widgetReducer,
  modalStatusVideo: modalStatus,
  brand: brandReducer,
  image: imageReducer,
  permission: permissionReducer,
  givenPermission: giverPermissionReducer,
  userstatus: userstatusReducer,
  registerDropdownValues: registerDropdownValues,
  hoverUser: hoverUserReducer,
  hoverTopic: hoverTopicReducer,
  hoverKeyword: hoverKeywordReducer,
  sideBarLinks: sideBarLinksReducer,
  brandVisuals:brandVisualReducer,
  assessment:assessmentReducer,
  examCenterReducer:examCenterReducer,
  examNameReducer:examNameReducer,
  examPeriodReducer:examPeriodReducer,
  brand_override_reducer:brand_override_reducer,
  course_type_reducer : course_type_reducer,
  file_system_reducer : file_system
});
export default rootReducer;

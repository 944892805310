import React, { useMemo, useState, useEffect } from "react";
import moment from "moment";
import { ColorRender } from "../../../systemadministration/usermanagement/CheckRole";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import {
  GetNoteSubjectList,
  GetStudentNoteList,
  AddOrUpdateStudentNote,
  GetStudentNoteListFilter,
  GetStudentNoteListCancelToken,
} from "../../../../services/TickectService";
import { Link, useHistory, useParams } from "react-router-dom";
import { InitialRender } from "../../../common/Helper";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import DataTable from "react-data-table-component";
import { FORM_DATE_TIME_FORMAT, FORM_TIME_FORMAT, IMAGE_URL, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../../utils/Constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import $ from "jquery";
import Hover from "../../../common/Hover";
import { DeleteStudentNote } from "../../../../services/StudentsSettingServices";
import PermissionsGate from "../../../../utils/permissionGate";
import { useSelector } from "react-redux";
import hasPermission from "../../../../utils/hasMultiplePermission";
import FilterComponent from "../../../common/FilterComponent";
import { handleTableScroll } from "../../../../utils/commonFunction";
import axios from "axios";

const Notes = () => {
  const [search, setSearch] = useState("");
  const { subType, id } = useParams();
  const [relatedCourses, setRelatedCourses] = useState([]);
  const [ticketList, setTicketList] = useState([]);
  const [deleterow, setDeleteRow] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [filePreview, setFilePreview] = useState(false);
  const [fileName, setFileName] = useState();
  const [disabled, setDisabled] = useState("");
  const history = useHistory();
  const [notesRow, setNotesRow] = useState({});
  const [value, onChange] = useState([]);
  const [loading, setLoading] = useState(false);
  const [courseIds, setCourseIds] = useState([]);
  const [relatedFilter, setRelatedFilter] = useState([]);

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $('.rdt_TableCell').css('z-index', 0)
        $(this).parents('.rdt_TableCell').css('z-index', 22)
      });
    });
  })

  useEffect(() => {
    let response = hasPermission({ scopes: ["snoview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }
  }, [])

  const myNoteCompare = (rowA, rowB) => {
    const name1 = rowA.label && rowA.label.toString();
    const name2 = rowB.label && rowB.label.toString();
    return name1.localeCompare(name2);
  };

  useEffect(() => {
    GetNoteSubjectList(id)
    .then((res) => {
      let subject = res?.data?.subject_list || [];
      subject = subject.filter((data)=>{
        return data.label!==null && data.label!=='' && data.value !==null && data.value !=='' 
      })
      setRelatedCourses(subject.sort(myNoteCompare));      
    })
    .catch((err) => {
      console.log(err);
    });
    GetStudentNoteListFilter(id).then((res)=>{
      if(res.status==200){
        let subjectList = res.data.subjectFilter;
        subjectList = subjectList.filter((data)=>{
          return data.label!==null && data.label!=='' && data.value !==null && data.value !=='' 
        });
        setRelatedFilter(subjectList.sort(myNoteCompare));
        if(subType){
          const selectedCourse = subjectList.filter(item => item.value == subType)
          setCourseIds([...courseIds, selectedCourse[0].label])
          onChange(selectedCourse);
        }
      }
    }).catch((err)=>{console.log(err)});
  }, []);

  useEffect(() => {
    const cancelTokenSources = [];

    const getProgramsList = async () => {
      setLoading(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      try {
        const res = await GetStudentNoteListCancelToken(id, source.token);
        if (res.status === 200) {
          setTicketList(res.data?.data_list);
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    };
  
    getProgramsList();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [deleterow]);

  const handleDelete = (noteId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = new FormData();
        data.append("note_id", noteId);
        DeleteStudentNote(data)
          .then((data) => {
            setDeleteRow(!deleterow);
            Swal.fire("Deleted!", "Your record has been deleted.", "success");
          })
          .catch((error) => {
            console.log("error while deleting >>>>> ", error);
          });
      }
    });
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: notesRow?.type ? notesRow?.type : "",
      details: notesRow?.details ? notesRow?.details : "",
      file: notesRow?.file ? notesRow?.file : "",
      note_front_show : notesRow?.note_front_show== "1" ? true : false
    },

    validationSchema: Yup.object({
      details: Yup.string().required("Details is required").trim(),
      type: Yup.string().required("Course is required"),
      // file: Yup.string().required("Attachment is required"),
    }),

    onSubmit: (values, { resetForm }) => {
      setDisabled(true)
      // console.log(values)
      const formInputs = new FormData();
      formInputs.append("id_student", id);
      formInputs.append("id_intake", values.type);
      formInputs.append("note_date", moment().format("YYYY-MM-DD HH:MM:SS"));
      formInputs.append("note_content", values.details);
      formInputs.append("note_front_show", values.note_front_show ===true ? 1 : 0);
      formInputs.append("photo", values.file);
      if(notesRow?.note_id){
        formInputs.append("id", notesRow?.note_id);
      }

      AddOrUpdateStudentNote(formInputs)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: notesRow?.note_id ? "Updated successfully" : "Added successfully",
          });
          $("#createNote").modal("hide");
          setDeleteRow(true);
          formik.resetForm();
          setFileName("");
          setFilePreview("");
          setDisabled(false)
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${err?.response?.data?.data}`,
          });
          setDisabled(false)
        });
    },
  });
  const sortingDate = (rowA, rowB) => {
    if (rowA?.note_date != null && rowB?.note_date != null && rowA?.note_date) {
      const name1 = new Date(`${rowA?.note_date.includes("-") ? moment(rowA?.note_date).format(TABLE_DATE_FORMAT) : (rowA?.note_date.length == 10) ? moment.unix(rowA?.note_date).format(TABLE_DATE_FORMAT) : moment.unix(rowA?.note_date/1000).format(TABLE_DATE_FORMAT)} ${rowA?.note_date.includes("-") ? moment(rowA?.note_date).format(TABLE_TIME_FORMAT) : (rowA?.note_date.length == 10) ? moment.unix(rowA?.note_date).format(TABLE_TIME_FORMAT) : moment.unix(rowA?.note_date/1000).format(TABLE_TIME_FORMAT)}`);
      const name2 = new Date(`${rowB?.note_date.includes("-") ? moment(rowB?.note_date).format(TABLE_DATE_FORMAT) : (rowB?.note_date.length == 10) ? moment.unix(rowB?.note_date).format(TABLE_DATE_FORMAT) : moment.unix(rowB?.note_date/1000).format(TABLE_DATE_FORMAT)} ${rowB?.note_date.includes("-") ? moment(rowB?.note_date).format(TABLE_TIME_FORMAT) : (rowB?.note_date.length == 10) ? moment.unix(rowB?.note_date).format(TABLE_TIME_FORMAT) : moment.unix(rowB?.note_date/1000).format(TABLE_TIME_FORMAT)}`);
      return name1 - name2;
    }
  };

  const columns = useMemo(() => [
    {
      name: "Number",
      selector: "note_number",
      sortable: true,
      cell: (row, index) => (
        <span className="assigned-title-itm overflow-ellipsis2 fst_line2" data-target="#createNote" data-toggle="modal"
          onClick={() => {
            setStartDate(new Date((row.note_date.includes("-") ? moment(row.note_date).format(TABLE_DATE_FORMAT) : (row.note_date.length == 10) ? moment.unix(row.note_date).format(TABLE_DATE_FORMAT) : moment.unix(row.note_date/1000).format(TABLE_DATE_FORMAT)) + " " + (row.note_date.includes("-") ? moment(row.note_date).format(TABLE_TIME_FORMAT) : (row.note_date.length == 10) ? moment.unix(row.note_date).format(TABLE_TIME_FORMAT) : moment.unix(row.note_date/1000).format(TABLE_TIME_FORMAT))));
            setNotesRow({ note_id: row.id, type: relatedCourses?.find(val=>val?.label == row?.course_name)?.value, details: row.note_content, file: row.files, note_front_show : row.note_front_show });
            formik.setFieldValue("type", relatedCourses?.find(val=>val?.label == row?.course_name)?.value);
            setFileName(row.files);
            setFilePreview(row.files?.split("/")[row.files?.split("/").length-1]);
          }}
        >
          <span title={row.note_number} className="as-text-blue curser textLimit2">
            {row.note_number ? row.note_number : "-"}
          </span>
        </span>
      ),
    },
    {
      name: "Date",
      selector: "note_date",
      sortable: true,
      sortFunction: sortingDate,
      cell: (row) => (
        <>
          {row.note_date ? (
            <>
              <p className="right-space">
                {row.note_date.includes("-") ? moment(row.note_date).format(TABLE_DATE_FORMAT) : (row.note_date.length == 10) ? moment.unix(row.note_date).format(TABLE_DATE_FORMAT) : moment.unix(row.note_date/1000).format(TABLE_DATE_FORMAT)}
              </p>
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "Submitted By",
      selector: "submitted_by",
      sortable: true,
      cell: (row, index) =>
        row.Firstname ? (
          <div className="assigned-title-block-new">
            <div className="assigned-title-itm">
              <span
                className={
                  "assigned-title-blk  name-icon cat-dark-red " +
                  ColorRender(row.role_name)
                }
              >
                {row.profile_pic ? (
                  <img
                    src={`${IMAGE_URL}/${row.profile_pic.replaceAll(
                      "/home/myaie/public_html/",
                      ""
                    )}`}
                    alt="AIE"
                  />
                ) : (
                  InitialRender([row.Firstname, row.Lastname])
                )}
                  <span className={`profile-box-2-status ${row.user_activity_status ? row.user_activity_status.toLowerCase()=="online" ? "Online" :row.user_activity_status.toLowerCase()== "away"? "Away" :"Offline":"Offline"}`}>
                    <i className="fas fa-circle"></i>
                  </span>
                <Hover
                  firstName={row.Firstname}
                  lastName={row.Lastname}
                  photo={row.profile_pic}
                  email={row.email}
                  mobile={row.mobile}
                  right={true}
                  status={row.user_status} 
                  activity_status={row.user_activity_status} 
                />
              </span>
              {/* <PermissionsGate scopes={["umedit"]} RenderError={() => <p>{row.Firstname}</p>}> */}
              <Link
                className="as-text-blue curser feature-name"
                to={`/systemAdministration/userManagement/open/${row.submit_by}/aboutYou`}
              >
                <span className="textLimit100" title={row.Firstname + " " + row.Lastname}>{row.Firstname + " " + row.Lastname}</span>
              </Link>
              {/* </PermissionsGate> */}
            </div>
          </div>
        ) : (
          <div
              className="assigned-title-itm overflow-ellipsis2 fst_line2"
              title={row.name}
            >
              <span className="textLimit2">{row.submitted_by ? row.submitted_by : "-"}</span>
            </div>
        ),
    },
    {
      name: "Details",
      selector: "note_content",
      // wrap: true,
      sortable: true,
      cell: (row) => {
        return (
          <span
            className="assigned-title-itm overflow-ellipsis2 fst_line2"
            title={row.note_content ? row.note_content : ""}
          >
            <span className="textLimit2">{row.note_content}</span>
          </span>
        );
      },
    },
    {
      name: "Related To",
      selector: "course_name",
      // wrap: true,
      sortable: true,
      cell: (row) => 
         row?.course_name 
          ? <span className="overflow-ellipsis2 fst_line2">
            <Link
              to={`/courseAdministration/coursesdetails/${row?.id_intake}/detailCourses/show`}
              title={row?.course_name}
              className="as-text-blue curser"
            >{row?.course_name}</Link>
          </span>
          : "-"      
    },
    {
      name: "Activity",
      selector: "activity",
      // wrap: true,
      sortable: true,
      cell: (row) => {
        let activity =
          row?.activity?.charAt(0).toUpperCase() + row?.activity?.slice(1);
        return <span className="overflow-ellipsis2 fst_line2">{activity ? activity.replace(/_/g, " ") : "-"}</span>;
      },
    },
    {
      name: "Attachments",
      selector: "files",
      // wrap: true,
      sortable: true,
      cell: (row) => {
        return row.files ? <span className="assigned-title-itm overflow-ellipsis2 fst_line2" title={row.files.split("/").reverse()[0]}> <span className="textLimit2">{row.files.split("/").reverse()[0]}</span></span> : "-";
      },
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            {/* <Link to={{ pathname: "/editticket/" + row.id_ticket }}> */}
            <PermissionsGate scopes={["snodelete"]} errorProps={{ disabled: true }}>
              <button
                title="Delete"
                className="btn btn-danger rounded-circle"
                onClick={() => handleDelete(row.id)}
                type={"button"}
              >
                <i className="fal fa-trash-alt"></i>
              </button>
            </PermissionsGate>
            {row.files ? (
              <a
                title="Download Attachment"
                className="btn btn-primary rounded-circle"
                target={"_blank"}
                download
                href={IMAGE_URL +
                    "/" +
                    encodeURI(row.files
                      .replaceAll("/home/myaie/public_html/", "")
                      .replace("public/", ""))
                }
              >
                <i className="fal fa-arrow-down"></i>
              </a>
            ) : (
              ""
            )}
            {/* </Link> */}
          </div>
        </div>
      ),
    },
  ], []);

  const dataToRender = () => {
    let updatedData = [];
    let allData = ticketList;
    if (search.length) {
      let temRelatedTo = allData.filter((item) => {
        let includes =
          item.id_intake && item.id_intake.toString().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempIdNumber = allData.filter((item) => {
        let includes =
          item.note_number &&
          item.note_number.toString().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempSearch = allData.filter((item) => {
        let includes =
          item.submitted_by &&
          item.submitted_by.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempStatus = allData.filter((item) => {
        let includes =
          item.note_content &&
          item.note_content.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempDateAdd = allData.filter((item) => {
        let includes =
          item.note_date &&
          moment(item.note_date)
            .format(TABLE_DATE_TIME_FORMAT)
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempDepartment = allData.filter((item) => {
        let includes =
          item.course_name &&
          item.course_name.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempAssignTo = allData.filter((item) => {
        let includes =
          item.activity &&
          item.activity
            .replace(/_/g, " ")
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [
        // ...tempId,
        ...tempIdNumber,
        ...tempSearch,
        ...temRelatedTo,
        ...tempStatus,
        ...tempDateAdd,
        ...tempDepartment,
        ...tempAssignTo,
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }
    if(courseIds.length) {
      let tempData = updatedData;
      let tempResult = tempData.filter((item)=>(courseIds.includes(item.course_name)));
      updatedData = tempResult;
    }
    return updatedData;
  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const resetFilter = () => {
    setSearch("");
    onChange([]);
    setCourseIds([])
  };

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = [
      "Number",
      "Date",
      "Submitted By",
      "Details",
      "Related To",
      "Activity",
      "Attachments"
    ];

    data = data?.map((row) => ({
      ...row,
      Number: row?.note_number !== null && row?.note_number.toString() ? row?.note_number : "-",
      Date: row?.note_date !== null ? row?.note_date.includes("-") ? moment(row?.note_date).format(TABLE_DATE_FORMAT) :(row?.note_date.length == 10) ? moment.unix(row?.note_date).format(TABLE_DATE_TIME_FORMAT) : moment.unix(row?.note_date/1000).format(TABLE_DATE_TIME_FORMAT) : "-",
      "Submitted By": row?.Firstname ? row?.Firstname + " " + row?.Lastname : "-",
      Details: row?.note_content ? row?.note_content : "-",
      "Related To": row?.course_name !== null && row?.course_name.toString() ? row?.course_name : "-",
      Activity: row?.activity !== null && row?.activity.toString() ? row?.activity : "-",
      Attachments: row?.files !== null && row?.files.toString() ? row?.files.split("/").reverse()[0] : "-",
    }));
    // console.log(typeof data);
    // console.log(typeof result);

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.forEach((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [
          row.Number,
          row.Date,
          row["Submitted By"],
          row.Details,
          row["Related To"],
          row.Activity,
          row.Attachments,
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  const handleAttachment=()=>{
    setFileName("");
    setFilePreview("");
    formik.setFieldValue("file","")
  }

  return (
    <>
      <div className="my-tickets-info-list Tickets-main-wra tickets-new-customp">
        <div className="custom-table-div filter-search-icon card card-table-custom">
          <div className="search-filter-div">
            <div className="search-filter-div-left">
              <div className="system-administration-table table-responsive">
                <div className="table-responsive-div">
                  <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                      <div id="assessment-table-main_filter" className="dataTables_filter">
                        <label>
                          <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                        </label>
                        <div className="filter-eff filter-data-btn">
                          <button className="filter-buttons">
                            <i className="fal fa-filter"></i>
                          </button>
                        </div>
                      </div>
                  </div>
                </div>
                <div className="filter-button-group">
                  <div className="assessment-table-filter mr-2">                    
                      <div className="btn-type-filter dropdown-comman assesment-dropdown">
                      <div className="dropdown multiselect">
                        <button
                          className={`btn btn-default dropdown-toggle ${value?.length ? "btn-selected" : ""
                            } `}
                          type="button"
                          id="dropdownMenu1"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="true"
                          title="Related To"
                        >
                          <span>
                            Related To <i className="fal fa-angle-down"></i>
                          </span>
                        </button>
                        <form className="dropdown-menu remove-list-style">
                          <ul aria-labelledby="dropdownMenu1">
                            <div className="dropdown-filter-pagination">
                              {relatedFilter.length ? (
                                <FilterComponent
                                  value={value}
                                  onChange={onChange}
                                  dataResponse={relatedFilter}
                                  backendResponse={setCourseIds}
                                  typeCheck = "frontend"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </ul>
                        </form>
                      </div>
                      </div>
                  </div>
                  <div className="reset-btn-group">
                    <div className="button-reset dropdown-comman">
                      <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                        <i className="fal fa-redo"></i>Reset
                      </button>
                    </div>

                    <div className="files-export-group">
                      <button
                        type="button"
                        className="btn btn-files"
                        title="Export spreadsheet"
                        onClick={() => {
                          exportData("xlsx", "Notes");
                        }}
                      >
                        <i className="fal fa-file-excel icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        title="Export CSV"
                        onClick={() => {
                          exportData("csv", "Notes");
                        }}
                      >
                        <i className="fal fa-file-csv icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        title="Export PDF"
                        onClick={() => {
                          exportData("pdf", "Notes");
                        }}
                      >
                        <i className="fal fa-file-pdf icon"></i>
                      </button>
                    </div>
                  </div>                
                </div>
              </div>
            </div>
            <div className="search-filter-div-right">
              <div className=" filter-search-bar-blk">
                <div className="add-ticket-blk button-reset dropdown-comman">
                <PermissionsGate scopes={["snoadd"]} errorProps={{ disabled: true }}>
                  <button
                    className="btn btn-primary"
                    data-target="#createNote"
                    data-toggle="modal"
                    title="Create New"
                    onClick={()=>{
                      setNotesRow({});
                      setStartDate(new Date());
                      formik.setFieldValue("type","");
                      setFileName("");
                      setFilePreview("");
                      formik.setFieldValue("file","");
                    }}
                  // onClick={() => window.open(`https://aiesuperadmin.devtechnosys.tech/addticket`)}
                  >
                    <i className="fal fa-plus"></i>Create New
                  </button>
                </PermissionsGate>
                </div>
              </div>
            </div>
            </div>
            {loading ? <SkeletonTicketList /> : <DataTable
              data={dataToRender()}
              columns={columns}
              pagination={true}
              onColumnOrderChange={(cols) => {}}
              progressPending={loading}
              defaultSortField="note_date"
              paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
            />}
        </div>
      </div>
    

      <div className="topic-add-modal modal fade" id="createNote" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <form onSubmit={formik.handleSubmit}>
              <div className="modal-body p-0">
                <div className="modal-header modal-header-custom">
                  <h5 className="modal-title">
                  <i className="fal fa-plus"></i> Add Note
                  </h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                </div>
                <div className="t p-30px">
                  <div className="topic-add-modal-content">
                    <div className="row center-label">
                      <div className="col-md-6 col-lg-12">
                        <div className="form-group-blk mb-3" title="Date">
                          <label htmlFor="StudentNumber">Date</label>
                          <DatePicker
                            disabled
                            selected={startDate}
                            dateFormat={FORM_DATE_TIME_FORMAT}
                            timeFormat={FORM_TIME_FORMAT}
                            onChange={(date) => {
                              setStartDate(date);
                            }}
                            className="form-control w-100"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-12">
                        <div className="form-group-blk mb-3" title="Related Course">
                          <label>Related Course *</label>
                          <Select
                            className={"form-control custom-select-box" + (formik.errors.type && formik.touched.type ? " is-invalid" : "")}
                            name="Related Course"
                            value={relatedCourses?.filter(val=>val.value === formik.values.type)}
                            onChange={(value) => {
                              if (value) {
                                formik.setFieldValue("type", value.value);
                              } else {
                                formik.setFieldValue("type", "");
                              }                              
                            }}
                            onBlur={formik.handleBlur}
                            options={relatedCourses}
                            maxMenuHeight={175}
                            placeholder={"Select Related Course"}
                            isClearable
                          />

                        </div>
                      </div>
                      <div className="col-md-6 col-lg-12">
                        <div className="form-group-blk mb-3">
                          <label>Attachment </label>
                          <div className="form-group atttach-file m-0">
                            <label className={formik.errors.file && formik.touched.file && "file-req is-invalid"}>
                              <i className="fal fa-paperclip"></i>
                              <span>Add Attachment</span>
                              <input
                                type="file"
                                className="form-control  form-control-aatch-file"
                                onChange={(e) => {
                                  if (e.target.files.length) {
                                    setFilePreview(e.target.files[0].name);
                                    formik.setFieldValue("file", e.target.files[0]);
                                    setFileName(URL.createObjectURL(e.target.files[0]));
                                  }
                                }}
                              />
                            </label>
                          </div>
                          {fileName && (
                            <>
                              <div className="frm-group">
                                <ul className="list-unstyled attached-file-ul m-0">
                                  <li>
                                    <a
                                      title={filePreview}
                                      href={
                                        fileName.includes("blob")
                                          ? fileName
                                          : `${IMAGE_URL}/${encodeURI(fileName.replace(
                                              "/public",
                                              ""
                                            ).replace("home/myaie_html/",""))}`
                                      }
                                      target="_blank"
                                    >
                                      <i className="fal fa-file"></i>
                                      &nbsp;
                                      {filePreview}
                                    </a>
                                    <button
                                      className="btn btn-danger rounded-circle btn-dropdown-item"
                                      title="Delete"
                                      onClick={() => handleAttachment()}
                                    >
                                      <i className="fal fa-trash-alt"></i>
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-12">
                        <div className="form-icon-group">
                          <div className="custom-control custom-checkbox text-left">
                            <input
                              style={{cursor:'pointer'}}
                              type="checkbox"
                              className="custom-control-input"
                              id="note_front_show"
                              name="note_front_show"
                              checked={formik.values.note_front_show}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="note_front_show"
                            >
                              Visible to Student
                            </label>
                          </div>
                        </div>                        
                      </div>
                      <div className="col-md-6 col-lg-12">
                        <div className="form-group-blk mb-3">
                          <label>Details *</label>
                          <textarea
                              cols={10}
                              type="text"
                              id="details"
                              className={"form-control" + (formik.errors.details && formik.touched.details ? " is-invalid" : "")}
                              name="details"
                              placeholder="Enter Details"
                              value={formik.values.details}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              title="Details"
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group form-group-save-cancel mt-3">
                    <div className="form-group-save-cancel">
                      <>
                        <button type="submit" title="Save" className="btn btn-save btn-success" disabled={disabled}>
                          {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                          Save
                        </button>
                        <button type="button" title="Cancel" className="btn btn-close btn-danger" data-dismiss="modal"
                          onClick={() => {
                            formik.resetForm();
                            setFileName("");
                            setFilePreview("");
                          }
                          }>
                          <i className="fal fa-times"></i>
                          Cancel
                        </button>
                      </>
                      {formik.touched.type && formik.errors.type ? <div className="invalid-feedback d-block">{formik.errors.type}</div> : null}
                      {formik.touched.file && formik.errors.file ? <div className="invalid-feedback d-block">{formik.errors.file}</div> : null}
                      {formik.touched.details && formik.errors.details ? <div className="invalid-feedback d-block">{formik.errors.details}</div> : null}
                    </div>
                  </div>
                </div>
              </div>
               
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notes;

import React, { useEffect, useRef, useState } from 'react'
import { RenderQuizTopic } from '../../utils/CommonStatusItems';

const ExpendableCell = ({ keywords, cell, filterData }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const contentRef = useRef(null);

    const wordSpliterTopics = (str) => {
        let htmlOutput = []
        const strArr = Array.isArray(str.split(",")) ? str.split(",") : [];
        const newStrArr = strArr.length ? strArr?.map(item => {
            filterData.topic.map((val)=>{
            if(val.value == item){
                htmlOutput.push(RenderQuizTopic(val.label,val.color).html)
            }
            return;
            });   
        }) : "-";
        return htmlOutput.length ? htmlOutput : []
    }

    const wordSpliter = (str, type) => {
       // const strArr = str?.toString()?.split(",");

        if (!str || str.trim() === '') {
            return [];
        }
        
        const strArr = Array.isArray(JSON.parse(str)) ? JSON.parse(str) : [];
        const newStrArr = strArr.length ? strArr.map((item, index) => {      
          return item ? <p className="as-widget mr-1 mt-1" key={index} style={{color: '#333333', background: '#e6e6e6'}}>{item}</p> : '-';
        }) : "-";
        return newStrArr;
    }
    
    const keywordElements = cell == "Keywords" ? wordSpliter(keywords) : wordSpliterTopics(keywords);

    useEffect(() => {
      const handleResize = () => {
        if (contentRef.current) {
          const element = contentRef.current;
          setIsOverflowing(element.scrollHeight > element.clientHeight);
        }
      };
  
      handleResize();
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [keywords]);
  
    return (
      <>
        <div
          ref={contentRef}
          className="d-flex flex-wrap"
          style={{
            overflow: 'hidden',
            maxHeight: isExpanded ? 'none' : '4em',
          }}
        >
            {keywordElements}
        </div>
        {isOverflowing &&
          <div
            onClick={() => setIsExpanded(!isExpanded)}
            className='ellipsis-icon'
            style={{ marginTop: 3, cursor: 'pointer' , display: 'inline-block'}}
          >
            <i className={`fal fa-ellipsis-h-alt`}></i>
          </div>
        }
      </>
    );
  };

export default ExpendableCell
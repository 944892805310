import React, { useEffect, useRef } from "react";

const DisplayCardOffLine = ({
  name,
  campusName,
  location,
  displayGroupName,
  displayGroupId,
}) => {
  const parseRichText = (text) => {
    // Replace *text* with <b>text</b>
    let formattedText = text.replace(/\*(.*?)\*/g, '<b>$1</b>');
  
    // Replace _text_ with <u>text</u>
    formattedText = formattedText.replace(/\_(.*?)\_/g, '<u>$1</u>');
  
    return formattedText;
  };
  const marqueeRef = useRef();

  // Map displayGroupId to a color (use consistent colors for identical IDs)
  const getColorFromId = (id) => {
    const colors = [
        "#FFC107", // Amber
        "#03A9F4", // Light Blue
        "#4CAF50", // Green
        "#E91E63", // Pink
        "#9C27B0", // Purple
        "#FF5722", // Deep Orange
        "#009688", // Teal
        "#3F51B5", // Indigo
        "#FF9800", // Orange
        "#2196F3", // Blue
        "#8BC34A", // Light Green
        "#FFEB3B", // Yellow
        "#673AB7", // Deep Purple
        "#795548", // Brown
        "#607D8B", // Blue Grey
        "#00BCD4", // Cyan
        "#9E9E9E", // Grey
        "#CDDC39", // Lime
        "#FF4081", // Pink Accent
        "#00BFAE", // Light Teal
      ];
      
    return colors[id % colors.length]; // Cycle through predefined colors
  };

  
  const displayGroupColor = getColorFromId(displayGroupId);  
  return (
    <div className="display-card">
      <div className="video-container">
          <div className="no-video">OFFLINE</div>
      </div>
      <div className='display-card-status-group-container'>
      <div className={"displaycard as-red-bg-smooth"}>
        <i className="fas fa-circle mr-1"></i>{"OFFLINE"}
      </div>
      <div
        className="display-group-name"
        style={{ backgroundColor: displayGroupColor }}
      >
        {displayGroupName}
      </div>
      </div>
      <div className="display-name">{name}</div>
      <div className="display-card-campus">
        <i className="fa fa-university mr-1"></i>{campusName}, {location}
      </div>  
    </div>
  );
};

export default DisplayCardOffLine;

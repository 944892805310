import React, { useRef, useEffect, useState } from 'react';
import SignaturePad from 'signature_pad';
import { IMAGE_URL } from '../../utils/Constants';
import { removeSignature } from '../../services/CourseService';

const SignaturePadComponent = ({signaturePadRef, formik, setShowIp, showIp, setIsSig, readOnly, isCancel, readOnlyData, is_Signature, setIs_Signature}) => {
    const canvasRef = useRef(null);
    const containerRef = useRef(null);
    const [isEmpty, setIsEmpty] = useState(true);
    const [erasorDisplay, setErasorDisplay] = useState(true);
    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);
    const [tool, setTool] = useState('pencil');
    const [isDisabled, setIsDisabled] = useState(false);

    // Function to resize canvas
    const resizeCanvas = () => {
        const canvas = canvasRef.current;
        const container = containerRef.current;
        if (!canvas || !container) return;

        // Store the signature data before resizing
        const data = signaturePadRef.current?.toData();

        // Set canvas width to match container width
        const ratio = Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = container.offsetWidth;
        canvas.height = 200; // Keep fixed height or adjust as needed
        canvas.style.width = `${container.offsetWidth}px`;
        canvas.style.height = '200px';

        // Scale canvas for better resolution on high DPI displays
        const context = canvas.getContext('2d');
        context.scale(ratio, ratio);

        // Restore the signature data after resizing
        if (signaturePadRef.current && data) {
            signaturePadRef.current.fromData(data);
        }
    };

    useEffect(() => {
        if(is_Signature){
            setShowIp(true);
        }
    },[is_Signature]);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            const signaturePad = new SignaturePad(canvas, {
                backgroundColor: 'rgb(255, 255, 255)',
                penColor: 'rgb(0, 0, 0)'
            });
            signaturePadRef.current = signaturePad;

            // Initial resize
            resizeCanvas();

            // Add resize event listener
            window.addEventListener('resize', resizeCanvas);

            const checkEmpty = () => {
                const currentData = signaturePad.toData();
                setUndoStack(currentData);      
                setRedoStack([]); // Clear redo stack when new stroke is added
                setIsEmpty(signaturePad.isEmpty());
            };
            signaturePad.addEventListener('endStroke', checkEmpty);

            return () => {
                window.removeEventListener('resize', resizeCanvas);
                signaturePad.removeEventListener('endStroke', checkEmpty);
                signaturePad.clear();
                const canvas = canvasRef.current;
                if (canvas && canvas.parentElement) {
                    const existingOverlay = canvas.parentElement.querySelector('.signature-overlay');
                    if (existingOverlay) {
                        canvas.parentElement.removeChild(existingOverlay);
                    }
                }
            };
        }
    }, [is_Signature]);

    useEffect(() => {
        if (signaturePadRef.current) {
            if (isDisabled) {
                signaturePadRef.current.off();
                const canvas = canvasRef.current;
                const overlay = document.createElement('div');
                overlay.style.position = 'absolute';
                overlay.style.top = '0';
                overlay.style.left = '0';
                overlay.style.right = '40px';
                overlay.style.bottom = '40px';
                overlay.style.cursor = 'not-allowed';
                overlay.style.zIndex = '1000';
                overlay.className = 'signature-overlay';
                canvas.parentElement.style.position = 'relative';
                canvas.parentElement.appendChild(overlay);

                return () => {
                    const canvas = canvasRef.current;
                    if (canvas && canvas.parentElement) {
                        const existingOverlay = canvas.parentElement.querySelector('.signature-overlay');
                        if (existingOverlay) {
                            canvas.parentElement.removeChild(existingOverlay);
                        }
                    }
                };
            } else {
                signaturePadRef.current.on();
                const canvas = canvasRef.current;
                const existingOverlay = canvas.parentElement.querySelector('.signature-overlay');
                if (existingOverlay) {
                    canvas.parentElement.removeChild(existingOverlay);
                }
            }
        }
    }, [isDisabled]);

    useEffect(() => {
        if(isCancel) {
            handleCancel();
        }
    }, [isCancel]);

    const clearSignature = () => {
        signaturePadRef.current.clear();
        setIsEmpty(true);
        setUndoStack([]);
        setRedoStack([]);
    };

    const base64ToFile = (dataUrl) => {
        const arr = dataUrl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], `signature.${mime.split('/')[1]}`, { type: mime });
    };

    const saveSignature = () => {
        if (signaturePadRef.current.isEmpty()) {
            alert("Please provide a signature first.");
        } else {
            const dataURL = signaturePadRef.current.toDataURL('image/png', 1.0);
            const binary = base64ToFile(dataURL);
            setShowIp(true);
            setIsDisabled(true);
            formik.setFieldValue('student_signature', binary);
            setIsSig(binary);
        }
    };
    
    const handleCancel = () => {
        setShowIp(false);
        setIsDisabled(false);
        formik.setFieldValue('student_signature', null);
        setIsSig(null);
        signaturePadRef.current.clear();
        setIsEmpty(true);
        setUndoStack([]);
        setRedoStack([]);   
        setIs_Signature(false);

        if(readOnlyData?.ass_type == 13 || readOnlyData?.ass_type == 14){
            removeSignature({
                submission_id: readOnlyData?.id,
                lecturer_signature_type: ""
            })
        }
    };

    const erase = () => {
        signaturePadRef.current.penColor = 'rgb(255, 255, 255)';
        setErasorDisplay(false);
        setTool('eraser');
    };

    const draw = () => {
        signaturePadRef.current.penColor = 'rgb(0, 0, 0)';
        setErasorDisplay(true);
        setTool('pencil');
    };

    const undo = () => {
        setShowIp(false);
        const data = signaturePadRef.current.toData();
        if (data.length > 0) {
            const lastStroke = data[data.length - 1];
            const newData = data.slice(0, -1);
            
            signaturePadRef.current.clear();
            signaturePadRef.current.fromData(newData);
            
            setUndoStack(newData);
            setRedoStack(prevStack => [...prevStack, lastStroke]);
            setIsEmpty(signaturePadRef.current.isEmpty());
        }
    };

    const redo = () => {
        if (redoStack.length > 0) {
            const strokeToRedo = redoStack[redoStack.length - 1];
            const currentData = signaturePadRef.current.toData();
            const newData = [...currentData, strokeToRedo];
            
            signaturePadRef.current.clear();
            signaturePadRef.current.fromData(newData);
            
            setUndoStack(newData);
            setRedoStack(prevStack => prevStack.slice(0, -1));
            setIsEmpty(false);
        }
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        if(!is_Signature){
            if (isDisabled) {
                canvas.style.cursor = 'not-allowed';
            } else {
                if (tool === 'pencil') {
                    canvas.style.cursor = 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\' viewBox=\'0 0 24 24\' fill=\'none\' stroke=\'currentColor\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\'%3E%3Cline x1=\'18\' y1=\'2\' x2=\'22\' y2=\'6\'%3E%3C/line%3E%3Cpath d=\'M7.5 20.5 19 9l-4-4L3.5 16.5 2 22z\'%3E%3C/path%3E%3C/svg%3E") 0 24, auto';
                } else if (tool === 'eraser') {
                    canvas.style.cursor = 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\' viewBox=\'0 0 24 24\' fill=\'none\' stroke=\'currentColor\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\'%3E%3Cpath d=\'m7 21-4.3-4.3c-1-1-1-2.5 0-3.4l9.6-9.6c1-1 2.5-1 3.4 0l5.6 5.6c1 1 1 2.5 0 3.4L13 21\'%3E%3C/path%3E%3Cpath d=\'M22 21H7\'%3E%3C/path%3E%3Cpath d=\'m5 11 9 9\'%3E%3C/path%3E%3C/svg%3E") 0 24, auto';
                }
            }
        }
    }, [tool, isDisabled, is_Signature]);

    return (
        <div className="max-w-md position-relative signature-pad-outer">
            <div className="px-md-30 border border-gray-300 rounded-lg overflow-hidden" ref={containerRef}>
                {
                is_Signature ? <img src={`${IMAGE_URL}/${readOnlyData?.lecturer_Signature?.replace("public/", "")}`} alt={`${readOnlyData?.lecturer_Signature?.replace("public/Library/Courses/", "")}`} style={{ width: '100%', height: '200px' , cursor: 'not-allowed' }} /> 
                    :
                    <canvas
                        ref={canvasRef}
                        style={{ width: '100%', height: '200px' }}
                    />
                }
                <hr className='mt-0'/>
                <div className="my-2 left-0 right-0 text-center position-relative text-gray-400">
                    Sign Above 
                </div>
                <div className={`position-absolute bg-white p-1 p-sm-0 d-flex`} style={{top: '10px', right: '10px', gap: '10px'}}>
                    <i role='button' onClick={undo} className={`fal fa-undo ${showIp ? 'd-none' : 'd-flex'}`}></i>
                    <i role='button' onClick={redo} className={`fal fa-redo ${showIp ? 'd-none' : 'd-flex'}`}></i>
                    {erasorDisplay ? 
                        <i role='button' onClick={erase} className={`fal fa-eraser ${showIp ? 'd-none' : 'd-flex'}`}></i>
                        :
                        <i role='button' className={`fal fa-pencil ${showIp ? 'd-none' : 'd-flex'}`} onClick={draw}></i>   
                    }
                </div>
            </div>
            <div className={`sig-btns ${readOnly ? 'd-none' : 'd-flex'} my-3 flex-column flex-sm-row gap-2`}>
                <button className='btn btn-primary mr-3' disabled={showIp} onClick={saveSignature} type='button'>Save Signature</button>
                <button className={`btn btn-danger`} style={{cursor: !showIp ? 'not-allowed' : 'pointer'}} onClick={handleCancel} disabled={!showIp} type='button'>Remove Signature</button>
            </div>
        </div>
    );
};

export default SignaturePadComponent;
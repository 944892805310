import React from 'react';
import HtmlParser from 'react-html-parser';
import { VideoIframe } from './VideoIframe';

export class ShowMoreHide extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      showMore: false,
      showLess: false,
    };
  }

  handleClickOutside(event) {
    if(this.ref.current && (this.state.showLess || this.state.showMore)){
      if (!this.ref.current.contains(event.target)) {
        this.props.onClickOutside && this.props.onClickOutside();
        this.setState({ showMore: true });
        this.setState({ showLess: false });
  
      }
      if (this.ref.current.contains(event.target)) {
        this.props.onClickOutside && this.props.onClickOutside();
        this.setState({ showMore: false });
        this.setState({ showLess: true });
      }
    }
  }

  componentDidMount() {
    this.setupVideoListeners();
    if (this.props.show && (this.props.show.length > 280 || this.props.attachInfo)) {
      this.setState({ showMore: true });
    }
    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  setupVideoListeners() {
    const videos = document.querySelectorAll("video");
    const handlePlay = (video) => {
      videos.forEach((otherVideo) => {
        if (otherVideo !== video) {
          otherVideo.pause();
        }
      });

      document.querySelectorAll('iframe').forEach(otherIframe => {
        try {
          otherIframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
        } catch (err) {
          console.warn('Failed to pause video:', err);
        }
      });
    };

    videos.forEach((video) => {
      video.addEventListener("play", () => handlePlay(video));
    });

    return () => {
      videos.forEach((video) => {
        video.removeEventListener("play", () => handlePlay(video));
      });
    };
  }

  transformHtml = (node, index) => {
    if (node.type === 'tag' && node.name === 'iframe') {
      return <VideoIframe node={node} index={index} key={index} />;
    }
    return undefined;
  };

  render() {
    if (!this.props.show) return null;

    return (
      <>
        <div ref={this.ref}>
          <div className={`discuss-text ${this.state.showMore ? "show-more-div " : " " + this.props.class ? this.props.class : ""}`} >
            {HtmlParser(this.props.show, { transform: this.transformHtml })}
            {this.props && this.props.attachInfo ? this.props.attachInfo : ""}
          </div>
          <div>
            {this.state.showMore ? (
              <div
                onClick={() =>
                  this.setState({ showMore: false, showLess: true })
                }
                className="show-more-txt"
              >
                See more
              </div>
            ) : null}

            {this.state.showLess ? (
              <div
                onClick={() =>
                  this.setState({ showMore: true, showLess: false })
                }
                className="show-more-txt"
              >
                See less
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

export default ShowMoreHide;
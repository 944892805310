import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import DataTable from "react-data-table-component";
import SkeletonTicketList from "../../../../../loaders/SkeletonTicketList";
import { LongAndShortAns, QuizQuestion, UpdateSubmissionQuestionScore, ViewaQuestion } from "../../../../../services/SmartAssessmentService";
import Str from "../../../../common/Str";
import { TrimText } from "../../../../common/TrimText";
import { ColorCode } from "../../../../systemadministration/usermanagement/CheckRole";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import QuizBar from "./QuizBar";
import "jspdf-autotable";
import JsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { Link } from "react-router-dom";
import { secToMin } from "../../../../common/SecnodsToMinutes";
import hasPermission from "../../../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import { useHistory,useLocation } from "react-router";
import { IMAGE_URL, STUDENT_FILE_DOWNLOAD, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE, downloadURL } from "../../../../../utils/Constants";
import HtmlParser from "react-html-parser";
import { formatQuestion } from "../../../../../utils/FormatQuestion";
import Tablefilter from "../../../../common/Tablefilter";
import { RenderLevelStatus, RenderQuizResult } from "../../../../../utils/CommonStatusItems";
import { setAssessmentId, setIntakeId, setQuizId } from "../../../../../store/actions";
import { useDispatch } from "react-redux";
import DisplayQuestion from "../../DisplayQuestion";
import $ from "jquery";
import { EditorState, convertFromRaw } from "draft-js";
import { tryParseJSONObject } from "../../../../../utils/TryParseJSONObject";
import { MathJax } from "better-react-mathjax";
import { RenderQuestionType } from "../../../../../utils/CommonGroupingItem";
import Swal from "sweetalert2";
import JSZip from "jszip";
import axios from "axios";
import { saveAs } from "file-saver";
import { handleTableScroll, twoDecimalRound } from "../../../../../utils/commonFunction";

const typeArr = [
  {
    "value": "short answer",
    "label": "short answer"
  },
  {
    "value": "multiple choice single answer",
    "label": "multiple choice single answer"
  },
  {
    "value": "match the column",
    "label": "match the column"
  },
  {
    "value": "choice matrix",
    "label": "choice matrix"
  },
  {
    "value": "fill in the blank",
    "label": "fill in the blank"
  },
  {
    "value": "order list",
    "label": "order list"
  },
  {
    "value": "multiple choice multiple answer",
    "label": "multiple choice multiple answer"
  },
  {
    "value": "document upload",
    "label": "document upload"
  }
]

const levelArr = [
  {
    label: "Easy",
    value: "Easy"
  },
  {
    label: "Medium",
    value: "Medium"
  },
  {
    label: "Hard",
    value: "Hard"
  }
]

const resultArr = [
  {
    label: "Correct",
    value: "1"
  },
  {
    label: "Incorrect",
    value: "0"
  },
  {
    label: "Partially Correct",
    value: "0.5"
  }
]

const SubmissionDetails = ({ setSubmissionName, subtab, submissionName }) => {
  const { id, subId } = useParams();
  const history = useHistory()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [gearLoading, setGearLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [type, setType] = useState({arr: [], checkObj: {}});
  const [level, setLevel] = useState({arr: [], checkObj: {}});
  const [searchLevelCheck, setSearchLevelCheck] = useState({});
  const [quizName, setQuizName] = useState("")
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const [result, setResult] = useState({arr: [], checkObj: {}})
  const [graphData, setGraphData] = useState({
    timeArray: [],
    questionArray: [],
  });
  const [selectedQ, setSelectedQ] = useState();
  const [selectedAns, setselectedAns] = useState([]);
  const [checkedAns, setCheckedAns] = useState([]);
  const [editorState1, setEditorState1] = React.useState(""); // question
  const [editorState2, setEditorState2] = React.useState(""); // description
  const [editorState3, setEditorState3] = React.useState({}); // options array
  const [possibleAnswers, setPossibleAnswers] = useState([]);
  const [isApiLoaded, setIsApiLoaded] = useState(false);
  const [questionData, setQuestionData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [scoreGear, setScoreGear] = useState("");  
  const [resultGear, setResultGear] = useState("");  
  const [individualScore, setIndividualScore] = useState([]);
  const [updateList, setUpdateList] = useState(false);
  const [isGradeLocked, setIsGradeLocked] = useState(0);
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const location = useLocation()

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    let response = hasPermission({ scopes: ["casubmissionresultview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }
  }, [])

  const getListData = () =>{
    const data = new FormData();
    data.append("rid", id);
    data.append("uid", subId.toString().includes('?') ? subId.split('?')[0] : subId);
    LongAndShortAns(data)
    .then((res) => {
      setData(res?.data?.dataAns?.map((item,ind)=>({...item,result:res?.data?.scoreArray[ind],avgTimeTaken:res?.data?.timeArray[0].split(",")[ind], totalScoreIndividual: res?.data?.total_score_individual[ind]})))
      setIndividualScore(res?.data?.scoreArray)
      setGraphData(res?.data)
      setIsGradeLocked(res?.data?.lock_grades?.lock_grades)
    })
    .catch((err) => {
      console.log("error", err)
    }).finally(()=>{
      setScoreGear("");
      setGearLoading(true);
    })
  }

  useEffect(() => {
    setLoading(true)  
    const data = new FormData();
    data.append("rid", id);
    data.append("uid", subId.toString().includes('?') ? subId.split('?')[0] : subId);
    QuizQuestion(data)
      .then((result) => {
        LongAndShortAns(data)
        .then((res) => {
          setData(res?.data?.dataAns?.map((item,ind)=>({...item,result:res?.data?.scoreArray[ind],avgTimeTaken:res?.data?.timeArray[0].split(",")[ind], totalScoreIndividual: res?.data?.total_score_individual[ind]})))
          setIndividualScore(res?.data?.scoreArray)

          setGraphData(res?.data)
          setIsGradeLocked(res?.data?.lock_grades?.lock_grades)
          setLoading(false)
          setSubmissionName(`${result?.data[0]?.quiz_name} - ${res?.data?.user[0]?.first_name} ${res?.data?.user[0]?.last_name} - Attempt ${res?.data?.SrID}`)
        })
        .catch((err) => {
          setLoading(false)
          console.log("error", err)
        })
        // setSubmissionName(result?.data[0]?.quiz_name);
        setDetails(result?.data[0])
        dispatch(setQuizId(result?.data[0]?.quid))
      })
      .catch((err) => {
        console.log("error :", err)
      })
  }, [updateList])


  const handleScoreUpdate = (e, rowId, maxValue, item) =>{
    let scr = e.target.value ? e.target.value : "a";
    setScoreGear(rowId);
    setGearLoading(true);
    if(parseInt(scr) > parseInt(maxValue)){
      setErrorMessage("Sorry!! you can't put score more then question score");
      setScoreGear("");
      setGearLoading(true);      
      return ;
    }else{
      setErrorMessage("");
    }
    const indexOfData = data.findIndex((item)=>item.id==rowId);
    let individualScoreArray = [...individualScore];
    individualScoreArray[indexOfData] = scr
    const sendData = {
      rid:id,
      score_individual:individualScoreArray.join(","),
      updated_qid:rowId
    }
    UpdateSubmissionQuestionScore(sendData).then((res)=>{
      if(res.status===200){        
        // setUpdateList(!updateList)
        getListData();
      }
    }).catch((err)=>{ 
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `${err?.response?.data?.data}`,
      });
      console.log("error in update", err);

    }).finally(()=>{
      // setScoreGear("");
      // setGearLoading(true);
    })
  }

  const handleResultUpdate = (e, rowId, maxValue, item) =>{
    let scr = e.target.value ? (maxValue/100)*(e.target.value) : "a";
    console.log(scr)
    setResultGear(rowId);
    if(parseInt(scr) > parseInt(maxValue)){
      setErrorMessage("Sorry!! you can't put value more then 100");      
      return 0;
    }
    const indexOfData = data.findIndex((item)=>item.id==rowId);
    let individualScoreArray = [...individualScore];
    individualScoreArray[indexOfData] = scr
    const sendData = {
      rid:id,
      score_individual:individualScoreArray.join(","),
      updated_qid:rowId
    }
    setLoading(true);
    UpdateSubmissionQuestionScore(sendData).then((res)=>{
      if(res.status===200){
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Updated successfully",
        });
        setUpdateList(!updateList)
      }
    }).catch((err)=>{ 
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `${err?.response?.data?.data}`,
      });
      console.log("error in update", err);

    }).finally(()=>{
      setResultGear("")
    })
  }

  const dataToRender = () => {
    let updatedData = [];
    let allData = data;
    if (search.length) {
      let tempname = allData.filter((item) => {
        let includes = item.questionName && item.questionName.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [...tempname];
      let unique = [...new Set(data)];
      updatedData = unique;
    } else {
      updatedData = allData;
    }
    if (type.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = type.arr.find(function (post, index) {
          if (post.toString() === item.questionType.toLowerCase() ? item.questionType.toString() : "") return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }
    if (level.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = level.arr.find(function (post, index) {
          if (post.toString() === item.level_name ? item.level_name.toString() : "") return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }
    if (result.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = result.arr.find(function (post, index) {
          if (post.toString() === "0.5" && Number(item.result/item?.score) > 0 && Number(item.result/item?.score) < 1) return item;
          if (post.toString() === "0" && Number(item.result/item?.score) == 0) return item;
          if (post.toString() === "1" && Number(item.result/item?.score) == 1) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }
    if (search.length || status.length || type.length || level.arr || result.arr) {
      return updatedData;
    } else {
      return data;
    }
  };

  const handleViewQuestionDetails = (ID,answerData) => {
    setModalLoading(true)
  ViewaQuestion({ qId: ID, isDuplicateSearched: 1 }).then((res) => {
    // setData(res?.data);
    setSelectedQ(res?.data[0]?.question_type)
    if (res?.data[0]?.question_type === "Short Answer") {
      if (res.data[0].q_option !== null) {
        setPossibleAnswers(res.data[0].q_option.split("~@~"))
      }
    }
    // set options array
    if (res?.data[0]?.question_type === "Multiple Choice Single Answer" || res?.data[0]?.question_type === "Multiple Choice Multiple Answer") {
      let obj = {};
      let arr1 = res.data.map((element, ind) => {
        let check = tryParseJSONObject(element.q_option ? element.q_option : "");
        if (check) {
          const q_optionBlock = convertFromRaw(JSON.parse(element.q_option ? element.q_option : ""));
          return (EditorState.createWithContent(q_optionBlock));
        } else {
          obj['customCheck' + ind] = element.q_option;
          return element.q_option;
        }
      });
      setEditorState3(obj);
      let option = []
      if (res?.data[0]?.question_type === "Multiple Choice Single Answer" || res?.data[0]?.question_type === "Multiple Choice Multiple Answer") {
        option = Object.keys(obj).map((key) => {
          return { q_option: obj[key].blocks ? JSON.stringify(obj[key]) : HtmlParser(obj[key]), q_option_match: "", oid: Math.floor(Math.random() * 10) + 1 }
        })
      }      
      setQuestionData({ question_type: res?.data[0]?.question_type, question: res.data[0].question, description: res.data[0].description, options: res.data, answerData: answerData ? answerData : {} })
    }else{
      setQuestionData({ question_type: res?.data[0]?.question_type, question: res.data[0].question, description: res.data[0].description, options: res.data, answerData: answerData ? answerData : {} })
    } 
    let arr = res?.data?.map((o) => o.score ? true : false);
    setCheckedAns(arr);

    let index = arr.indexOf(true);
    setselectedAns(index.toString());

    // set question and description
    if (res.data[0]) {
      setEditorState1(res.data[0].question);
      setEditorState2(res.data[0].description);
    }
    setIsApiLoaded(true)
    setModalLoading(false)
  });
  }

  const handleDocumentDownload = (submittedFiles) =>{
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
          Swal.showLoading();
      },
    });
    const zip = new JSZip();
    try{
      if(Array.isArray(submittedFiles) && submittedFiles.length){
        let p = Promise.all(submittedFiles.map(async (item, index)=>{
          if(item.docs && item.docs!="" && item.docs!=null){
            const fileName = item.docs.split("/").reverse()[0];
            const fileURL = downloadURL + `${item.docs.includes("s3.af-south-1.amazonaws.com") ? "" : STUDENT_FILE_DOWNLOAD+"/"}${encodeURI(item.docs.replaceAll(
              "/home/myaie/public_html/","").replace("public/",""))}`;
            try{
              const getFile = await axios({
                url : fileURL,
                method : 'get',
                responseType : 'blob',              
              })
              zip.file(`${fileName}`, getFile.data, {binary:true});
            }catch(errrr){
              console.log(errrr)
              Swal.close();
              return;    
            }

          }
        }))
        p.then(()=>{
          zip.generateAsync({type : 'blob'}).then((content)=>{
            saveAs(content, `submitted_file_ques_upload` + ".zip");
          }).catch((err1)=>{console.log(err1)})
        }).catch((err)=>{
          console.log(err)
        })
        
      }
    }catch(err){
      console.log(err)  
    }finally{
      Swal.close()
    }
  }

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "question_name",
      sortable: true,
      // minWidth: "190px",
      cell: (row) => (
        <span className="as-text-blue curser feature-name"  title={row.question_name}   onClick={() => {
          $("#previewQuestion").modal('show');
          handleViewQuestionDetails(row?.id,row)
        }}><span className="textLimit100">{row.question_name ? HtmlParser(row.question_name) : "-"}</span>
        </span>
      ),
    },
    {
      name: "Question",
      selector: "questionName",
      sortable: true,
      cell: (row) => (
        <>
        <span className="feature-name" title={row.questionName}><MathJax><span className="textLimit100">{row.questionName ? HtmlParser(row.questionName) : "-"}</span></MathJax>
        </span>
        </>
      ),
    },
    {
      name: "Question Type",
      selector: "question_type",
      cell: (row) => (<> {row.questionType ? RenderQuestionType(row.questionType.trim()).html : "-"} </>)
    },
    {
      name: "Question Level",
      selector: "submission_status",
      cell: (row, index) => (
         <span className="feature-name" title={row.level_name}><span className="textLimit100">{row.level_name ? ColorCode(row.level_name.toLowerCase()) : "-"}</span></span>
        // <p>{row.level ? row.level : "-"}</p>
      ),
    },
    {
      name: "Submitted Answer",
      selector: "UserAnswer",
      cell: (row) => (row.questionType==="Document Upload" ? 
        (row.answerdDocument.length ? <p data-toggle="modal" data-target="#downloadAssessment" onClick={() => handleDocumentDownload(row.answerdDocument)} className="as-text-blue curser">
          Download Submission
        </p> : "-")
        :<div className="overflow-ellipsis2 fst_line2" title={formatQuestion(row?.UserAnswer)}>
          <span className="textLimit100">
            {row?.UserAnswer ? HtmlParser(row?.UserAnswer) : "-"}
          </span>
        </div>
      )
    },
    {
      name: "Correct Answer",
      selector: "correctAnswer",
      cell: (row) => {
        return (row?.correctAnswer ? <div className="overflow-ellipsis2 fst_line2" title={formatQuestion(row?.correctAnswer)}><MathJax><span className="textLimit100">{HtmlParser(row?.correctAnswer)}</span></MathJax></div> : "-")
      }
    },
    {
      name: "Score",
      selector: "score",
      cell: (row) => (row.questionType!=="Document Upload" && row.questionType!=="Long Answer") ? (row?.result && 
        (row?.totalScoreIndividual || row?.score) ? 
        `${twoDecimalRound(row?.result)}/${row?.totalScoreIndividual ? 
          twoDecimalRound(row?.totalScoreIndividual) : 
          twoDecimalRound(row?.score)}` : "-")
        :(<div className="input-submission-col resultstatus_flex arrowhide">
          <input
            className={"form-control" + (errorMessage && scoreGear == row.id ? " is-invalid" : "")}
            type="number"
            max={row.totalScoreIndividual}
            defaultValue={row?.result && row.showValue==1 ? twoDecimalRound(row?.result) : ""}
            onBlur={(e) => handleScoreUpdate(e, row.id, row.totalScoreIndividual, row)}
            disabled={isGradeLocked == 1}
            />
            <span>{"/" + row.totalScoreIndividual}</span>
            {(gearLoading==true && scoreGear == row.id) ? <i className="fas fa-cog fa-spin"></i> : ""}
        </div>)
      // cell: (row) => (row?.result && 
      //   (row?.totalScoreIndividual || row?.score) ? 
      //   `${Number(row?.result).toFixed(row?.result.toString().split(".")[1]?.length > 2 ? 2 : row?.result.toString().split(".")[1]?.length)}/${row?.totalScoreIndividual ? 
      //     Number(row?.totalScoreIndividual).toFixed(row?.totalScoreIndividual.toString().split(".")[1]?.length > 2 ? 2 : row?.totalScoreIndividual.toString().split(".")[1]?.length) : 
      //     Number(row?.score).toFixed(row?.score.toString().split(".")[1]?.length > 2 ? 2 : row?.score.toString().split(".")[1]?.length)}` : "-")
    },
    {
      name: "Time Spent",
      selector: "taken_time",
      cell: (row) => (row?.avgTimeTaken ? (secToMin(row?.avgTimeTaken)) : "-")
    },
   
    {
      name: "Result",
      selector: "attemptedstatus",
      // cell: (row) => (row.attemptedstatus ? row.attemptedstatus == "Incorrect" ? <span className={"cat cat-red"}>
      //   <i className="fas fa-circle mr-1"></i> Incorrect
      // </span> : <span className={"cat cat-greenhex"}>
      //   <i className="fas fa-circle mr-1"></i> Correct
      // </span> : "-")
      cell: (row) => (row.showValue==0 ? RenderQuizResult("notmarked").html : (row?.totalScoreIndividual ? row?.totalScoreIndividual == 0 : row?.score == 0)  ? 
        RenderQuizResult("0").html  : (row?.totalScoreIndividual ? row.result/row?.totalScoreIndividual != 0 : row.result/row?.score != 0 ) ? 
        RenderQuizResult((row.result/row?.totalScoreIndividual).toString()).html : 
        parseInt(row.result) == (row?.totalScoreIndividual ? row?.totalScoreIndividual : row?.score) ? 
        RenderQuizResult("1").html : RenderQuizResult("0").html),
      // cell: (row) => (
      //   (row?.totalScoreIndividual ? row?.totalScoreIndividual == 0 : row?.score == 0)  ? RenderQuizResult("0").html : row?.score == 0 ? RenderQuizResult("0").html : (row?.questionType == "Multiple Choice Multiple Answer" || row?.questionType == "Match the Column") && (row?.totalScoreIndividual ? row.result/row?.totalScoreIndividual != 0 : row.result/row?.score != 0 ) ? RenderQuizResult((row.result/row?.totalScoreIndividual).toString()).html : parseInt(row.result) == (row?.totalScoreIndividual ? row?.totalScoreIndividual : row?.score) ? RenderQuizResult("1").html : RenderQuizResult("0").html
      //  ),
 },
    {
      name: "Actions",
      maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
            {/* <Link
              className="btn btn-primary rounded-circle"
              title="Open"
              to={`/courseAdministration/smartAssessment/questionBank/question/view/${row?.id}`} 
            >
              <i className="fal fa-folder-open"></i>
            </Link> */}
            <button
                  className="btn btn-primary rounded-circle"
                  data-toggle="modal"
                  // data-target="#previewQuestion"
                  title="View Question"
                  onClick={() => {
                    $("#previewQuestion").modal('show');
                    handleViewQuestionDetails(row?.id,row)
                  }}
                >
                  <i className="fal fa-eye"></i>
                </button>
          </div>
        </div>
      ),
    },
  ],[data, individualScore, scoreGear, errorMessage, isGradeLocked]);

  const customCell = (cell, row) => {
    const typesetCell = () => {
      MathJax.Hub.Queue(['Typeset', MathJax.Hub, cell]);
    };
  
    // Call typesetCell after MathJax has been fully initialized
    MathJax.Hub.Register.StartupHook('End', typesetCell);
  
    return (
      <div dangerouslySetInnerHTML={{ __html: cell }} />
    );
  };

  const handleSearch = (e) => {    
    setSearch(e.target.value);    
  };

  const resetFilter = () => {
    setSearch("");
    setType({arr: [], checkObj: {}});
    setLevel({arr: [], checkObj: {}});
    setResult({arr:[], checkObj: {}});
    setSearchLevelCheck({});
  };

  const exportData = async (fileType, fileName) => {
    let data = dataToRender();

    const header = ["Name", "Question", "Question Type", "Question Level", "Submitted Answer", "Correct Answer", "Score", "Time Spent", "Result"];

    data = data?.map((row) => ({
      ...row,
      Name: row.question_name ? row.question_name : "-",
      Question: row?.questionName ? row?.questionName : "-",
      ["Question Type"]: row?.questionType ? row?.questionType : "-",
      ["Question Level"]: row?.level_name ? row?.level_name : "-",
      ["Submitted Answer"]: row?.UserAnswer ? formatQuestion(row?.UserAnswer) : "-",
      ["Correct Answer"]: row?.correctAnswer ? formatQuestion(row?.correctAnswer) : "-",
      Score: (row?.result &&  (row?.totalScoreIndividual || row?.score) ? `${twoDecimalRound(row?.result)}/${row?.totalScoreIndividual ? twoDecimalRound(row?.totalScoreIndividual) : twoDecimalRound(row?.score)}` : "-"),
      ["Time Spent"]: row?.avgTimeTaken ? secToMin(row?.avgTimeTaken) : "-",
      ["Result"]: (row.showValue==0 ? RenderQuizResult("notmarked").text : (row?.totalScoreIndividual ? row?.totalScoreIndividual == 0 : row?.score == 0)  ? 
      RenderQuizResult("0").text  : (row?.totalScoreIndividual ? row.result/row?.totalScoreIndividual != 0 : row.result/row?.score != 0 ) ? 
      RenderQuizResult((row.result/row?.totalScoreIndividual).toString()).text : 
      parseInt(row.result) == (row?.totalScoreIndividual ? row?.totalScoreIndividual : row?.score) ? 
      RenderQuizResult("1").text : RenderQuizResult("0").text),
    }));

    
    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row["Name"], row["Question"], row["Question Type"], row["Question Level"], row["Submitted Answer"], row["Correct Answer"], row["Score"], row["Time Spent"], row["Result"]];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        columnStyles: {
          0: { columnWidth: 15 },
          1: { columnWidth: 45 },
          2: { columnWidth: 15 },
          3: { columnWidth: 10 },
          4: { columnWidth: 30 },
          5: { columnWidth: 30 },
          6: { columnWidth: 10 },
          7: { columnWidth: 10 },
          8: { columnWidth: 15 },
        },
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  return (
    <>
    {
        subtab !== "submissionquestionresult" &&
    <div className="tabs-wrap">
      <div className="card-new card card-profile-info-card mb-30">
        <div class="">
          {
            subtab === "submissiondetails" && 
            <>
          {/* <div className="my-tickets-info-list Tickets-main-wrap">
            <div className="edit-icon new-card-header">
              <div className="card-header">Details</div>
            </div>
          </div> */}
          {/* <div class="form-group">
            <label>
              <strong>
                {details && details.first_name} {details && details.second_name}{" "}
                {details && details.last_name}
              </strong>
            </label>
          </div> */}
          {/* {detailsLoading ? <SkeletonTicketList /> :
            <div className="learning-quiz-wrap">
              <div class="table-responsive">
                <table class="table table-bordered table-striped table-custom-info mb-0">
                  <tbody>
                    <tr>
                      <th>Score</th>
                      <td>
                        {details && details.score_obtained ? details.score_obtained : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Time Spent (Approx.)</th>
                      <td>
                        {details && details.total_time ? secToMin(details.total_time) : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Attempt Time</th>
                      <td>
                        {details && details.start_time ? moment.unix(details.start_time).format(TABLE_DATE_TIME_FORMAT) : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Percentages</th>
                      <td>
                        {details && details.percentage_obtained
                          ? ~~details.percentage_obtained + "%"
                          : "0%"}
                      </td>
                    </tr>
                    <tr>
                      <th>Status</th>
                      <td>{details && details.result_status ? (details.result_status).charAt(0).toUpperCase() + details.result_status.slice(1) : "-"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          } */}
          </>}
          {/* <hr /> */}
          
          {/* <div class="card-header">
          Time spent
        </div> */}
          {/* <hr /> */}
          {
            subtab === "submissiontimespent" && 
          <>
          {/* <div className="my-tickets-info-list Tickets-main-wrap">
            <div className="edit-icon new-card-header">
              <div className="card-header">Time Spent</div>
            </div>
          </div> */}
          <div class="quiz-graph">
            {loading ? <SkeletonTicketList /> : graphData && graphData.timeArray.length > 0 ? <QuizBar
              timeArr={graphData.timeArray[0].split(",")}
              questionArray={graphData.questionArray}
              scoreArray={graphData.scoreArray}
              dataAns={graphData.dataAns}
            /> : <DataTable data={[]} columns={[]} noDataComponent={Str.noRecord} paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE} />}
            {/* {(!loading && graphData && graphData.timeArray.length > 0) ? (
              <QuizBar
                timeArr={graphData.timeArray[0].split(",")}
                questionArray={graphData.questionArray}
                scoreArray={graphData.scoreArray}
                dataAns={graphData.dataAns}
              />
            ) : <SkeletonTicketList />} */}
          </div>
          </>}
          {/* <div className="my-tickets-info-list Tickets-main-wrap">
            <div className="edit-icon new-card-header">
              <div className="card-header">Time Spent</div>
            </div>
          </div>
          <div className="custom-table-div filter-search-icon card card-table-custom course-table-filter-sec">
            <div className="row">
              <div className="col-md-4">
                <div className="graphs-info-items">
                  <ColumnChart
                    data={attendanceSummery}
                    title={{
                      visible: true,
                      text: "Attendance Summery",
                    }}
                    xAxis={{
                      label: {
                        visible: true,
                        autoRotate: true,
                      },
                      title: {
                        visible: true,
                        text: "Question #",
                        style: {
                          fontSize: 14,
                          fill: "black",
                          lineWidth: 4,
                          fontWeight: 600,
                        },
                      },
                    }}
                    yAxis={{
                      label: {
                        visible: true,
                        autoRotate: true,
                      },
                      title: {
                        visible: true,
                        text: "Time",
                        style: {
                          fontSize: 14,
                          fill: "black",
                          lineWidth: 4,
                          fontWeight: 600,
                        },
                      },
                    }}
                    autoFit
                    padding="auto"
                    xField="type"
                    yField="Students"
                    meta={{
                      type: {
                        alias: "category",
                      },
                      Students: {
                        alias: "Students",
                      },
                    }}
                  /></div></div></div>
          </div> */}
        </div>
      </div>
    </div>
}
      {
        subtab === "submissionquestionresult" &&
        <>
          {/* <div className="my-tickets-info-list Tickets-main-wrap">
            <div className="edit-icon new-card-header">
              <div className="card-header">Questions Results</div>
            </div>
          </div> */}
          <div className="my-tickets-info-list Tickets-main-wrap filter-scrolable">
            <div className="custom-table-div filter-search-icon card card-table-custom">
              <div className="search-filter-div">
                <div className="search-filter-div-left">
                  <div className="system-administration-table table-responsive">
                    <div className="table-responsive-div">
                      <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                        <div id="assessment-table-main_filter" className="dataTables_filter">
                          <label>
                            <input type="search" placeholder="Search" aria-controls="assessment-table-main" onChange={(e) => handleSearch(e)} value={search} />
                          </label>
                          <div className="filter-eff filter-data-btn">
                            <button className="filter-buttons">
                              <i className="fal fa-filter"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="filter-button-group">
                      <div className="filter-scroll">
                        <div className={`filter-scroll-inner  filter-custom-new`}>                          
                          <Tablefilter
                          filterName={"Type"}
                          optionArr={typeArr}
                          state={type}
                          setState={setType}
                          renderLabelFunction={RenderQuestionType}
                          />
                          <Tablefilter
                          filterName={"Level"}
                          optionArr={levelArr}
                          state={level}
                          setState={setLevel}
                          renderLabelFunction={RenderLevelStatus}
                          />
                          <Tablefilter
                          filterName={"Result"}
                          optionArr={resultArr}
                          state={result}
                          setState={setResult}
                          renderLabelFunction={RenderQuizResult}
                          />
                        </div>
                      </div>
                      <div className="reset-btn-group">
                      <div className="button-reset dropdown-comman">
                        <button className="btn btn-primary" title="Reset" onClick={resetFilter}>
                          <i className="fal fa-redo"></i>Reset
                        </button>
                      </div>
                      <div className="files-export-group">
                        <button type="button" className="btn btn-files" title="Export spreadsheet" onClick={() => { exportData("xlsx", "Submissions"); }} >
                          <i className="fal fa-file-excel icon"></i>
                        </button>
                        <button type="button" className="btn btn-files" title="Export CSV" onClick={() => { exportData("csv", "Submissions"); }} >
                          <i className="fal fa-file-csv icon"></i>
                        </button>
                        <button type="button" className="btn btn-files" title="Export PDF" onClick={() => { exportData("pdf", "Submissions"); }}>
                          <i className="fal fa-file-pdf icon"></i>
                        </button>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             
              <DataTable
                data={dataToRender()}
                defaultSortField="question_name"
                defaultSortAsc={true}
                columns={columns}
                pagination={true}
                noDataComponent={Str.noRecord}
                paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                progressComponent={<SkeletonTicketList />}
                progressPending={loading}
                customCell={customCell}
              />
              
            </div>
          </div>
        </>}
        <div
                className="topic-add-modal modal fade"
                id="previewQuestion"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered modal-lg"
                  role="document"
                >

                  <div className="modal-content modal-border-update">
                    <div className="modal-body p-0">

                      <div className="modal-header modal-header-custom">
                        <h5 className="modal-title">
                          View Question
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            // formik.resetForm()
                            $("#previewQuestion").modal("hide");
                            setQuestionData("")
                            // setEditorState1("");
                            // setShowCkEditor(false);

                            // formik.setFieldValue("type", "");
                          }}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>

                      <div className="t p-30px">
                        {modalLoading ? <SkeletonTicketList/> : <DisplayQuestion question={questionData} from="viewAnswer" />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    </>
  );
};

export default React.memo(SubmissionDetails);

import React, { useEffect, useRef } from 'react';
import { Editor } from 'react-draft-wysiwyg';
const EditorComponent = ({ editable, editorState, onEditorStateChange, handlePastedFiles, feedStateChange, suggestions }) => {
  const containerRef = useRef(null);
  // Helper function to handle embedded content
  const embed = (url) => {
    // Basic URL validation
    if (!url) return '';
    // Handle YouTube URLs
    if (url.match(/youtube\.com|youtu\.be/)) {
      const videoId = url.match(/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/)?.[1];
      if (videoId) {
        return `<iframe width="560" height="315" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe>`;
      }
    }
    // Handle Vimeo URLs
    if (url.match(/vimeo\.com/)) {
      const videoId = url.match(/vimeo\.com\/(?:video\/)?([0-9]+)/)?.[1];
      if (videoId) {
        return `<iframe width="560" height="315" src="https://player.vimeo.com/video/${videoId}" frameborder="0" allowfullscreen></iframe>`;
      }
    }
    // Default fallback for other URLs
    return `<iframe src="${url}" frameborder="0" allowfullscreen></iframe>`;
  };
  useEffect(() => {
    // When editor becomes hidden, find and pause all media elements
    if (!editable && containerRef.current) {
      const mediaElements = containerRef.current.querySelectorAll('video, iframe');
      mediaElements.forEach(media => {
        // For HTML5 video elements
        if (media.tagName === 'VIDEO' && !media.paused) {
          media.pause();
        }
        
        // For iframes (like YouTube), remove src and store it
        if (media.tagName === 'IFRAME') {
          media.setAttribute('data-src', media.src);
          media.setAttribute('src', '');
        }
      });
    }
    // When editor becomes visible again, restore media elements
    if (editable && containerRef.current) {
      const mediaElements = containerRef.current.querySelectorAll('iframe[data-src]');
      mediaElements.forEach(media => {
        media.setAttribute('src', media.getAttribute('data-src'));
      });
    }
  }, [editable]);
  if (!editable) {
    return null;
  }
  return (
    <div ref={containerRef}>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        handlePastedFiles={handlePastedFiles}
        onChange={feedStateChange}
        mention={{
          separator: ' ',
          trigger: '@',
          suggestions: suggestions,
        }}
        toolbar={{
          options: ['inline', 'textAlign', 'list', 'embedded'],
          inline: {
            options: ['bold', 'italic', 'underline'],
            italic: { className: 'i-italic-icon' },
            bold: { className: 'i-bold-icon' },
            underline: { className: 'i-underline-icon' },
          },
          list: {
            options: ['unordered', 'ordered'],
            unordered: { className: 'i-unordered-icon' },
            ordered: { className: 'i-ordered-icon' },
          },
          textAlign: {
            options: ['left', 'center', 'right', 'justify'],
            left: { className: 'i-left-icon' },
            center: { className: 'i-center-icon' },
            right: { className: 'i-right-icon' },
            justify: { className: 'i-justify-icon' },
          },
          embedded: {
            className: 'i-embedded-icon',
            embedCallback: link => {
              const detectedSrc = /<iframe.*? src="(.*?)"/.exec(embed(link));
              return (detectedSrc && detectedSrc[1]) || link;
            }
          }
        }}
      />
    </div>
  );
};
export default EditorComponent;
import React, { useEffect, useRef, useState } from 'react';
import UploadSignatureFile from './UploadSignatureFile';
import moment from "moment";
import SignaturePadComponent from './SignaturePadComponent';
import { addSignature } from '../../services/CourseService';

const DigitalSignatureComponent = ({signaturePadRef,fileInputRef,formik,readOnly,readOnlyData,setIsSig,ipAddress,setIpAddress,cDate,setCDate,isCancel,is_Signature,setIs_Signature,signature_Type,isSig}) => {
  const [signatureType, setSignatureType] = useState(0);
  const [fetchDateNIp, setFetchDateNIp] = useState(false);
  
  useEffect(() => {
    if(fetchDateNIp && !readOnly){
      fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        if(!is_Signature){
          setIpAddress(data.ip);
          formik.setFieldValue('ip', data.ip)
        } else {
          setIpAddress(readOnlyData?.lecturer_Signature_Ip)
          formik.setFieldValue('ip', readOnlyData?.lecturer_Signature_Ip)
        }
      })
      .catch(error => {
        console.error('Error fetching IP address:', error);
      });
      if (!is_Signature){
        setCDate(moment(new Date().getTime()).format('DD MMM YYYY, HH:mm'))
        formik.setFieldValue('signature_date',new Date().getTime())
      } else {
        setCDate(readOnlyData?.lecturer_Signature_Date && readOnlyData?.lecturer_Signature_Date != 0 ? moment(parseInt(readOnlyData?.lecturer_Signature_Date*1000)).format('DD MMM YYYY, HH:mm') : null)
        formik.setFieldValue('signature_date',readOnlyData?.lecturer_Signature_Date)
      }
    }else if(!readOnly){
      setIpAddress('')
      setCDate('')
    }
  },[fetchDateNIp , is_Signature])

  useEffect(() => {
    if(readOnly){
      setSignatureType(1)
    }
  },[readOnly])

  const handleUpload = () => {
    formik.setFieldValue("lecturer_signature_type", 1)
    setSignatureType(1)
  }

  const handleDraw = () => {
    formik.setFieldValue("lecturer_signature_type", 0)
    setSignatureType(0)
  }

  useEffect(() => {
    if((readOnlyData?.ass_type == 13 || readOnlyData?.ass_type == 14) && isSig && ipAddress && cDate){

      const formData = new FormData();

      formData.append('submission_id', readOnlyData?.id);
      formData.append('lecturer_Signature_Ip', ipAddress);
      formData.append('lecturer_Signature_Date', cDate ? parseInt(new Date(cDate).getTime()/1000) : null);
      formData.append('lecturer_Signature', isSig);
      formData.append('lecturer_signature_type' , signatureType)
  
      addSignature(formData)
      .then((res) => {

      })
      .catch((err) => {
          console.error(err);
      })
    }
  },[isSig , ipAddress , cDate])

  useEffect(() => {
    if(signature_Type){
      setSignatureType(signature_Type)
    }
  },[signature_Type])


  
  //************************************************* */ if there is no signature in case of old forms then no need to show signature components 
  // the below condition is for this only  *******************************
    return (
      readOnly && !readOnlyData?.lecturer_Signature ? null :
        <div className='mb-3'>
            <div className="mt-3 mb-1" title="Signature">Signature*</div>
                <div className={`${readOnly ? 'd-none' : 'd-flex'} gap-2 mb-1 py-1`}>
                  <div className="border-b-black mr-3" style={{borderBottom : signatureType == 0 ? '1px #467FCF solid' : '0',color : signatureType == 0 ? '#467FCF' : fetchDateNIp || readOnly ? 'gray' : 'black',cursor: readOnly ? "" : 'pointer'}} onClick={() => {!fetchDateNIp && !readOnly && handleDraw()}} title="Draw">
                    <i className="fal fa-signature"></i>
                    <span className="ml-1">Draw</span>
                  </div>
                  <div className="border-b-black" style={{borderBottom : signatureType == 1 ? '1px #467FCF solid' : '0',color : signatureType == 1 ? '#467FCF' : fetchDateNIp || readOnly ? 'gray' : 'black',cursor: readOnly ? "" : 'pointer'}} onClick={() => {!fetchDateNIp && !readOnly && handleUpload()}} title="Upload Image">
                    <i className="fal fa-image"></i>
                    <span className="ml-1">Upload Image </span>
                  </div>
                </div>
                {signatureType == 0 && (
                  <>
                    <SignaturePadComponent setIsSig={setIsSig} isCancel={isCancel} readOnly={readOnly} setShowIp={setFetchDateNIp} formik={formik} showIp={fetchDateNIp} signaturePadRef={signaturePadRef} readOnlyData={readOnlyData} is_Signature={is_Signature} setIs_Signature={setIs_Signature} />
                    {/* <i className="fal fa-info fa-circle"></i> */}
                  </>
                )}
                {signatureType == 1 && 
                  <UploadSignatureFile setIsSig={setIsSig} isCancel={isCancel} readOnly={readOnly} readOnlyData={readOnlyData} setShowIp={setFetchDateNIp} formik={formik} showIp={fetchDateNIp} fileInputRef={fileInputRef} is_Signature={is_Signature} setIs_Signature={setIs_Signature}  />
                }
                <div className='mt-2'>
                <span><i className='fal fa-info-circle mr-1'></i><b>IP:</b> {readOnly ? readOnlyData?.lecturer_Signature_Ip : ipAddress}</span>
                <span className='ml-2'><b>Date: </b>{(readOnly && readOnlyData?.lecturer_Signature_Date != 0 && readOnlyData?.lecturer_Signature_Date != null) ? moment(parseInt(readOnlyData?.lecturer_Signature_Date*1000))?.format('DD MMM YYYY, HH:mm') : cDate !== null ? cDate : null}</span>
                </div>
                {formik?.touched?.student_signature && formik?.errors?.student_signature ? (
                    <div className="invalid-feedback d-block">
                      {formik.errors.student_signature}
                    </div>
                  ) : null}
        </div>
    );
}
export default DigitalSignatureComponent;
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { IMAGE_URL, FORM_DATE_FORMAT } from "../../../utils/Constants";
import Select from "react-select";
import {
  CreateProgramme,
  UpdateProgramme,
  GetSchoolListCancelToken,
  GetNetsuiteProductListCancelToken,
  GetCommonContentCancelToken
} from "../../../services/ProgrammeServices";
import { setHours, setMinutes } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import hasPermission from "../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import { TrimText } from "../../common/TrimText";
import axios from "axios";
import HtmlInputEditor from "../../common/HtmlInputEditor";
import HtmlParser from "react-html-parser";
import ReactDOM from 'react-dom/server';

const ProgrammeForm = ({ setEnableProgramOverview, enableProgramOverview, programmeFunction, timeTableDoc, timeTableDocName, setTimeTableDoc, setTimeTableDocName, programmeData, refresh, setRefresh }) => {
  const { id, type } = useParams();
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  // const [programmeData, setProgrammeData] = useState();
  // const [timeTableDoc, setTimeTableDoc] = useState();
  // const [timeTableDocName, setTimeTableDocName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [openDate, setOpenDate] = useState(
    setHours(setMinutes(new Date(), 0), 9)
  );

  //DropDown Arrays Start
  const [programmeTypeArr, setProgrammeTypeArr] = useState([]);
  const [intakeNumberArr, setIntakeNumberArr] = useState([]);
  const [intakeArr, setIntakeArr] = useState([]);
  const [programmeLeadSupportArr, setProgrammeLeadSupportArr] = useState([]);
  const [studyTypeArr, setStudyTypeArr] = useState([]);
  const [crmProductArr, setCrmProductArr] = useState([]);
  const [netsuiteProductArr, setNetsuiteProductArr] = useState([]);
  const [campusArr, setCampusArr] = useState([]);
  const [termPeriodArr, setTermPeriodArr] = useState([]);
  const [documentTemplateArr, setDocumentTemplateArr] = useState([]);
  const [gradingStandardArr, setGradingStandardArr] = useState([])
  const [editorState, setEditorState] = useState("");
  const [editDataCKeditor, setEditCKeditor] = useState({
    overview: '',
  });

  //DropDown Arrays End

  const [updateData, setUpdateData] = useState(true);
  const [schoolList, setSchoolList] = useState([]);
  const givenPermsisions = useSelector((state) => state.givenPermission);

  //selected Dropdown Values

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  // const fixFilePath = (path) => {
  //   let filePath = path;
  //   let fileName = path && path != null ? path.split("/").reverse()[0] : "";

  //   if (path && path != null ? path.includes("public/") : false) {
  //     filePath = path.replace("public/", "");
  //   } else if (path && path != null ? path.includes("home/myaie/public_html/") : false) {
  //     filePath = path.replace("home/myaie/public_html/", "");
  //   } else {
  //     filePath = path;
  //   }
  //   setTimeTableDocName(fileName);
  //   setTimeTableDoc(filePath);
  // };

  useEffect(()=>{
    setEditorState(programmeData?.overview)
    setEditCKeditor({overview : programmeData?.overview})
  },[programmeData])

  const fixNetsuiteArr = (arr) => {
    let tempArr = [];
    arr.map((item) => {
      let singleItem = {
        value: item.id,
        label: item.displayname,
        departmentId: item.department,
      };
      tempArr.push(singleItem);
    });
    setNetsuiteProductArr(tempArr);
  };

  useEffect(() => {
    const cancelTokenSources = [];
    const getSchoolsList = async ()=>{
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      try {
        let res = await GetSchoolListCancelToken(source.token)
        if (res.status == 200) {
          setSchoolList(res.data.data)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }
    getSchoolsList()

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, []);
  
  useEffect(() => {
    let response = hasPermission({
      scopes: ["capview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  useEffect(() => {
    const cancelTokenSources = [];
    const getData = async () => {
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      try {
        let res = await GetNetsuiteProductListCancelToken(source.token)
        if (res.status == 200) {
          fixNetsuiteArr(res.data.data.items)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }

    getData()

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, []);

  useEffect(() => {
    const cancelTokenSources = [];
    const getData = async () => {
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      try {
        let res = await GetCommonContentCancelToken(source.token)
        if (res.status == 200) {
          let tempIntakeArr = []
          res.data.intake_year.map(year => {
            res.data.intake_number.map(number => {
              tempIntakeArr.push({ value: `${year.value}/${number.label}`, label: `${year.value}-${number.label}` })
            })
          })
          setIntakeArr(tempIntakeArr)
          setCrmProductArr(res.data.crm_product && res.data.crm_product.results);
          setDocumentTemplateArr(res.data.document_template);
          // setNetsuiteProductArr(res.data.netsuit_product);
          setProgrammeLeadSupportArr(res.data.program_lead_support);
          setProgrammeTypeArr(res.data.programs);
          setStudyTypeArr(res.data.study_type);
          setTermPeriodArr(res.data.term_period);
          setCampusArr(res.data.program_campus);
          setDocumentTemplateArr(res.data.document_template);
          setGradingStandardArr(res?.data?.grading_standards || [])
          setIsLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }

    getData()

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, []);
  // useEffect(() => {
  //   const cancelTokenSources = [];
  //   if (type == "open" || type == "duplicate") {

  //     const getProgrammeDetails = async () => {

  //       cancelTokenSources.forEach(source => {
  //         source.cancel('New request made');
  //       });

  //       const source = axios.CancelToken.source();
  //       cancelTokenSources.push(source);
  //       try {
  //         const res = await GetProgramsDetailsCancelToken(id, source.token);
  //         if (res.status === 200) {
  //           if (res.data) {
  //             setProgrammeData(res.data.programs);
  //             programmeFunction(res.data.programs);
  //             setTimeTableDoc(res.data.programs.timeTable);
  //             setTimeTableDocName(res.data.programs.timeTable)
  //           }
  //         } else {
  //           Swal.fire({
  //             icon: "Info",
  //             title: "Info",
  //             text: "No record found with this id",
  //           })
  //           history.push("/courseAdministration/Programmes/programme/show")
  //         }
  //       } catch (error) {
  //         if (!axios.isCancel(error)) {
  //           console.error(error);
  //         }
  //       }
  //     };

  //     getProgrammeDetails();
  //   }
  //   return () => {
  //     cancelTokenSources.forEach(source => {
  //       source.cancel('Component unmounted');
  //     });
  //   };
  // }, [updateData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Name: programmeData && programmeData.course ? programmeData.course : "",
      Code: programmeData && programmeData.code ? programmeData.code : "",
      is_show_overview: programmeData && programmeData.is_show_overview || 0,
      // IntakeNo:
      //   programmeData && programmeData.number ? programmeData.number : "",
      // IntakeYear: programmeData && programmeData.year ? programmeData.year : "",\
      intake: programmeData && programmeData.year && programmeData.number ? `${programmeData.year}/${programmeData.number}` : "",
      ProgrammeLead:
        programmeData && programmeData.programme_lead
          ? programmeData.programme_lead
          : "",
      ProgrammeSupport:
        programmeData && programmeData.programme_support
          ? programmeData.programme_support
          : "",
      StartDate:
        programmeData && programmeData.start
          ? new Date(programmeData.start)
          : "",
      // StartMonth: programmeData && programmeData.start ? startMonth : "",
      // StartYear: programmeData && programmeData.start ? startYear : "",
      EndDate:
        programmeData && programmeData.end ? new Date(programmeData.end) : "",
      // EndMonth: programmeData && programmeData.end ? endMonth : "",
      // EndYear: programmeData && programmeData.end ? endYear : "",
      StudyType: programmeData && programmeData.type ? programmeData.type : "",
      ProgrammeType:
        programmeData && programmeData.program_type
          ? programmeData.program_type
          : "",
        grading_standard:
        programmeData && programmeData.grading_standard
        ? programmeData.grading_standard
        : "",
      CRMProductId:
        programmeData && programmeData.product_id
          ? programmeData.product_id
          : "",
      NetsuiteProductId:
        programmeData && programmeData.netsuite_id
          ? programmeData.netsuite_id
          : "",
      netsuite_department_id:
        programmeData && programmeData.netsuite_department_id
          ? programmeData.netsuite_department_id
          : "",
      Campus: programmeData && programmeData.campus ? programmeData.campus : "",
      School:
        programmeData && programmeData.school_type
          ? programmeData.school_type
          : "",
      TermPeriod:
        programmeData && programmeData.term_periods
          ? programmeData.term_periods
          : "",
      DocumentTemplate:
        programmeData && programmeData.pdf_template_id
        ? programmeData.pdf_template_id
        : "",
        TimeTable:
        programmeData && programmeData.timeTable !== null &&programmeData.timeTable !== "null" && programmeData.timeTable ? programmeData.timeTable : "",
        editorState: programmeData && programmeData.overview ? programmeData.overview : "<p></p>",
      },
      validationSchema: Yup.object({
        Name: Yup.string()
        .trim("Name cannot include leading and trailing spaces")
        .required("Name is required"),
      Code: Yup.string()
        .max(20, "Max 20 Character Accepted")
        .trim("Code cannot include leading and trailing spaces"),
      // IntakeNo: Yup.string().required("Intake No is required"),
      // IntakeYear: Yup.string().required("Intake Year is required"),
      intake: Yup.string().required("Intake is required"),
      ProgrammeLead: Yup.string().required("Programme Lead is required"),
      // ProgrammeSupport: Yup.string().required("Programme Support is required"),
      StartDate: Yup.string().required("Start Date is required"),
      // StartMonth: Yup.string().required("Start Month is required"),
      // StartYear: Yup.string().required("Start Year is required"),
      EndDate: Yup.string().required("End Date is required"),
      // EndMonth: Yup.string().required("End Month is required"),
      // EndYear: Yup.string().required("End Year is required"),
      StudyType: Yup.string().required("Learning Method is required"),
      ProgrammeType: Yup.string().required("Programme Type is required"),
      grading_standard: Yup.string().required("Grading Standard is required"),
      // CRMProductId: Yup.string().required("CRM Product Id is required"),
      // NetsuiteProductId: Yup.string().required("Netsuite Product Id is required"),
      Campus: Yup.string().required("Campus is required"),
      School: Yup.string().required("School is required"),
      // TermPeriod: Yup.string().required("Term Period is required"),
      // DocumentTemplate: Yup.string().required("Document Template is required"),
      // TimeTable: Yup.string().required("Time Table is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      let tempFullStartDate = new Date(values?.StartDate);
      let tempStart =
        tempFullStartDate.getDate() +
        "-" +
        (tempFullStartDate.getMonth() + 1) +
        "-" +
        tempFullStartDate.getFullYear();
      let tempFullEndDate = new Date(values?.EndDate);
      let tempEnd =
        tempFullEndDate.getDate() +
        "-" +
        (tempFullEndDate.getMonth() + 1) +
        "-" +
        tempFullEndDate.getFullYear();
      if (tempFullStartDate > tempFullEndDate) {
        formik.setFieldError(
          "StartDate",
          "Start date should be less than end date"
        );
        return;
      }

      setDisabled(true);
      let formData = new FormData();
      type == "open" && formData.append("id", id);
      formData.append("course", values?.Name);
      formData.append("number", values?.intake.split("/")[1]);
      formData.append("start", tempStart);
      formData.append("end", tempEnd);
      formData.append("type", values?.StudyType);
      formData.append("campus", values?.Campus);
      formData.append("code", values?.Code);
      formData.append("year", values?.intake.split("/")[0]);
      formData.append("programme_lead", values?.ProgrammeLead);
      formData.append("programme_support", values?.ProgrammeSupport);
      formData.append("pdf_template_id", values?.DocumentTemplate);
      // formData.append("support_email", values?.EmailDomain);
      formData.append("product_id", values?.CRMProductId);
      formData.append("product_name", values?.Name);
      formData.append("school_type", values?.School);
      formData.append("timeTable", timeTableDoc);
      formData.append("term_periods", values?.TermPeriod);
      formData.append("program_type", values?.ProgrammeType);
      formData.append("netsuite_id", values?.NetsuiteProductId);
      formData.append("netsuite_department_id", values?.netsuite_department_id);
      formData.append("grading_standard", values?.grading_standard);
      formData.append("is_show_overview", values?.is_show_overview);
      formData.append("overview",editorState && editorState ? editorState : "<p></p>")
      if (type == "open") {
        UpdateProgramme(formData)
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Updated successfully",
            });
            setDisabled(false);
            setUpdateData(!updateData);
            setRefresh(!refresh)
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Failed",
              text: err.response.data[0].message,
            });
            console.log("error :", err.response);
          });
      } else {
        if (type == "duplicate") {
          formData.append("duplicateId", id)
        }
        CreateProgramme(formData)
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Added successfully",
            });
            setDisabled(false);
            history.push("/courseAdministration/Programmes/programme/show");
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Failed",
              text: err.response.data[0].message,
            });
            console.log("error :", err);
          });
      }
      // AddBrandSettingAPI(formData)
      //   .then((res) => {
      //     Swal.fire({
      //       icon: "success",
      //       title: "Success",
      //       text: "Added successfully",
      //     });
      //     // resetForm();
      //     // setImage(user);
      //     history.push(`/administration/brandSetting/addPortalSetting/${res?.data?.inserted_record}`);
      //   })
      //   .catch((err) => {
      //     console.log("error: ", err?.response?.data[0]);
      //     Swal.fire({
      //       icon: "error",
      //       title: "Error",
      //       text: `${err?.response?.data}`,
      //     });
      //     history.push("/administration/brandSetting/addNewBrand");
      //   });
    },
  });

  const modifiyOptionsForSelect = (arr) => {
    let modifiedArr = [];
    arr && arr.map((elem) => {
      modifiedArr.push({
        value: elem[0],
        label: elem[1] + " (" + elem[4] + ") " + elem[2],
        id: elem[0],
      });
    });

    return modifiedArr;
  };

  const handleTimeTable = () => {
    formik.setFieldValue("TimeTable", "");
    setTimeTableDoc("");
    setTimeTableDocName("");
  }
  
  return (
    <div className="card card-body-inr">
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Name"> Name *</label>
            <div className="form-icon-group mb-4" title="Name">
              <input
                type="text"
                className={
                  "form-control" +
                  (formik.errors.Name && formik.touched.Name
                    ? " is-invalid"
                    : "")
                }
                name="Name"
                id="Name"
                placeholder="Name"
                title="Name"
                value={formik.values.Name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Intake">Intake *</label>
            <div className="">
              <div className="form-icon-group mb-4 ">
                <Select
                  className={
                    "form-control custom-select-box " +
                    (formik.errors.intake && formik.touched.intake
                      ? " is-invalid"
                      : "")
                  }
                  name="Intake"
                  value={intakeArr.filter((val) => {
                    return val.value == formik.values.intake
                  })}
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue("intake", value.value);
                    } else {
                      formik.setFieldValue("intake", "");
                    }
                  }}
                  isClearable
                  onBlur={formik.handleBlur}
                  options={intakeArr}
                  maxMenuHeight={175}
                  placeholder={
                    formik.values.intake
                      ? formik.values.intake
                      : "Select"
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="DocumentTemplate">Document Template </label>
            <div className="">
              <div className="form-icon-group mb-4 ">
                <Select
                  className={
                    "form-control custom-select-box " +
                    (formik.errors.DocumentTemplate &&
                      formik.touched.DocumentTemplate
                      ? " is-invalid"
                      : "")
                  }
                  name="DocumentTemplate"
                  value={documentTemplateArr.filter(function (option) {
                    return option.value == formik.values.DocumentTemplate;
                  })}
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue("DocumentTemplate", value.value);
                    } else {
                      formik.setFieldValue("DocumentTemplate", "");
                    }
                  }}
                  isClearable
                  // getOptionLabel={(option) => option.title}
                  // getOptionValue={(option) => option.value}
                  onBlur={formik.handleBlur}
                  options={documentTemplateArr}
                  maxMenuHeight={175}
                  placeholder={
                    formik.values.DocumentTemplate
                      ? formik.values.DocumentTemplate
                      : "Select"
                  }
                />
                {formik.errors.DocumentTemplate &&
                  formik.touched.DocumentTemplate ? (
                  <span className="exclamation">
                    <i className="fal fa-exclamation-circle"></i>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Code">Code </label>
            <div className="form-icon-group mb-4" title="Title">
              <input
                type="text"
                className={
                  "form-control" +
                  (formik.errors.Code && formik.touched.Code
                    ? " is-invalid"
                    : "")
                }
                name="Code"
                id="Code"
                placeholder="Code"
                title="Code"
                value={formik.values.Code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="ProgrammeLead">Programme Coordinator *</label>
            <div className="">
              <div className="form-icon-group mb-4 ">
                <Select
                  className={
                    "form-control custom-select-box " +
                    (formik.errors.ProgrammeLead && formik.touched.ProgrammeLead
                      ? " is-invalid"
                      : "")
                  }
                  name="ProgrammeLead"
                  value={programmeLeadSupportArr.filter(function (option) {
                    return option.value == formik.values.ProgrammeLead;
                  })}
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue("ProgrammeLead", value.value);
                    } else {
                      formik.setFieldValue("ProgrammeLead", "");
                    }
                  }}
                  isClearable
                  onBlur={formik.handleBlur}
                  options={programmeLeadSupportArr}
                  maxMenuHeight={175}
                  placeholder={
                    formik.values.ProgrammeLead
                      ? formik.values.ProgrammeLead
                      : "Select"
                  }
                />
                {formik.errors.ProgrammeLead && formik.touched.ProgrammeLead ? (
                  <span className="exclamation">
                    <i className="fal fa-exclamation-circle"></i>
                  </span>
                ) : null}
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4">
            <label htmlFor="ProgrammeSupport">Programme Support</label>
            <div className="">
              <div className="form-icon-group mb-4 ">
                <Select
                  className={
                    "form-control custom-select-box " +
                    (formik.errors.ProgrammeSupport &&
                      formik.touched.ProgrammeSupport
                      ? " is-invalid"
                      : "")
                  }
                  name="ProgrammeSupport"
                  value={programmeLeadSupportArr.filter(function (option) {
                    return option.value == formik.values.ProgrammeSupport;
                  })}
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue("ProgrammeSupport", value.value);
                    } else {
                      formik.setFieldValue("ProgrammeSupport", "");
                    }
                  }}
                  isClearable
                  // getOptionLabel={(option) => option.title}
                  // getOptionValue={(option) => option.value}
                  onBlur={formik.handleBlur}
                  options={programmeLeadSupportArr}
                  maxMenuHeight={175}
                  placeholder={
                    formik.values.ProgrammeSupport
                      ? formik.values.ProgrammeSupport
                      : "Select"
                  }
                />
                {formik.errors.ProgrammeSupport &&
                  formik.touched.ProgrammeSupport ? (
                  <span className="exclamation">
                    <i className="fal fa-exclamation-circle"></i>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label>Start Date *</label>
            <div className="form-group-blk mb-3">
              <DatePicker
                selected={formik.values.StartDate}
                onChange={(date) => {
                  formik.setFieldValue("StartDate", date);
                  setOpenDate(date);
                }}
                // showTimeSelect
                filterTime={filterPassedTime}
                dateFormat={FORM_DATE_FORMAT}
                // className="form-control"
                className={
                  "form-control cursor-pointer" +
                  (formik.errors.StartDate && formik.touched.StartDate
                    ? " is-invalid"
                    : "")
                }
                title="Start Date"
                onBlur={formik.handleBlur}
                placeholderText="Select Date"
                minDate={new Date()}
                onChangeRaw={(e) => e.preventDefault()}
              />
            </div>
            {formik.errors.StartDate && formik.touched.StartDate ? (
              <span className="exclamation">
                <i className="fal fa-exclamation-circle"></i>
              </span>
            ) : null}
          </div>
          <div className="col-md-6 col-lg-4">
            <label>End Date *</label>
            <div className="form-group-blk mb-3">
              <DatePicker
                selected={formik.values.EndDate}
                onChange={(date) => {
                  formik.setFieldValue("EndDate", date);
                }}
                // showTimeSelect
                filterTime={filterPassedTime}
                dateFormat={FORM_DATE_FORMAT}
                // className="form-control"
                className={
                  "form-control cursor-pointer" +
                  (formik.errors.EndDate && formik.touched.EndDate
                    ? " is-invalid"
                    : "")
                }
                title="End Date"
                onBlur={formik.handleBlur}
                placeholderText="Select Date"
                disabled={!formik.values.StartDate}
                minDate={formik.values.StartDate}
                onChangeRaw={(e) => e.preventDefault()}
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="StudyType">Learning Method *</label>
            <div className="">
              <div className="form-icon-group mb-4 ">
                <Select
                  className={
                    "form-control custom-select-box " +
                    (formik.errors.StudyType && formik.touched.StudyType
                      ? " is-invalid"
                      : "")
                  }
                  name="StudyType"
                  // value={formik.values.StudyType || "Learning Method"}
                  value={studyTypeArr.filter(function (option) {
                    return option.value == formik.values.StudyType;
                  })}
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue("StudyType", value.value);
                    } else {
                      formik.setFieldValue("StudyType", "");
                    }
                  }}
                  // getOptionLabel={(option) => option.title}
                  // getOptionValue={(option) => option.value}
                  onBlur={formik.handleBlur}
                  options={studyTypeArr}
                  maxMenuHeight={175}
                  placeholder={
                    formik.values.StudyType ? formik.values.StudyType : "Select"
                  }
                  isClearable
                />
                {formik.errors.StudyType && formik.touched.StudyType ? (
                  <span className="exclamation">
                    <i className="fal fa-exclamation-circle"></i>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="ProgrammeType">Programme Type *</label>
            <div className="">
              <div className="form-icon-group mb-4 ">
                <Select
                  className={
                    "form-control custom-select-box " +
                    (formik.errors.ProgrammeType && formik.touched.ProgrammeType
                      ? " is-invalid"
                      : "")
                  }
                  name="ProgrammeType"
                  // value={formik.values.ProgrammeType || "Programme Type"}
                  value={programmeTypeArr.filter(function (option) {
                    return option.value == formik.values.ProgrammeType;
                  })}
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue("ProgrammeType", value.value);
                    } else {
                      formik.setFieldValue("ProgrammeType", "");
                    }
                  }}
                  isClearable
                  // getOptionLabel={(option) => option.title}
                  // getOptionValue={(option) => option.value}
                  onBlur={formik.handleBlur}
                  options={programmeTypeArr}
                  maxMenuHeight={175}
                  placeholder={
                    formik.values.ProgrammeType
                      ? formik.values.ProgrammeType
                      : "Select"
                  }
                />
                {formik.errors.ProgrammeType && formik.touched.ProgrammeType ? (
                  <span className="exclamation">
                    <i className="fal fa-exclamation-circle"></i>
                  </span>
                ) : null}
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4">
            <label htmlFor="gradingStandard" title="Grading Standard">Grading Standard *
            <i className="fal fa-info-circle ml-1" title={"The grading standard determines how grades are structured for the programme"}></i>
            </label>
            <div className="">
              <div className="form-icon-group mb-4 ">
              <Select
              className={
                "form-control custom-select-box " +
                (formik.errors.grading_standard && formik.touched.grading_standard
                  ? " is-invalid"
                  : "")
                }
                name="gradingStandard"
                value={gradingStandardArr?.filter(function (option) {
                  return option.value == formik.values?.grading_standard;
                })}
                onChange={(value) => {
                  if (value && !programmeData?.assigned_courses) {
                    formik.setFieldValue("grading_standard", value?.value);
                  } else if (programmeData?.assigned_courses) {
                    Swal.fire({
                      icon: "warning",
                      title: "Warning",
                      text: `Changing the grade type on this programme is not recommended due to the programme being linked to courses that are assigned to other ${formik.values?.grading_standard == 2 ? "Grade Mix" : "Default" } type programmes.`,
                      confirmButtonText: 'Confirm',
                      cancelButtonText: 'Cancel',
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      showCancelButton: true,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        // User clicked confirm
                        formik.setFieldValue("grading_standard", value?.value);
                      }
                    });
                  } else {
                    formik.setFieldValue("grading_standard", "");
                  }
                }}
                isClearable
                onBlur={formik.handleBlur}
                options={gradingStandardArr}
                maxMenuHeight={175}
                placeholder={
                  formik.values.grading_standard
                  ? formik.values.grading_standard
                  : "Select"
                }
                />
                {formik.errors.grading_standard && formik.touched.grading_standard ? (
                  <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                  </span>
                  ) : null}
                  </div>
                </div>
          </div>

          <div className="col-md-6 col-lg-4">
            <label htmlFor="CRMProductId">CRM Product</label>
            <div className="">
              <div className="form-icon-group mb-4 ">
                <Select
                  className={
                    "form-control custom-select-box " +
                    (formik.errors.CRMProductId && formik.touched.CRMProductId
                      ? " is-invalid"
                      : "")
                  }
                  name="CRMProductId"
                  // value={formik.values.CRMProductId || "CRM Product Id"}
                  value={modifiyOptionsForSelect(crmProductArr).filter(
                    function (option) {
                      return option.id == formik.values.CRMProductId;
                    }
                  )}
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue("CRMProductId", value.value);
                    } else {
                      formik.setFieldValue("CRMProductId", "");
                    }
                  }}
                  isClearable
                  onBlur={formik.handleBlur}
                  options={
                    crmProductArr ? modifiyOptionsForSelect(crmProductArr) : []
                  }
                  maxMenuHeight={175}
                  placeholder={
                    formik.values.CRMProductId
                      ? formik.values.CRMProductId
                      : "Select"
                  }
                />
                {formik.errors.CRMProductId && formik.touched.CRMProductId ? (
                  <span className="exclamation">
                    <i className="fal fa-exclamation-circle"></i>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="NetsuiteProductId">Netsuite Product</label>
            <div className="">
              <div className="form-icon-group mb-4 ">
                <Select
                  className={
                    "form-control custom-select-box " +
                    (formik.errors.NetsuiteProductId &&
                      formik.touched.NetsuiteProductId
                      ? " is-invalid"
                      : "")
                  }
                  name="NetsuiteProductId"
                  // value={formik.values.NetsuiteProductId || "Netsuite Product Id"}
                  value={netsuiteProductArr.filter(function (option) {
                    return option.value == formik.values.NetsuiteProductId;
                  })}
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue("NetsuiteProductId", value.value);
                      formik.setFieldValue(
                        "netsuite_department_id",
                        value.departmentId
                      );
                    } else {
                      formik.setFieldValue("NetsuiteProductId", "");
                      formik.setFieldValue(
                        "netsuite_department_id",
                        ""
                      );
                    }

                  }}
                  isClearable
                  onBlur={formik.handleBlur}
                  options={netsuiteProductArr}
                  maxMenuHeight={175}
                  placeholder={
                    formik.values.NetsuiteProductId
                      ? formik.values.NetsuiteProductId
                      : "Select"
                  }
                />
                {formik.errors.NetsuiteProductId &&
                  formik.touched.NetsuiteProductId ? (
                  <span className="exclamation">
                    <i className="fal fa-exclamation-circle"></i>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Campus">Campus *</label>
            <div className="">
              <div className="form-icon-group mb-4 ">
                <Select
                  className={
                    "form-control custom-select-box " +
                    (formik.errors.Campus && formik.touched.Campus
                      ? " is-invalid"
                      : "")
                  }
                  name="Campus"
                  value={campusArr.filter(function (option) {
                    return option.value == formik.values.Campus;
                  })}
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue("Campus", value.value);
                    } else {
                      formik.setFieldValue("Campus", "");
                    }
                  }}
                  isClearable
                  onBlur={formik.handleBlur}
                  options={campusArr}
                  maxMenuHeight={175}
                  placeholder={
                    formik.values.Campus ? formik.values.Campus : "Select"
                  }
                />
                {formik.errors.Campus && formik.touched.Campus ? (
                  <span className="exclamation">
                    <i className="fal fa-exclamation-circle"></i>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="School">School *</label>
            <div className="">
              <div className="form-icon-group mb-4 ">
                <Select
                  className={"form-control custom-select-box " + (formik.errors.School && formik.touched.School ? " is-invalid" : "")}
                  name="School"
                  value={schoolList.filter((item) => {
                    return item.value == formik.values.School;
                  })}
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue("School", value.value);
                    } else {
                      formik.setFieldValue("School", "");
                    }
                  }}
                  isClearable
                  onBlur={formik.handleBlur}
                  options={schoolList}
                  maxMenuHeight={175}
                  placeholder={formik.values.School ? formik.values.School : "Select"}
                />
                {formik.errors.School && formik.touched.School ? (
                  <span className="exclamation">
                    <i className="fal fa-exclamation-circle"></i>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="TermPeriod">Term Period </label>
            <div className="">
              <div className="form-icon-group mb-4 ">
                <Select
                  className={
                    "form-control custom-select-box " +
                    (formik.errors.TermPeriod && formik.touched.TermPeriod
                      ? " is-invalid"
                      : "")
                  }
                  name="TermPeriod"
                  value={termPeriodArr.filter(function (option) {
                    return option.value == formik.values.TermPeriod;
                  })}
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue("TermPeriod", value.value);
                    } else {
                      formik.setFieldValue("TermPeriod", "");
                    }
                  }}
                  isClearable
                  // getOptionLabel={(option) => option.title}
                  // getOptionValue={(option) => option.value}
                  onBlur={formik.handleBlur}
                  options={termPeriodArr}
                  maxMenuHeight={175}
                  placeholder={
                    formik.values.TermPeriod
                      ? formik.values.TermPeriod
                      : "Select"
                  }
                />
                {formik.errors.TermPeriod && formik.touched.TermPeriod ? (
                  <span className="exclamation">
                    <i className="fal fa-exclamation-circle"></i>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <label htmlFor="IndexLogo" style={{ marginBottom: 13 }}>
              Time Table
            </label>
            <div className="form-group atttach-file">
              <label
                className={
                  formik.errors.TimeTable &&
                  formik.touched.TimeTable &&
                  " file-req is-invalid"
                }
              >
                <i className="fal fa-paperclip"></i>
                <span>Add Attachment</span>
                <input
                  type="file"
                  id="input"
                  className="form-control  form-control-aatch-file"
                  name="file"
                  title="Time Table"
                  onChange={(e) => {
                    if (e.target.files.length) {
                      formik.setFieldValue("TimeTable", e.target.files[0]);
                      setTimeTableDoc(e.target.files[0]);
                      setTimeTableDocName(URL.createObjectURL(e.target.files[0]));
                    }
                  }}
                />
              </label>
            </div>
            {timeTableDocName && timeTableDocName !== "undefined" && (
              <>
                <div className="frm-group">
                  <ul className="list-unstyled attached-file-ul m-0">
                    <li>

                      <a
                        title={timeTableDocName}
                        href={
                          timeTableDocName.includes("blob")
                            ? timeTableDocName
                            : `${IMAGE_URL}/${encodeURI(timeTableDocName.replaceAll(
                              "/public/",
                              ""
                            ).replaceAll("home/myaie/public_html/", ""))}`
                        }
                        target="_blank"
                      >
                        <i className="fal fa-file"></i>
                        &nbsp;{
                          timeTableDoc?.name
                            ? TrimText(timeTableDoc?.name, 40)
                            : TrimText(formik.values.TimeTable.split(
                              "/"
                            ).reverse()[0], 40)

                        }
                      </a>
                      <button
                        className="btn btn-danger rounded-circle btn-dropdown-item"
                        title="Delete"
                        onClick={() => handleTimeTable()}
                      >
                        <i className="fal fa-trash-alt"></i>
                      </button>


                    </li>
                  </ul>
                </div>
              </>
            )}

            {formik.errors.TimeTable && formik.touched.TimeTable ? (
              <span className="exclamation">
                <i className="fal fa-exclamation-circle"></i>
              </span>
            ) : null}
          </div>
          <div className="col-md-4 col-lg-2">
            <div className="d-flex align-items mt-3" title="Enable Programme Overview">
              <label className="mb-0">
                Enable Programme Overview
              </label>
              <div className="toggle-switch ml-2">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={enableProgramOverview}
                    onChange={(e) => {
                      const newValue = e.target.checked ? 1 : 0;
                      setEnableProgramOverview(e.target.checked);
                      formik.setFieldValue("is_show_overview", newValue);
                    }}
                  />
                  <span className="slider slider-round"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        {enableProgramOverview ? <div className="col-md-12" style={{paddingRight:"0", paddingLeft:"0"}}>
          <div className="form-group-blk mb-3">
            <label>Overview</label>
            <HtmlInputEditor
              editorState={editorState}
              setEditorState={(newState) => {
                setEditorState(newState);
                formik.setFieldValue("editorState", newState); 
              }}
              initialValue={programmeData?.overview}
              hideSign={true}
              isCKEditor={true}
              // isInvalid={
              //   formik.touched.feature_benefit &&
              //   formik.errors.feature_benefit
              // }
            />
          </div>
        </div> : null}

        <div className="form-group form-group-save-cancel">
          {type == "add" || type == "duplicate" ? <button className="btn btn-save btn-success" type="submit" title="Save" disabled={disabled}>
            {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
            Save
          </button> :
            <button className="btn btn-save btn-success" type="submit" title="Save"  disabled={
              isLoading ||
              (!formik.dirty &&
                ReactDOM.renderToString(
                  HtmlParser(editDataCKeditor.overview)
                ) === ReactDOM.renderToString(HtmlParser(editorState))
                ? true
                : false)
            }>
              {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
              Save
            </button>
          }
          <button
            className="btn btn-close btn-danger"
            type="button"
            title="Cancel"
            onClick={() => history.goBack()}
          >
            <i className="fal fa-times"></i>
            Cancel
          </button>

          {Object.keys(formik.values).map(key => {
            if (formik.touched[key] && formik.errors[key]) {
              return (
                <div className="invalid-feedback d-block" key={key}>
                  {formik.errors[key]}
                </div>
              )
            }
          })}
        </div>
      </form>
    </div>
  );
};

export default ProgrammeForm;

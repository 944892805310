import React, { useState, useEffect, useMemo } from "react";
import SkeletonTicketList from "../../../../../loaders/SkeletonTicketList";
import { GetListOfLinkedAssembliesCancelToken } from "../../../../../services/CourseService";
import { Color } from "../../../ContentAssembliesBlock/UI";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import PermissionsGate from "../../../../../utils/permissionGate";
import Hover from "../../../../common/Hover";
import { ColorRender } from "../../../../systemadministration/usermanagement/CheckRole";
import { IMAGE_URL, TABLE_DATE_FORMAT} from "../../../../../utils/Constants";
import { InitialRender } from "../../../../common/Helper";
import axios from "axios";
import { handleTableScroll } from "../../../../../utils/commonFunction";
import DataTableComponentFrontPagination from "../../../../common/DataTableComponentFrontPagination";
const AssignedAssemblies = ({quizId }) => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [version, setVersion] = useState({ arr: [], checkObj: {} });
  const [loading, setLoading] = useState(true);
  const [filterData, setFilterData] = useState([]);
  const [filterCheck, setFilterCheck] = useState("true")
 

  useEffect(() => {
    handleTableScroll()
  }, [loading])


  useEffect(() => {
    const cancelTokenSources = [];

    const getData = async () => {
      setLoading(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      try {
        const res = await GetListOfLinkedAssembliesCancelToken(quizId, source.token);
        setData(res?.data?.assemblies_list);
        if(filterCheck == "true" && res.data.item_version_filter && res.data.item_version_filter.length){
          let version = res.data.item_version_filter.sort((a,b)=>{
            a=Number(a.label.replaceAll("V",""));
            b=Number(b.label.replaceAll("V",""));
            return a > b ? 1 : -1
          })
          console.log("version", version)
          setFilterData(version)
        }
        setFilterCheck("false")
        if (res.status === 200) {
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    };
  
    getData();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };

  }, []);

  const sortingVersion = (rowA, rowB) => {
    const version1 = rowA.item_version ? rowA.item_version.toString() : "";
    const version2 = rowB.item_version ? rowB.item_version.toString() : "";
  
    return version1.localeCompare(version2, undefined, { numeric: true, sensitivity: "base" });
  };

  const sortingCreatedBy = (rowA, rowB) => {
    const createdBy1 = rowA.Firstname ? rowA.Firstname.toString() : "";
    const createdBy2 = rowB.Firstname ? rowB.Firstname.toString() : "";
    return createdBy1.localeCompare(createdBy2, undefined, { numeric: true, sensitivity: "base" });
  };

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortField: "name",
      sortable: true,
      cell: (row) => (
        <div className="ticket-id">
          <PermissionsGate scopes={["casbview"]} RenderError={() => (
           <span title={row.name ? row.name : "-"} className="textLimit100">{row.name ? row.name : "-"}</span>
        )}>
          <Link class="as-text-blue curser feature-name" to={`/courses/content/assemblies/edit/${row.id}`}>
            <span title={row.name ? row.name : "-"} className="textLimit100">{row.name ? row.name : "-"}</span>
          </Link>
          </PermissionsGate>
        </div>
      ),
    },
    {
      name: "Created By",
      selector: "created_by",
      sortField: "Firstname",
      sortFunction: sortingCreatedBy,
      sortable: true,
      wrap: true,
      cell: (row) => (row.created_by ? <div className="assigned-title-block-new">
      <div className="assigned-title-itm">
        <span className={"assigned-title-blk  name-icon cat-dark-red " + ColorRender(row.role_name)}>
          {row.picture_me ? <img src={`${IMAGE_URL}/${row.picture_me.replaceAll("/home/myaie/public_html/", "")}`} alt="AIE" /> : InitialRender([row.Firstname, row.Lastname])}
          <span className={`profile-box-2-status ${row.created_by_activity_status ? row.created_by_activity_status.toLowerCase()=="online" ? "Online" :row.created_by_activity_status.toLowerCase()== "away"? "Away" :"Offline":"Offline"}`}>
            <i className="fas fa-circle"></i>
          </span>
          <Hover firstName={row.Firstname} lastName={row.Lastname} photo={row.picture_me} email={row.Email} mobile={row.Mobile} status={row.created_by_status} activity_status={row.created_by_activity_status} right={true} />          
        </span>
       
        <p className="as-text-blue curser " >
          {row.Firstname + " " + row.Lastname}
        </p>
       
      </div>
    </div> : "-")
    },
    {
      name: "Created Date",
      selector: "item_date",
      sortable: true,
      sortField:"item_date",
      wrap: true,
      cell: (row) =>
        row.item_date && row.item_date !== "0000-00-00" ? (
          <div className="dateTime">
            <p className="right-space" title={moment(row.item_date).format(TABLE_DATE_FORMAT)}>{moment(row.item_date).format(TABLE_DATE_FORMAT)}</p>
            {/* <p>{moment(row.item_date).format(TABLE_TIME_FORMAT)}</p> */}
          </div>
        ) : (
          "-"
        ),
      // <p className="cat">{`${moment.utc(row.item_date).format(TABLE_DATE_FORMAT)} ${moment.utc(row.item_date).format(TABLE_TIME_FORMAT)}`}</p>
    },
    {
      name: "Version",
      selector: "item_version",
      sortable: true,
      sortFunction: sortingVersion,
      sortField:"item_version",
      wrap: true,

      cell: (row) =>
        row.item_version ? (
          <p className={`cat ${Color(row.item_version)}`}>
            <i className="fas fa-circle mr-1"></i> {row.item_version}
          </p>
        ) : (
          "-"
        ),
    },
    {
      name: "Action",
      selector: "",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
          <PermissionsGate scopes={["casbview"]} errorProps={{ disabled: true }}> 
              {/* <Link to={`/courses/content/assemblies/edit/${row.id}`}>
                <button className="btn btn-primary rounded-circle" title="Open">
                  <i className="fal fa-folder-open"></i>
                </button>
              </Link> */}
              <button className="btn btn-primary rounded-circle mr-1" title="Open" disabled={row.disable_assessment=="1"} onClick={()=>{history.push(`/courses/content/assemblies/edit/${row.id}`)}}>
            <i className="fal fa-folder-open"></i>
            </button>   
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ],[]);


  const RenderVersionLabel = (item) => {
    return {color: null, text: item, html:(
      <div className={`cat ${Color(item)}`}>
        <i className="fas fa-circle mr-1"></i> {item}
      </div>
    )}
  };
        
  const dataToRender = () => {
    let updatedData = [];
    let allData = data;

    if (search.length) {
      let name = allData?.filter((item) => {
        let includes = item?.name?.toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [...name];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (version.arr.length) {
      let tempAssignStudy = updatedData;
      let tempIntakeHash = tempAssignStudy.filter((item) => {
        const startsMarking = version.arr.find((it) => it == item.item_version)
        if (startsMarking) {
          return startsMarking;
        } else return null;
      });
      updatedData = tempIntakeHash;
    }  

    if (
      search.length ||
      version.arr.length
    ) {
      return updatedData;
    } else {
      return data;
    }
  };

  const exportData = () => {
    const header = ["Name", "Created By", "Created Date", "Version"];
    let data = dataToRender();
    data = data?.map((row) => ({
      ...row,
      Name: row?.name,
      "Created By" : row?.created_by ? row?.Firstname + " " + row?.Lastname  : "NA",
      "Created Date": `${moment(row.item_date).format(TABLE_DATE_FORMAT)}`,
      "Version": row?.item_version,
    }));
    let pdfData = data.map((row) => {
      return [row.Name, row["Created By"], row["Created Date"], row.Version];
    });
    return{
      header,
      data,
      pdfData
    }
    
  };

return (         
    <>
    {loading ? (
        <SkeletonTicketList />
    ) : 
    <DataTableComponentFrontPagination
    isActionAlignRight={true}
    data={dataToRender()}
    columns={columns}
    loading={loading}
    defaultSort={'name'}
    search={search}
    exportData={exportData}
    exportFileName={"Assigned Assemblies"}
    setSearch={setSearch}
    isExport={true}
    filters={[
        {
          filterName: "Version",
          optionArr: filterData,
          state: version,
          setState: setVersion,
          renderLabelFunction: RenderVersionLabel,
          stopOptionSorting: true
        }
      ]}
    
  />
}
</>
)

};

export default AssignedAssemblies;

const initialState = {
    file_type: 'all'
};

const file_system = (state = initialState, action) => {

    switch (action.type) {
        case 'SET_FILE_SYSTEM_TYPE':
            state.file_type = action.payload
            return { file_type: state.file_type }
        default:
            return state;
    }

}

export default file_system;
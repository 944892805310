import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";
import AuditTrail from "../../StudentAdministration/Students/AuditTrail";
import StaticPagesOpt from "./StaticPagesOpt";
import { GetStaticUpdateList } from "../../../services/StaticPgesServer";
import AuditTrailsTable from "../../common/AuditTrailsTable";
import { getStaticPageAuditTrailList, getStaticPageAuditTrailListFilters } from "../../../services/WebsiteListServer";
import { RenderStudentResourceType } from "../../../utils/CommonGroupingItem";
// import { getWebsiteListByBrand } from "../../../services/ProgramService";
import PreviewDropdown from "../../common/PreviewDropdown";
// This will be shown in merge-all branch

function CreateStaticPages() {
    const history = useHistory();
    const { id, tab, subId } = useParams();
    const columnsToShow = ["Action Type", "Resource Type", "Resource Name"]
    const apiParams = {
        action_id: subId || id,
    }
    // const [previewDomain, setPreviewDomain] = useState([]);
    const [updateData, setUpdateData] = useState({});
    const [pageUrl, setPageUrl] = useState("");
    const [saveChanges, setSaveChanges] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            try {
                GetStaticUpdateList(id)
                .then(res => {
                    setUpdateData(res?.data?.Data)
                    let url = res?.data?.Data?.url;
                    if(url && url != ''){
                        setPageUrl(url);
                    }
                    // let brandTemplates = res?.data?.Data?.brandTemplete;
                    
                    // if (res?.data?.Data?.IsPublished == 1){
                    //     setPreviewDomain("https://aie.ac");
                    // } else if (brandTemplates) {
                    //     //check if brand_ids contain 1
                    //     if (brandTemplates.includes(1)) {
                    //         setPreviewDomain('https://next.aie.ac');
                    //     } else if (brandTemplates.includes(4)) {
                    //         setPreviewDomain('https://next.designcenter.co.za');
                    //     } else if(brandTemplates.includes(3)) {
                    //         setPreviewDomain('https://next.emendy.co.za');
                    //     } else if(brandTemplates.includes(2)) {
                    //         setPreviewDomain('https://next.learnfast.co.za');
                    //     } else if(brandTemplates.includes(6)) {
                    //         setPreviewDomain("https://next.cadco.co.za");
                    //     } else {
                    //         setPreviewDomain("https://next.aie.ac");
                    //     }
                    // }else{
                    //     setPreviewDomain("https://next.aie.ac");
                    // }

                    if(saveChanges){
                        setSaveChanges(false);
                    }
                })
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };
        if(tab == "update"){
            fetchData();
        }
    }, [id , saveChanges]);

    // useEffect(() => {
    //     const previewUrl = () => {
    //         if (!updateData?.brandTemplete) return;

    //         const brandId = updateData.brandTemplete.map(item => item.brandID);
    //         const brandIdData = { brandId: brandId }
            
    //         getWebsiteListByBrand(brandIdData)
    //             .then(res => {
    //                 if (updateData?.IsPublished == 1) {
    //                     const website = res?.data?.data?.filter(item => item.branch == "development" || item.branch == "production");
    //                     setPreviewDomain(website)
    //                 } else {
    //                     const website = res?.data?.data?.filter(item => item.branch == "development");
    //                     setPreviewDomain(website)
    //                 }
    //             })
    //             .catch(err => {
    //                 console.error(err, "err")
    //             })
    //     } 
    
    //     if(updateData.length != 0){
    //       previewUrl()
    //     }
    //   }, [updateData])
    
    return (
        <>
        <Header />
        <div className="content-wrapper d-flex flex-wrap">
        <SideBar />
        <div className={"sidebar-right " + (tab === "gradebook" ? "bottom-100" : "")}>
        <div className="sub-menu-content-block">
        {/* <Tabs /> */}
        <div className="sub-menu-right-block">
        <div className="wrap-box-right">
        <div className="my-tickets-heading-blk flex-direction mb-2">
        <div className="d-flex align-items-center ">
        <div className="name-w-head d-flex align-items-center ">
        <h4 className="text-left-align landing-heading heading_color_dynamic">
        {/* Student <span>List</span> */}
        Static Pages: <span>{tab == "update" || tab == "auditTrail" ? updateData?.PageTitle : "Create New"}</span>
        </h4>
        </div>
        <div className="text-md-right action2-blk">
        <div className="ticket-view-btn d-flex align-items-center">
        <div className="add-new-ticket-bx">
        <button onClick={() => history.goBack()} title="Back" className="btn btn-white-bordered">
        <i className="fal fa-angle-left"></i>Back
        </button>
        </div>
        </div>
        </div>
        {/* {id && <div className="text-md-right action2-blk">
            <div className="ticket-view-btn d-flex align-items-center">
            <div className="add-new-ticket-bx">
            <a
            href={`${previewDomain}/${pageUrl && pageUrl != '' ? pageUrl : `page?id=${id}`}`}
            title="Preview"
            target="_blank"
            className="btn btn-white-bordered"
            >
            <i className="fal fa-eye"></i>Preview
            </a>
            </div>
            </div>
            </div>
        } */}
        {id ? <PreviewDropdown updateData={updateData} status={updateData?.IsPublished} pageUrl={pageUrl} id={encodeURIComponent(encodeURIComponent(updateData?.slug))} saveChanges={saveChanges}/> : null}
        </div>
        </div>
        <div className="tabs-wrap">
        {(tab === "auditTrail" || tab === "update") && (
            <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
            <li className="nav-item" onClick={() => history.push(`/website_management/pages/staticpages/table/update/${id}`)}>
            <Link
            className={`nav-link ${tab === "update" && "active"}`}
            id="pills-tab1"
            data-toggle="pill"
            to={`/website_management/pages/staticpages/table/update/${id}`}
            role="tab"
            aria-controls="pills-tab11"
            aria-selected="true"
            >
            <i className="fa fa-info-circle"></i>
            Page Details
            </Link>
            </li>
            <li className="nav-item" onClick={() => history.push(`/website_management/pages/staticpages/table/auditTrail/${id}`)}>
            <Link
            className={`nav-link ${tab === "auditTrail" && "active"}`}
            id="pills-tab2"
            data-toggle="pill"
            to={`/website_management/pages/staticpages/table/auditTrail/${id}`}
            role="tab"
            aria-controls="pills-tab12"
            aria-selected="true"
            >
            <i className="fal fa-history"></i>Audit trail
            </Link>
            </li>
            </ul>
        )}
        <div className="tab-content" id="pills-tabContent">
        <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
        {tab == "create" && <StaticPagesOpt />}
        {tab == "update" && <StaticPagesOpt updateData={updateData} setSaveChanges={setSaveChanges}/>}
        {tab == "auditTrail" && <AuditTrailsTable
            apiFunction={getStaticPageAuditTrailList}
            filterArrApi={getStaticPageAuditTrailListFilters}
            columnsToShow={columnsToShow}
            exportFileName={"Static pages audit trails"}
            isResourceNameShown={true}
            apiParams={apiParams}
            actionId={subId || id}
            isShowingResourceTypeFilter
            renderResourceTypeFunction={RenderStudentResourceType}
            />}
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </>
        );
    }
    
    export default CreateStaticPages;
    
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { formatFileSize, InitialRender } from '../../../common/Helper';
import { IMAGE_URL, TABLE_DATE_FORMAT } from '../../../../utils/Constants';
import { TrimText } from '../../../common/TrimText';
import { RenderSubmissionAndResultResult, RenderSubmissionAndResultStatus } from '../../../../utils/CommonStatusItems';
import moment from 'moment';
import { RenderCategoriesType } from '../../../../utils/CommonGroupingItem';
import DataTableComponentFrontPagination from '../../../common/DataTableComponentFrontPagination';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Hover from '../../../common/Hover';
import { Link, Prompt, useParams, useHistory } from 'react-router-dom';
import { generatePdfForPracticalEvaluation, getPracticalAssessmentStudentSubmissionDetails, getPracticalAssessmentSubmissionResult, GradeLetterTemplateList, markPracticalAssessmentStudentQuestions, practicalAssessmentSetToFinal } from '../../../../services/CourseService';
import HtmlParser from "react-html-parser";
import PermissionsGate from '../../../../utils/permissionGate';
import { FileUploadField } from '../../../common/FormFields';
import Select from "react-select";
import CriteriaSubmission from './CriteriaSubmission';
import Swal from 'sweetalert2';
import { twoDecimalRound } from '../../../../utils/commonFunction';
import SkeletonTicketList from '../../../../loaders/SkeletonTicketList';
import { RenderAttachList } from '../../../common/RenderAttachList';
import DigitalSignatureComponent from '../../../common/DigitalSignatureComponent';
const PublishstatusArr = [
  {
    value: "Draft",
    label: HtmlParser(`<span className="red" title="Draft">Draft  </span> `),
  },
  {
    value: "Published",
    label: HtmlParser(
      `<span className="blue" title="Published">Published</span>`
    ),
  },
  {
    value: "Unpublished",
    label: HtmlParser(
      `<span className="blue" title="Unpublished">Unpublished</span>`
    ),
  },
];

function StudentSelfAssessment({ studentList = [], totalRows = '' }) {
  const [loading, setloading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [searchStudentList, setSearchStudentList] = useState("");
  const [showSeeMore, setShowSeeMore] = useState(false);
  const [description, setDescription] = useState("");
  const descriptionRef = useRef({});
  const [expanded, setExpanded] = useState({});
  const [studentDetailList, setStudentDetailList] = useState([]);
  const [options, setOptions] = useState([
    { label: 'Red', value: '0' },
    { label: 'Yellow', value: '49' },
    { label: 'Green', value: '100' },
  ])
  const [updateValueArr, setUpdateValueArr] = useState([]);
  const [generateEvaluationLoading, setGenerateEvaluationLoading] = useState(false);
  const [setToFinalLoading, setSetToFinalLoading] = useState(false);
  const [removeFiles, setRemoveFiles] = useState([]);
  const [lecturerFeedbackFiles, setLecturerFeedbackFiles] = useState([]);
  const [lecturerRemarksChanged, setLecturerRemarksChanged] = useState(false);
  const [cDate, setCDate] = useState(null);
  const [ipAddress, setIpAddress] = useState(null);
  const [readOnly, setReadOnly] = useState(false);
  const [isSig, setIsSig] = useState(null);
  const [isCancel, setIsCancel] = useState(false)
  const [is_Signature, setIs_Signature] = useState(false)
  const [grademix, setGrademix] = useState({})
  const [backupFeedbackFiles, setBackupFeedbackFiles] = useState({});
  const signaturePadRef = useRef(null);
  const fileInputRef = useRef(null);
  const [previousCDate, setPreviousCDate] = useState(null);
  const [allQuestionsMarked, setAllQuestionsMarked] = useState(false);

  const history = useHistory();

  const handleToggle = (rowId) => {
    setExpanded((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  const [criteriaBody, setCriteriaBody] = useState([]);
  const [criteriaHeader, SetCriteriaHeader] = useState([]);
  const [feedbackFiles, setFeedbackFiles] = useState([]);
  const { subSubId, subId, id } = useParams()

  const renderEvidences = (row) => {

    let evidence_photo;
    let evidence_video;
    let evidence_document;

    if(row?.getSubmissionPracticalFiles) {
      evidence_photo = row?.getSubmissionPracticalFiles?.filter(item => item?.type == 0);
      evidence_video = row?.getSubmissionPracticalFiles?.filter(item => item?.type == 1);
      evidence_document = row?.getSubmissionPracticalFiles?.filter(item => item?.type == 2);
    }
    
    return (
      <div className='evidence__box'>
        {/* Evidence Photo */}
        {row?.getSubmissionPracticalQuestionsBank?.evidence_photo == 1 && <span className='evidence__item'>
          {evidence_photo?.length > 0 ? (
            <i className="fal fa-check-circle" style={{ fontSize: 18 }} />
          ) : (
            <i className="fal fa-times-circle" style={{ fontSize: 18 }}   />
          )}
          <i className="fal fa-camera evidence" />
          <span style={{ fontSize: 16 }}>
            Photo
          </span>
        </span>}

        {/* Evidence Video */}
        {row?.getSubmissionPracticalQuestionsBank?.evidence_video == 1 && <span className='evidence__item'>
          {evidence_video?.length > 0 ? (
            <i className="fal fa-check-circle" style={{ fontSize: 18 }} />
          ) : (
            <i className="fal fa-times-circle" style={{ fontSize: 18 }} />
          )}
          <i className="fal fa-video evidence"/>
          <span style={{ fontSize: 16 }}>
            Video
          </span>
        </span>}

        {/* Evidence Document */}
        {row?.getSubmissionPracticalQuestionsBank?.evidence_document == 1 && <span className='evidence__item'>
          {evidence_document?.length > 0 ? (
            <i className="fal fa-check-circle" style={{ fontSize: 18 }} />
          ) : (
            <i className="fal fa-times-circle" style={{ fontSize: 18 }} />
          )}
          <i className="fal fa-file-alt evidence" />
          <span style={{ fontSize: 16 }}>
            Document
          </span>
        </span>}

        {/* Evidence Lecturer Observation */}
        {row?.getSubmissionPracticalQuestionsBank?.evidence_lecturer_observation == 1 && <div className='my-2'>
        <span className='evidence__item'>
          {row?.lecturer_observation_toggle == 1 ? (
            <i className="fal fa-check-circle" style={{ fontSize: 18 }} />
          ) : (
            <i className="fal fa-times-circle" style={{ fontSize: 18 }} />
          )}
          <i className="fal fa-user-check evidence" />
          <span style={{ fontSize: 16 }}>
            Lecturer Observation
          </span>
        </span>

        {
            row?.lecturer_observation_toggle == 1 && row?.observation_date != 0 && row?.observation_date != null ? <p style={{ marginTop: 15 }} title={`Confirmed on ${row?.observation_date != 0 && row?.observation_date != null ? formatDate(row.observation_date) : ""} at ${row?.observation_date != 0 && row?.observation_date != null ? formatTime(row.observation_date) : ""} by ${row?.observationBy?.fullname}`}><i className="fal fa-history" style={{ color: "var(--topbar-color)" }}></i> {`Confirmed on ${row?.observation_date != 0 && row?.observation_date != null ? formatDate(row.observation_date) : ""} at ${row?.observation_date != 0 && row?.observation_date != null ? formatTime(row.observation_date) : ""} by ${row?.observationBy ? row?.observationBy?.fullname : ""}`}</p> : null
        }
        </div>
        }
      </div>
    );
  };

  const formatTime = (seconds) => {
    const date = new Date(seconds * 1000);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedHours = hours % 12 || 12;
    return `${formattedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  const formatDate = (seconds) => {
    const date = new Date(seconds * 1000);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const formattedDate = `${String(date.getDate()).padStart(2, '0')} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;

    return formattedDate;
  };

  const fetchData = () => {
    setloading(true)

    getPracticalAssessmentStudentSubmissionDetails(subId, subSubId)
      .then((res) => {
        if (res.status == 200) {
          setCriteriaBody(res.data?.student_submission?.getStudentsSubmissionPracticalQuestions)
          SetCriteriaHeader(res.data?.student_submission)

          const allMarked = res.data?.student_submission?.getStudentsSubmissionPracticalQuestions.every(question => 
            question.is_marked === 1 || question.marked_absent === 1
          );
          setAllQuestionsMarked(allMarked);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setloading(false);
      })
  }

  useEffect(() => {
    fetchData();
  }, []);

  const signatureFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      student_signature: criteriaHeader?.lecturer_Signature || null,
      ip: criteriaHeader?.lecturer_Signature_Ip || null,
      signature_date: criteriaHeader?.lecturer_Signature_Date || null,
      lecturer_signature_type: criteriaHeader?.lecturer_signature_type || 0,
    },
    validationSchema: Yup.object().shape({
      student_signature: Yup.mixed().required('Please upload a signature'),
    }),
    onSubmit: (values) => {
    }
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      lecturer_remarks: criteriaHeader?.lecturer_remarks || '',
      lecturer_feedback_files: [],
      removeFiles: [],
      grademix: grademix || {},
      type: "",
      is_Use_Generated_Evaluation: criteriaHeader?.is_Use_Generated_Evaluation || 0,
      location: ""
    },
    validationSchema: Yup.object().shape({
      grademix: Yup.lazy((values) => {
        if (!values || Object.keys(values).length === 0) {
          return Yup.object().shape({}); 
        }

        const dynamicSchema = Object.keys(values).reduce((acc, questionId) => {

          const question = criteriaBody.find(
            (q) => q?.getSubmissionPracticalQuestionsBank?.qid.toString() == questionId
          );

          if (question) {
            const maxValue = question.getSubmissionPracticalQuestionsBank?.correct_score;

            if (maxValue) {
              acc[questionId] = Yup.number()
                .max(maxValue, `Sorry, you can't put a mark more than the ${maxValue} at ${question.getSubmissionPracticalQuestionsBank?.question_name}`)
            }
          }
          return acc;
        }, {});
        return Yup.object().shape(dynamicSchema);
      }),
    }),    
    onSubmit: (values , { resetForm }) => {
      if(values.type == "setToFinal"){
        setSaveLoading(false);
      } else {
        setSaveLoading(true);
      }
      
      const formData = new FormData();
      
      const trimmedValues = Object.fromEntries(
        Object.entries(values).map(([key, value]) => [
          key,
          typeof value === "string" ? value.trim().replace(/ +/g, " ") : value,
        ])
      );
      
      const markedData = Object.keys(trimmedValues.grademix).map((questionId) => ({
        grade: trimmedValues.grademix[questionId],
        question_id: parseInt(questionId),
      }));
      
      Object.keys(trimmedValues).forEach((key) => {
        if (key !== 'grademix') {
          if (Array.isArray(trimmedValues[key])) {
              trimmedValues[key].forEach((val, idx) => {
                formData.append(`${key}[${idx}]`, val);
              });
          } else {
            formData.append(key, trimmedValues[key]);
          }
        }
      });
  
      markedData.forEach((item, idx) => {
        formData.append(`grademix[${idx}][grade]`, item.grade);
        formData.append(`grademix[${idx}][question_id]`, item.question_id);
      });
  
      formData.append("assessment_id", subId);
      formData.append("submission_id", criteriaHeader?.id);

      formData.delete("type");
      formData.delete("location");
      markPracticalAssessmentStudentQuestions(formData)
      .then(res => {
        Swal.fire({
          title: "Success",
          text: "Updated successfully",
          icon: "success",
          confirmButtonText: "OK"
        });
        fetchData();
        formik.setFieldValue("lecturer_feedback_files" , [])
        formik.setFieldValue("removeFiles" , [])
        formik.setFieldValue("type" , "")
        setLecturerRemarksChanged(false);
        setLecturerFeedbackFiles([]);
        setRemoveFiles([]);
        resetForm();
        if(values.type == "SaveChange"){
          Swal.hideLoading();
          history.push(values?.location);
        }
      })
      .catch(err => {
        console.error(err);
        if(values.type == "SaveChange"){
          Swal.hideLoading();
        } else {
          Swal.fire({
            title: "Error",
            text: "Something went wrong",
            icon: "error",
            confirmButtonText: "OK"
          });
        }
      })
      .finally(() => {
        setSaveLoading(false);
      });
    },
  });

  const dataToRenderStudentList = () => {
    if (!searchStudentList.length) return criteriaBody;

    const filteredStudents = criteriaBody.filter(item => {
      const fullNameMatch = item.fullName?.toLowerCase().includes(searchStudentList.toLowerCase());
      const studentIdMatch = item.student_crm_id?.toString().toLowerCase().includes(searchStudentList.toLowerCase());
      return fullNameMatch || studentIdMatch;
    });

    return [...new Set(filteredStudents)];
  };

  const shouldBlockNavigation = (formik?.dirty);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (shouldBlockNavigation) {
        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [shouldBlockNavigation]);

  const handleNavigation = (location) => {
    if (shouldBlockNavigation) {
      if (Swal.isVisible()) {
        return false;
      }
      Swal.fire({
        title: "Warning",
        text: "You Have Unsaved Changes Left! Do you want to save changes",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No, Leave",
        confirmButtonText: "Yes, Save Changes",
      }).then((value) => {
        if (value.isConfirmed) {
          Swal.fire({
            title: "Please wait",
            text: "Saving changes...",
            allowOutsideClick: false,
            onBeforeOpen: () => {
              Swal.showLoading();
              handleSubmit();
              setUpdateValueArr([]);
              setLecturerFeedbackFiles([]);
              setRemoveFiles([]);
              formik.setFieldValue("type" , "SaveChange")
              formik.setFieldValue("location" , location)
              setLecturerRemarksChanged(false);
            },
          });
        } else {
          setUpdateValueArr([]);
          setLecturerFeedbackFiles([]);
          setRemoveFiles([]);
          setLecturerRemarksChanged(false);
          formik.resetForm();
          if (location) {
            history.push(location.pathname);
          }
        }
      });

      return false;
    }
  };

  const handleResult = (row) => {
    const options = [
        { value: row?.getSubmissionPracticalQuestionsBank?.correct_score, label: "Competent" },
        { value: 0, label: "Not Yet Competent" },
    ];

    if (row?.is_marked == 1 || row?.marked_absent) {
        // let marked;
        // if(row?.is_marked == 1){
        //     marked = options?.find(option => option.value == row?.marked)?.label;
        // }
        return <div>
            {RenderSubmissionAndResultStatus(row?.marked_absent ? "INCOMPLETE" : row?.marked >= 1 ? "Competent" : "Not Yet Competent", "programme")?.html}
        </div>
    } else {
        return (
            <div className="form-group-blk" style={{ width: "200px" }}>
                {/* <PermissionsGate scopes={["overidersmart"]}  errorProps={{ isDisabled: true }}> */}
                <Select
                    className="form-control custom-select-box"
                    options={options}
                    onChange={(selectedOption) => {
                                  if (selectedOption) {
                                    formik.setFieldValue('grademix', {
                                      ...formik.values.grademix,
                                      [row.question_id]: selectedOption.value,
                                    });
                                    setUpdateValueArr(prev => [...prev, row.question_id]);
                                  } else {
                                    const updatedGrademix = { ...formik.values.grademix };
                                    delete updatedGrademix[row.question_id];
                                    formik.setFieldValue('grademix', updatedGrademix);
                                    setUpdateValueArr(prev => prev.filter(id => id !== row.question_id));
                                  }
                                }}
                    value={options.find(option => option.value == formik.values.grademix[row.question_id]) || null}
                    maxMenuHeight={108}
                    isClearable
                />
                {/* </PermissionsGate> */}
            </div>
        )
    }
}

  const crieteriaColumns = useMemo(() => [
    {
      name: "Criteria",
      selector: "question_name",
      sortField: "question_name",
      sortable: true,
      cell: (row) => {
        const isExpanded = expanded[row.id]
        setDescription(row?.getSubmissionPracticalQuestionsBank?.description)

        return (
          <div>
            {row?.getSubmissionPracticalQuestionsBank?.question_name ? (
              <>
                <div className="criteria">
                  {TrimText(HtmlParser(row?.getSubmissionPracticalQuestionsBank?.question_name), 15)}
                </div>
                <div
                  className={`description-p ${isExpanded ? "expanded" : "collapsed"}`}
                  style={{ width: "200px", whiteSpace: "normal" }}
                  ref={(el) => {
                    descriptionRef.current[row.id] = el;
                  }}
                >
                  {description
                    ? isExpanded
                      ? HtmlParser(description)
                      : TrimText(HtmlParser(description), 10)
                    : null}
                </div>
                <div className="criteria__btn">
                  {description && showSeeMore && (
                    <span onClick={() => handleToggle(row.id)} className="toggle-button">
                      {isExpanded ? "See Less" : "See More"}
                    </span>
                  )}
                </div>
              </>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    { 
      name: "Score", 
      selector: "correct_score", 
      sortable: false, cell: (row) => row?.getSubmissionPracticalQuestionsBank?.correct_score || "-" 
    },
    {
      name: "Evidence",
      selector: "Evidence",
      sortable: false,
      cell: (row) => {
        const evidenceData = row?.getSubmissionPracticalQuestionsBank;
        return evidenceData && Object.keys(evidenceData).length > 0
          ? renderEvidences(row)
          : 'N/A';
      }
    },
    {
      name: "Result",
      selector: "result",
      sortable: true,
      cell: (row) => handleResult(row)
    },
    {
      name: "Actions",
      cell: (row) => {
          return (
              <div className="assessment-08 btn-dropdown-grp">
                  <div className="as-buttons d-flex">
                      {/* <button
                          className="btn btn-save btn-success rounded-circle"
                          title="update Result"
                          // onClick={{}}
                      >
                          <i className="fal fa-pen"></i>
                      </button> */}
                      <button
                          className="btn btn-save btn-success rounded-circle"
                          title="Open Criteria Submission"
                          data-toggle="modal" 
                          data-target="#criteriaSubmission"
                          onClick={() => setStudentDetailList(row?.question_id)}
                      >
                          <i className="fal fa-clipboard-list"></i>
                      </button>
                  </div>
              </div>
          )
      },
  },
  ]);

  useEffect(() => {
    if (criteriaHeader) {
      setFeedbackFiles(criteriaHeader?.admin_feedBack_files || []);
      setBackupFeedbackFiles(criteriaHeader?.admin_feedBack_files || []);
      // formik.setFieldValue("lecturer_feedback_files", criteriaHeader?.admin_feedBack_files || []);
  
      if (Array.isArray(criteriaHeader?.getStudentsSubmissionPracticalQuestions)) {
        const updatedGrademix = { ...formik.values.grademix };
  
        criteriaHeader.getStudentsSubmissionPracticalQuestions.forEach((item) => {
          if (item?.is_marked === 1) {
            updatedGrademix[item.question_id] = item.marked;
          }
        });
        setGrademix(updatedGrademix);
      }

      setIs_Signature(readOnly ? false : criteriaHeader?.lecturer_Signature ? true : false);
      setIpAddress(criteriaHeader?.lecturer_Signature_Ip || null);
      setCDate(criteriaHeader?.lecturer_Signature_Date && criteriaHeader?.lecturer_Signature_Date != 0 ? moment(parseInt(criteriaHeader?.lecturer_Signature_Date *1000))?.format('DD MMM YYYY, HH:mm') : null);
      setPreviousCDate(moment(parseInt(criteriaHeader?.lecturer_Signature_Date *1000))?.format('DD MMM YYYY, HH:mm'));
    }
  }, [criteriaHeader]);

  useEffect(() => {
    const checkOverflow = (id) => {
      const descriptionElement = descriptionRef.current[id];
      if (descriptionElement) {
        setShowSeeMore(descriptionElement.scrollHeight > descriptionElement.clientHeight);
      }
    };

    // Initial check on component mount and description change
    Object.keys(descriptionRef.current).forEach((id) => {
      checkOverflow(id);
    });

    // Event listener for resize
    const handleResize = () => {
      Object.keys(descriptionRef.current).forEach((id) => {
        checkOverflow(id);
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [description]);

  useEffect(() => {
    const fetchAssessmentData = () => {
      getPracticalAssessmentSubmissionResult(subId, id)
        .then((res) => {
          if (res?.data?.header?.grading_type && res?.data?.header?.grading_type === 2) {
            GradeLetterTemplateList({ exportStatus: true }).then((result) => {
              if (result.status == 200) {
                let respData = result.data.list_data;
                respData = respData.find(item => item.id === res.data.header.grading_template_id);
                const optionData = respData?.gradeOptions?.length > 0 ? respData?.gradeOptions?.map(item => ({ label: item.text, value: item.id, minValue: item.min_score, maxValue: item.max_score })) : [];
                setOptions([...optionData]);
              }
            })
          }
        })
        .catch((err) => {
          console.error(err);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err.response.data.message,
          })
        })
    }

    if(criteriaHeader?.grade == 2){
      fetchAssessmentData();
    }

  }, [criteriaHeader])

  const handleDeleteFile = (file, index) => {
    if (file?.id) {
      setFeedbackFiles(feedbackFiles.filter(f => f.id !== file?.id));
      formik.setFieldValue('removeFiles', [...formik.values.removeFiles, file?.id]);
      setRemoveFiles([...removeFiles, file?.id]);
    } else {
      const updatedFeedbackFiles = [...feedbackFiles];
      updatedFeedbackFiles.splice(index, 1);
      setFeedbackFiles(updatedFeedbackFiles);
      setRemoveFiles([...removeFiles, index]);
    }
  };

  const handleSubmit = (type) => {
    const filesToSave = feedbackFiles.filter(file => !formik.values.removeFiles.includes(file.id));
    formik.setFieldValue("lecturer_feedback_files", filesToSave);
    formik.setFieldValue("type", type);
    formik.handleSubmit();
  };

  const handleSetToFinal = () => {
    "use strict";

    setSetToFinalLoading(true);

    if(criteriaHeader?.is_Student_Signature_Required == 1 ? signatureFormik.values.student_signature && criteriaHeader?.student_Signature && allQuestionsMarked : signatureFormik.values.student_signature && allQuestionsMarked){
      handleSubmit("setToFinal");

      const data = {
        assessment_id: parseInt(subId),
        submission_id: criteriaHeader?.id,
      }
  
      practicalAssessmentSetToFinal(data)
      .then(res => {
        Swal.fire({
          title: "Success",
          text: "Assessment set to final successfully",
          icon: "success",
          confirmButtonText: "OK"
        });
        setReadOnly(true);
        fetchData();
      })
      .catch(err => {
        console.error(err);
        Swal.fire({
          title: "Error",
          text: "Something went wrong",
          icon: "error",
          confirmButtonText: "OK"
        });
      })
      .finally(() => {
        setSetToFinalLoading(false);
      })
    } else if(criteriaHeader?.is_Student_Signature_Required == 1 && !criteriaHeader?.student_Signature && !signatureFormik.values.student_signature && !allQuestionsMarked) {
      Swal.fire({
        title: "Error",
        text: "Please upload lecturer and student signature and all questions are marked",
        icon: "error",
        confirmButtonText: "OK"
      });
      setSetToFinalLoading(false);
    } else if(criteriaHeader?.is_Student_Signature_Required == 1 && !criteriaHeader?.student_Signature) {
      Swal.fire({
        title: "Error",
        text: "Please upload student signature",
        icon: "error",
        confirmButtonText: "OK"
      });
      setSetToFinalLoading(false);
    } else if(!signatureFormik.values.student_signature) {
      Swal.fire({
        title: "Error",
        text: "Please upload lecturer signature",
        icon: "error",
        confirmButtonText: "OK"
      });
      setSetToFinalLoading(false);
    } else if(!allQuestionsMarked) {
      Swal.fire({
        title: "Error",
        text: "Please mark all questions",
        icon: "error",
        confirmButtonText: "OK"
      });
      setSetToFinalLoading(false);
    } else {
      Swal.fire({
        title: "Error",
        text: "Something went wrong",
        icon: "error",
        confirmButtonText: "OK"
      });
      setSetToFinalLoading(false);
    }
  }

  const exportData = () => {
    let data = criteriaBody;
    let header = ["Criteria", "Score", "Evidence", "Result"];
    let pdfData;

    data = data?.map((row) => {
        const rowData = {
            Criteria: row?.getSubmissionPracticalQuestionsBank?.question_name,
            Score: row?.getSubmissionPracticalQuestionsBank?.correct_score,
            Result: `${row?.marked} / ${row?.getSubmissionPracticalQuestionsBank?.correct_score}`,
        };

        let evidence_photo = [];
        let evidence_video = [];
        let evidence_document = [];

        if (row?.getSubmissionPracticalFiles) {
            evidence_photo = row?.getSubmissionPracticalFiles?.filter(item => item?.type === 0);
            evidence_video = row?.getSubmissionPracticalFiles?.filter(item => item?.type === 1);
            evidence_document = row?.getSubmissionPracticalFiles?.filter(item => item?.type === 2);
        }

        // Prepare evidence counts and statuses
        const photoCount = row?.getSubmissionPracticalQuestionsBank?.evidence_photo === 1 ? evidence_photo.length : 0;
        const videoCount = row?.getSubmissionPracticalQuestionsBank?.evidence_video === 1 ? evidence_video.length : 0;
        const documentCount = row?.getSubmissionPracticalQuestionsBank?.evidence_document === 1 ? evidence_document.length : 0;
        const lecturerObservation = row?.getSubmissionPracticalQuestionsBank?.evidence_lecturer_observation === 1 
            ? (row?.lecturer_observation_toggle === 1 ? "Yes" : "No") 
            : "N/A";

        // Format the Evidence string
        rowData.Evidence = `Photos: ${photoCount}\nVideos: ${videoCount}\nDocuments: ${documentCount}\nLecturer Observation: ${lecturerObservation}`;

        return rowData;
    });

    pdfData = data.map((row) => {
        return header.map(col => row[col]);
    });

    return {
        data,
        header,
        pdfData
    };
};

const handleOverallResult = () => {
  if(criteriaHeader?.grade == 0 || criteriaHeader?.grade == 3){
    if(criteriaHeader?.grade == 0){
      return criteriaHeader?.ass_percentage ? `${Math.round(criteriaHeader?.ass_percentage)}%` : "-";
    } else {
      return criteriaHeader?.ass_mark ? `${criteriaHeader?.ass_mark} / ${criteriaHeader?.ass_total_mark}` : "-";
    }
  } else if(criteriaHeader?.grade == 1){
    const result = options.find(function (option) {
      if (criteriaHeader?.ass_percentage != null && Number(criteriaHeader?.ass_percentage.toString().replace(/%\s?/g, '')) >= 0 && (criteriaHeader?.ass_percentage.toString().replace(/%\s?/g, '')) != "") {
        return option.value >= Number(criteriaHeader?.ass_percentage.toString().replace(/%\s?/g, ''))
      }
    })
    return result?.label;
  } else if(criteriaHeader?.grade == 2) {
    const result = options.filter(function (option) {
      if (criteriaHeader?.ass_percentage != null && Number(criteriaHeader?.ass_percentage.toString().replace(/%\s?/g, '')) >= 0 && (criteriaHeader?.ass_percentage.toString().replace(/%\s?/g, '')) != "") {
        return (option.maxValue >= Number(criteriaHeader?.ass_percentage?.toString()?.replace(/%\s?/g, ''))) && (option.minValue <= Number(criteriaHeader?.ass_percentage?.toString()?.replace(/%\s?/g, '')))
      }
    })
    return result[0]?.label;
  }
  return "-";
}

const handleGenerateEvaluation = () => {
  setGenerateEvaluationLoading(true);
  generatePdfForPracticalEvaluation({
    assessment_id: parseInt(subId),
    student_id: criteriaHeader?.student_id,
  })
  .then(res => {
    Swal.fire({
      title: "Success",
      text: "Evaluation generated successfully",
      icon: "success",
      confirmButtonText: "OK"
    });
    fetchData();
  })
  .catch(err => {
    console.error(err);
    Swal.fire({
      title: "Error",
      text: "Something went wrong",
      icon: "error",
      confirmButtonText: "OK"
    });
  }).finally(() => {
    setGenerateEvaluationLoading(false);
  })
}

useEffect(() => {
  if(formik.values.type == "SaveChange" && Object.keys(formik.errors).length) {
    Swal.hideLoading();
    Swal.fire({
      title: "Error",
      text: "Please fix the errors before leaving.",
      icon: "error",
      confirmButtonText: "OK"
    });
  }
}, [formik.values.type])

useEffect(() => {
  if(criteriaHeader?.submited && criteriaHeader?.submited != 0){
    setReadOnly(true);
    setIs_Signature(false);
  }
} , [criteriaHeader?.submited])

useEffect(() => {
  if(formik.values.type == "SaveChange" && formik?.errors?.length > 0){
    Swal.fire({
      title: "Error",
      text: "Please fix the errors before leaving.",
      icon: "error",
      confirmButtonText: "OK"
    });
  }
}, [formik.values.type])

useEffect(() => {
  if(isSig){
    signatureFormik.setFieldValue("student_signature" , isSig)
    signatureFormik.setFieldValue("signature_date" , parseInt(new Date(cDate).getTime()/1000))
    signatureFormik.setFieldValue("ip" , ipAddress)
  }
}, [isSig])

useEffect(() => {
  const currentRemarks = criteriaHeader?.lecturer_remarks || '';
  if (formik.values.lecturer_remarks !== currentRemarks) {
    setLecturerRemarksChanged(true);
  } else {
    setLecturerRemarksChanged(false);
  }
}, [formik.values.lecturer_remarks, criteriaHeader?.lecturer_remarks]);

  return (
    <>
      <Prompt when={shouldBlockNavigation} message={handleNavigation} />

      {loading ? <SkeletonTicketList /> : <div className="card-new card card-profile-info-card mb-30">
        <div className="">
          <div className="card-header" title="Practical Assessment Details">Practical Assessment Details</div>
          <div className="card-body-inr card-body-info">
            <div className="basic-info-box">
              <div className="row criteria__header mx-3 justify-content-between">
                <div className="">
                  <div className="basic-info-box-list">
                    <ul className='p-0'>
                      <li>
                        <span className="header__text__title" title="Student">Student</span>
                      </li>
                      <div className="assigned-title-block-new">
                        <div className="assigned-title-itm">
                          <span className="assigned-title-blk name-icon cat-dark-red">
                            {criteriaHeader?.photo ? (
                              <img
                                src={`${IMAGE_URL}/${criteriaHeader?.picture_me?.replace(/\/home\/myaie\/public_html\//, "").replace("public/", "")}`}
                                alt="AIE"
                              />
                            ) : (
                              criteriaHeader?.full_name !== '' ? InitialRender([criteriaHeader?.fullName?.split(" ")[0], criteriaHeader?.fullName?.split(" ")[1]]) : InitialRender(["N", "A"])
                            )}
                            <span className={`profile-box-2-status ${criteriaHeader?.activity_status?.toLowerCase() || "offline"}`}>
                              <i className="fas fa-circle"></i>
                            </span>
                            <Hover
                              firstName={() => criteriaHeader?.fullName?.split(" ")[0]}
                              lastName={() => criteriaHeader?.fullName?.split(" ")[1]}
                              photo={criteriaHeader?.picture_me}
                              email={criteriaHeader?.email}
                              mobile={criteriaHeader?.mobile}
                              right={true}
                              activity_status={criteriaHeader?.activity_status}
                              showNumber={true}
                              number={criteriaHeader?.student_crm_id}
                            />
                          </span>
                          <Link
                            to={`/studentAdministration/students/open/${criteriaHeader?.student_id}/general`}
                            target="_blank"
                            className="as-text-blue curser feature-name width-155"
                            title={criteriaHeader?.fullName}
                          >
                            <span className="textLimit100 student__details">{criteriaHeader?.fullName}</span>
                          </Link>
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
                <div className="">
                  <div className="basic-info-box-list">
                    <ul className='p-0'>
                      <li title="Student Number">
                        <span className="header__text__title">Student Number :</span>
                      </li>
                      <span className='header__item__box' title={criteriaHeader?.student_num || '-'}>{criteriaHeader?.student_num || '-'}</span>
                    </ul>
                  </div>
                </div>
                <div className="">
                  <div className="basic-info-box-list">
                    <ul className='p-0'>
                      <li title="Assessment Status">
                        <span className="header__text__title">Assessment Status :</span>
                      </li>
                      <span className='header__item__box' title={criteriaHeader?.assessment_status || "-"}>{RenderSubmissionAndResultStatus(criteriaHeader?.assessment_status).html || "-"}</span>
                    </ul>
                  </div>
                </div>
                <div className="">
                  <div className="basic-info-box-list">
                    <ul className='p-0'>
                      <li title="Date Submitted">
                        <span className="header__text__title">Date Submitted :</span>
                      </li>
                      <span className='header__item__box' title={criteriaHeader?.student_submission_date > 0 ? moment.unix(criteriaHeader?.student_submission_date).format(TABLE_DATE_FORMAT) : criteriaHeader?.submited > 0 ? moment.unix(criteriaHeader?.submited).format(TABLE_DATE_FORMAT) : "-"}>{criteriaHeader?.student_submission_date > 0 ? moment.unix(criteriaHeader?.student_submission_date).format(TABLE_DATE_FORMAT) : criteriaHeader?.submited > 0 ? moment.unix(criteriaHeader?.submited).format(TABLE_DATE_FORMAT) : "-"}</span>
                    </ul>
                  </div>
                </div>
                <div className="">
                  <div className="basic-info-box-list">
                    <ul className='p-0'>
                      <li title="Assessment Method">
                        <span className="header__text__title">Assessment Method :</span>
                      </li>
                      <span className='Assessment Method header__item__box' title={criteriaHeader?.assessment_method || "-"}>{RenderCategoriesType(criteriaHeader?.assessment_method).html || "-"}</span>
                    </ul>
                  </div>
                </div>
                <div className="">
                  <div className="basic-info-box-list">
                    <ul className='p-0'>
                      <li title="Date Finalised">
                        <span className="header__text__title">Date Finalised :</span>
                      </li>
                      <span className='header__item__box' title={criteriaHeader?.assessment_method == "Lecturer Assessment" ? ((criteriaHeader?.lecturer_assessed_finalize_mark_date != 0 && criteriaHeader?.lecturer_assessed_finalize_mark_date != null && criteriaHeader?.lecturer_assessed_finalize_mark_date != undefined) ? moment.unix(criteriaHeader?.lecturer_assessed_finalize_mark_date).format(TABLE_DATE_FORMAT) : "-") : ((criteriaHeader?.self_assessed_finalize_mark_date != 0 && criteriaHeader?.self_assessed_finalize_mark_date != null && criteriaHeader?.self_assessed_finalize_mark_date != undefined) ? moment.unix(criteriaHeader?.self_assessed_finalize_mark_date).format(TABLE_DATE_FORMAT) : "-")}>
                        {criteriaHeader?.assessment_method == "Lecturer Assessment" ? ((criteriaHeader?.lecturer_assessed_finalize_mark_date != 0 && criteriaHeader?.lecturer_assessed_finalize_mark_date != null && criteriaHeader?.lecturer_assessed_finalize_mark_date != undefined) ? moment.unix(criteriaHeader?.lecturer_assessed_finalize_mark_date).format(TABLE_DATE_FORMAT) : "-") : ((criteriaHeader?.self_assessed_finalize_mark_date != 0 && criteriaHeader?.self_assessed_finalize_mark_date != null && criteriaHeader?.self_assessed_finalize_mark_date != undefined) ? moment.unix(criteriaHeader?.self_assessed_finalize_mark_date).format(TABLE_DATE_FORMAT) : "-")}
                      </span>
                    </ul>
                  </div>
                </div>
                <div className="">
                  <div className="basic-info-box-list">
                    <ul className='p-0'>
                      <li title="Overall Result">
                        <span className="header__text__title">Overall Result :</span>
                      </li>
                      <span className='header__item__box' title={handleOverallResult()}>{handleOverallResult()}</span>
                    </ul>
                  </div>
                </div>
                <div className="">
                  <div className="basic-info-box-list">
                    <ul className='p-0'>
                      <li title="Assessed By">
                        <span className="header__text__title">Assessed By</span>
                      </li>
                      {criteriaHeader?.assessed_by > 0 ? <div className="assigned-title-block-new">
                        <div className="assigned-title-itm">
                          <span className="assigned-title-blk name-icon cat-dark-red">
                            {criteriaHeader?.photo ? (
                              <img
                                src={`${IMAGE_URL}/${criteriaHeader?.assessedBy?.picture_me?.replace(/\/home\/myaie\/public_html\//, "")?.replace("public/", "")}`}
                                alt="AIE"
                              />
                            ) : (
                              criteriaHeader?.assessedBy?.fullname !== '' ? InitialRender([criteriaHeader?.assessedBy?.fullname?.split(" ")[0], criteriaHeader?.assessedBy?.fullName?.split(" ")[1]]) : InitialRender(["N", "A"])
                            )}
                            <span className={`profile-box-2-status ${criteriaHeader?.assessedBy?.activity_status?.toLowerCase() || "offline"}`}>
                              <i className="fas fa-circle"></i>
                            </span>
                            <Hover
                              firstName={criteriaHeader?.first_name}
                              lastName={() => criteriaHeader?.assessedBy?.fullname?.split(" ")[1]}
                              photo={criteriaHeader?.assessedBy?.picture_me}
                              email={criteriaHeader?.assessedBy?.email}
                              mobile={criteriaHeader?.assessedBy?.Mobile}
                              right={true}
                              activity_status={criteriaHeader?.assessedBy?.activity_status}
                              showNumber={true}
                              number={criteriaHeader?.assessedBy?.UserID}
                            />
                          </span>
                          <Link
                            to={`/studentAdministration/students/open/${criteriaHeader?.assessedBy?.student_id}/general`}
                            target="_blank"
                            className="as-text-blue curser feature-name width-155"
                            title={criteriaHeader?.assessedBy?.fullname}
                          >
                            <span className="textLimit100 student__details">{criteriaHeader?.assessedBy?.fullname}</span>
                          </Link>
                        </div>
                      </div> : '-'}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}

      <div className="card-new card card-profile-info-card mb-30 assessment_student_list_col">
        <div className='assessment_student_list'>
          <div className="card-header" title="Marking Criteria">Marking Criteria</div>
          <DataTableComponentFrontPagination
            isInsidePopUp
            data={dataToRenderStudentList()}
            columns={crieteriaColumns}
            loading={loading}
            totalRows={totalRows}
            search={searchStudentList}
            defaultSortField="Criteria"
            defaultSortAsc={true}
            setSearch={setSearchStudentList}
            exportFileName="Marking Criteria"
            exportData={exportData}
          />
        </div>
        <div className="new-card-header">
          <div className="card-header pt-0" title="Lecturer Feedback"><i className="fas fa-file-user user__icon"></i> Lecturer Feedback</div>

          <div className="card-body-inr card-body-info">
            <form onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group-blk mb-3" title="Remarks">
                    <label htmlFor="name">Remarks <i className="fal fa-info-circle"></i></label>
                    <input
                      type="text"
                      className={
                          "form-control" +
                          (formik.errors.lecturer_remarks && formik.touched.lecturer_remarks
                              ? " is-invalid"
                              : "")
                          }
                      placeholder="Enter Remarks"
                      name="lecturer_remarks"
                      id="lecturer_remarks"
                      value={formik.values.lecturer_remarks}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>

                <div className={`col-md-4 col-lg-2 mb-1`} title={"Upload Additional Feedback"}>
                  <div className={`form-group-blk mb-3`}> 
                    <label htmlFor="lecturer_feedback_files">
                     Upload Additional Feedback
                    </label>

                    <div className="form-group atttach-file m-0" title={"Upload Additional Feedback"}>
                      <label className={`${formik.touched.lecturer_feedback_files && formik.errors.lecturer_feedback_files && "file-req is-invalid"}`}>
                        <i className="fal fa-upload"></i>
                        <span>Upload</span>

                        <input
                          type="file"
                          className="form-control form-control-aatch-file"
                          onChange={(e) => {
                            const files = Array.from(e.currentTarget.files);
                            if (files.length) {
                              setFeedbackFiles([...feedbackFiles, ...files]);
                              const currentFiles = formik.values.lecturer_feedback_files || [];
                              formik.setFieldValue("lecturer_feedback_files", [...currentFiles, ...files]);
                              setLecturerFeedbackFiles([...lecturerFeedbackFiles, ...files]);
                            }
                            e.target.value = "";
                          }}
                          name="lecturer_feedback_files"
                          id="lecturer_feedback_files"
                          multiple={true}
                        />
                      </label>
                    </div>
                  </div>

                  {feedbackFiles?.length > 0 && (
                  <>
                    <div className="frm-group">
                      <ul className="list-unstyled attached-file-ul m-0">
                        {feedbackFiles.map((file, index) => {
                          const fileName = file?.name ? file?.name : file?.path || "Unknown File";
                          return (
                          <li key={index} title={fileName}>
                            <a
                              href={
                                file?.file?.includes("blob")
                                  ? file?.file
                                  : `${IMAGE_URL}/${file?.file ? file?.file : file?.path}`
                                      .replaceAll("public/", "")
                                      .replaceAll("home/myaie/public_html/", "")
                              }
                              
                              target="_blank"
                            >
                              <i className="fal fa-file"></i>
                              &nbsp;
                              {file?.name
                                ? TrimText(file?.name, 40)
                                : TrimText(
                                  file?.path.split(
                                    "/"
                                  ).reverse()[0],
                                  40
                                )
                              }
                            </a>
                            <button
                              type="button"
                              className="btn btn-danger rounded-circle btn-dropdown-item"
                              title="Delete"
                              onClick={() => handleDeleteFile(file, index)}
                            >
                              <i className="fal fa-trash-alt"></i>
                            </button>
                          </li>
                        )})}
                      </ul>
                    </div>
                  </>
                )}
                </div>

                {criteriaHeader?.is_generated_feedback ? <>
                  <div className="col-md-4 col-lg-2">
                  <div className="form-group-blk mb-3">
                    <label htmlFor="generated_evaluation" title="View Generated Feedback">View Generated Feedback</label>
                    <div className="form-group atttach-file m-0" title="Generated Evaluation">
                      {RenderAttachList({
                        fileName: criteriaHeader?.fileName?.substring(criteriaHeader?.fileName?.lastIndexOf("/") + 1),
                        size: formatFileSize(criteriaHeader?.fileSize),
                        filePath: criteriaHeader?.fileName,
                        showClose: false,
                      })}
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-lg-2" style={{marginTop: 32}}>
                  <div className="form-group-blk" title="Use Generated Evaluation">
                    <div className="">
                      <div className="custom-check-group">
                        <div
                          className="custom-check custom-control custom-checkbox"
                        >
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="is_Use_Generated_Evaluation"
                            name="is_Use_Generated_Evaluation"
                            checked={formik.values.is_Use_Generated_Evaluation == 1}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "is_Use_Generated_Evaluation",
                                e.target.checked ? 1 : 0
                              )
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="is_Use_Generated_Evaluation"
                          >
                            <div className="mr-3"></div> Use Generated Evaluation
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </> : null}

                <div className={`${criteriaHeader?.fileName ? "col-lg-6" : "col-lg-8"}`}></div>

                <div className="col-md-4 col-lg-4">
                  <div className="form-group-blk mb-3">
                    <div className="form-group m-0">
                      <DigitalSignatureComponent isCancel={isCancel} formik={signatureFormik} signaturePadRef={signaturePadRef} fileInputRef={fileInputRef} cDate={cDate} ipAddress={ipAddress} setIpAddress={setIpAddress} setCDate={setCDate} setIsSig={setIsSig} readOnly={readOnly} readOnlyData={criteriaHeader} is_Signature={is_Signature} setIs_Signature={setIs_Signature} signature_Type={signatureFormik.values.lecturer_signature_type} isSig={isSig} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group form-group-save-cancel mt-4">
                {/* <PermissionsGate scopes={[]} RenderError={() => (
                  <button className="btn btn-save btn-success" disabled>
                    <i className="fal fa-save"></i> Save
                  </button>
                )}> */}

                  <button
                    className="btn btn-save btn-success"
                    type="submit"
                    title="Save"
                    disabled={setToFinalLoading || saveLoading || loading || generateEvaluationLoading}
                  >
                    {saveLoading ? (
                      <i className="fas fa-cog fa-spin"></i>
                    ) : (
                      <i className="fal fa-save"></i>
                    )}
                    Save
                  </button>
                {/* </PermissionsGate> */}

                <button
                    className="btn btn-save btn-success"
                    type="button"
                    title="Generate Evaluation"
                    disabled={setToFinalLoading || saveLoading || loading || generateEvaluationLoading}
                    onClick={() => handleGenerateEvaluation()}
                  >
                    {generateEvaluationLoading ? (
                      <i className="fas fa-cog fa-spin"></i>
                    ) : (
                      <i className="fal fa-clipboard-check"></i>
                    )}
                    Generate Evaluation
                  </button>

                <button
                    className="btn btn-save btn-success"
                    type="button"
                    title="Set to Final"
                    onClick={handleSetToFinal}
                    disabled={(criteriaHeader?.submited != 0 && criteriaHeader?.submited) ? setToFinalLoading || saveLoading || loading || generateEvaluationLoading ||criteriaHeader?.submited : setToFinalLoading || saveLoading || loading || generateEvaluationLoading  || !allQuestionsMarked}
                  >
                    {setToFinalLoading ? (
                      <i className="fas fa-cog fa-spin"></i>
                    ) : (
                      <i className="fal fa-check"></i>
                    )}
                    Set to Final
                  </button>

                <button
                  className="btn btn-close btn-danger"
                  type="button"
                  title="Cancel"
                  onClick={() => {
                      formik.resetForm()
                      setFeedbackFiles(backupFeedbackFiles)
                    }
                  }
                >
                  <i className="fal fa-times"></i>Cancel
                </button>
              </div>
              {/** map function to render all errors  */}
              {Object.keys(formik.errors).map((key) => {
                if (formik.touched[key]) {
                    return (
                        <div className="invalid-feedback d-block" key={key}>
                        {formik.errors[key]}
                        </div>
                    );
                }
                return null;
              })}
            </form>
          </div>
        </div>
      </div>

      <div className="topic-add-modal modal fade able_modal01" id="criteriaSubmission" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered available-assessment-modal" role="document">

          <div className="modal-content">
            {/* <div className="modal-body"> */}
            <div className="modal-header modal-header-custom">
              <h5 className="modal-title" title="Criteria Evidence"><i className="fal fa-clipboard-list-check"></i> Criteria Evidence</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" title="Close"><span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <CriteriaSubmission studentDetailList={studentDetailList} setStudentDetailList={setStudentDetailList} fetchData={fetchData} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StudentSelfAssessment
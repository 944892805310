import React, { useState, useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import "jspdf-autotable";
import Select from "react-select";
import SkeletonTicketList from "../../../../../loaders/SkeletonTicketList";
import { IMAGE_URL, TABLE_ROWS_PER_PAGE } from "../../../../../utils/Constants";
import { Year } from "../../../../systemadministration/DataReactSelect";
import { DeleteCertificate, DeleteHighestSchoolDetails, HighestSchoolCertificate, UpdateHighestSchoolDetails } from "../../../../../services/StudentsSettingServices";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import Str from "../../../../common/Str";
import DataTable from "react-data-table-component";
import { myCompare } from "../../../../common/MyCompare";
import PermissionsGate from "../../../../../utils/permissionGate";
function HighestQualificationDetailsBlock({ highestQualification, setDeleteRow, deleteRow }) {
  const { id } = useParams();

  const [studentData, setStudentData] = useState([]);

  const [loading, setloading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [filePreview, setFilePreview] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Qualification: "",
      Place: "",
      Year: "",
      File: "",
      TempRowId: "",
    },
    validationSchema: Yup.object({
      Qualification: Yup.string().required("Programme is required").trim(),
      Place: Yup.string().required("Place is required").trim(),
      Year: Yup.string().required("Year is required"),
      File: Yup.mixed().required("File is required"),
    }),
    onSubmit: (values) => {
      setDisabled(true);
      let tempUserData = studentData;
      tempUserData.map((item) => {
        if (item.id == values.TempRowId) {
          item.newRow = false;
          return item;
        } else {
          return item;
        }
      });
      setStudentData((tempUserData) => [...tempUserData]);

      const formData = new FormData();
      formData.append("student_id", id);
      formData.append("from_admin", 1);
      formData.append("qualification", values.Qualification);
      formData.append("place", values.Place);
      formData.append("year", values.Year);
      formData.append("HighestSchoolCertificate", values.File);
      UpdateHighestSchoolDetails(formData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Added successfully",
          });
          setDeleteRow(!deleteRow);
        })
        .catch((err) => {
          console.log("error :", err);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${err?.response?.data}`,
          });
          setDeleteRow(!deleteRow);
        });
    },
  });

  useEffect(() => {
    highestQualification && setStudentData(highestQualification);
  }, [highestQualification]);

  const handleAddRow = () => {
    // let tempArr = studentData;
    let newVal = {
      id: Math.random().toPrecision(10).slice(2),
      qualification: "",
      place: "",
      year: "",
      url: "",
      file: "",
      newRow: true,
    };
    // tempArr.push(newVal)
    setStudentData((studentData) => [...studentData, newVal]);
    // dataToRender()
    // console.log("test.", tempArr, studentData)
  };

  const handleChangeQualificationName = (e, rowId) => {
    let tempUserData = studentData;
    tempUserData.map((item) => {
      if (item.id == rowId) {
        item.qualification = e.target.value;
        return item;
      } else {
        return item;
      }
    });
    formik.setFieldValue("Qualification", e.target.value);
    setStudentData((tempUserData) => [...tempUserData]);
  };

  const handleChangePlace = (e, rowId) => {
    let tempUserData = studentData;
    tempUserData.map((item) => {
      if (item.id == rowId) {
        item.place = e.target.value;
        return item;
      } else {
        return item;
      }
    });
    formik.setFieldValue("Place", e.target.value);
    setStudentData((tempUserData) => [...tempUserData]);
  };

  const handleChangeYear = (e, rowId) => {
    let tempUserData = studentData;
    tempUserData.map((item) => {
      if (item.id == rowId) {
        item.year = e.value;
        return item;
      } else {
        return item;
      }
    });
    formik.setFieldValue("Year", e.value);
    setStudentData((tempUserData) => [...tempUserData]);
  };
  const addFileFun = (e, rowId, row) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      setFilePreview(e.target.result);
    };
    reader.readAsDataURL(e.target.files[0]);
    let tempUserData = studentData;
    tempUserData.map((item) => {
      if (item.id == rowId) {
        item.url = e.target.files[0].name;
        item.file = e.target.files[0];
        return item;
      } else {
        return item;
      }
    });
    formik.setFieldValue("File", e.target.files[0]);
    setStudentData((tempUserData) => [...tempUserData]);
    if (!row.newRow) {
      const formData = new FormData();
      formData.append("student_id", id);
      formData.append("qualification_id", rowId);
      formData.append("HighestSchoolCertificate", e.target.files[0]);
      HighestSchoolCertificate(formData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Added",
            text: "File Added successfully",
          });
          setDeleteRow(!deleteRow);
        })
        .catch((err) => {
          console.log("error :", err);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${err?.response?.data}`,
          });
          setDeleteRow(!deleteRow);
        });
    }
  };

  const removeFileFunc = (idNewRow, certId, rowId) => {
    let tempUserData = studentData;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "warning",
          title: "Deleting Record",
          showConfirmButton: false,
        });
        if (idNewRow) {
          tempUserData.map((item) => {
            if (item.id == rowId) {
              item.url = "";
              item.file = "";
              return item;
            } else {
              return item;
            }
          });
          setStudentData((tempUserData) => [...tempUserData]);
        } else {
          DeleteCertificate(id, certId, "certificate")
            .then((res) => {
              Swal.fire("Deleted!", "File Deleted.", "success");
              setDeleteRow(!deleteRow);
            })
            .catch((err) => {
              if (err.response.status === 422) {
                setDeleteRow(!deleteRow);
                Swal.fire("", `Unable To Delete.`, "warning");
              }
            });
        }
        Swal.fire("Deleted!", "File has been deleted.", "success");
      }
    });
  };

  const handleDelete = (rowId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "warning",
          title: "Deleting Record",
          showConfirmButton: false,
        });
        DeleteHighestSchoolDetails(id, rowId)
          .then((res) => {
            setDeleteRow(!deleteRow);
            Swal.fire("Deleted!", "Record has been deleted.", "success");
          })
          .catch((err) => {
            if (err.response.status === 422) {
              setDeleteRow(!deleteRow);
              Swal.fire("", `Unable To Delete.`, "warning");
            }
          });
      }
    });
  };

  const handleDeleteCertificate = (certId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: "warning",
            title: "Deleting Record",
            showConfirmButton: false,
          });

          DeleteCertificate(id, certId, "certificate")
            .then((res) => {
              setDeleteRow(!deleteRow);
              Swal.fire("Deleted!", "File Deleted.", "success");
            })
            .catch((err) => {
              if (err.response.status === 422) {
                setDeleteRow(!deleteRow);
                Swal.fire("", `Unable To Delete.`, "warning");
              }
            });
        }
      })
      .catch((error) => {
        setDeleteRow(!deleteRow);
      });
  };

  const handleSave = async (rowId, row) => {
    await formik.setFieldValue("Qualification", row.qualification);
    await formik.setFieldValue("Place", row.place);
    await formik.setFieldValue("Year", row.year);
    await formik.setFieldValue("File", row.file);
    await formik.setFieldValue("TempRowId", row.id);
    formik.handleSubmit();
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header justify-content-between">
        <div className="card-header">Highest Qualification Details</div>
        <PermissionsGate errorProps={{ disabled: true }} scopes={["sedadd"]}>
          <button className="btn btn-primary" onClick={() => handleAddRow()} title="Add Row">
            <i className="fal fa-plus"></i>Add Row
          </button>
        </PermissionsGate>
      </div>
      {/* <div className="add-ticket-blk button-reset dropdown-comman mb-2 right-align">
      </div> */}
      <div
      // className="custom-table-div filter-search-icon card card-table-custom program-table"
      >
        {loading ? (
          <SkeletonTicketList />
        ) : !studentData || !studentData.length ? (
          <DataTable
            columns={[]}
            data={[]}
            noDataComponent={Str.noRecord}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />
        ) : (





          <div className="custom-main-table custom-table-div select_tp_year01">


            {/* --new4r-- */}
            {/* <div className="highest_qualification_dtls">
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-md-4 mb-4">
                        <label htmlFor="Title">Title </label>
                        <div className="form-icon-group ">
                          <input
                            type="text"
                            id="Title"
                            className={
                              "form-control" +
                              (formik.errors.Title && formik.touched.Title
                                ? " is-invalid"
                                : "")
                            }
                            name="Title"
                            placeholder="Enter Title"
                            value={formik.values.Title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            title="Title"
                          />
                          {formik.errors.Title && formik.touched.Title ? (
                            <span className="exclamation">
                              <i className="fal fa-exclamation-circle"></i>
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4 mb-4">
                        <label htmlFor="Title">Title </label>
                        <div className="form-icon-group ">
                          <input
                            type="text"
                            id="Title"
                            className={
                              "form-control" +
                              (formik.errors.Title && formik.touched.Title
                                ? " is-invalid"
                                : "")
                            }
                            name="Title"
                            placeholder="Enter Title"
                            value={formik.values.Title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            title="Title"
                          />
                          {formik.errors.Title && formik.touched.Title ? (
                            <span className="exclamation">
                              <i className="fal fa-exclamation-circle"></i>
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4 mb-4">
                        <label htmlFor="Title">Title </label>
                        <div className="form-icon-group ">
                          <input
                            type="text"
                            id="Title"
                            className={
                              "form-control" +
                              (formik.errors.Title && formik.touched.Title
                                ? " is-invalid"
                                : "")
                            }
                            name="Title"
                            placeholder="Enter Title"
                            value={formik.values.Title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            title="Title"
                          />
                          {formik.errors.Title && formik.touched.Title ? (
                            <span className="exclamation">
                              <i className="fal fa-exclamation-circle"></i>
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-12">
                         <div className="form-group atttach-file">
                            <label className={formik.errors.ProfilePicture && formik.touched.ProfilePicture && "file-req is-invalid"}>
                              <i className="fal fa-paperclip"></i>
                              <span>Add Attachment</span>
                              <input
                                type="file"
                                accept="image/*"
                                className="form-control  form-control-aatch-file"
                                // onChange={(e) => {
                                //   if (e.target.files.length) {
                                //     let reader = new FileReader();
                                //     reader.onload = (e) => {
                                //       setFilePreview(e.target.result);
                                //     };
                                //     reader.readAsDataURL(e.target.files[0]);
                                //     formik.setFieldValue("ProfilePicture", e.target.files[0]);
                                //     setProfilePic(e.target.files[0]);
                                //     setProfilePicName(e.target.files[0].name);
                                //   }
                                // }}
                              />
                            </label>
                          </div>
                      </div>
                    </div>
                </form>
                </div> */}
            {/* --End_new4r-- */}



            {/* <div className="rdt_Table">
              <div className="rdt_TableHead">
                <div className="rdt_TableHeadRow">
                  <div className="rdt_TableCol">Qualification Name</div>
                  <div className="rdt_TableCol">Place Of Study</div>
                  <div className="rdt_TableCol">Year</div>
                  <div className="rdt_TableCol">Upload File</div>
                  <div className="rdt_TableCol">Action</div>
                </div>
              </div> */}
            {studentData.map((data) => {
              return (
                <div className="highest_qualification_dtls">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-md-6 col-lg-3 mb-4">
                        <label htmlFor="QualificationName">Qualification Name *</label>
                        <div className="form-icon-group ">
                          <input
                            type="text"
                            title="Qualification Name"
                            id={data.qualification?.substring(data.qualification?.lastIndexOf("/") + 1)}
                            className={"form-control" + (formik.errors.Qualification && formik.touched.Qualification && formik.values.TempRowId == data.id ? " is-invalid" : "")}
                            placeholder="Enter Qualification"
                            value={data.qualification?.substring(data.qualification?.lastIndexOf("/") + 1)}
                            name={data.qualification?.substring(data.qualification?.lastIndexOf("/") + 1)}
                            // onChange={(e) => handleChangeQualificationName(e, row.id)}
                            onChange={(e) => handleChangeQualificationName(e, data.id)}
                          />
                          {formik.errors.Qualification && formik.touched.Qualification ? (
                            <span className="exclamation">
                              <i className="fal fa-exclamation-circle"></i>
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3 mb-4">
                        <label htmlFor="place">Place Of Study *</label>
                        <div className="form-icon-group ">
                          <input
                            type="text"
                            title="Place"
                            id={data.place}
                            className={"form-control" + (formik.errors.Place && formik.touched.Place && formik.values.TempRowId == data.id ? " is-invalid" : "")}
                            name={data.place}
                            placeholder="Enter Place Of Study"
                            value={data.place}
                            onChange={(e) => handleChangePlace(e, data.id)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3 mb-4">
                        <label htmlFor="Year">Year *</label>
                        <div className="form-icon-group" title="Year">
                          <Select
                            className={"form-control custom-select-box " + (formik.errors.Year && formik.touched.Year && formik.values.TempRowId == data.id ? " is-invalid" : "")}
                            name="Year"
                            value={Year.filter((item) => {
                              return item.value == data.year;
                            })}
                            onChange={(e) => {
                              if(e){
                                handleChangeYear(e, data.id)
                              } else {
                                handleChangeYear("", data.id)
                              }
                            }}
                            options={Year.sort(myCompare).reverse()}
                            maxMenuHeight={175}
                            autoComplete="off"
                            isClearable
                            placeholder={data.year ? data.year : "Select"}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <label htmlFor="Upload File">Upload File *</label>
                        <div className="form-group-blk mb-3 upload-file-icon d-flex justify-content-between">
                          <div className="">
                            <div className=" atttach-file ">
                              <label className={formik.errors.File && formik.touched.File && formik.values.TempRowId == data.id ? "file-req is-invalid" : ""}>
                                <i className="fal fa-paperclip"></i>
                                <span>Add Attachment</span>
                                <PermissionsGate errorProps={{ disabled: true }} scopes={["sededit"]}>
                                  <input
                                    type="file"
                                    title={data.url ? data.url?.substring(data.url?.lastIndexOf("/") + 1) : "No File Uploaded"}
                                    className="form-control  form-control-aatch-file"
                                    onChange={(e) => addFileFun(e, data.id, data)}
                                  />
                                </PermissionsGate>
                              </label>
                            </div>
                            {data.url && (
                              <ul className="list-unstyled attached-file-ul m-0">
                                <li title={data.url?.substring(data.url?.lastIndexOf("/") + 1)}>
                                  {data.newRow ? (
                                    <>
                                      <a download href={filePreview} target="_blank">
                                        <i className="fal fa-file"></i>
                                        &nbsp;
                                        {(data.url?.substring(data.url?.lastIndexOf("/") + 1)).length > 15
                                          ? data.url?.substring(data.url?.lastIndexOf("/") + 1).slice(0, 15) + "..."
                                          : data.url?.substring(data.url?.lastIndexOf("/") + 1)}
                                      </a>
                                      {/* <PermissionsGate errorProps={{ disabled: true }} scopes={["sededit"]}>
                                      <button className="btn btn-danger" title="Delete File" onClick={() => removeFileFunc(data.newRow, data.id, data.id)}>
                                        <i className="fal fa-trash-alt"></i>
                                      </button>
                                    </PermissionsGate> */}
                                    </>
                                  ) : (
                                    <>
                                      <a download href={IMAGE_URL + "/" + encodeURI(data.url.replace("public/", ""))} target="_blank">
                                        <i className="fal fa-file"></i>
                                        &nbsp;
                                        {(data.url?.substring(data.url?.lastIndexOf("/") + 1)).length > 15
                                          ? data.url?.substring(data.url?.lastIndexOf("/") + 1).slice(0, 15) + "..."
                                          : data.url?.substring(data.url?.lastIndexOf("/") + 1)}
                                      </a>
                                      {/* <PermissionsGate errorProps={{ disabled: true }} scopes={["sededit"]}>
                                      <button className="btn btn-danger" title="Delete File" onClick={() => removeFileFunc(data.newRow, data.certificate_id, data.id)} type={"button"}>
                                        <i className="fal fa-trash-alt"></i>
                                      </button>
                                    </PermissionsGate> */}
                                    </>
                                  )}
                                </li>
                              </ul>
                            )}
                          </div>
                          <div className="">
                            <div className="assessment-08">
                              <div className="as-buttons">
                                {!data.newRow ? (
                                  <PermissionsGate errorProps={{ disabled: true }} scopes={["sededit"]}>
                                    <button className="btn btn-danger rounded-circle" title="Delete" type="button" onClick={() => handleDelete(data.id)}>
                                      <i className="fal fa-trash-alt"></i>
                                    </button>
                                  </PermissionsGate>
                                ) : (
                                  <PermissionsGate errorProps={{ disabled: true }} scopes={["sededit"]}>
                                    <button className="btn btn-primary rounded-circle" title="Save" onClick={() => handleSave(data.id, data)}>
                                      <i className="fal fa-save"></i>
                                    </button>
                                  </PermissionsGate>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                // <div className="rdt_TableBody select-table">
                //   <div className="rdt_TableRow">
                //     <div className="rdt_TableCell">
                //       {/* <input type="text" id={data.certificate?.substring(data.certificate?.lastIndexOf('/')+1)} className="form-control" disabled="" name={data.certificate?.substring(data.certificate?.lastIndexOf('/')+1)} value={data.certificate?.substring(data.certificate?.lastIndexOf('/')+1)}/> */}
                //       <input
                //         type="text"
                //         title="Qualification Name"
                //         id={data.qualification?.substring(data.qualification?.lastIndexOf("/") + 1)}
                //         className={"form-control" + (formik.errors.Qualification && formik.touched.Qualification && formik.values.TempRowId == data.id ? " is-invalid" : "")}
                //         placeholder="Enter Qualification"
                //         value={data.qualification?.substring(data.qualification?.lastIndexOf("/") + 1)}
                //         name={data.qualification?.substring(data.qualification?.lastIndexOf("/") + 1)}
                //         // onChange={(e) => handleChangeQualificationName(e, row.id)}
                //         onChange={(e) => handleChangeQualificationName(e, data.id)}
                //       />
                //     </div>
                //     <div className="rdt_TableCell">
                //       {/* <input type="text" id={data.certificate_original_name} className="form-control" disabled="" name={data.certificate_original_name} value={data.certificate_original_name} /> */}
                //       <input
                //         type="text"
                //         title="Place"
                //         id={data.place}
                //         className={"form-control" + (formik.errors.Place && formik.touched.Place && formik.values.TempRowId == data.id ? " is-invalid" : "")}
                //         name={data.place}
                //         placeholder="Enter Place Of Study"
                //         value={data.place}
                //         onChange={(e) => handleChangePlace(e, data.id)}
                //       />
                //     </div>
                //     <div className="rdt_TableCell">
                //       <div className="form-icon-group">
                //         <Select
                //           className={"form-control custom-select-box " + (formik.errors.Year && formik.touched.Year && formik.values.TempRowId == data.id ? " is-invalid" : "")}
                //           name="Year"
                //           value={Year.filter((item) => {
                //             return item.value == data.year;
                //           })}
                //           onChange={(e) => handleChangeYear(e, data.id)}
                //           options={Year.sort(myCompare).reverse()}
                //           maxMenuHeight={175}
                //           autoComplete="off"
                //           // placeholder={"Select"}
                //         />
                //       </div>
                //     </div>
                //     <div className="rdt_TableCell">
                //       <div className="files-list-item frm-group">
                //         <div className=" atttach-file ">
                //           <label className={formik.errors.File && formik.touched.File && formik.values.TempRowId == data.id ? "file-req is-invalid" : ""}>
                //             <i className="fal fa-paperclip"></i>
                //             <span>Add Attachment</span>
                //             <PermissionsGate errorProps={{ disabled: true }} scopes={["sededit"]}>
                //               <input
                //                 type="file"
                //                 title={data.url ? data.url?.substring(data.url?.lastIndexOf("/") + 1) : "No File Uploaded"}
                //                 className="form-control  form-control-aatch-file"
                //                 onChange={(e) => addFileFun(e, data.id, data)}
                //               />
                //             </PermissionsGate>
                //           </label>
                //         </div>
                //         {data.url && (
                //           <ul className="list-unstyled attached-file-ul m-0">
                //             <li title={data.url?.substring(data.url?.lastIndexOf("/") + 1)}>
                //               {data.newRow ? (
                //                 <>
                //                   <a download href={filePreview} target="_blank">
                //                     <i className="fal fa-file"></i>
                //                     &nbsp;
                //                     {(data.url?.substring(data.url?.lastIndexOf("/") + 1)).length > 15
                //                       ? data.url?.substring(data.url?.lastIndexOf("/") + 1).slice(0, 15) + "..."
                //                       : data.url?.substring(data.url?.lastIndexOf("/") + 1)}
                //                   </a>
                //                   {/* <PermissionsGate errorProps={{ disabled: true }} scopes={["sededit"]}>
                //                     <button className="btn btn-danger" title="Delete File" onClick={() => removeFileFunc(data.newRow, data.id, data.id)}>
                //                       <i className="fal fa-trash-alt"></i>
                //                     </button>
                //                   </PermissionsGate> */}
                //                 </>
                //               ) : (
                //                 <>
                //                   <a download href={IMAGE_URL + "/" + data.url.replace("public/", "")} target="_blank">
                //                     <i className="fal fa-file"></i>
                //                     &nbsp;
                //                     {(data.url?.substring(data.url?.lastIndexOf("/") + 1)).length > 15
                //                       ? data.url?.substring(data.url?.lastIndexOf("/") + 1).slice(0, 15) + "..."
                //                       : data.url?.substring(data.url?.lastIndexOf("/") + 1)}
                //                   </a>
                //                   {/* <PermissionsGate errorProps={{ disabled: true }} scopes={["sededit"]}>
                //                     <button className="btn btn-danger" title="Delete File" onClick={() => removeFileFunc(data.newRow, data.certificate_id, data.id)} type={"button"}>
                //                       <i className="fal fa-trash-alt"></i>
                //                     </button>
                //                   </PermissionsGate> */}
                //                 </>
                //               )}
                //             </li>
                //           </ul>
                //         )}
                //       </div>
                //     </div>
                //     <div className="rdt_TableCell">
                //       <div className="assessment-08">
                //         <div className="as-buttons">
                //           {!data.newRow ? (
                //             <PermissionsGate errorProps={{ disabled: true }} scopes={["sededit"]}>
                //               <button className="btn btn-danger rounded-circle" title="Delete" onClick={() => handleDelete(data.id)}>
                //                 <i className="fal fa-trash-alt"></i>
                //               </button>
                //             </PermissionsGate>
                //           ) : (
                //             <PermissionsGate errorProps={{ disabled: true }} scopes={["sededit"]}>
                //               <button className="btn btn-primary rounded-circle" title="Save" onClick={() => handleSave(data.id, data)}>
                //                 <i className="fal fa-save"></i>
                //               </button>
                //             </PermissionsGate>
                //           )}
                //         </div>
                //       </div>
                //     </div>
                //   </div>
                // </div>
              );
            })}
            {/* </div> */}
            {formik.touched.Qualification && formik.errors.Qualification ? <div className="invalid-feedback d-block">{formik.errors.Qualification}</div> : null}
            {formik.touched.Place && formik.errors.Place ? <div className="invalid-feedback d-block">{formik.errors.Place}</div> : null}
            {formik.touched.Year && formik.errors.Year ? <div className="invalid-feedback d-block">{formik.errors.Year}</div> : null}
            {formik.touched.File && formik.errors.File ? <div className="invalid-feedback d-block">{formik.errors.File}</div> : null}
          </div>
        )}
      </div>
    </div>
  );
}

export default HighestQualificationDetailsBlock;

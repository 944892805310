import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";
import AuditTrail from "../../StudentAdministration/Students/AuditTrail";
import CourseLandingPagesOpt from "./CourseLandingPagesOpt";
import { GetCourseLandingUpdateList } from "../../../services/CourseLandingPageServer";
import { getCourseLandingPagesAuditTrailList, getCourseLandingPagesAuditTrailListFilters } from "../../../services/WebsiteListServer";
import { RenderStudentResourceType } from "../../../utils/CommonGroupingItem";
import AuditTrailsTable from "../../common/AuditTrailsTable";
import PreviewDropdown from "../../common/PreviewDropdown";
// import { getWebsiteListByBrand } from "../../../services/ProgramService";
// This will be shown in merge-all branch

function CreateCourseLandingPages() {
    const history = useHistory();
    const { tab, type, id, subId } = useParams();
    const columnsToShow = ["Action Type", "Resource Type", "Resource Name"]
    const apiParams = {
        action_id: subId || id,
    }
    // const [previewDomain, setPreviewDomain] = useState([]);
    const [updateData, setUpdateData] = useState({});
    const [saveChanges, setSaveChanges] = useState(false);
    
    useEffect(() => {
        GetCourseLandingUpdateList(id)
        .then(res => {
            setUpdateData(res?.data?.Data)
            // let brandTemplates = res?.data?.Data?.brandTemplete;
            // let brandIds  = brandTemplates.map(brand => brand.brandID);

            // if (res?.data?.Data?.status == 1){
            //     setPreviewDomain("https://aie.ac");
            // }else if (brandIds) {
            //     //check if brand_ids contain 1
            //     if (brandIds.includes(1)) {
            //         setPreviewDomain('https://next.aie.ac');
            //     } else if (brandIds.includes(4)) {
            //         setPreviewDomain('https://next.designcenter.co.za');
            //     } else if(brandIds.includes(3)) {
            //         setPreviewDomain('https://next.emendy.co.za');
            //     } else if(brandIds.includes(2)) {
            //         setPreviewDomain('https://next.learnfast.co.za');
            //     } else if(brandIds.includes(6)) {
            //         setPreviewDomain("https://next.cadco.co.za");
            //     }
            // }else{
            //     setPreviewDomain("https://next.aie.ac");
            // }

            if(saveChanges){
                setSaveChanges(false);
            }
        })
        .catch(err => console.log("Error fetching Get For Update data", err))
    }, [id , saveChanges])

    // useEffect(() => {
    //     const previewUrl = () => {
    //         if (!updateData?.brandTemplete) return;

    //         const brandId = updateData.brandTemplete.map(item => item.brandID);
    //         const brandIdData = { brandId: brandId }

    //         getWebsiteListByBrand(brandIdData)
    //             .then(res => {
    //                 if (updateData?.status == 1) {
    //                     const website = res?.data?.data?.filter(item => item.branch == "development" || item.branch == "production");
    //                     setPreviewDomain(website)
    //                 } else {
    //                     const website = res?.data?.data?.filter(item => item.branch == "development");
    //                     setPreviewDomain(website)
    //                 }
    //             })
    //             .catch(err => {
    //                 console.error(err, "err")
    //             })
    //     }

    //     if (updateData?.length != 0) {
    //         previewUrl()
    //     }
    // }, [updateData])
    
    return (
        <>
        <Header />
        <div className="content-wrapper d-flex flex-wrap">
        <SideBar />
        <div className={"sidebar-right " + (tab === "gradebook" ? "bottom-100" : "")}>
        <div className="sub-menu-content-block">
        {/* <Tabs /> */}
        <div className="sub-menu-right-block">
        <div className="wrap-box-right">
        <div className="my-tickets-heading-blk flex-direction mb-2">
        <div className="d-flex align-items-center ">
        <div className="name-w-head d-flex align-items-center ">
        <h4 className="text-left-align landing-heading heading_color_dynamic">
        {/* Student <span>List</span> */}
        Course Landing Pages: <span>{type == "open" || type == "auditTrail" ? updateData?.PageTitle : "Create New"}</span>
        </h4>
        </div>
        <div className="text-md-right action2-blk">
        <div className="ticket-view-btn d-flex align-items-center">
        <div className="add-new-ticket-bx">
        <button onClick={() => history.goBack()} title="Back" className="btn btn-white-bordered">
        <i className="fal fa-angle-left"></i>Back
        </button>
        </div>
        </div>
        </div>
        {id ? <PreviewDropdown updateData={updateData} status={updateData?.status} pageUrl="courselandingpages" id={encodeURIComponent(encodeURIComponent(updateData?.Slug))} saveChanges={saveChanges}/> : null}
        </div>
        </div>
        <div className="tabs-wrap">
        {(type === "auditTrail" || type === "open") && (
            <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
            <li className="nav-item" onClick={() => history.push(`/website_management/pages/courselandingpages/table/open/${id}`)}>
            <Link
            className={`nav-link ${type === "open" && "active"}`}
            id="pills-tab1"
            data-toggle="pill"
            to={`/website_management/pages/courselandingpages/table/open/${id}`}
            role="tab"
            aria-controls="pills-tab11"
            aria-selected="true"
            title="Course Landing Pages"
            >
            <i className="fa fa-info-circle"></i>
            Course Landing Pages
            </Link>
            </li>
            <li className="nav-item" onClick={() => history.push(`/website_management/pages/courselandingpages/table/auditTrail/${id}`)}>
            <Link
            className={`nav-link ${type === "auditTrail" && "active"}`}
            id="pills-tab2"
            data-toggle="pill"
            to={`/website_management/pages/courselandingpages/table/auditTrail/${id}`}
            role="tab"
            aria-controls="pills-tab12"
            aria-selected="true"
            title="audit trail"
            >
            <i className="fal fa-history"></i>Audit trail
            </Link>
            </li>
            </ul>
        )}
        <div className="tab-content" id="pills-tabContent">
        <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
        {type == "create" && <CourseLandingPagesOpt />}
        {(type == "open" || type == "re-arrange") && <CourseLandingPagesOpt id={id} setSaveChanges={setSaveChanges} />}
        {type == "auditTrail" && <AuditTrailsTable
            apiFunction={getCourseLandingPagesAuditTrailList}
            filterArrApi={getCourseLandingPagesAuditTrailListFilters}
            columnsToShow={columnsToShow}
            exportFileName={"Course Landing Pages Audit Trails"}
            isResourceNameShown={true}
            apiParams={apiParams}
            actionId={subId || id}
            isShowingResourceTypeFilter
            renderResourceTypeFunction={RenderStudentResourceType}
            />}
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </>
        );
    }
    
    export default CreateCourseLandingPages;
    
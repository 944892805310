import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { StudentSendTrackingCommunication, StudentFileUploads, StudentSendTrackingCommunicationLaptop,StudentSendConfirmationCommunicationLaptop , StudentSendConfirmationCommunication, deletePdfLetter, checkCheckboxRejectDetails } from "../../../../services/RegistrationService";
import Swal from "sweetalert2";
import { RenderAttachList } from "../../../common/RenderAttachList";
import { FORM_DATE_FORMAT, IMAGE_URL } from "../../../../utils/Constants";
import $ from 'jquery'
import RenderPreviousValues from "./RenderPreviousValues";
import { useSelector } from "react-redux";

function StudyKits(props) {
  const [kitTypeData, setKitTypeData] = useState([]);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const studentId = params.get('studentId');
  const [loading, setLoading] = useState(false);
  const [otherDocumentFormList, setOtherDocumentFormList] = useState([]);  
  const [laptopDocumentFormList, setLaptopDocumentFormList] = useState([]);  
  let laptopDelTypeOptions = [
    {
      label: "Select Delivery Method",
      value: ""
    },
    {
      label: "Student to Collect",
      value: "collect"
    },
    {
      label: "Deliver to Student",
      value: "deliver"
    }
  ]
  const formik = useFormikContext()

  useEffect(() => {
    setOtherDocumentFormList(props.studentData?.attach_way_bill)
    setLaptopDocumentFormList(props.studentData?.laptop_attach_way_bill)
    let data = props.studentData?.re_reg_data;
    let kitTypeOption = []
    if (data?.status == 'new-status' || data?.status == 'Closed-Ordered2' || data?.status == 'Rejected' || data?.status == 'assign-subjects' || data?.status == 'Closed-Ordered' || data?.status == 'Registration Withdrawn' || data?.status == 'Final-Status') {
      if (formik.values.school_id == "engineering" || formik.values.school_id == "business") {
        kitTypeOption = [
          {
            label: "Select Kit Type",
            value: ""
          },
          {
            label: "Student to Collect",
            value: "collect"
          },
          {
            label: "Deliver to Student",
            value: "deliver"
          },
          {
            label: "eKit only",
            value: "ekit"
          },
        ]
      } else {
        kitTypeOption = [
          {
            label: "Select Kit Type",
            value: ""
          },
          {
            label: "Deliver to Student",
            value: "deliver"
          },
          {
            label: "Student to Collect",
            value: "collect"
          },
          {
            label: "No kit required",
            value: "no_kit_required"
          }
        ]
      }
      if (data?.student_kit_sent == "ekit") {
        $('#collect_kit_div').hide();
        $('#sent_kit_div').hide();
      }
    } else {
      if (formik.values.school_id == "engineering") {
        kitTypeOption = [
          {
            label: "Select Kit Type",
            value: ""
          },
          {
            label: "Student to Collect",
            value: "collect"
          },
          {
            label: "Deliver to Student",
            value: "deliver"
          },
          {
            label: "eKit only",
            value: "ekit"
          },
        ]
      } else if (formik.values.school_id == "draughting") {
        kitTypeOption = [
          {
            label: "Select Kit Type",
            value: ""
          },
          {
            label: "No kit required",
            value: "no_kit_required"
          },
          {
            label: "Student to Collect",
            value: "collect"
          },
          {
            label: "Deliver to Student",
            value: "deliver"
          },
        ]
      } else {
        kitTypeOption = [
          {
            label: "Select Kit Type",
            value: ""
          },
          {
            label: "Deliver to Student",
            value: "deliver"
          },
          {
            label: "Student to Collect",
            value: "collect"
          },
        ]
          kitTypeOption.push({
            label: "No kit required",
            value: "no_kit_required"
          })
      }
      if (data?.student_kit_sent == "ekit") {
        $('#collect_kit_div').hide();
        $('#sent_kit_div').hide();
      }

      if (data?.student_kit_sent == "no_kit_required") {
        $('#collect_kit_div').hide();
        $('#sent_kit_div').hide();
      }
    }
    setKitTypeData(kitTypeOption)
    // if(data?.student_kit_sent=="deliver"){
    //   $('#sent_kit_div').show();
    //   $('#collect_kit_div').hide();
    //   }

  }, [props, formik]);

  useEffect(() => {
    let data = props.studentData?.re_reg_data;
    if (data?.student_kit_sent == "deliver") {
      $('#sent_kit_div').show();
      $('#collect_kit_div').hide();
    }
    else if (data?.student_kit_sent == "ekit") {
      $('#collect_kit_div').hide();
      $('#sent_kit_div').hide();

    }
    else if (data?.student_kit_sent == "no_kit_required") {
      $('#collect_kit_div').hide();
      $('#sent_kit_div').hide();

    } else if (data?.student_kit_sent == "collect") {
      $('#collect_kit_div').show();
      $('#sent_kit_div').hide();

    } else {
      if (data?.tracking_no != '' && data?.student_kit_sent != 'no_kit_required') {
        $('#sent_kit_div').show();
        $('#collect_kit_div').hide();
      } else
        if (data?.kit_collected_date != '' && data?.student_kit_sent != 'no_kit_required') {
          $('#collect_kit_div').show();
          $('#sent_kit_div').hide();
        } else{
            $('#collect_kit_div').hide();
            $('#sent_kit_div').hide();
          }
    }
  }, [])

  const sendNotification = async () => {
    setLoading(true);
    if (!formik.values.trackingNo) {
      await formik.setFieldTouched("trackingNo", true, true)
      await formik.setFieldError("trackingNo", "Tracking No is required")
      return
    }
    const formdata = new FormData();
    formdata.append("snew", 0)
    formdata.append("userid", studentId)
    formdata.append("tracking_no", formik.values.trackingNo)
    formdata.append("email", formik.values.Email)
    formdata.append("mobile", formik.values.Mobile)
    formdata.append("kitType", formik.values.kitType)
    formdata.append("notify_email", 1)
    formdata.append("notify_sms", false ? 1 : 0)
    formdata.append("re_reg", 1)

    StudentSendTrackingCommunication(formdata)
      .then((res) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `${res?.data?.message}`,
        });
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.message}`,
        });
      });
  }

  const sendNotificationLaptop = async () => {
    setLoading(true);
    if (!formik.values.laptop_tracking_num) {
      await formik.setFieldTouched("laptop_tracking_num", true, true)
      await formik.setFieldError("laptop_tracking_num", "Tracking No is required")
      return
    }
    const formdata = new FormData();
    formdata.append("snew", 0)
    formdata.append("userid", studentId)
    formdata.append("tracking_no", formik.values.laptop_tracking_num)
    formdata.append("email", formik.values.Email)
    formdata.append("mobile", formik.values.Mobile)
    formdata.append("kitType", "Laptop")
    formdata.append("notify_email", 1)
    formdata.append("notify_sms", false ? 1 : 0)
    formdata.append("re_reg", 1)

    StudentSendTrackingCommunicationLaptop(formdata)
      .then((res) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `${res?.data?.message}`,
        });
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.message}`,
        });
      });
  };

  const sendConfirmationCommunication = async() => {
    setLoading(true);
    if (!formik.values.dateCollected) {
      await formik.setFieldTouched("dateCollected", true, true)
      await formik.setFieldError("dateCollected", "Kit Collected Date is required");
      return 
    }
    const formdata = new FormData();
    formdata.append("snew", 0)
    formdata.append("userid", studentId)
    formdata.append("kit_collected_date", formik.values.dateCollected)
    formdata.append("email", formik.values.Email)
    formdata.append("mobile", formik.values.Mobile)
    formdata.append("kitType", formik.values.kitType)
    formdata.append("notify_email", 1)
    formdata.append("re_reg", 1)

    StudentSendConfirmationCommunication(formdata)
      .then((res) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `${res?.data?.message}`,
        });
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire({          
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.message}`,
        });
      });
  }
  const sendConfirmationCommunicationLaptop = async() => {
    setLoading(true);
    if (!formik.values.laptop_collected_date) {
      await formik.setFieldTouched("laptop_collected_date", true, true)
      await formik.setFieldError("laptop_collected_date", "Laptop Collected Date is required");
      return 
    }
    const formdata = new FormData();
    formdata.append("snew", 0)
    formdata.append("userid", studentId)
    formdata.append("kit_collected_date", formik.values.laptop_collected_date)
    formdata.append("email", formik.values.Email)
    formdata.append("mobile", formik.values.Mobile)
    formdata.append("kitType", "Laptop")
    formdata.append("notify_email", 1)
    formdata.append("re_reg", 1)    

    StudentSendConfirmationCommunicationLaptop(formdata)
      .then((res) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `${res?.data?.message}`,
        });
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.message}`,
        });
      });
  };


  const handledocEdit = (e) => {
    const formData = new FormData();
    formData.append("student_id", studentId)
    formData.append("type", "tracking_doc")
    formData.append("file_input", e.target.files[0])
    StudentFileUploads(formData)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `${res?.data?.message || "Updated Successfully"}`,
        });
        props.setrefresh(!props.refresh);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.message}`,
        });
      });
  };

  const handledocEditLaptop = (e) => {
    const formData = new FormData();
    formData.append("student_id", studentId)
    formData.append("type", "tracking_doc_laptop")
    formData.append("file_input", e.target.files[0])
    StudentFileUploads(formData)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `${res?.data?.message || "Updated Successfully"}`,
        });
        props.setrefresh(!props.refresh);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.message}`,
        });
      });
  };
  const setDate = (date) => {
    formik.setFieldValue("dateCollected", date)
  };
  const setDateLaptop = (date) => {
    formik.setFieldValue("laptop_collected_date", date)
  };


  const basename = (path) => {
    return path.split("/").reverse()[0];
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deletePdfLetter({ id: id }).then((res) => { });

        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        props.setrefresh(!props.refresh);
      }
    });
  };

  const showHideStudyKit = (event) => {
    if (event) {
      formik.setFieldValue("kitType", event.value);
      let data = props?.studentData?.re_reg_data
      if (data?.status == 'new-status' || data?.status == 'Closed-Ordered2' || data?.status == 'Rejected' || data?.status == 'assign-subjects' || data?.status == 'Closed-Ordered' || data?.status == 'Registration Withdrawn' || data?.status == 'Final-Status') {
        if (event.value == "deliver") {
          $('#sent_kit_div').show();
          $('#collect_kit_div').hide();
        }
        else if (event.value == "ekit") {
          $('#collect_kit_div').hide();
          $('#sent_kit_div').hide();
        }
        else if (event.value == "no_kit_required") {
          $('#collect_kit_div').hide();
          $('#sent_kit_div').hide();
        } else if (event.value == "collect") {
          $('#collect_kit_div').show();
          $('#sent_kit_div').hide();
        } else {
          $('#collect_kit_div').hide();
          $('#sent_kit_div').hide();
        }
      } else {
        if (event.value == "deliver") {
          $('#sent_kit_div').show();
          $('#collect_kit_div').hide();
        }
        else if (event.value == "ekit") {
          $('#collect_kit_div').hide();
          $('#sent_kit_div').hide();

        }
        else if (event.value == "no_kit_required") {
          $('#collect_kit_div').hide();
          $('#sent_kit_div').hide();

        }
         else if (event.value == "collect") {
          $('#collect_kit_div').show();
          $('#sent_kit_div').hide();
        } else {
          $('#collect_kit_div').hide();
          $('#sent_kit_div').hide();
        }
      }
    } else {
      formik.setFieldValue("kitType", "");
      $('#collect_kit_div').hide();
      $('#sent_kit_div').hide();
    }
  }
  const studyKitSeleted = () => {
    if (props.studentData && props.studentData.re_reg_data) {
      let data = props.studentData.re_reg_data;
      if (data?.status == 'new-status' || data?.status == 'Closed-Ordered2' || data?.status == 'Rejected' || data?.status == 'assign-subjects' || data?.status == 'Closed-Ordered' || data?.status == 'Registration Withdrawn' || data?.status == 'Final-Status') {

        if (data?.academy == "business" || data?.academy == "engineering") {
          if (data?.kit_collected_date != '' && data?.student_kit_sent != 'no_kit_required') {
            return { value: "collect", label: "Student to Collect" }
          }
          if (data?.tracking_no != '' && data?.student_kit_sent != 'no_kit_required') {
            return { value: "deliver", label: "Deliver to Student" }
          }
          if (data?.ekit != '' && data?.student_kit_sent != 'no_kit_required') {
            return { value: "ekit", label: "eKit only" }
          }
        } else {
          if (data?.tracking_no != '' && data?.student_kit_sent != 'no_kit_required') {
            return { value: "deliver", label: "Deliver to Student" }
          }
          if (data?.kit_collected_date != '' && data?.student_kit_sent != 'no_kit_required') {
            return { value: "collect", label: "Student to Collect" }
          }
          if (data?.student_kit_sent == 'no_kit_required') {
            return { value: "no_kit_required", label: "No kit required" }
          }
        }
      } else {
        if (data?.academy == "engineering") {
          if (data?.kit_collected_date != '' && data?.student_kit_sent != 'no_kit_required') {
            return { value: "no_kit_required", label: "No kit required" }
          }
          if (data?.tracking_no != '' && data?.student_kit_sent != 'no_kit_required') {
            return { value: "collect", label: "Student to Collect" }
          }
          if (data?.ekit != '' && data?.student_kit_sent != 'no_kit_required') {
            return { value: "deliver", label: "Deliver to Student" }
          }
        } else if (data?.academy == "draughting") {
          if (data?.laptop != 'no_laptop' && data?.kit_collected_date == '' && data?.tracking_no == '') {
            return { value: "Select Kit Type", label: "Select Kit Type" }
          }
          if (data?.laptop == 'no_laptop' && data?.kit_collected_date == '' && data?.tracking_no == '') {
            return { value: "no_kit_required", label: "No kit required" }
          }
          if (data?.kit_collected_date != '' && data?.student_kit_sent != 'no_kit_required') {
            return { value: "collect", label: "Student to Collect" }
          }
          if (data?.tracking_no != '' && data?.student_kit_sent != 'no_kit_required') {
            return { value: "deliver", label: "Deliver to Student" }
          }
        } else {
          if (data?.tracking_no != '' && data?.student_kit_sent != 'no_kit_required') {
            return { value: "deliver", label: "Deliver to Student" }
          }
          if (data?.kit_collected_date != '' && data?.student_kit_sent != 'no_kit_required') {
            return { value: "collect", label: "Student to Collect" }
          }
          if (data?.laptop == 'no_laptop') {
            return { value: "no_kit_required", label: "No kit required" }
          }
        }
      }
      return { value: "", label: "Select Kit Type" }
    }
  }

  const studentKitSendDiv = <div id="sent_kit_div">
    <div className="edit-icon new-card-header">
      <div className="card-subheading">Student Kit Sent</div>
    </div>
    <div className="row">
      <div className="col-md-6 col-lg-3">
        <label htmlFor="trackingNo">Tracking No </label>
        <div className="form-icon-group mb-4" title="trackingNo">
          <input type="text" className={"form-control" + (formik.errors.trackingNo && formik.touched.trackingNo ? " is-invalid" : "")} name="trackingNo" id="trackingNo" placeholder="Tracking Number  " title="trackingNo" value={formik.values.trackingNo} onChange={(e) => formik.setFieldValue("trackingNo", e.target.value)} />
        </div>
        <RenderPreviousValues status={props.studentData?.re_reg_data?.status} currentValue={props.studentData?.re_reg_data?.tracking_no} previousValue={props.studentData?.RegStudentsPreviousDetails?.tracking_no} />
      </div>
      <div className="col-md-6 col-lg-2">
        <label>&nbsp;</label>
        <div className="form-group-blk mb-3">
        <button className="btn btn-save btn-success" type="button" title="Update" onClick={() => sendNotification()}>
        {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-paper-plane"></i>} Send Tracking
          </button>
        </div>
      </div>
      {formik.values.kitType === "deliver" ? <div className="col-md-6 col-lg-3">
        <label htmlFor="kitType">Date Dispatched</label>
        <div className="form-group-blk mb-3">
          <DatePicker
            selected={formik.values.dateCollected}
            onChange={setDate}
            className={"form-control w-100" +
              (formik.errors.dateCollected &&
              formik.touched.dateCollected
                ? " is-invalid"
                : "")
              }
            title="Date Dispatched"
            dateFormat={FORM_DATE_FORMAT}
            placeholderText="Select Date Dispatched"
            onChangeRaw={(e) => e.preventDefault()}
            isClearable
          />
        </div>
        <RenderPreviousValues status={props.studentData?.re_reg_data?.status} currentValue={props.studentData?.re_reg_data?.kit_collected_date} previousValue={props.studentData?.RegStudentsPreviousDetails?.kit_collected_date} />
      </div> : ""}
      <div className="col-md-6 col-lg-3">
        <label htmlFor="trackingBill">Waybill</label>
        <div className="form-icon-group mb-4" title="trackingBill">
          {/* <input type="file" name="trackingBill" id="trackingBill"  title="Invoice Form" onChange={handledoc}  /> */}
          <input
            type="file"
            name="trackingBill"
            id="trackingBill"
            onChange={handledocEdit}
          />
        </div>
        <div className="frm-group">
          <ul className="list-unstyled attached-file-ul m-0">
            {otherDocumentFormList && otherDocumentFormList.map((item) => {
              return (
                <li key={item.id} className="mb-2">
                  <div className="col-md-11">
                    {RenderAttachList({
                      fileName: basename(item.url),
                      // size: "1205",
                      filePath: IMAGE_URL + "/" + encodeURI(item.url.replace("home/myaie/public_html/", "").replace("public", "")),
                      showClose: false,
                    })}
                  </div>
                  <button title="Delete" type="button" className="btn btn-danger rounded-circle" onClick={() => handleDelete(item.id)}>
                    <i className="fal fa-trash-alt"></i>
                  </button>

                </li>
              );
            })}
          </ul>
        </div>
      </div>

    </div>
  </div>

  const studyKitCollectDiv = <div id="collect_kit_div">
    <div className="edit-icon new-card-header">
      <div className="card-subheading">Study Kit Collected</div>
    </div>
    <div className="row">
      <div className="col-md-6 col-lg-3">
        <label htmlFor="kitType">Date Collected</label>
        <div className="form-group-blk mb-3">
          {
            <DatePicker
              selected={formik.values.dateCollected}
              onChange={setDate}
              className={"form-control w-100" +
              (formik.errors.dateCollected &&
              formik.touched.dateCollected
                ? " is-invalid"
                : "")
              }
              title="Date Collected"
              dateFormat={FORM_DATE_FORMAT}
              placeholderText="Select Date Collected"
              onChangeRaw={(e) => e.preventDefault()}
              isClearable
            />
          }
        </div>
      </div>
      <div className="col-md-6 col-lg-6">
        <label>&nbsp;</label>
        <div className="form-group-blk mb-3">
        <button className="btn btn-save btn-success" type="button" title="Update" onClick={sendConfirmationCommunication}>
        {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-paper-plane"></i>} Send Confirmation
          </button>
        </div>
      </div>
    </div>
  </div>

const laptopCollectDiv = 
<div id="collect_laptop_div">
<div className="row">
  <div className="col-md-6 col-lg-3">
    <label htmlFor="laptop_collected_date">Date Collected</label>
    <div className="form-group-blk mb-3">
      {
        <DatePicker
          selected={formik.values.laptop_collected_date}
          onChange={setDateLaptop}
          className={"form-control w-100" +
          (formik.errors.laptop_collected_date &&
          formik.touched.laptop_collected_date
            ? " is-invalid"
            : "")
        }
          title="Date Collected"
          dateFormat={FORM_DATE_FORMAT}
          placeholderText="Select Date Collected"
          onChangeRaw={(e) => e.preventDefault()}
          isClearable
        />
        
      }
    </div>
  </div>
  <div className="col-md-6 col-lg-6">
    <label>&nbsp;</label>
    <div className="form-group-blk mb-3">
    <button className="btn btn-save btn-success" type="button" title="Update" onClick={sendConfirmationCommunicationLaptop}>
    {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-paper-plane"></i>} Send Confirmation
      </button>
    </div>
  </div>
</div>
</div>

  const laptopDeliveryTypeDiv = 
  <div id="laptop_delivery_div">
    <hr/>
    <div className="edit-icon new-card-header">
        <div className="card-header">Laptop Delivery</div>
    </div>
    <div className="row">
        <div className="col-md-4">
          <label htmlFor="laptop_deliv_type">Laptop Delivery Type</label>
          <div className="form-icon-group mb-4">
            <Select
              className={
                "form-control custom-select-box " +
                (formik.errors.laptop_deliv_type && formik.touched.laptop_deliv_type
                  ? " is-invalid"
                  : "")
              }
              name="laptop_deliv_type"
              value={laptopDelTypeOptions?.filter(function (option) {
                return (
                  option.value ==
                  formik?.values?.laptop_deliv_type
                );
              })}
              onChange={(value) => laptopDelTypeOptions?.filter(function (option) {
                  if(value){
                    if (option?.value == value?.value){
                      formik.setFieldValue("laptop_deliv_type",option?.value);
                      return option;
                  }
                }else{
                  formik.setFieldValue("laptop_deliv_type",'');
                  return laptopDelTypeOptions[0];
                }
                })
              }
              placeholder={
                formik.values.laptop_deliv_type
                  ? formik.values.laptop_deliv_type
                  : "Select Delivery Method"
              }
              default={laptopDelTypeOptions[0]}
              onBlur={formik.handleBlur}
              options={laptopDelTypeOptions}
              maxMenuHeight={175}
              isClearable
            />
          </div>
        </div>        
    <div className="col-md-4" id="lapreqlabel">
    <label htmlFor="laptopSerialNr">Laptop Serial Nr</label>
    <div className="form-icon-group mb-4">
      <input
        className={"form-control" + (formik.errors.laptopSerialNr && formik.touched.laptopSerialNr ? " is-invalid" : "")}
        name="laptopSerialNr"
        id="laptopSerialNr"
        value={formik.values.laptopSerialNr}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder={"Please Enter Laptop Serial Nr"}
      />
    </div>
    </div>
  </div>
  </div>

const LaptopSendDiv = 
  <div id="sent_laptop_div">
    <div className="row">
      <div className="col-md-6 col-lg-3">
        <label htmlFor="laptop_tracking_num">Tracking No </label>
        <div className="form-icon-group mb-4" title="laptop_tracking_num">
          <input type="text" className={"form-control" + (formik.errors.laptop_tracking_num && formik.touched.laptop_tracking_num ? " is-invalid" : "")} name="laptop_tracking_num" id="laptop_tracking_num" placeholder="Tracking Number  " title="laptop_tracking_num" value={formik.values.laptop_tracking_num} onChange={(e) => formik.setFieldValue("laptop_tracking_num", e.target.value)} />
        </div>
      </div>
      <div className="col-md-6 col-lg-2">
        <label>&nbsp;</label>
        <div className="form-group-blk mb-3">
        <button className="btn btn-save btn-success" type="button" title="Update" onClick={() => sendNotificationLaptop()}>
        {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-paper-plane"></i>} Send Tracking
          </button>
        </div>
      </div>
      <div className="col-md-6 col-lg-3">
        <label htmlFor="laptop_collected_date">Date Dispatched</label>
        <div className="form-group-blk mb-3">
          <DatePicker
            selected={formik.values.laptop_collected_date}
            onChange={setDateLaptop}
            className="form-control w-100"
            title="Date Dispatched"
            dateFormat={FORM_DATE_FORMAT}
            placeholderText="Select Date Dispatched"
            onChangeRaw={(e) => e.preventDefault()}
            isClearable
          />
        </div>
      </div>
      <div className="col-md-6 col-lg-3">
        <label htmlFor="trackingBillLaptop">Waybill</label>
        <div className="form-icon-group mb-4" title="trackingBillLaptop">
          <input
            type="file"
            name="trackingBillLaptop"
            id="trackingBillLaptop"
            onChange={handledocEditLaptop}
          />
        </div> 
        <div className="frm-group">
          <ul className="list-unstyled attached-file-ul m-0">
            {laptopDocumentFormList && laptopDocumentFormList.map((item) => {
              return (
                <li key={item.id} className="mb-2">
                  <div className="col-md-11">
                    {RenderAttachList({
                      fileName: basename(item.url),
                      // size: "1205",
                      filePath: IMAGE_URL + "/" + encodeURI(item.url.replace("home/myaie/public_html/", "").replace("public", "")),
                      showClose: false,
                    })}
                  </div>
                  <button title="Delete" type="button" className="btn btn-danger rounded-circle" onClick={() => handleDelete(item.id)}>
                    <i className="fal fa-trash-alt"></i>
                  </button>

                </li>
              );
            })}
          </ul>
        </div>
        </div>      
    </div>
  </div>

  return (
     <div className="my-tickets-info-list Tickets-main-wrap">
      {!props.isAcademicDepartment && (
      <>
      <div className="edit-icon new-card-header">
        <div className="card-header">Study Kit</div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label htmlFor="kitType">Kit Type</label>
          <div className="form-icon-group mb-4">
            <Select
              className={
                "form-control custom-select-box " +
                (formik.errors.kitType && formik.touched.kitType
                  ? " is-invalid"
                  : "")
              }
              name="kitType"
              value={kitTypeData?.filter((item) => {
                return item.value == formik.values.kitType;
              })}
              defaultValue={studyKitSeleted()}
              onChange={showHideStudyKit}
              onBlur={formik.handleBlur}
              options={kitTypeData}
              maxMenuHeight={175}
              isClearable
            />
          </div>
          <RenderPreviousValues status={props.studentData?.re_reg_data?.status} currentValue={props.studentData?.re_reg_data?.ekit} previousValue={props.studentData?.RegStudentsPreviousDetails?.ekit} type="dropdown" typeSupportingProps={{filterArray : kitTypeData}} />
        </div>        
      </div>
      </>
      )}
      {props.isAcademicDepartment ? null : studyKitCollectDiv}
      {props.isAcademicDepartment ? null : studentKitSendDiv}
      {formik.values.laptop !== "no_laptop" && laptopDeliveryTypeDiv}
      {formik.values.laptop_deliv_type && formik.values.laptop_deliv_type == "deliver" && LaptopSendDiv}
      {formik.values.laptop_deliv_type && formik.values.laptop_deliv_type == "collect" && laptopCollectDiv}
      
    </div>
  );
}

export default StudyKits;

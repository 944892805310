import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { TABLE_ROWS_PER_PAGE } from "../../utils/Constants";
import SkeletonTicketList from "../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import hasPermission from "../../utils/hasMultiplePermission";
import Tablefilter from "../common/Tablefilter";
import axios from "axios";
import { handleTableScroll } from "../../utils/commonFunction";
import $ from "jquery";
import { RenderType } from "../../utils/CommonGroupingItem";
import { getEmailsPendingDropdown, getEmailsPendingList, GetEmailsPendingPageListCancelToken } from "../../services/EmailsPageServer";
import HtmlParser from "react-html-parser";

function EmailsPendingPagesTable() {

    const history = useHistory();
    const [search, setSearch] = useState("");
    const [debouncedTerm, setDebouncedTerm] = useState("");
    const [qualificationFilters, setQualificationFilters] = useState({ arr: [], checkObj: {} });
    const [eventTypeFilter, setEventTypeFilter] = useState({ arr: [], checkObj: {} });
    const [searchStatusCheck, setSearchStatusCheck] = useState({});
    const givenPermsisions = useSelector((state) => state.givenPermission);
    const [deleterow, setDeleteRow] = useState(false);
    const [emailsPagesData, setEmailsPagesData] = useState([]);
    const [loading, setloading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [sortkey, setSortKey] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [filterData, setFilterData] = useState({ qualificationFilters: [], eventTypeFilter: [] });
    const [pendingData, setPendingData] = useState({});


    useEffect(() => {
        handleTableScroll()
    }, [loading])

    useEffect(() => {
        $(document).ready(function () {
            $(".dropdown-toggle").click(function () {
                $('.rdt_TableCell').css('z-index', 0)
                $(this).parents('.rdt_TableCell').css('z-index', 22)
            });
        });
    })
    useEffect(() => {
        const storedFilters = JSON.parse(localStorage.getItem("filterEmailspages"));
        if (storedFilters) {
            setQualificationFilters(storedFilters.qualificationFilters);
            setEventTypeFilter(storedFilters.eventTypeFilter);
        }
    }, []);
    useEffect(() => {
        const filterState = {
            qualificationFilters: qualificationFilters,
            eventTypeFilter: eventTypeFilter
        };
        localStorage.setItem("filterEmailspages", JSON.stringify(filterState));
    }, [qualificationFilters, eventTypeFilter]);
    useEffect(() => {
        $(document).ready(function () {
            $(".dropdown-toggle").click(function () {
                $('.rdt_TableCell').css('z-index', 0)
                $(this).parents('.rdt_TableCell').css('z-index', 22)
            });
        });

    })

    const handleSort = (column, sortDirection) => {
        setSortKey(column.sortField);
        setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
    };

    useEffect(() => {
        const cancelTokenSources = [];

        const getEmailsPageList = async () => {
            setloading(true);

            cancelTokenSources.forEach(source => {
                source.cancel('New request made');
            });

            const source = axios.CancelToken.source();
            cancelTokenSources.push(source);

            const EmailsPage = {
                page: page,
                perPage: perPage,
                key: sortkey,
                sort: sortOrder,
                search: search,
                viaAddress: qualificationFilters?.arr,
                viaEmailTemplete: eventTypeFilter?.arr
            };

            try {
                const res = await GetEmailsPendingPageListCancelToken(EmailsPage, source.token);

                setEmailsPagesData(res?.data?.data);
                setTotalRows(res?.data?.total);
                setPerPage(res?.data?.perPage);
                if (res.status == 200) {
                    setloading(false);
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                    setloading(false);
                }
            }
        }

        getEmailsPageList();

        return () => {
            cancelTokenSources.forEach(source => {
                source.cancel('Component unmounted');
            });
        };
    }, [page, perPage, sortOrder, sortkey, search, qualificationFilters, eventTypeFilter, searchStatusCheck]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getEmailsPendingDropdown();
                // console.log(res);

                setFilterData({
                    qualificationFilters: res?.data?.emailList,
                    eventTypeFilter: res?.data?.emailTemplate
                })
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                }
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        let response = hasPermission({
            scopes: ["sview"],
            permissions: givenPermsisions,
        });
        if (!response) {
            history.push("/noaccess");
        }
    }, [deleterow]);

    const handleSearchFilter = (e) => {
        const value = e.target.value;
        setDebouncedTerm(value);
    };

    const resetFilter = () => {
        setQualificationFilters({ arr: [], checkObj: {} });
        setEventTypeFilter({ arr: [], checkObj: {} });
        setSearchStatusCheck({});
        setSearch("");
        setDebouncedTerm("")
    };

    useEffect(() => {
        return resetFilter();
    }, [])

    const handlePageChange = (pageNo) => {
        setPage(pageNo);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    const handleRedirection = (ID) => {
        if (hasPermission({ scopes: ["sgiview"], permissions: givenPermsisions })) {
            return `/studentAdministration/students/open/${ID}/general`;
        } else if (
            hasPermission({ scopes: ["spiview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/personal`;
        } else if (
            hasPermission({ scopes: ["sedview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/education`;
        } else if (
            hasPermission({ scopes: ["sapiview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/account`;
        } else if (
            hasPermission({ scopes: ["scpview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/coursesAndProgramme`;
        } else if (
            hasPermission({ scopes: ["sasview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/assessments`;
        } else if (
            hasPermission({ scopes: ["snview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/notifications`;
        } else if (
            hasPermission({ scopes: ["scaview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/classAttendance`;
        } else if (
            hasPermission({ scopes: ["slview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/licenses`;
        } else if (
            hasPermission({ scopes: ["scview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/certificates`;
        } else if (
            hasPermission({ scopes: ["saview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/appeals`;
        } else if (
            hasPermission({ scopes: ["stview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/tickets`;
        } else if (
            hasPermission({ scopes: ["snoview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/notes`;
        } else {
            return `/studentAdministration/students/open/${ID}/auditTrail/employee`;
        }
        // history.push(redirectLink)
    };

    const columns = useMemo(() => [
        {
            name: "Email Template",
            selector: "type",
            sortField: "type",
            sortable: true,
            cell: (row) => (
                <div className="assigned-title-block-new">
                    {/* <PermissionsGate scopes={["sgiview"]} RenderError={() => <p>{row.first_name + " " + row.last_name}</p>}> */}
                    <Link
                        className="as-text-blue curser feature-name"
                        onClick={() => setPendingData(row)}
                        data-toggle="modal"
                        data-target="#notifications"
                        title={row.type}
                    >
                        <span className="textLimit100">{row.type}</span>
                    </Link>
                    {/* </PermissionsGate> */}
                </div>
            ),
        },
        {
            name: "Recipient",
            selector: "to",
            sortField: "to",
            sortable: true,
            cell: (row) => (
                <>
                    {row.to ? (
                        <span>
                            {row.to}
                        </span>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            name: "Subject",
            selector: "subject",
            sortField: "subject",
            sortable: true,
            cell: (row) => (
                <>
                    {row.subject ? (
                        <span>
                            {row.subject}
                        </span>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            name: "From Address",
            selector: "from_email",
            sortField: "from_email",
            sortable: true,
            cell: (row) => (
                <>
                    {row.from_email ? (
                        <span>
                            {row.from_email}
                        </span>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            name: "Time",
            selector: "created_at",
            sortField: "created_at",
            sortable: true,
            cell: (row) => {
                let dateString = row.created_at;
                let dateObj = new Date(dateString);
                let day = ('0' + dateObj.getDate()).slice(-2);
                let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                let month = monthNames[dateObj.getMonth()];
                let year = ('' + dateObj.getFullYear()).slice(-2);
                let formattedDate = `${day} ${month} ${year}`;

                let Hours = dateObj.getHours().toString().padStart(2, '0');
                let Minutes = dateObj.getMinutes().toString().padStart(2, '0');
                let formattedTime = `${Hours}:${Minutes}`;


                return row.created_at ? (
                    <>
                        <p>{formattedDate}</p>
                        <p>{formattedTime}</p>
                    </>
                ) : "-"
            },
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className="assessment-08 btn-dropdown-grp">
                    <div className="as-buttons d-flex">
                        <button
                            title="View"
                            className="btn btn-primary rounded-circle"
                            onClick={() => setPendingData(row)}
                            data-toggle="modal"
                            data-target="#notifications"
                        >
                            <i className="fal fa-eye"></i>
                        </button>
                    </div>
                </div>
            ),
        },
    ]);

    // TODO Excel
    // TODO Csv
    // TODO Pdf

    const exportData = (fileType, fileName) => {
        let data = [];
        const header = ["Email Template", "Recipient", "Subject", "From Address", "Time"];
        Swal.fire({
            title: "File downloading",
            onOpen: function () {
                Swal.showLoading();
            },
        });

        const emailsPagesData = {
            key: sortkey,
            sort: sortOrder,
            search: search,
            viaAddress: qualificationFilters?.arr,
            viaEmailTemplete: eventTypeFilter?.arr,
            exportStatus: "true",
        };

        getEmailsPendingList(emailsPagesData)
            .then((res) => {
                data = res?.data;


                data = data?.map((row) => {
                    let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

                    let lastdateString = row.created_at;
                    let lastdateObj = new Date(lastdateString);
                    let lastday = ('0' + lastdateObj.getDate()).slice(-2);
                    let lastmonth = monthNames[lastdateObj.getMonth()];
                    let lastyear = ('' + lastdateObj.getFullYear()).slice(-2);
                    let formattedLastDate = `${lastday} ${lastmonth} ${lastyear}`;

                    let Hours = lastdateObj.getHours().toString().padStart(2, '0');
                    let Minutes = lastdateObj.getMinutes().toString().padStart(2, '0');
                    let formattedTime = `${Hours}:${Minutes}`;

                    return ({
                        ...row,
                        "Email Template": row?.type ? row?.type : "-",
                        Recipient: row?.to ? row?.to : "-",
                        Subject: row.subject ? row.subject : "-",
                        "From Address": row.from_email ? row.from_email : "-",
                        Time: row.created_at ? `${formattedLastDate}\n${formattedTime}` : "-",
                    })
                });

                if (fileType === "csv") {
                    const csvString = Papa.unparse({ fields: header, data });
                    const blob = new Blob([csvString], {
                        type: "text/csv;charset=utf-8,",
                    });

                    const blobURL = window.URL.createObjectURL(blob);

                    // Create new tag for download file
                    const anchor = document.createElement("a");
                    anchor.download = fileName;
                    anchor.href = blobURL;
                    anchor.dataset.downloadurl = [
                        "text/csv",
                        anchor.download,
                        anchor.href,
                    ].join(":");
                    anchor.click();

                    // Remove URL.createObjectURL. The browser should not save the reference to the file.
                    setTimeout(() => {
                        // For Firefox it is necessary to delay revoking the ObjectURL
                        URL.revokeObjectURL(blobURL);
                    }, 1000);
                    Swal.close();
                } else if (fileType === "xlsx") {
                    const compatibleData = data.map((row) => {
                        const obj = {};
                        header.map((col, index) => {
                            obj[col] = row[col];
                        });
                        return obj;
                    });

                    let wb = XLSX.utils.book_new();
                    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                        header,
                    });
                    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
                    XLSX.writeFile(wb, `${fileName}.xlsx`);
                    Swal.close();
                    // Returning false as downloading of file is already taken care of
                    return false;
                }
                if (fileType === "pdf") {
                    const compatibleData = data?.map((row) => {
                        let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

                        let lastdateString = row.created_at;
                        let lastdateObj = new Date(lastdateString);
                        let lastday = ('0' + lastdateObj.getDate()).slice(-2);
                        let lastmonth = monthNames[lastdateObj.getMonth()];
                        let lastyear = ('' + lastdateObj.getFullYear()).slice(-2);
                        let formattedLastDate = `${lastday} ${lastmonth} ${lastyear}`;

                        let Hours = lastdateObj.getHours().toString().padStart(2, '0');
                        let Minutes = lastdateObj.getMinutes().toString().padStart(2, '0');
                        let formattedTime = `${Hours}:${Minutes}`;

                        return [
                            row?.type ? row?.type : "-",
                            row?.to ? row?.to : "-",
                            row.subject ? row.subject : "-",
                            row.from_email ? row.from_email : "-",
                            row.created_at ? `${formattedLastDate}\n${formattedTime}` : "-",
                        ];
                    });
                    const doc = new JsPDF();
                    doc.autoTable({
                        head: [header],
                        body: compatibleData,
                        styles: {
                            minCellHeight: 10,
                            minCellWidth: 5,
                            halign: "left",
                            // valign: "center",
                            fontSize: 8,
                        },
                    });
                    doc.save(`${fileName}.pdf`);
                    Swal.close();
                    return false;
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
        return () => {
            clearTimeout(timer);
        }
    }, [debouncedTerm])

    return (
        <>
            <div className="my-tickets-info-list Tickets-main-wrap">
                <div className="custom-table-div filter-search-icon card card-table-custom program-table">
                    <div className="search-filter-div">
                        <div className="search-filter-div-left">
                            <div className="system-administration-table table-responsive">
                                <div className="table-responsive-div">
                                    <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                        <div id="assessment-table-main_filter" className="dataTables_filter">
                                            <label>
                                                <input
                                                    type="search"
                                                    className=""
                                                    placeholder="Search"
                                                    aria-controls="assessment-table-main"
                                                    onChange={handleSearchFilter}
                                                    value={debouncedTerm}
                                                />
                                            </label>
                                            <div className="filter-eff filter-data-btn">
                                                <button className="filter-buttons">
                                                    <i className="fal fa-filter"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-button-group">
                                    <div className="filter-scroll">
                                        <div className={`filter-scroll-inner filter-custom-new`}>
                                            <Tablefilter
                                                filterName="From Address"
                                                optionArr={filterData?.qualificationFilters}
                                                state={qualificationFilters}
                                                setState={setQualificationFilters}
                                                stopOptionSorting={true}
                                                isOptionReversed={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="filter-scroll">
                                        <div className={`filter-scroll-inner filter-custom-new`}>
                                            <Tablefilter
                                                filterName="Email Template"
                                                optionArr={filterData?.eventTypeFilter}
                                                state={eventTypeFilter}
                                                setState={setEventTypeFilter}
                                                stopOptionSorting={true}
                                                isOptionReversed={false}
                                                renderLabelFunction={RenderType}
                                            />
                                        </div>
                                    </div>
                                    <div className="reset-btn-group">
                                        <div className="button-reset dropdown-comman">
                                            <button
                                                className="btn btn-primary"
                                                onClick={resetFilter}
                                                title="Reset"
                                            >
                                                <i className="fal fa-redo"></i>Reset
                                            </button>
                                        </div>
                                        <div className="files-export-group">
                                            <button
                                                type="button"
                                                className="btn btn-files"
                                                onClick={() => {
                                                    exportData("xlsx", "websitelist");
                                                }}
                                                title="Export spreadsheet"
                                            >
                                                <i className="fal fa-file-excel icon"></i>
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-files"
                                                onClick={() => {
                                                    exportData("csv", "Students_list");
                                                }}
                                                title="Export CSV"
                                            >
                                                <i className="fal fa-file-csv icon"></i>
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-files"
                                                onClick={() => {
                                                    exportData("pdf", "Students_list");
                                                }}
                                                title="Export PDF"
                                            >
                                                <i className="fal fa-file-pdf icon"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DataTable
                        paginationDefaultPage={page}
                        progressPending={loading}
                        data={emailsPagesData}
                        progressComponent={<SkeletonTicketList />}
                        defaultSortField={sortkey}
                        defaultSortAsc={false}
                        columns={columns}
                        pagination={true}
                        // noDataComponent={Str.noRecord}
                        onSort={handleSort}
                        sortServer
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        highlightOnHover={false}
                        paginationPerPage={perPage}
                        paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                    />
                </div>
            </div>

            <div
                className="modal fade"
                id="notifications"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-lg"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-body p-0">
                            <div className="modal-header modal-header-custom">
                                <h5 className="modal-title">
                                    <i className="fas fa-envelope"></i> Generated Details
                                </h5>
                                <button
                                    type="button"
                                    data-dismiss="modal"
                                    class="close"
                                    title="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                    <span class="sr-only">Close</span>
                                </button>
                            </div>
                            <div className="p-30px">
                                <div className="row">
                                    <div className="col-md-12 col-lg-12">
                                        <div className="basic-info-box-list">
                                            <ul className="d-flex flex-column p-0">
                                                <li className="d-flex">
                                                    <span className="title mw-0">Recipient:&nbsp;</span>
                                                    <span className="word-break-all">
                                                        {pendingData?.to ? pendingData?.to : "NA"}
                                                    </span>
                                                </li>
                                                <li className="d-flex">
                                                    <span className="title mw-0">From:&nbsp;</span>
                                                    <span className="word-break-all">
                                                        {pendingData?.from_email ? pendingData?.from_email : "NA"}
                                                    </span>
                                                </li>
                                                <li className="d-flex">
                                                    <span className="title mw-0">Template:&nbsp;</span>
                                                    <span className="word-break-all">
                                                        {pendingData?.type ? pendingData?.type : "NA"}
                                                    </span>
                                                </li>
                                                <li className="d-flex">
                                                    <span className="title mw-0">Subject:&nbsp;</span>
                                                    <span className="word-break-all">
                                                        {pendingData?.subject ? pendingData?.subject : "NA"}
                                                    </span>
                                                </li>

                                                <div>
                                                    <hr
                                                        className="border border-2"
                                                        style={{ borderColor: "#E6EBF1" }}
                                                    />
                                                </div>

                                                <li className="d-flex">
                                                    <span className="title mw-0">Email Content:&nbsp;</span>
                                                    <span className="word-break-all">
                                                        {pendingData?.body ? HtmlParser(pendingData?.body) : "NA"}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group form-group-save-cancel mt-3">
                                    <button
                                        type="button"
                                        className="btn btn-danger"
                                        data-dismiss="modal"
                                        title="Close"
                                    >
                                        <i className="fal fa-times"></i>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EmailsPendingPagesTable;

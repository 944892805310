import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
// import { getCurrentTimeHomePage } from "../../common/Helper";
// import {
//   DeleteUser,
//   GetListOfRole,
//   GetListOfUser,
// } from "../../../services/UserService";

// import { InitialRender } from "../../common/Helper";
// import { IMAGE_URL } from "../../../utils/Constants";

import SkeletonTicketList from "../../../loaders/SkeletonTicketList";

import Str from "../../common/Str";

import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";

import PermissionsGate from "../../../utils/permissionGate";

// import { TrimText } from "../../../common/TrimText";

import { emailList, getBulkNotification, getReceiverDetail, getReceiverDetailCancelToken } from "../../../services/EmailServices";
import { TrimText } from "../../common/TrimText";
import moment from "moment";
import HtmlParser from "react-html-parser";
import { GetDropdownLecturer } from "../../../services/DashBoardServices";
import FilterComponent from "../../common/FilterComponent";
import AssignedIntake from "./AssignedIntakeBulk";
import hasPermission from "../../../utils/hasMultiplePermission";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../utils/Constants";
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";

const ListOfBulkNotification = () => {  
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [pendingData, setPendingData] = useState({});
  const [deleterow, setDeleteRow] = useState(false);
  const [userData, setUserData] = useState([]);
  const [receiverDetails, setReceiverDetails] = useState([]);
  const [loading, setloading] = useState(false);
  const [sortkey, setSortKey] = useState("created");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [valueLecturer, onChangeLecturer] = useState([]);
  const [lectureIds, setLectureIds] = useState([]);
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [openFileSearch, setOpenFileSearch] = useState("");
  const [filterData, setFilterData] = useState({sent : []});
  const [filterCheck, setFilterCheck] = useState("true");
  const [debouncedTerm, setDebouncedTerm] = useState("");
  const [recieverLoading, setRecieverLoading] = useState(false)
  const [recieverSource, setRecieverSource] = useState("")
  const [modalHeader, setModalHeader] = useState('');

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
    return () => {
      clearTimeout(timer);
    }
  }, [debouncedTerm])

  // TODO Api Call

  useEffect(() => {
    let response = hasPermission({ scopes: ["bnbnview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }

    const cancelTokenSources = [];
    const getListData = async () =>{
      setloading(true)
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      
      cancelTokenSources.push(source);
      const data = {
        page: page,
        limit: perPage,
        viaSearch: search ? search : "",
        exportStatus: false,
        key: sortkey,
        sort: sortOrder,
        sendViaUser: lectureIds.length > 0 ? JSON.stringify(lectureIds) : [],
        dynamicFilter: filterCheck
      };

      try {
        const res = await getBulkNotification(data, source.token);
        if(res.status==200){
          setTotalRows(res?.data?.result?.total)
          setUserData(res?.data?.result?.data);
          setloading(false);
          if(filterCheck == "true"){
            setFilterData({...res.data, sent : res.data.sendByFilter});
            setFilterCheck("false")
          }
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }
    }    

    getListData();
    return () => {
      cancelTokenSources.forEach(source => {
          source.cancel('Component unmounted');
      });
    }
  }, [deleterow, search, page, perPage, sortkey, sortOrder, lectureIds]);
  // TODO Searching
  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };
  // handleOpenFileSearchFilter
  const handleOpenFileSearchFilter = (e) => {
    const value = e.target.value;
    setOpenFileSearch(value);
  };
  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column?.selector);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  // TODO Reset Filter

  const resetFilter = () => {
    setSearch("");
    setLectureIds([]);
    onChangeLecturer([])
    setLectureIds([]);
    setDebouncedTerm("");
  };
  // resetOpenFileFilter
  const resetOpenFileFilter = () => {
    setOpenFileSearch("");
  };

  // TODO Data
  const dataToRender2 = () => {
    let updatedData = [];
    let allData = receiverDetails;
    if (openFileSearch.length) {
      let tempSubject = allData.filter((item) => {
        let includes =
          item.data &&
          item.data.toLowerCase().includes(openFileSearch.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [
        ...tempSubject
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }
    if (openFileSearch.length) {
      return updatedData;
    } else {
      return allData;
    }
};


  const receiverDetail = async (row, id) => {

    setRecieverLoading(true)
    setModalHeader(row.subject ?? '');
    const recieveSource = axios.CancelToken.source();
    setRecieverSource(recieveSource)
    if (row?.draft_notification === 0) {
      try {
        const payload = {
          id: id,
          subject: row.subject ? row.subject : "",
        }
      
        const res = await getReceiverDetailCancelToken(payload, recieveSource.token);
        if (res.status == 200) {
          let arr = res.data?.result?.map((x, i) => ({ ...x, index: i }))
          const emailRecipients = res?.data?.emailRecipients;
          if (arr.length > 0) {
            setReceiverDetails(arr);
          }
          else if (emailRecipients.length > 0) {
            setReceiverDetails(emailRecipients)
          }
          setRecieverLoading(false)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setRecieverLoading(false)
        }
      };
    }
    else if (row?.draft_notification === 1) {

      const savedData = userData.filter((item) => item.id === id);
      
      history.push(`/notification/bulknotification/bulk/edit/${id}`, { saved_data: savedData && savedData[0] })
    }

    // getReceiverDetailCancelToken({ id: id }, recieveSource.token).then((res) => {
    //   let arr = res.data?.result?.map((x,i) => ({...x, index:i}))
    //   setReceiverDetails(arr)
    //   setRecieverLoading(false)
    //   // setTotalRows(res?.data?.result?.total)
    //   // setUserData(res?.data?.result?.data);
    //   // setloading(false);
    // })
    //   .catch((err) => {
    //     console.log(err)
    //     setRecieverLoading(false)
    //   });
  }

  const handleReturningIcon = (row) => {
    return (
      <>
        {row.isEmailActive ? (
          <>
            <i class="fal fa-envelope"></i>&nbsp;
          </>
        ) : (
          ""
        )}
        {row.isSmsActive ? (
          <>
            <i class="fal fa-comment"></i>&nbsp;
          </>
        ) : (
          ""
        )}
        {row.isPushnotificationActive ? <i class="fal fa-mobile"></i> : ""}

        {!row.isPushnotificationActive && !row.isSmsActive && !row.isEmailActive ? "-" : ""}
      </>
    );
  };

  const sortingUser = (rowA, rowB) => {
    const name1 = rowA?.data?.toString() ? rowA?.data?.toString() : "-";
    const name2 = rowB?.data?.toString() ? rowB?.data?.toString() : "-";
    return name1.localeCompare(name2);
  };

  const columns = useMemo(() => [
    {
      name: "Subject",
      selector: "subject",
      sortable: true,
      cell: (row) => (row?.subject ? <p
        className="as-text-blue curser textLimit100"
        title={row?.subject}
        onClick={() => receiverDetail(row, row?.id)}
        data-toggle="modal"
        data-target={row?.draft_notification === 0 ? "#receiver-details" : ''}
      >{row?.subject}</p> : "-"),
    },

    // {
    //   name: "Type",
    //   selector: "type",
    //   sortable: true,
    //   cell: (row) => (row.type ? row.type : "NA"),
    // },
    {
      name: "Related To",
      // selector: "type",
      // sortable: true,
      cell: (row) => ("Bulk Notification"),
    },

    {
      name: "Sent Via",
      selector: "sendVia",
      sortable: false,
      // maxWidth: "80px",
      // minWidth: "80px",
      cell: (row) => (
        <div className="ticket-id">
          <span className="overflow-ellipsis2">
            {row.draft_notification ? <div className="as-green-bg as-widget">{"Draft Saved On " + moment.unix(row.start).format(TABLE_DATE_TIME_FORMAT)}</div> : handleReturningIcon(row)}
          </span>
        </div>
      ),
    },
    {
      name: "Sent By",
      selector: "log",
      sortable: true,
      wrap: true,
      sortFunction: sortingUser,
      cell: (row) =>
        row.sendByCourse && row.sendByCourse.log ?
          <span className="feature-name" title={row.sendByCourse && row.sendByCourse.log}>
            <span className="textLimit100">{row.sendByCourse && row.sendByCourse.log}</span>
          </span>
          : (
            <span className="feature-name" title={row.SendByName && `Sent by ${row.SendByName}`}>
            <span className="textLimit100">{row.SendByName && `Sent by ${row.SendByName}`}</span>
          </span>
          ),
    },
    {
      name: "Sent Date",
      selector: "start",
      sortable: true,
      wrap: true, 
      // maxWidth: "250px",
      cell: (row) => (
        <>{row.start ?
          <div className="dateTime">
            <p className="right-space">{moment.unix(row.start.toString().length > 13 ? row.start/1000 : row.start).format(TABLE_DATE_FORMAT + ",")}</p>
            <p>{moment.unix(row.start.toString().length > 13 ? row.start/1000 : row.start).format(TABLE_TIME_FORMAT)}</p>
          </div>
          : (
            <p>-</p>
          )}</>
      )
    },
    // {
    //   name: "Created Date",
    //   selector: "created",
    //   sortable: true,
    //   cell: (row) => (row?.created && row?.created != 0 ? `${moment.unix(Number(row?.created)).format(TABLE_DATE_TIME_FORMAT)}` : "-"),
    // },

    {
      name: "Action",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <PermissionsGate scopes={["umedit"]}>
              <button
                title="Open"
                className="btn btn-primary rounded-circle"
                // onClick={() => handleEdit(row, row.id)}
                onClick={() => receiverDetail(row, row.id)}
                data-toggle="modal"
                data-target={row?.draft_notification === 0 ? "#receiver-details" : ''}
              >
                <i className="fal fa-folder-open"></i>
              </button>
            </PermissionsGate>
            {/* <button
              title="Delete"
              className="btn btn-danger rounded-circle"
              onClick={() => handleDelete(row.id)}
            >
              <i className="fal fa-trash-alt"></i>
            </button> */}
            <button
              title="View"
              className="btn btn-primary rounded-circle"
              onClick={() => setPendingData(row)}
              data-toggle="modal" data-target="#notifications"
            >
              <i className="fal fa-eye"></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);

  const columns2 = useMemo(() => [
    // {
    //   name: "S.NO",
    //   // selector: "i",
    //   // sortable: true,
    //   cell: (row) => (<span>{row?.index+1}</span>),
    // },
    {
      name: "Detail",
      selector: "data",
      sortable: true,
      sortFunction: sortingUser,
      cell: (row) => (row?.data ? <p title={row?.data}>{row?.data}</p> : "-"),
    },

  ]);

  // TODO Excel
  // TODO Csv
  // TODO Pdf

  const exportData = (fileType, fileName) => {
    let data;
    const header = ["Subject", "RelatedTo", "Sent Via",  "Sent By", "CreatedDate"];

    getBulkNotification({
      page: page,
      limit: perPage,
      viaSearch: search ? search : "",
      exportStatus: false,
      key: sortkey,
      sort: sortOrder,
      sendViaUser: lectureIds.length > 0 ? JSON.stringify(lectureIds) : [],
      exportStatus: true,
    })
      .then((res) => {
        data = res?.data?.result?.map((row) => ({
          ...row,
          Subject: row?.subject ? row?.subject : "-",
          RelatedTo: "Bulk Notification",
          "Sent Via": row.draft_notification ? "Draft Saved On " + moment.unix(row.start).format(TABLE_DATE_TIME_FORMAT) : ((row.isEmailActive ? "Email " : "") + (row.isPushnotificationActive ? " Push" : "")),
          ["Sent By"]: row.sendByCourse && row.sendByCourse ? row.sendByCourse.log : "-",
          CreatedDate: (row?.created && row?.created != 0 ? `${moment.unix(Number(row?.created)).format(TABLE_DATE_TIME_FORMAT)}` : "-"),
        }));

        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

          const blobURL = window.URL.createObjectURL(blob);

          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          // Remove URL.createObjectURL. The browser should not save the reference to the file.
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobURL);
          }, 1000);
        } else if (fileType === "xlsx") {
          const compatibleData = data?.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);

          // Returning false as downloading of file is already taken care of
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data?.map((row) => {
            return [
              row["Subject"],row["RelatedTo"],row["Sent Via"],row["Sent By"],row["CreatedDate"]
             ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 7,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);

          return false;
        }
      });
  };

  const exportData2 = (fileType, fileName) => {
    let data = dataToRender2();
    const header = ["Detail"];

    data = data?.map((row, i) => ({
      ...row,
      Detail: row?.data ? row?.data : "-",
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row, i) => {
        return [row?.data];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  const customSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
     // use the selector function to resolve your field names by passing the sort comparitors
     const aField = rowA[selector]?.toString()?.toLowerCase().trim()
     const bField = rowB[selector]?.toString()?.toLowerCase().trim()
   
     let comparison = 0;
   
     if (aField > bField) {
      comparison = 1;
     } else if (aField < bField) {
      comparison = -1;
     }
   
     return direction === 'desc' ? comparison * -1 : comparison;
    });
   };

  return (
    <>
      <div className="my-tickets-info-list Tickets-main-wrap">
        <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
          <div className="search-filter-div">
            <div className="search-filter-div-left">
              <div className="system-administration-table table-responsive">
                <div className="table-responsive-div">
                  <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      id="assessment-table-main_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <div
                        id="assessment-table-main_filter"
                        className="dataTables_filter"
                      >
                        <label>
                          <input
                            type="search"
                            className=""
                            placeholder="Search"
                            aria-controls="assessment-table-main"
                            onChange={e => setDebouncedTerm(e.target.value)} 
                            value={debouncedTerm}
                          />
                        </label>
                        <div className="filter-eff filter-data-btn">
                          <button className="filter-buttons">
                            <i className="fal fa-filter"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filter-button-group">
                  {/* Lecturer filter */}
                  <div className="filter-scroll">
                    <div className="filter-scroll-inner filter-custom-new">
                      <div className="assessment-table-filter">
                        <div className="btn-type-filter dropdown-comman assesment-dropdown">
                          <div className="dropdown multiselect">
                            <button
                              className={`btn btn-default dropdown-toggle ${valueLecturer?.length ? "btn-selected" : ""
                                } `}
                              type="button"
                              id="dropdownMenu1"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="true"
                              title="Sent Via"
                            >
                              <span>
                                Sent Via <i className="fal fa-angle-down"></i>
                              </span>
                            </button>
                            <form className="dropdown-menu remove-list-style">
                              <ul aria-labelledby="dropdownMenu1">
                                <div className="dropdown-filter-pagination">
                                  {filterData.sent && filterData.sent.length ? (
                                    <FilterComponent
                                      value={valueLecturer}
                                      onChange={onChangeLecturer}
                                      dataResponse={filterData.sent}
                                      backendResponse={setLectureIds}
                                    />
                                  ) : (
                                    // <AsyncPaginate
                                    //   value={value}
                                    //   loadOptions={loadOptions}
                                    //   onChange={handleFilterChange}
                                    //   // shouldLoadMore={shouldLoadMore}
                                    //    menuIsOpen={true}
                                    //   isMulti
                                    // />
                                    ""
                                  )}
                                </div>
                              </ul>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End of Lecturerfilter */}
                  <div className="reset-btn-group">
                    <div className="button-reset dropdown-comman">
                      <button
                        className="btn btn-primary"
                        onClick={resetFilter}
                        title="Reset"
                      >
                        <i className="fal fa-redo"></i>Reset
                      </button>
                    </div>
                    <div className="files-export-group">
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("xlsx", "Bulk Notifications");
                        }}
                        title="Export spreadsheet"
                      >
                        <i className="fal fa-file-excel icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("csv", "Bulk Notifications");
                        }}
                        title="Export CSV"
                      >
                        <i className="fal fa-file-csv icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("pdf", "Bulk Notifications");
                        }}
                        title="Export PDF"
                      >
                        <i className="fal fa-file-pdf icon"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="search-filter-div-right">
              <div className="filter-search-bar-blk">
                <div className="add-ticket-blk button-reset dropdown-comman">
                  <PermissionsGate scopes={["bnbnadd"]}>
                    <button className="btn btn-primary mr-2" onClick={() => history.push(`/notification/bulknotification/bulk/add`)} title="Send Bulk Notification">
                      <i className="fal fa-plus"></i>Send Bulk Notification
                    </button>
                  </PermissionsGate>
                </div>
                <div className="add-ticket-blk button-reset dropdown-comman">
                  {/* <PermissionsGate scopes={["bnbnadd"]}>
                    <button className="btn btn-primary" onClick={() => history.push(`/notification/bulknotification/notifications-list/add`)} title="Send Individual Notification">
                      <i className="fal fa-plus"></i>Send Individual Notification
                    </button>
                  </PermissionsGate> */}
                </div>
              </div>
            </div>
          </div>

          {/* <DataTable
          data={dataToRender()}
          defaultSortField="Firstname"
          defaultSortAsc={true}
          columns={columns}
          pagination={true}
          noDataComponent={Str.noRecord}
            />*/}
        
            <DataTable
              data={userData}
              defaultSortField="created"
              defaultSortAsc={false}
              columns={columns}
              pagination
              noDataComponent={Str.noRecord}
              paginationDefaultPage={page}
              progressComponent={<SkeletonTicketList />}
              progressPending={loading}
              onSort={handleSort}
              paginationServer
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              highlightOnHover={false}
              paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
              sortFunction={customSort}
            />
        </div>

      </div>

      {/* notification details popup view icon start */}
      <div
        className="topic-add-modal modal fade"
        id="notifications"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content modal-border-update">
            <div className="modal-body p-0">
              <div className="modal-header modal-header-custom">
                <h5 className="modal-title">
                  <i className="fas fa-bars"></i> Notification Details
                </h5>
                <button type="button" data-dismiss="modal" class="close" title="Close"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
              </div>
              <div className="t p-30px">
                <div className="topic-add-modal-content">
                  <div className="row center-label">
                    <div className="col-md-12 col-lg-12">
                      <div className="form-group-blk mb-3">
                        <div className="notification-view basic-info-box-list">
                          <ul className="p-0 course-name-merge">
                            <li>
                              <span className="title">Subject/Title : </span>
                              <span className="">{pendingData?.subject ? pendingData?.subject : 'NA'}</span>
                            </li>
                            <li>
                              <span className="title">Received Date/Time : </span>
                              <span className="">{pendingData?.created ? `${moment.unix(Number(pendingData?.created)).format(TABLE_DATE_TIME_FORMAT)}` : 'NA'}</span>
                            </li>
                          </ul>
                          <ul className="p-0 course-name-merge">
                            <li>
                              <span className="title">Related Qualification/Subject : </span>
                              <span className="">{"Bulk Notification"}</span>
                            </li>
                          </ul>
                          <ul className="p-0 course-name-merge">
                            <li>
                              <span className="title">Content : </span>
                              <span className="word-break-all">{pendingData?.content ? HtmlParser(pendingData?.content) : 'NA'}</span>
                            </li>
                          </ul>
                          <ul className="p-0 course-name-merge">
                            <li>
                              <span className="title">Attachment : </span>
                              {/* <span>{pendingData && pendingData.subject && <a href={pendingData.subject.replace("https://myaie.ac", "").replace("/home/myaie/public_html/", "").replace("public/", "").replace("adminapi.myaie.ac17278/", "")}>{pendingData.subject}</a>}</span> */}
                              <span title={pendingData?.attachment?.split("/")[pendingData?.attachment?.split("/").length-1]}>
                                {pendingData?.attachment ? <a href={IMAGE_URL + "/" + encodeURI(pendingData?.attachment?.replace("home/myaie/public_html/", "").replace("public/", ""))} target="_blank">{TrimText(pendingData?.attachment?.split("/")[pendingData?.attachment?.split("/").length-1],40)}</a>
                                  : 'NA'}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group form-group-save-cancel mt-3">
                  <button type="button" className="btn btn-danger" data-dismiss="modal" title="Close">
                    <i className="fal fa-times"></i>
                    Close
                  </button>
                </div>
              </div>
            </div>

            {/* <div className="modal-footer"> */}
            {/* <a href={!notificationData || !notificationData.attachment ? "" : IMAGE_URL + "/" + (notificationData.attachment).replace("/home/myaie/public_html/", "").replace("public/", "")} target="_blank" download>
                  <button className="btn btn-primary" disabled={notificationData.attachment == "" || notificationData.attachment == null || !notificationData.attachment} title="Download Document">
                    <i className="fal fa-arrow-down"></i>
                    Download Document
                  </button>
                </a> */}


            {/* </div> */}
          </div>
        </div>
      </div>
      {/* notification details popup view icon end */}

      {/* receiver detail popu open start */}
    <div className="topic-add-modal modal fade" id="receiver-details" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="static">
        <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="modal-header modal-header-custom border-0">
                <h5 className="modal-title">
                  <i className="fal fa-folder-open"></i> {modalHeader}
                </h5>
                <button type="button" data-dismiss="modal" class="close" title="Close"><span aria-hidden="true" onClick={()=> recieverSource.cancel('Component unmounted')}>×</span><span class="sr-only">Close</span></button>
              </div>
              {/* <div className="px-4 p-0 custom-table-div filter-search-icon card-table-custom c-a-p-c-t"> */}
              <div className="p-30px l-o-c-t custom-table-div filter-search-icon card card-table-custom bulk-notification-table">
                {/* search/filters  */}

                <div className="search-filter-div">
                  <div className="search-filter-div-left">
                    <div className="system-administration-table table-responsive">
                      <div className="table-responsive-div">
                        <div
                          id="assessment-table-main_wrapper"
                          className="dataTables_wrapper no-footer"
                        >
                          <div
                            id="assessment-table-main_filter"
                            className="dataTables_filter"
                          >
                            <label>
                              <input
                                type="search"
                                className=""
                                placeholder="Search"
                                aria-controls="assessment-table-main"
                                onChange={handleOpenFileSearchFilter}
                                value={openFileSearch}
                              />
                            </label>
                            <div className="filter-eff filter-data-btn">
                              <button className="filter-buttons">
                                <i className="fal fa-filter"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="filter-button-group">
                        <div className="reset-btn-group">
                          <div className="button-reset dropdown-comman">
                            <button
                              className="btn btn-primary"
                              onClick={resetOpenFileFilter}
                              title="Reset"
                            >
                              <i className="fal fa-redo"></i>Reset
                            </button>
                          </div>
                          <div className="files-export-group">
                            <button
                              type="button"
                              className="btn btn-files"
                              onClick={() => {
                                exportData2("xlsx", "Year-end-Moderation-Timetable");
                              }}
                              title="Export spreadsheet"
                            >
                              <i className="fal fa-file-excel icon"></i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-files"
                              onClick={() => {
                                exportData2("csv", "Year-end-Moderation-Timetable");
                              }}
                              title="Export CSV"
                            >
                              <i className="fal fa-file-csv icon"></i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-files"
                              onClick={() => {
                                exportData2("pdf", "Year-end-Moderation-Timetable");
                              }}
                              title="Export PDF"
                            >
                              <i className="fal fa-file-pdf icon"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* search/filters */}
                <DataTable
                  data={dataToRender2()}
                  // defaultSortField="data"
                  defaultSortAsc={true}
                  progressPending={recieverLoading}
                  progressComponent={<SkeletonTicketList />}
                  columns={columns2}
                  pagination={true}
                  noDataComponent={Str.noRecord}
                  paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                />
              </div>
            </div>

            {/* <div className="modal-footer">

              <button type="button" className="btn btn-danger" data-dismiss="modal" title="Close">
                <i className="fal fa-times"></i>
                Close
              </button>
            </div> */}
          </div>
        </div>
      </div>
      {/* receiver detail popu open end */}
    </>
  );
};

export default ListOfBulkNotification;

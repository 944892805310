import React, { useEffect, useMemo, useState } from "react";
import { downloadURL, FORM_DATE_TIME_FORMAT, IMAGE_URL, STUDENT_FILE_DOWNLOAD, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT } from "../../../../utils/Constants";
import PermissionsGate from "../../../../utils/permissionGate";
import { InitialRender, convertToPlain } from "../../../common/Helper";
import { Link, useHistory, useParams, Prompt } from "react-router-dom";
import Swal from "sweetalert2";
import Papa from "papaparse";
import { TrimText } from "../../../common/TrimText";
import { AddAndGetTicketComment, DeleteAssessmentSubmissionUpload, DownloadAssessmentsStudentResults, GetAssessmentSubmission, GetdownloadAssements, GetimportMarks, GetMarkasCompelete, getPracticalAssessmentSubmissionResult, GradeLetterTemplateList, SendAssignmentInstruction, startStopPracticalAssignment, updateGrademixMarkSubmission, UpdateHybridMarks, UpDateMarkssubmit, UploadMarkAssessment, ResetTotalMark} from "../../../../services/CourseService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ApproveOrDeclineAppeal, ViewAppeal } from "../../../../services/AppealsService";
import moment from "moment";
import Hover from "../../../common/Hover";
import { useFormik } from "formik";
import * as Yup from "yup";
import $ from "jquery"
import Select from "react-select"
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import { useRef } from 'react';
import { checkIfImageExists, formatBytes, twoDecimalRound } from "../../../../utils/commonFunction";
import DataTableComponentFrontPagination from "../../../common/DataTableComponentFrontPagination";
import { RenderQuizResult, RenderSubmissionAndResultResult, RenderSubmissionAndResultStatus } from "../../../../utils/CommonStatusItems";
import { RenderCategoriesType, RenderSubmissionStatus } from "../../../../utils/CommonGroupingItem";
import PopupComponent from "../../../common/PopupComponent";
import { useSelector } from "react-redux";
import axios from "axios";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";
import * as XLSX from "xlsx";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import GradeBookSaveBar from "../../../common/GradeBookSaveBar";
import hasPermission from "../../../../utils/hasMultiplePermission";

function SubmissionAndResults({assName}) {
  const history = useHistory();
  const { id, type, subId, subType, subSubId, statusValue } = useParams();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [submissionStatus, setSubmissionStatus] = useState({ arr: [], checkObj: {} });
  const [assessementStatus, SetAssessementStatus] = useState({ arr: [], checkObj: {} });
  const [assessementMethod, setAssessementMethod] = useState({ arr: [], checkObj: {} });
  const [assessementResult, setAssessementResult  ] = useState({ arr: [], checkObj: {} });
  const [comment, setComment] = useState("");
  const [deleterow, setDeleteRow] = useState(false);
  const [userData, setUserData] = useState([]);
  const [headerData, setHeaderData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [submissionId, setSubmissionId] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [studentMessage, setStudentMessage] = useState("");
  const [toggle,setToggle] = useState(false);
  const [inputvalue, setInputValue] = useState("")
  const [studentId, setStudentId] = useState();

  const [appealsDetails, setAppealsDetails] = useState();

  const [disableNeeded, setDisableNeeded] = useState(false);
  const [percent, setPercent] = useState(0);
  const [loaded, setLoaded] = useState(0);
  const [total, setTotal] = useState(0);
  const [checkTotal, setCheckTotal] = useState(0);
  const [checkPending, setCheckPending] = useState(0);
  const [instructionLoading, setInstructionLoading] = useState(false)
  const [checkAssignment, setcheckAssignment] = useState("false")
  const [appealDisable, setAppealDisable] = useState("");
  const [messageError, setMessageError] = useState("")
  const [fileLoading, setFileLoading] = useState(false);
  const [finalTotal, setFinalTotal] = useState(0)
  const [totalFiles, setTotalFiles] = useState(0)
  const [importLoading, setImportLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [updateValueArr, setUpdateValueArr] = useState([]);
  const [saveGradesLoading, setSaveGradesLoading] = useState(false);
  const [oldValue, setOldValue] = useState();
  const [options, setOptions] = useState([
    { label: 'Red', value: '0' },
    { label: 'Yellow', value: '49' },
    { label: 'Green', value: '100' },
  ])
  const [updatedata, setupDatedata] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gearid, setGearid] = useState("")
  const [hGear, setHGear] = useState("")
  const [notificationval, setNotificationVal] = useState(false)
  const [downloadZip, setDownloadZip] = useState([])
  const inputRef = useRef(null);
  const [zipfileupload, setZipFileUpload] = useState("")
  const [zipfilename, setZipFileName] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [isSubmissionMarked, setIsSubmissionMarked] = useState(false);
  const [totalLoaded, setTotalLoaded] = useState(0);
  const [totalSize, setTotalSize] = useState(0)
  const [FinalLoadedData, setFinalLoadedData] = useState(0)
  const [isHybrid, setIsHybrid] = useState(false);
  const [isSaveGradesDisabled, setIsSaveGradesDisabled] = useState(false);  
  const downloadRef = React.useRef(null);
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const assessmentTypes=useSelector((state)=>state.assessment?.assessement_type)
  const [assessementStatusFilterList, setAssessementStatusFilterList] = useState([]);
  const [assessementMethodFilterList, setAssessementMethodFilterList] = useState([]);
  const [assessementResultFilterList, setAssessementResultFilterList] = useState([]);
  const [isStartAssessment, setIsStartAssessment] = useState(false);
  const [isStartAssessmentLoading, setIsStartAssessmentLoading] = useState(false)

  // Define a state to hold error messages for each student
  const [studentErrors, setStudentErrors] = useState({});

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $('.rdt_TableCell').css('z-index', 0)
        $(this).parents('.rdt_TableCell').css('z-index', 22)
      });
    });
  })
  useEffect(() => {
    let response = hasPermission({ scopes: ["casubmissionresultview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }
  }, [])
  
  const fetchAssessmentData = () => {
    setLoading(true);
    setIsLoading(true);

    GetAssessmentSubmission(subId, id)
      .then((res) => {
        let tempStatus = false;
        const hybridRecord = res.data.body.filter(item => (item.isHybrid == "true"))
        if (hybridRecord.length) {
          setIsHybrid(true);
          if (hybridRecord.filter(element => element.submission_status != "MARKED").length) {
            tempStatus = true;
          }
        } else if (res.data.body.filter(item => ((item.submission_status == "SUBMITTED" && !item.mark))).length) {
          tempStatus = true;
        }

        // res.data.body.map(item => {
        //   if(item.isHybrid==="true"){
        //     setIsHybrid(true);
        //     if (item.submission_status != "MARKED") {
        //       tempStatus = true
        //     }
        //   }else{
        //     if ((item.submission_status == "SUBMITTED" && !item.mark) || item.submission_status == "NOT SUBMITTED") {
        //       tempStatus = true
        //     }            
        //   }
        // })
        setIsSubmissionMarked(tempStatus)
        setUserData(res?.data?.body);
        const updatedMarks = res?.data?.body?.map(item => item?.assTotaleMarkUpdate);
        setIsSaveGradesDisabled(updatedMarks);
        setHeaderData(res?.data?.header);
        if(res?.data?.header?.is_start_assessment){
          history.push(`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/assessmentMarking/${subId}`)
        }
        //filter for table
        setAssessementMethodFilterList(res?.data?.assessementMethodFilters)
        setAssessementResultFilterList(res?.data?.header?.ass_type == 13 || res?.data?.header?.ass_type == 14 ? res?.data?.practicalAssessementStatusFilters : res?.data?.assessementResultFilters)
        setAssessementStatusFilterList(res?.data?.assessementStatusFilters)

        if (res?.data?.header?.grading_type && res?.data?.header?.grading_type === 2) {
          GradeLetterTemplateList({ exportStatus: true }).then((result) => {
            if (result.status == 200) {
              let respData = result.data.list_data;
              respData = respData.find(item => item.id === res.data.header.grading_template_id);
              const optionData = respData?.gradeOptions?.length > 0 ? respData?.gradeOptions?.map(item => ({ label: item.text, value: item.id, minValue: item.min_score, maxValue: item.max_score })) : [];
              setOptions([...optionData]);
            }
          })
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message == "Assignment data not found.") {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err.response && err.response.data ? err.response.data.message : "An unexpected error occurred.",
          });
          history.push(`/courseAdministration/coursesdetails/${id}/assessments/show`);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err.response && err.response.data ? err.response.data.message : "An unexpected error occurred.",
          });
        }
      }).finally(() => { 
        setLoading(false); 
        setIsLoading(false);
      });
  }
  
  useEffect(() => {
    fetchAssessmentData();
  }, [deleterow, updatedata, importLoading]);

  const getSubmissionData = () =>{

      GetAssessmentSubmission(subId, id)
      .then((res) => {
        let tempStatus = false;
        const hybridRecord = res.data.body.filter(item => (item.isHybrid=="true"))
        if(hybridRecord.length){
          setIsHybrid(true);
          if(hybridRecord.filter(element => element.submission_status!="MARKED").length){
            tempStatus = true;
          }
        }else if(res.data.body.filter(item=>((item.submission_status == "SUBMITTED" && !item.mark))).length){
          tempStatus = true;
        }
        setIsSubmissionMarked(tempStatus)
        setUserData(res?.data?.body);
        setHeaderData(res?.data?.header);        
        if(res.data.header.grading_type && res.data.header.grading_type === 2){
          GradeLetterTemplateList({exportStatus:true}).then((result)=>{
            if(result.status==200){
              let respData =  result.data.list_data;
              respData = respData.find(item => item.id === res.data.header.grading_template_id);
              const optionData = respData?.gradeOptions?.map(item =>({label : item.text, value : item.id, minValue : item.min_score, maxValue : item.max_score}))
              setOptions([...optionData]);
            }
          })
        }
      })
      .catch((err) => {
        console.error(err);
      })
  }

  useEffect(() => {
    GetdownloadAssements(subId)
      .then((res) => {
        setDownloadZip(res?.data?.assignment_details)
        setZipFileName(res.data.assignments_codes.name)
        // let checkTotalFiles = calculateFileSize(res?.data?.assignment_details).checkTotal;
        // setTotalFiles(checkTotalFiles)
      }).catch((err) => {
      })
  }, [updatedata,deleterow])

  const dataToRender = () => {
    let updatedData = [];
    let allData = userData;
    if (search.length) {
      let tempId = allData.filter((item) => {
        let includes = item.id && item.id.toString().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempname = allData.filter((item) => {
        let includes = item.name && item.name.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [...tempId, ...tempname];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (status.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = status.arr.find(function (post, index) {
          if (item.status && post.toLowerCase() == item.status.toLowerCase() ? item.status : "") return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (submissionStatus.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = submissionStatus.arr.find(function (post, index) {
          if (item.submission_status && post.toLowerCase() == item.submission_status.toLowerCase() ? item.submission_status : "") return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (status.arr.length || search.length || submissionStatus.arr.length) {
      return updatedData;
    } else {
      return userData;
    }
  };
  const renderPracticalData = () => {
    let updatedData = [];
    let allData = userData;
    if (search.length) {
      let tempId = allData.filter((item) => {
        let includes = item.id && item.id.toString().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempname = allData.filter((item) => {
        let includes = item.name && item.name.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [...tempId, ...tempname];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (assessementStatus.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = assessementStatus.arr.find(function (post, index) {
          if (item.assessment_status && post.toLowerCase() == item.assessment_status.toLowerCase() ? item.assessment_status : "") return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (assessementMethod.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = assessementMethod.arr.find(function (post, index) {
          if (item.assessment_method && post.toLowerCase() == item.assessment_method.toLowerCase() ? item.assessment_method : "") return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (assessementResult.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = assessementResult.arr.find(function (post, index) {
          if (item.result && post.toLowerCase() == item.result.toLowerCase() ? item.result : "") return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (assessementStatus.arr.length || search.length || assessementMethod.arr.length || assessementResult.arr.length) {
      return updatedData;
    } else {
      return userData;
    }
  };

  const addFileFun = (e, rowId) => {
    let tempUserData = userData;
    const data = new FormData();
    const listFile = e.target.files;
    setFileLoading(rowId);
    data.append("submission", rowId);
    Object.keys(listFile).map((key, index)=>{      
      data.append(`file[${index}]`, listFile[key]);
    })
    
    UploadMarkAssessment(data)
      .then((res) => {        
        getSubmissionData()
      })
      .catch((err) => {
        console.error("error :", err);
      }).finally(()=>{
        setFileLoading("")
      });
    tempUserData.map((item) => {
      if (item.id == rowId) {
        item.fileName = Object.keys(listFile).map((key)=>(listFile[key].name));
        item.file = e.target.files;
        return item;
      } else {
        return item;
      }
    });

    setUserData((tempUserData) => [...tempUserData]);
  };

  const handleSort = (key) =>{
    setToggle(!toggle)
  }
  useEffect(() => {
    setToggle(!toggle)
  },[status,submissionStatus,search])

  const updateSingleGrademix = async (data) => {
    const inputValue = parseFloat(data.ass_mark);
    const totalMark = parseFloat(data.ass_total_mark);
    if (inputValue > totalMark) {
      setErrorMessage(`Mark cannot exceed total mark of ${totalMark}`);
      setGearid(data.id);
      return;
    }

    // Clear error if valid
    setErrorMessage("");
    setGearid(null);

    // Your existing grade update logic here
    // ...
  
    const checkGrade = updateValueArr.filter(item => item?.student_id == data?.quiz_student_id)[0]
    let formData
    if (checkGrade) {
       formData = {
        assessment_id: subId,
        intake_id: id,
        type: 0,
        grademix: [{
          grade: checkGrade?.grade,
          grade_type: checkGrade?.grade_type,
          student_id: checkGrade?.student_id,
        }],
      };
    }else{
       formData = {
        assessment_id: subId,
        intake_id: id,
        type: 0,
        grademix: [{
          grade: data?.ass_mark ?? "  ",
          grade_type: (data?.grade == 0 || data?.grade == 3) ? 3 : data?.grade,
          student_id: data?.quiz_student_id ?? ""
        }],
      };
    }
    try {
      await ResetTotalMark(formData);
      await Swal.fire({
        icon: "success",
        title: "Success",
        text: "Grade Updated Successfully",
      });
      fetchAssessmentData();
    } catch (error) {
      console.error("Error updating grades:", error);

      await Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update grades",
      });
      throw error;
    }
  };

  const sortName = (rowA, rowB) => {
    const name1 = rowA.name
      ? rowA.name.toString().toLowerCase().trim()
      : "";
    const name2 = rowB.name
      ? rowB.name.toString().toLowerCase().trim()
      : "";
    return name1.localeCompare(name2);
  }
  const basename = (path) => {
    return path.split("/").reverse()[0];
  };
  const conditionalRowStyles = [
    {
      when: row => row?.assTotaleMarkUpdate == 1 && row?.quiz_id == 0 && (row?.grade == 3 || row?.grade == 0),
      style: {
        backgroundColor: '#FEF4E8!important',
      },
    },
  ];
  
  const columns = [
    {
      name: "User",
      selector: "name",
      sortable: true,
      sortFunction: sortName,
      style: row => ({
        backgroundColor: row?.assTotaleMarkUpdate == 0 ? '#FEF4E8' : 'inherit',
      }),
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <span className={"assigned-title-blk  name-icon cat-dark-red "}>
              {row.image ? <img src={`${IMAGE_URL.replaceAll("http:","https:")}/${encodeURI(row.image.replaceAll("/home/myaie/public_html/", ""))}`} alt="AIE" /> : row.name ? InitialRender([row.name.split(" ")[0], row.name.split(" ")[1]]) : InitialRender(["N", "A"])}
              <span className={`profile-box-2-status ${row.activity_status ? row.activity_status.toLowerCase() == "online" ? "Online" : row.activity_status.toLowerCase() == "away" ? "Away" : "Offline" : "Offline"}`}>
                <i className="fas fa-circle"></i>
              </span>
              <Hover firstName={row.name ? row.name.split(" ")[0] : ""} lastName={row.name ? row.name.split(" ")[1] : ""} photo={row.image} email={row.email} mobile={row.mobile} right={true} role={row.role_name} status={row.status} activity_status={row.activity_status} showNumber={true} number={row.student_crm_id} />
            </span>
            <PermissionsGate scopes={["umedit"]} RenderError={() => <p>{row.name}</p>}>
              <Link title={row.name} className="as-text-blue curser " to={`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/submissionsAndResults/${subId}/update/${row.id}`}>
                {TrimText(row.name, 15)}
              </Link>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      cell: (row) => row.status ? RenderSubmissionAndResultStatus(row.status).html : "-"
    },
    {
      name: "Submission Status",
      selector: "submission_status",
      sortable: true,
      cell: (row) => {
        return (
          <>
            {row.submission_status ? RenderSubmissionStatus(row.submission_status).html : "-"}
            {((row.student_id != "" && row.student_id != null) || row.appeal_status == "Approved" || row.appeal_status == "Declined") ? (
              <button
                className="btn btn-primary mt-1"
                title="View Appeal"
                data-toggle="modal"
                data-target="#notifications1"
                onClick={() => {
                  setStudentId(row.student_id);
                  handleGetAppealDetails(row.student_id);
                }}
              >
                <i className="fal fa-gavel"></i> View Appeal
              </button>
            ) : ""}
          </>
        )
      }
    },
    { name: "Submission Date", selector: "submission_date", sortable: true, cell: (row) => (row.submission_date ? row.submission_date : "NA") },
    { name: "Launch Time", selector: "launchTime", sortable: true, cell: (row) => (row.launchTime ? moment.unix(row.launchTime).format("DD MMM YYYY HH:mm:ss") : "NA") },
    {
      name: "Student Submission",
      selector: "path",
      sortable: true,
      cell: (row) => row.path ? <p
        data-toggle="modal" data-target="#downloadAssessment"
        onClick={() => handleStudentDownload(row?.multipleFiles?.length && row?.multipleFiles?.filter((item)=>(item?.type===0))?.length ? row.multipleFiles?.filter((item)=>(item?.type===0)) : row, row)}
        className="as-text-blue curser">Download Assignment</p> : row?.quiz_student_id && row?.quiz_id && row?.submissionCount ? <Link
          title="View Submissions"
          className="as-text-blue curser"
          to={{pathname: `/courseAdministration/assessment/quizzes/open/submissionList/${row?.quiz_id}/${row?.quiz_student_id}?intake_id=${id}&assess_id=${subId}`,state: { id: id,subId: subId }}}
        >
          View Submissions
        </Link> : "-"
    },
    {
      name: "Upload File",
      selector: "",
      cell: (row) => {
        return (
          <div className="frm-group">
            <div className=" atttach-file m-0">
              <label>
                {fileLoading==row.id ? <i className="fas fa-cog fa-spin"></i>
                : <i className="fal fa-paperclip"></i>}
                <span>Add Attachment</span>
                <PermissionsGate scopes={["castedit"]} errorProps={{ disabled: true }}>
                  <input disabled={fileLoading==row.id} type="file" multiple className="form-control  form-control-aatch-file" onChange={(e) => addFileFun(e, row.id)} />
                </PermissionsGate>
              </label>
            </div>
            <ul className="list-unstyled attached-file-ul m-0">
              {row.multipleFiles && row.multipleFiles.filter(item=>item.type==1).map((element, ind)=>(
                <li key={ind}>
                  <a
                    href={`${downloadURL}${STUDENT_FILE_DOWNLOAD}/${encodeURI(element.path)}`.replaceAll("/home/myaie/public_html/", "").replace("public/", "")} 
                    target="_blank" 
                    title={element.path.split("/").reverse()[0]}>
                    <i className="fal fa-file"></i> {TrimText(element?.path?.split("/").reverse()[0], 15)}
                  </a>
                  <button
                    className="btn btn-danger rounded-circle btn-dropdown-item"
                    title="Delete"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText:
                          "Yes, delete it!",
                      }).then((result) =>{ 
                        if(result.isConfirmed){
                          DeleteAssessmentSubmissionUpload({ submission_id: element.id, intake: id, type : element.type, submited_type : element.submited_type  }).then(res => {
                            Swal.fire(
                              "Deleted!",
                              "File has been deleted.",
                              "success"
                            );
                            getSubmissionData();
                          }).catch(err => {
                            Swal.fire(
                              "Not Deleted!",
                              "File not deleted.",
                              "error"
                            );
                          })
                        }
                      })
                    }}
                  >
                    <i className="fal fa-trash-alt"></i>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        );
      },
    },
    {
      name: "Student Results",
      selector: "student_result",
      sortable: false,
      cell: (row,id) => ((row.grade == 0 || row.grade == 3) ?
        <div className="input_wt01 resultstatus_flex arrowhide" key={id}>
          <PermissionsGate scopes={["cassgradeassedit"]} errorProps={{ disabled: true }}>
            <input
              className={`form-control ${(errorMessage && gearid === row.id) ||
                  (parseFloat(row.ass_mark) > parseFloat(headerData.ass_total_mark))
                  ? "is-invalid"
                  : ""
                }`}
              style={{width:'100%',maxWidth:'100px'}}
              id={`handleinput${row.id}`}
              name={`handleinputname${row.id}`}
              type="number"
              defaultValue={
                (row.ass_percentage === 0 || row.ass_percentage === null) &&
                  row.mark === null &&
                  updateValueArr.filter(item => item?.student_id === row?.quiz_student_id).length === 0
                  ? ""
                  : row.ass_mark === ""
                    ? row.ass_mark
                    : twoDecimalRound(row.ass_mark)
              }
              onBlur={(e) => {
                handleUpdateMarks(
                  e.target.value,
                  row.quiz_student_id,
                  "inputbox",
                  headerData.ass_total_mark ,
                  row
                );
              }}
              disabled={row.lock_grades == 1}
            />
            </PermissionsGate>
            {errorMessage && gearid === row.id && (
              <div className="invalid-feedback d-block">
               "Please enter a valid mark"
              </div>
            )}
          
          <span>{"/" + row.ass_total_mark}</span>
          {loading == true && gearid == row.id ? <i className="fas fa-cog fa-spin"></i> : ""}
        </div> : row.grade == 1 ?
        <div className="form-icon-group">
          <PermissionsGate scopes={["cassgradeassedit"]} RenderError={() => {
            return <Select
            className="form-control custom-select-box wdtcontrlrobot submission-result-dropdown"
            value={
              options.find(function (option) {                
                if (row.mark && Number(row.mark.replace(/%\s?/g, ''))>=0) {
                  return option.value >= Number(row.mark.toString().replace(/%\s?/g, ''))
                }
              })
            }
            isDisabled={true}
          />
            }}>
             
          <Select
            className="form-control custom-select-box wdtcontrlrobot submission-result-dropdown"
            options={options}
            value={
              options.find(function (option) {  
                if (row.ass_percentage!=null && (row.mark != null || updateValueArr.filter(item => item?.student_id == row?.quiz_student_id).length!=0) && Number(row.ass_percentage.toString().replace(/%\s?/g, ''))>=0 && (row.ass_percentage.toString().replace(/%\s?/g, '')) !== "") {
                  return option.value >= Number(row.ass_percentage.toString().replace(/%\s?/g, ''))
                }
              })
            }
            onChange={(value) => {
              if(value) {
                handleUpdateMarks(value.value, row.quiz_student_id, "selectbox", row.ass_total_mark, row)
              } else {
                handleUpdateMarks("", row.quiz_student_id, "selectbox", row.ass_total_mark, row)
              }
            }}
            maxMenuHeight={108}
            isDisabled={row.lock_grades == 1}
            isClearable
          />
          </PermissionsGate>
          {loading == true && gearid == row.id ? <span className="flex_wt_loder ml-2"><i className="fas fa-cog fa-spin"></i></span> : ""}
        </div>
        :<div className="form-icon-group">
          <PermissionsGate scopes={["cassgradeassedit"]} RenderError={() => {
            return <Select
            className="form-control custom-select-box wdtcontrl submission-result-dropdown"
            isDisabled={true}
            value={
              options.filter(function (option) {
                if (row.mark && Number(row.mark.replace(/%\s?/g, ''))>=0) {
                  return (option.maxValue >= Number(row.mark.replace(/%\s?/g, ''))) && (option.minValue <= Number(row.mark.replace(/%\s?/g, '')))
                }
              })
            }
          />
          }}>
          <Select
            className="form-control custom-select-box wdtcontrl submission-result-dropdown"
            options={options}
            // isDisabled={true}
            value={
              options.filter(function (option) {
                if (row.ass_percentage!=null && (row.mark != null || updateValueArr.filter(item => item?.student_id == row?.quiz_student_id).length!=0) && Number(row?.ass_percentage?.toString()?.replace(/%\s?/g, ''))>=0 && (row.ass_percentage.toString().replace(/%\s?/g, '')) !== "") {
                  return (option.maxValue >= Number(row?.ass_percentage?.toString()?.replace(/%\s?/g, ''))) && (option.minValue <= Number(row?.ass_percentage?.toString()?.replace(/%\s?/g, '')))
                }
              })
            }
            onChange={(value) => {
              if (value){
                handleUpdateMarks(value.value, row?.quiz_student_id, "selectbox", row.ass_total_mark, row)
              } else {
                handleUpdateMarks("", row?.quiz_student_id, "selectbox", row.ass_total_mark, row)
              } 
            }}
            maxMenuHeight={108}
            isDisabled={row.lock_grades == 1}
            isClearable
          />
          </PermissionsGate>
          {loading == true && gearid == row.id ? <span className="flex_wt_loder ml-2"><i className="fas fa-cog fa-spin"></i></span> : ""}
        </div>
      )
    },
    {
      name: "Mark",
      selector: "mark",
      sortable: true,
      cell: (row) => (
        <div className="flex items-center gap-2" style={{display:'flex',alignItems:'center' ,gap:'5px'}}>
          <span>
            {row.mark && row.showValue ?
              <p className="cat">
                {row.grade === 0 ? `${twoDecimalRound(row.assCalculateGradeStatus.grade)}%`
                  : row.grade === 3 ? `${twoDecimalRound(row.assCalculateGradeStatus.gradeText)}/${row.ass_total_mark}`
                    : row.assCalculateGradeStatus.gradeText}
              </p>
              : headerData?.notify_assessed == 1 ? row.grade == 3 ? `${twoDecimalRound(row.assCalculateGradeStatus.gradeText)}/${row.ass_total_mark}` : row.assCalculateGradeStatus.gradeText : <p className="cat">NA</p>}
          </span>
          {row?.assTotaleMarkUpdate == 1 && (row?.grade == 0 || row?.grade == 3) && (<i className="fa fa-info-circle text-blue-500" title="Original assessment value is shown. To edit this mark please convert the mark"></i>)}
        </div>
      )
    },
    {
      name: "Action",
      selector: "",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          {headerData?.quiz_id && !row.upload_file ? <div className="as-buttons">
            <button
              className="btn btn-primary rounded-circle"
              data-toggle="modal"
              data-target="#notifications"
              onClick={() => {
                handleGetComment(row.id);
                setSubmissionId(row.id);
              }}
              title="Comment"
            >
              <i className="fal fa-comment"></i>
            </button>

            <button
              className="btn btn-primary rounded-circle"
              onClick={() => history.push(`/studentAdministration/students/open/${row.quiz_student_id}/general`)}
              title="Open Student Profile"
            >
              <i className="fal fa-user"></i>
            </button>
          </div> : headerData?.quiz_id && row.upload_file ? <div className="as-buttons">
            <a className="btn btn-primary rounded-circle" title="Download Submission" href={IMAGE_URL.replaceAll("http:", "https:") + "/" + encodeURI(row.upload_file)} target="_blank" download={"submission"}>
              <i className="fal fa-arrow-down"></i>
            </a>
            <div className="dropdown btn-dropdown-item">
              <button className="btn btn-primary rounded-circle dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fal fa-ellipsis-h-alt"></i>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button
                  className="btn btn-primary rounded-circle"
                  data-toggle="modal"
                  data-target="#notifications"
                  onClick={() => {
                    handleGetComment(row.id);
                    setSubmissionId(row.id);
                  }}
                  title="Comment"
                >
                  <i className="fal fa-comment"></i>
                </button>

                <button
                  className="btn btn-primary rounded-circle"
                  onClick={() => history.push(`/studentAdministration/students/open/${row.quiz_student_id}/general`)}
                  title="Open Student Profile"
                >
                  <i className="fal fa-user"></i>
                </button>
              </div>
            </div>
          </div> : <div className="as-buttons">
            <button
              className="btn btn-primary rounded-circle"
              onClick={() => history.push(`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/submissionsAndResults/${subId}/update/${row.id}`)}
              title="Open"
            >
              <i className="fal fa-folder-open"></i>
            </button>
            <div className="dropdown btn-dropdown-item">
              <button className="btn btn-primary rounded-circle dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fal fa-ellipsis-h-alt"></i>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {row.upload_file && <a className="btn btn-primary rounded-circle" title="Download Submission" href={IMAGE_URL.replaceAll("http:", "https:") + "/" + encodeURI(row.upload_file)} target="_blank" download={"submission"}>
                  <i className="fal fa-arrow-down"></i>
                </a>}

                <button
                  className="btn btn-primary rounded-circle"
                  data-toggle="modal"
                  data-target="#notifications"
                  onClick={() => {
                    handleGetComment(row.id);
                    setSubmissionId(row.id);
                  }}
                  title="Comment"
                >
                  <i className="fal fa-comment"></i>
                </button>

                <button
                  className="btn btn-primary rounded-circle"
                  onClick={() => history.push(`/studentAdministration/students/open/${row.quiz_student_id}/general`)}
                  title="Open Student Profile"
                >
                  <i className="fal fa-user"></i>
                    </button>
                    {(row?.assTotaleMarkUpdate == 1 && row?.quiz_id == 0 && (row?.grade == 0 || row?.grade == 3)) && (
                      <button
                        className="btn btn-primary rounded-circle"
                        title="Update Mark"
                        disabled={(errorMessage && gearid === row.id) ||
                          (parseFloat(row.ass_mark) > parseFloat(headerData.ass_total_mark))}
                        onClick={() => {
                          updateSingleGrademix(row );
                        }}
                      >
                        <i className="fal fa-redo"></i>
                      </button>
                    )}
              </div>
            </div>
          </div>}
        </div>
      ),
    },
  ]

  const quizColumns = useMemo(() => [
    {
      name: "User",
      selector: "name",
      sortable: true,
      sortFunction: sortName,
      // minWidth: "190px",
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <span className={"assigned-title-blk  name-icon cat-dark-red "}>
              {row.image ? <img src={`${IMAGE_URL.replaceAll("http:","https:")}/${row.image.replaceAll("/home/myaie/public_html/", "")}`} alt="AIE" /> : row.name ? InitialRender([row.name.split(" ")[0], row.name.split(" ")[1]]) : InitialRender(["N", "A"])}
              <span className={`profile-box-2-status ${row.activity_status ? row.activity_status.toLowerCase() == "online" ? "Online" : row.activity_status.toLowerCase() == "away" ? "Away" : "Offline" : "Offline"}`}>
                <i className="fas fa-circle"></i>
              </span>
              <Hover firstName={row.name ? row.name.split(" ")[0] : ""} lastName={row.name ? row.name.split(" ")[1] : ""} photo={row.image} email={row.email} mobile={row.mobile} right={true} role={row.role_name} status={row.status} activity_status={row.activity_status} showNumber={true} number={row.student_crm_id} />
            </span>
            <PermissionsGate scopes={["umedit"]} RenderError={() => <p>{row.name}</p>}>
              <Link title={row.name} className="as-text-blue curser " to={`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/submissionsAndResults/${subId}/update/${row.id}`}>
                {TrimText(row.name, 15)}
              </Link>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      // maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) => row.status ? RenderSubmissionAndResultStatus(row.status).html : "-"
    },
    {
      name: "Submission Status",
      selector: "submission_status",
      // maxWidth: "240px",
      sortable: true,
      cell: (row) => {
        return (
          <>
            {row.submission_status ? RenderSubmissionStatus(row.submission_status).html : "-"}
            {((row.student_id != "" && row.student_id != null) || row.appeal_status == "Approved" || row.appeal_status == "Declined") ? (
              <button
                className="btn btn-primary mt-1"
                title="View Appeal"
                data-toggle="modal"
                data-target="#notifications1"
                onClick={() => {
                  setStudentId(row.student_id);
                  handleGetAppealDetails(row.student_id);
                }}
              >
                <i className="fal fa-gavel"></i> View Appeal
              </button>
            ) : ""}
          </>
        )
      }
    },
    { name: "Launch Time", selector: "launchTime", sortable: true, cell: (row) => (row.launchTime ? moment.unix(row.launchTime).format("DD MMM YYYY HH:mm:ss") : "NA") },
    { name: "Submission Date", selector: "submission_date", sortable: true, cell: (row) => (row.submission_date ? row.submission_date : "NA") },
    {
      name: "Student Submission",
      selector: "submissionCount",
      sortable: true,
      cell: (row) => row.path ? <p
        data-toggle="modal" data-target="#downloadAssessment"
        onClick={() => handleStudentDownload(row?.multipleFiles?.length && row.multipleFiles.filter((item)=>(item.type===0)).length ? row.multipleFiles?.filter((item)=>(item?.type===0)) : row)}
        className="as-text-blue curser">Download Assignment</p> : row?.quiz_student_id && row?.quiz_id && row?.submissionCount ? <Link
          title="View Submissions"
          className="as-text-blue curser"
          // to={`/courseAdministration/assessment/quizzes/open/submissionList/${row?.quiz_id}/${row?.quiz_student_id}`}
          to={{pathname: `/courseAdministration/assessment/quizzes/open/submissionList/${row?.quiz_id}/${row?.quiz_student_id}?intake_id=${id}&assess_id=${subId}`,state: { id: id,subId: subId }}}
        >
          View Submissions
        </Link> : "-"
    },
    {
      name: "Student Result",
      selector: "student_result",
      sortable: false,
      cell: (row) => ((row.grade == 0 || row.grade == 3)?
        <div className="input_wt01 resultstatus_flex arrowhide">
          <PermissionsGate scopes={["overidersmart"]}  errorProps={{ disabled: true }}>
            <input             
              className={"form-control" + (studentErrors[row.quiz_student_id] ? " is-invalid" : "")}
              // id={"handleinput"+ row.id}
              // name={"handleinputname"+ row.id}
              max={row.ass_total_mark}
              type="number"
              // defaultValue={(row.mark!="" && row.mark!=null) && row.ass_mark>=0 ? Math.round(row.ass_mark) : "-"}
              defaultValue={(row.ass_percentage == 0 || row.ass_percentage == null) && row.mark == null && updateValueArr.filter(item => item?.student_id == row?.quiz_student_id).length==0 ? "" : row.ass_mark=="" ? row.ass_mark : twoDecimalRound(row.ass_mark)}
              // onBlur={(e) => handlegradeChange(e, row.id, "inputbox", row.ass_total_mark, row)}
              // defaultValue={(row.mark=="" ||row.mark==null) && row.ass_mark==0 ? "-" : row.ass_mark == "" ? row.ass_mark : Math.round(row.ass_mark)}
              onBlur={(e) => handleUpdateMarks(e.target?.value, row.quiz_student_id, "inputbox", row.ass_total_mark, row)}
              disabled={row.lock_grades == 1}
            />
          </PermissionsGate>
          <span>{"/" + row.ass_total_mark}</span>
        </div> : row.grade == 1 ?
        <div className="form-icon-group">
          <PermissionsGate scopes={["overidersmart"]}  errorProps={{ isDisabled: true }}>
          <Select
            className="form-control custom-select-box wdtcontrlrobot submission-result-dropdown"
            options={options}
            // isDisabled={true}
            onChange={(value) => {
              if (value){
                handleUpdateMarks(value.value, row?.quiz_student_id, "selectbox", row.ass_total_mark, row)
              } else {
                handleUpdateMarks("", row?.quiz_student_id, "selectbox", row.ass_total_mark, row)
              } 
            }}
            isDisabled={row.lock_grades == 1}
            value={
              options.find(function (option) {
                if (row.ass_percentage!=null && (row.mark != null || updateValueArr.filter(item => item?.student_id == row?.quiz_student_id).length!=0) && Number(row.ass_percentage.toString().replace(/%\s?/g, ''))>=0 && (row.ass_percentage.toString().replace(/%\s?/g, '')) !== "") {
                  return option.value >= Number(row.ass_percentage.toString().replace(/%\s?/g, ''))
                }
              })
            }
            // onChange={(e) => handlegradeChange(e, row.id, "selectbox", row.ass_total_mark, row)}
            maxMenuHeight={108}
            isClearable
          />
          </PermissionsGate>
        </div>
        :<div className="form-icon-group">
          <PermissionsGate scopes={["overidersmart"]}  errorProps={{ isDisabled: true }}>
          <Select
            className="form-control custom-select-box wdtcontrl submission-result-dropdown"
            options={options}
            // value={
            //   options.filter(function (option) {
            //     if (row.mark && row.normalMarksPerchange>=0) {
            //       return (option.maxValue >= Math.round(row.normalMarksPerchange)) && (option.minValue <= Math.round(row.normalMarksPerchange))
            //     }
            //   })
            // }
            value={
              options.filter(function (option) {
                if (row.ass_percentage!=null && (row.mark != null || updateValueArr.filter(item => item?.student_id == row?.quiz_student_id).length!=0) && Number(row?.ass_percentage?.toString()?.replace(/%\s?/g, ''))>=0 && (row.ass_percentage.toString().replace(/%\s?/g, '')) !== "") {
                  return (option.maxValue >= Number(row?.ass_percentage?.toString()?.replace(/%\s?/g, ''))) && (option.minValue <= Number(row?.ass_percentage?.toString()?.replace(/%\s?/g, '')))
                }
              })
            }
            onChange={(value) => {
              if (value){
                handleUpdateMarks(value.value, row?.quiz_student_id, "selectbox", row.ass_total_mark, row)
              } else {
                handleUpdateMarks("", row?.quiz_student_id, "selectbox", row.ass_total_mark, row)
              } 
            }}
            maxMenuHeight={108}
            isDisabled={row.lock_grades == 1}
            isClearable
          />
          </PermissionsGate>
        </div>
      )
    },
    {
      name: "Mark",
      selector: "mark",
      sortable: true,
      cell: (row) => 
      (row.mark && row.showValue ? 
        <p className="cat">
          {row.grade === 0 ? `${twoDecimalRound(row.assCalculateGradeStatus.grade)}%`
          : row.grade === 3 ? `${twoDecimalRound(row.assCalculateGradeStatus.gradeText)}/${row.ass_total_mark}` 
          : row.assCalculateGradeStatus.gradeText}
        </p> 
        : headerData?.notify_assessed == 1 ? row.grade == 3 ? `${twoDecimalRound(row.assCalculateGradeStatus.gradeText)}/${row.ass_total_mark}` : row.assCalculateGradeStatus.gradeText : <p className="cat">NA</p>),
    },
    {
      name: "Action",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          {headerData.quiz_id && !row.upload_file ? <div className="as-buttons">
            <button
              className="btn btn-primary rounded-circle"
              data-toggle="modal"
              data-target="#notifications"
              onClick={() => {
                handleGetComment(row.id);
                setSubmissionId(row.id);
              }}
              title="Comment"
            >
              <i className="fal fa-comment"></i>
            </button>

            <button
              className="btn btn-primary rounded-circle"
              onClick={() => history.push(`/studentAdministration/students/open/${row.quiz_student_id}/general`)}
              title="Open Student Profile"
            >
              <i className="fal fa-user"></i>
            </button>
          </div> : headerData.quiz_id && row.upload_file ? <div className="as-buttons">
            <a className="btn btn-primary rounded-circle" title="Download Submission" href={IMAGE_URL.replaceAll("http:", "https:") + "/" + encodeURI(row.upload_file)} target="_blank" download={"submission"}>
              <i className="fal fa-arrow-down"></i>
            </a>
            <div className="dropdown btn-dropdown-item">
              <button className="btn btn-primary rounded-circle dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fal fa-ellipsis-h-alt"></i>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">

                <button
                  className="btn btn-primary rounded-circle"
                  data-toggle="modal"
                  data-target="#notifications"
                  onClick={() => {
                    handleGetComment(row.id);
                    setSubmissionId(row.id);
                  }}
                  title="Comment"
                >
                  <i className="fal fa-comment"></i>
                </button>

                <button
                  className="btn btn-primary rounded-circle"
                  onClick={() => history.push(`/studentAdministration/students/open/${row.quiz_student_id}/general`)}
                  title="Open Student Profile"
                >
                  <i className="fal fa-user"></i>
                </button>
              </div>
            </div>
          </div> : <div className="as-buttons">
            <button
              className="btn btn-primary rounded-circle"
              onClick={() => history.push(`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/submissionsAndResults/${subId}/update/${row.id}`)}
              title="Open"
            >
              <i className="fal fa-folder-open"></i>
            </button>
            <div className="dropdown btn-dropdown-item">
              <button className="btn btn-primary rounded-circle dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fal fa-ellipsis-h-alt"></i>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {row.upload_file && <a className="btn btn-primary rounded-circle" title="Download Submission" href={IMAGE_URL.replaceAll("http:", "https:") + "/" + encodeURI(row.upload_file)} target="_blank" download={"submission"}>
                  <i className="fal fa-arrow-down"></i>
                </a>}

                <button
                  className="btn btn-primary rounded-circle"
                  data-toggle="modal"
                  data-target="#notifications"
                  onClick={() => {
                    handleGetComment(row.id);
                    setSubmissionId(row.id);
                  }}
                  title="Comment"
                >
                  <i className="fal fa-comment"></i>
                </button>

                <button
                  className="btn btn-primary rounded-circle"
                  onClick={() => history.push(`/studentAdministration/students/open/${row.quiz_student_id}/general`)}
                  title="Open Student Profile"
                >
                  <i className="fal fa-user"></i>
                </button>
              </div>
            </div>
          </div>}
        </div>
      ),
    },
  ]);

  const practicalColumns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      // sortFunction: sortName,
      // minWidth: "190px",
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <span className={"assigned-title-blk  name-icon cat-dark-red "}>
              {row.photo ? <img src={`${IMAGE_URL.replaceAll("http:","https:")}/${row.photo.replaceAll("/home/myaie/public_html/", "")}`} alt="AIE" /> : row.name ? InitialRender([row.name.split(" ")[0], row.name.split(" ")[1]]) : InitialRender(["N", "A"])}
              <span className={`profile-box-2-status ${row.activity_status ? row.activity_status.toLowerCase() == "online" ? "Online" : row.activity_status.toLowerCase() == "away" ? "Away" : "Offline" : "Offline"}`}>
                <i className="fas fa-circle"></i>
              </span>
              <Hover firstName={row.name ? row.name?.split(" ")[0] : ""} lastName={row.name ? row.name?.split(" ")[1] : ""} photo={row?.image} email={row?.email} mobile={row?.mobile} right={true} role={row?.role_name} status={row?.status} activity_status={row?.activity_status} showNumber={true} number={row?.student_crm_id} />
            </span>
            <PermissionsGate scopes={["umedit"]} RenderError={() => <p>{row.name}</p>}>
              <Link title={row?.name} className="as-text-blue curser " to={`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/submissionsAndResults/${subId}/openStudentAssessment/${row.quiz_student_id}`}>
                {TrimText(row?.name, 15)}
              </Link>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
    {
      name: "Assessment Status",
      selector: "assessment_status",
      sortable: true,
      // maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) => row?.assessment_status ? RenderSubmissionAndResultStatus(row.assessment_status).html : "-"
    },
    {
      name: "Assessment Completed",
      selector: "submission_date",
      // maxWidth: "240px",
      sortable: true,
      // cell: (row) => row?.submited > 0 ? moment.unix(row?.submited).format(TABLE_DATE_TIME_FORMAT)  : "-"
      cell: (row) => row?.student_submission_date ? moment.unix(row?.student_submission_date).format(TABLE_DATE_TIME_FORMAT) : row?.submission_date > 0 ? row?.submission_date : "-"
    },
    { 
      name: "Attempts", 
      selector: "attempts", 
      sortable: true, 
      cell: (row) => row?.attempts || "-" 
    },
    {
      name: "Assessment Method",
      selector: "assessment_method",
      sortable: true,
      cell: (row) => (row?.assessment_method ? RenderCategoriesType(row.assessment_method).html : "-")
    },
    {
      name: "Result",
      selector: "result",
      sortable: false,
      cell: (row) => ((row.grade == 0 || row.grade == 3)?
        <div className="input_wt01 resultstatus_flex arrowhide">
          {/* <PermissionsGate scopes={["overidersmart"]}  errorProps={{ disabled: true }}> */}
            <input             
              className={"form-control" + (studentErrors[row.quiz_student_id] ? " is-invalid" : "")}
              // id={"handleinput"+ row.id}
              // name={"handleinputname"+ row.id}
              max={row.ass_total_mark}
              type="number"
              
              defaultValue={row?.assessment_status == "MARKED" ? (row.assessment_method == "Student Assessed" ? headerData?.self_assessed_finalize_mark == 1 : headerData?.lecturer_assessed_finalize_mark == 1) ? ((row.ass_percentage == 0 || row.ass_percentage == null) && row.mark == null && updateValueArr.filter(item => item?.student_id == row?.quiz_student_id).length==0) ? "" : row.ass_mark=="" ? row.ass_mark : twoDecimalRound(row.ass_mark) : "" : ""}

              // onBlur={(e) => handlegradeChange(e, row.id, "inputbox", row.ass_total_mark, row)}
              // defaultValue={(row.mark=="" ||row.mark==null) && row.ass_mark==0 ? "-" : row.ass_mark == "" ? row.ass_mark : Math.round(row.ass_mark)}
              onBlur={(e) => handleUpdateMarks(e.target?.value, row.quiz_student_id, "inputbox", row.ass_total_mark, row)}
              disabled={true}
            />
          {/* </PermissionsGate> */}
          <span>{"/" + row.ass_total_mark}</span>
        </div> : row.grade == 1 ?
        <div className="form-icon-group">
          {/* <PermissionsGate scopes={["overidersmart"]}  errorProps={{ isDisabled: true }}> */}
          <Select
            className="form-control custom-select-box wdtcontrlrobot submission-result-dropdown"
            options={options}
            isDisabled={true}
            onChange={(value) => {
              if (value){
                handleUpdateMarks(value.value, row?.quiz_student_id, "selectbox", row.ass_total_mark, row)
              } else {
                handleUpdateMarks("", row?.quiz_student_id, "selectbox", row.ass_total_mark, row)
              } 
            }}
            value={
              options.find(function (option) {
                if (row.ass_percentage!=null && headerData?.isShowMarkAsCompleteButton == 1 && Number(row.ass_percentage.toString().replace(/%\s?/g, ''))>=0 && (row.ass_percentage.toString().replace(/%\s?/g, '')) !== "") {
                  return option.value >= Number(row.ass_percentage.toString().replace(/%\s?/g, ''))
                }
              })
            }
            // onChange={(e) => handlegradeChange(e, row.id, "selectbox", row.ass_total_mark, row)}
            maxMenuHeight={108}
            isClearable
          />
          {/* </PermissionsGate> */}
        </div>
        :<div className="form-icon-group">
          {/* <PermissionsGate scopes={["overidersmart"]}  errorProps={{ isDisabled: true }}> */}
          <Select
            className="form-control custom-select-box wdtcontrl submission-result-dropdown"
            options={options}
            // value={
            //   options.filter(function (option) {
            //     if (row.mark && row.normalMarksPerchange>=0) {
            //       return (option.maxValue >= Math.round(row.normalMarksPerchange)) && (option.minValue <= Math.round(row.normalMarksPerchange))
            //     }
            //   })
            // }
            value={
              options.filter(function (option) {
                if (row.ass_percentage!=null && (row.mark != null || updateValueArr.filter(item => item?.student_id == row?.quiz_student_id).length!=0) && headerData?.isShowMarkAsCompleteButton == 1 && Number(row?.ass_percentage?.toString()?.replace(/%\s?/g, ''))>=0 && (row.ass_percentage.toString().replace(/%\s?/g, '')) !== "") {
                  return (option.maxValue >= Number(row?.ass_percentage?.toString()?.replace(/%\s?/g, ''))) && (option.minValue <= Number(row?.ass_percentage?.toString()?.replace(/%\s?/g, '')))
                }
              })
            }
            onChange={(value) => {
              if (value){
                handleUpdateMarks(value.value, row?.quiz_student_id, "selectbox", row.ass_total_mark, row)
              } else {
                handleUpdateMarks("", row?.quiz_student_id, "selectbox", row.ass_total_mark, row)
              } 
            }}
            maxMenuHeight={108}
            isDisabled={true}
            isClearable
          />
          {/* </PermissionsGate> */}
        </div>
      )
    },
    {
      name: "Action",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <button
              className="btn btn-primary rounded-circle"
              onClick={() => history.push(`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/submissionsAndResults/${subId}/openStudentAssessment/${row?.quiz_student_id}`)}
              title="Open"
            >
              <i className="fal fa-folder-open"></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);


  const hybridColumns = useMemo(() => [
    {
      name: "User",
      selector: "name",
      sortable: true,
      sortFunction: sortName,
      // minWidth: "190px",
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <span className={"assigned-title-blk  name-icon cat-dark-red "}>
              {row.image ? <img src={`${IMAGE_URL.replaceAll("http:","https:")}/${encodeURI(row.image.replaceAll("/home/myaie/public_html/", ""))}`} alt="AIE" /> : row.name ? InitialRender([row.name.split(" ")[0], row.name.split(" ")[1]]) : InitialRender(["N", "A"])}
              <span className={`profile-box-2-status ${row.activity_status ? row.activity_status.toLowerCase() == "online" ? "Online" : row.activity_status.toLowerCase() == "away" ? "Away" : "Offline" : "Offline"}`}>
                <i className="fas fa-circle"></i>
              </span>
              <Hover firstName={row.name ? row.name.split(" ")[0] : ""} lastName={row.name ? row.name.split(" ")[1] : ""} photo={row.image} email={row.email} mobile={row.mobile} right={true} role={row.role_name} status={row.status} activity_status={row.activity_status} showNumber={true} number={row.student_crm_id} />
            </span>
            <PermissionsGate scopes={["umedit"]} RenderError={() => <p>{row.name}</p>}>
              <Link title={row.name} className="as-text-blue curser " to={`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/submissionsAndResults/${subId}/update/${row.id}`}>
                {TrimText(row.name, 15)}
              </Link>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      // maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) => row.status ? RenderSubmissionAndResultStatus(row.status).html : "-"
    },
    {
      name: "Submission Status",
      selector: "submission_status",
      // maxWidth: "240px",
      sortable: true,
      cell: (row) => {
        return (
          <>
            {row.submission_status ? RenderSubmissionStatus(row.submission_status).html : "-"}
            {((row.student_id != "" && row.student_id != null) || row.appeal_status == "Approved" || row.appeal_status == "Declined") ? (
              <button
                className="btn btn-primary mt-1"
                title="View Appeal"
                data-toggle="modal"
                data-target="#notifications1"
                onClick={() => {
                  setStudentId(row.student_id);
                  handleGetAppealDetails(row.student_id);
                }}
              >
                <i className="fal fa-gavel"></i> View Appeal
              </button>
            ) : ""}
          </>
        )
      }
    },
    { name: "Launch Time", selector: "launchTime", sortable: true, cell: (row) => (row.launchTime ? moment.unix(row.launchTime).format("DD MMM YYYY HH:mm:ss") : "NA") },
    { name: "Submission Date", selector: "submission_date", sortable: true, cell: (row) => (row.submission_date ? row.submission_date : "NA") },
    {
      name: "Student Submission",
      selector: "submissionCount", 
      sortable: true,
      cell: (row) => row.path ? <p
        data-toggle="modal" data-target="#downloadAssessment"
        onClick={() => handleStudentDownload(row?.multipleFiles?.length && row.multipleFiles?.filter((item)=>(item.type===0)).length ? row.multipleFiles?.filter((item)=>(item?.type===0)) : row)}
        className="as-text-blue curser">Download Assignment</p> : row?.quiz_student_id && row?.quiz_id && row?.submissionCount ? <Link
          title="View Submissions"
          className="as-text-blue curser"
          // to={`/courseAdministration/assessment/quizzes/open/submissionList/${row?.quiz_id}/${row?.quiz_student_id}`}
          to={{pathname: `/courseAdministration/assessment/quizzes/open/submissionList/${row?.quiz_id}/${row?.quiz_student_id}?intake_id=${id}&assess_id=${subId}`,state: { id: id,subId: subId }}}
        >
          View Submissions
        </Link> : "-"
    },
    {
      name: "Hybrid Results",
      selector: "student_result",
      sortable: false,
      cell: (row) => ((row.grade == 0 || row.grade == 3) ?
        <div className="input_wt01 resultstatus_flex arrowhide">
          <PermissionsGate scopes={["cassgradeassedit"]} errorProps={{ disabled: true }}>
            <input
              disabled
              className={"form-control" + (errorMessage && hGear == row.id ? " is-invalid" : "")}
              // id={"handleinput"+ row.id}
              // name={"handleinputname"+ row.id}
              type="number"
              defaultValue={(row.mark!="" && row.mark!=null) && row.hybrid_marks>=0 && row.hybrid_marks !== null ? row.hybrid_marks : "-"}
              onBlur={(e) => handleHybridResultChange(e, row.id, "inputbox", row.hybirdQuestionScore)}
            />
          </PermissionsGate>
          <span>{"/" + row.hybirdQuestionScore}</span>
          {loading == true && hGear == row.id ? <i className="fas fa-cog fa-spin"></i> : ""}
        </div> : row.grade == 1 ?
        <div className="form-icon-group">
          <Select
            className="form-control custom-select-box wdtcontrlrobot"
            options={options}
            isDisabled={true}
            value={
              options.filter(function (option) {
                if (row.hybirdMarksPerchange>=0) {
                  return option.value >= (row.hybirdMarksPerchange == null ? null : Math.round(row.hybirdMarksPerchange))
                }
              })
            }
            onChange={(e) => handleHybridResultChange(e, row.id, "selectbox", row.hybirdQuestionScore)}
            isClearable
          />
          {loading == true && hGear == row.id ? <span className="flex_wt_loder ml-2"><i className="fas fa-cog fa-spin"></i></span> : ""}
        </div>
        :<div className="form-icon-group">
        <Select
          className="form-control custom-select-box wdtcontrl"
          options={options}
          isDisabled={true}
          value={
            options.filter(function (option) {
              if (row.hybirdMarksPerchange>=0) {
                return (option.maxValue >= Math.round(row.hybirdMarksPerchange)) && (option.minValue <= Math.round(row.hybirdMarksPerchange))
              }
            })
          }
          onChange={(e) => handlegradeChange(e, row.id, "selectbox", row.ass_total_mark, row)
          }
        />
        {loading == true && gearid == row.id ? <span className="flex_wt_loder ml-2"><i className="fas fa-cog fa-spin"></i></span> : ""}
      </div>
      )
    },
    {
      name: "Smart Results",
      selector: "student_result",
      sortable: false,
      cell: (row) => ((row.grade == 0 || row.grade == 3) ?
        <div className="input_wt01 resultstatus_flex arrowhide">
          <PermissionsGate scopes={["cassgradeassedit"]} errorProps={{ disabled: true }}>
            <input
              disabled
              className={"form-control" + (errorMessage && gearid == row.id ? " is-invalid" : "")}
              // id={"handleinput"+ row.id}
              // name={"handleinputname"+ row.id}
              type="number"
              defaultValue={(row.mark!="" && row.mark!=null) && row.ass_normal_marks>=0 ? row.ass_normal_marks : "-"}
              onBlur={(e) => handlegradeChange(e, row.id, "inputbox", row.totalSmartQuestionScore, row)}
            />
          </PermissionsGate>
          <span>{"/" + row.totalSmartQuestionScore}</span>
          {loading == true && gearid == row.id ? <i className="fas fa-cog fa-spin"></i> : ""}
        </div> : row.grade == 1 ?
        <div className="form-icon-group">
          <Select
            className="form-control custom-select-box wdtcontrl"
            options={options}
            isDisabled={true}
            value={
              options.filter(function (option) {
                if (row.normalMarksPerchange>=0) {
                  return option.value >= Math.round(row.normalMarksPerchange)
                }
              })
            }
            onChange={(e) => handlegradeChange(e, row.id, "selectbox", row.hybirdQuestionScore,)
            }
          />
          {loading == true && gearid == row.id ? <span className="flex_wt_loder ml-2"><i className="fas fa-cog fa-spin"></i></span> : ""}
        </div>
        :<div className="form-icon-group">
          <Select
            className="form-control custom-select-box wdtcontrl"
            options={options}
            isDisabled={true}
            value={
              options.filter(function (option) {
                if (row.normalMarksPerchange>=0) {
                  return (option.maxValue >= Math.round(row.normalMarksPerchange)) && (option.minValue <= Math.round(row.normalMarksPerchange))
                }
              })
            }
            onChange={(e) => handlegradeChange(e, row.id, "selectbox", row.ass_total_mark, row)
            }
          />
          {loading == true && gearid == row.id ? <span className="flex_wt_loder ml-2"><i className="fas fa-cog fa-spin"></i></span> : ""}
        </div>
      )
    },
    {
      name: "Upload File",
      selector: "",
      cell: (row) => {
        return (
          <div className="frm-group">
            <div className=" atttach-file m-0">
              <label>
                {fileLoading==row.id ? <i className="fas fa-cog fa-spin"></i>
                : <i className="fal fa-paperclip"></i>}
                <span>Add Attachment</span>
                <PermissionsGate scopes={["castedit"]} errorProps={{ disabled: true }}>
                  <input disabled={fileLoading==row.id} type="file" multiple className="form-control  form-control-aatch-file" onChange={(e) => addFileFun(e, row.id)} />
                </PermissionsGate>
              </label>
            </div>
            <ul className="list-unstyled attached-file-ul m-0">
              {row?.multipleFiles && row?.multipleFiles?.filter(item => !item?.inExamWarning)?.map((element, ind)=>(
                <li key={ind}>
                  <a
                    href={`${downloadURL}${STUDENT_FILE_DOWNLOAD}/${encodeURI(element.path)}`.replaceAll("/home/myaie/public_html/", "").replace("public/", "")} 
                    target="_blank" 
                    title={element?.path?.split("/").reverse()[0]}>
                    <i className="fal fa-file"></i> {TrimText(element?.path?.split("/").reverse()[0], 15)}
                  </a>
                  <button
                    className="btn btn-danger rounded-circle btn-dropdown-item"
                    title="Delete"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText:
                          "Yes, delete it!",
                      }).then((result) => {
                        if(result.isConfirmed){
                          DeleteAssessmentSubmissionUpload({ submission_id: element.id, intake: id, type : element.type, submited_type : element.submited_type }).then(res => {
                            Swal.fire(
                              "Deleted!",
                              "File has been deleted.",
                              "success"
                            );
                            getSubmissionData()
                          }).catch(err => {
                            console.error("error :", err)
                            Swal.fire(
                              "Not Deleted!",
                              "File not deleted.",
                              "error"
                            );
                          })                          
                        }
                      })
                    }}
                  >
                    <i className="fal fa-trash-alt"></i>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        );
      },
    },
    {
      name: "Mark",
      selector: "mark",
      sortable: true,
      cell: (row) => (row.mark && row.showValue ? 
      <p className="cat">
        {row.grade === 0 ? `${twoDecimalRound(row.assCalculateGradeStatus.grade)}%`
        : row.grade === 3 ? `${twoDecimalRound(row.assCalculateGradeStatus.gradeText)}/${row.ass_total_mark}` 
        : row.assCalculateGradeStatus.gradeText}
      </p> 
      : headerData?.notify_assessed == 1 ? row.grade == 3 ? `${twoDecimalRound(row.assCalculateGradeStatus.gradeText)}/${row.ass_total_mark}` : row.assCalculateGradeStatus.gradeText : <p className="cat">NA</p>),
    },
    {
      name: "Action",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          {headerData.quiz_id && !row.upload_file ? <div className="as-buttons">
            <button
              className="btn btn-primary rounded-circle"
              data-toggle="modal"
              data-target="#notifications"
              onClick={() => {
                handleGetComment(row.id);
                setSubmissionId(row.id);
              }}
              title="Comment"
            >
              <i className="fal fa-comment"></i>
            </button>

            <button
              className="btn btn-primary rounded-circle"
              onClick={() => history.push(`/studentAdministration/students/open/${row.quiz_student_id}/general`)}
              title="Open Student Profile"
            >
              <i className="fal fa-user"></i>
            </button>
          </div> : headerData.quiz_id && row.upload_file ? <div className="as-buttons">
            <a className="btn btn-primary rounded-circle" title="Download Submission" href={IMAGE_URL.replaceAll("http:", "https:") + "/" + encodeURI(row.upload_file)} target="_blank" download={"submission"}>
              <i className="fal fa-arrow-down"></i>
            </a>
            <div className="dropdown btn-dropdown-item">
              <button className="btn btn-primary rounded-circle dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fal fa-ellipsis-h-alt"></i>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">

                <button
                  className="btn btn-primary rounded-circle"
                  data-toggle="modal"
                  data-target="#notifications"
                  onClick={() => {
                    handleGetComment(row.id);
                    setSubmissionId(row.id);
                  }}
                  title="Comment"
                >
                  <i className="fal fa-comment"></i>
                </button>

                <button
                  className="btn btn-primary rounded-circle"
                  onClick={() => history.push(`/studentAdministration/students/open/${row.quiz_student_id}/general`)}
                  title="Open Student Profile"
                >
                  <i className="fal fa-user"></i>
                </button>
              </div>
            </div>
          </div> : <div className="as-buttons">
            <button
              className="btn btn-primary rounded-circle"
              onClick={() => history.push(`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/submissionsAndResults/${subId}/update/${row.id}`)}
              title="Open"
            >
              <i className="fal fa-folder-open"></i>
            </button>
            <div className="dropdown btn-dropdown-item">
              <button className="btn btn-primary rounded-circle dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fal fa-ellipsis-h-alt"></i>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {row.upload_file && <a className="btn btn-primary rounded-circle" title="Download Submission" href={IMAGE_URL.replaceAll("http:", "https:") + "/" + encodeURI(row.upload_file)} target="_blank" download={"submission"}>
                  <i className="fal fa-arrow-down"></i>
                </a>}

                <button
                  className="btn btn-primary rounded-circle"
                  data-toggle="modal"
                  data-target="#notifications"
                  onClick={() => {
                    handleGetComment(row.id);
                    setSubmissionId(row.id);
                  }}
                  title="Comment"
                >
                  <i className="fal fa-comment"></i>
                </button>

                <button
                  className="btn btn-primary rounded-circle"
                  onClick={() => history.push(`/studentAdministration/students/open/${row.quiz_student_id}/general`)}
                  title="Open Student Profile"
                >
                  <i className="fal fa-user"></i>
                </button>
              </div>
            </div>
          </div>}
        </div>
      ),
    },
  ]);

  const exportData = () => {
    let data = dataToRender();
    let header;
    let pdfData;
    if(headerData && headerData.quiz_id && isHybrid===true){
      header = ["User", "Status", "Submission Status", "Launch Time", "Submission Date", "Hybrid Results", "Smart Results", "Mark"];
  
      data = data?.map((row) => ({
        ...row,
        User: row?.name,
        Status: row?.status,
        "Submission Status": row?.submission_status ? row?.submission_status === 'closednot submitted' ? 'CLOSED-NOT SUBMITTED' : row?.submission_status  : '-',
        "Launch Time": row?.launchTime ? moment.unix(row.launchTime).format("DD MMM YYYY HH:mm:ss") : "-",
        "Submission Date": row?.submission_date ? row?.submission_date : "-",
        "Hybrid Results": (row.grade == 0 || row.grade == 3) ?  (row.hybrid_marks>=0 && row.showValue ? Math.round(row.hybrid_marks) + "/" + row.hybirdQuestionScore : "-") : 
        row.grade == 1 ? (row.hybirdMarksPerchange >=0 ? options.find(function (option) {
          if (row.hybirdMarksPerchange >=0) {
            return option.value >= Math.round(row.hybirdMarksPerchange)
          }
        }).label : "-") : (row.hybirdMarksPerchange>=0 ? options.find(function (option) {
          if (row.hybirdMarksPerchange>=0) {
            return (option.maxValue >= row.hybirdMarksPerchange) && (option.minValue <= row.hybirdMarksPerchange)
          }
        }).label : "-"),
        "Smart Results": (row.grade == 0 || row.grade == 3) ?  (row.ass_normal_marks>=0 ? Math.round(row.ass_normal_marks) + "/" + row.totalSmartQuestionScore : "-") : 
        row.grade == 1 ? (row.normalMarksPerchange>=0 ? options.find(function (option) {
          if (row.normalMarksPerchange>=0) {
            return option.value >= Math.round(row.normalMarksPerchange)
          }
        }).label : "-") : (row.normalMarksPerchange>=0 ? options.find(function (option) {
          if (row.normalMarksPerchange>=0) {
            return (option.maxValue >= row.normalMarksPerchange) && (option.minValue <= row.normalMarksPerchange)
          }
        }).label : "-"),        
        Mark: row.mark && row.showValue ? (row.grade === 0 ? (row.assCalculateGradeStatus.grade ? `${twoDecimalRound(row.assCalculateGradeStatus.grade)}%` : "-")
        : row.grade === 3 ? (row.assCalculateGradeStatus.gradeText ? `${row.assCalculateGradeStatus.gradeText}/${row.ass_total_mark}` : "-") 
        : (row.assCalculateGradeStatus.gradeText ? row.assCalculateGradeStatus.gradeText : "-")) : "NA",
      }));
      pdfData = data.map((row) => {
        return [row.User, row.Status, row["Submission Status"], row["Launch Time"], row["Submission Date"], row["Hybrid Results"], row["Smart Results"], row.Mark];
      });
    }else{
      header = ["User", "Status", "Launch Time", "Submission Date", "Submission Status", "Student Result", "Mark"];
  
      data = data?.map((row) => ({
        ...row,
        User: row?.name,
        Status: row?.status,
        "Launch Time": row?.launchTime ? moment.unix(row.launchTime).format("DD MMM YYYY HH:mm:ss") : "-",
        "Submission Date": row?.submission_date ? row?.submission_date : "-",
        "Submission Status": row?.submission_status ? row?.submission_status === 'closednot submitted' ? 'CLOSED-NOT SUBMITTED' : row?.submission_status  : '-',
        "Student Result": (row.grade == 0 || row.grade == 3) ?  (row.ass_mark ? twoDecimalRound(row.ass_mark) + "/" + row.ass_total_mark : "-") : 
        row.grade == 1 ? (row.mark ? options.find(function (option) {
          if (row.mark) {
            return option.value === row.mark.toString().replace(/%\s?/g, '')
          }
        }).label : "-") : (row.mark ? options.filter(function (option) {
          if (row.mark) {
            return (option.maxValue >= Number(row.mark.replace(/%\s?/g, ''))) && (option.minValue <= Number(row.mark.replace(/%\s?/g, '')))
          }
        }):"-"),
        Mark: row.grade === 0 ? (row.assCalculateGradeStatus.grade ? `${twoDecimalRound(row.assCalculateGradeStatus.grade)}%` : "-")
        : row.grade === 3 ? (row.assCalculateGradeStatus.gradeText ? `${row.assCalculateGradeStatus.gradeText}/${row.ass_total_mark}` : "-") 
        : (row.assCalculateGradeStatus.gradeText ? row.assCalculateGradeStatus.gradeText : "-"),
      }));
      pdfData = data.map((row) => {
        return [row.User, row.Status, row["Submission Date"], row["Launch Time"], row["Submission Status"], row["Student Result"], row.Mark];
      });
    }

    return {
      data,
      header,
      pdfData
    }
  };

  const exportPracticalData = () => {
    let data = renderPracticalData();
    let header;
    let pdfData;
    if(headerData){
      header = ["Name", "Assessment Status", "Assessment Completed", "Attempts", "Assessment Method" , "Result"];
  
      data = data?.map((row) => ({
        ...row,
        Name: row?.name,
        'Assessment Status': row?.assessment_status,
        "Assessment Completed": row?.submission_date ? row?.submission_date  : "-",
        "Attempts": row?.attempts || "-",
        "Assessment Method": row?.assessment_method ? RenderCategoriesType(row.assessment_method).text : "-",
        "Result": headerData?.isShowMarkAsCompleteButton == 1 ? (row.grade == 0 || row.grade == 3) ?  (row.ass_mark ? twoDecimalRound(row.ass_mark) + "/" + row.ass_total_mark : "-") : 
        row.grade == 1 ? (row.mark ? options.find(function (option) {
          if (row.mark) {
            return option.value === row.mark.toString().replace(/%\s?/g, '')
          }
        }).label : "-") : (row.mark ? options.filter(function (option) {
          if (row.mark) {
            return (option.maxValue >= Number(row.mark.replace(/%\s?/g, ''))) && (option.minValue <= Number(row.mark.replace(/%\s?/g, '')))
          }
        }):"-") : "-",
      }));
      pdfData = data.map((row) => {
        return [row["Name"], row["Assessment Status"], row["Assessment Completed"], row["Attempts"], row["Assessment Method"], row["Result"]];
      });
    }

    return {
      data,
      header,
      pdfData
    }
  };

  const handleGetComment = (submissionID) => {
    const data = new FormData();
    data.append("assignment_id", subId);
    data.append("stdid", submissionID);
    data.append("action_type", "get_comment");
    AddAndGetTicketComment(data)
      .then((response) => {
        setComment(response.data.comment);
      })
      .catch((error) => {
        console.error("AddAndGetTicketComment error:", error);
      });
  };

  const handleGetAppealDetails = (ID) => {
    setStudentMessage("")
    ViewAppeal(ID, subId)
      .then((res) => {
        setStudentMessage(res.data.student_appeal.student_message);
        // let tempDate = startDate.setDate(startDate.getDate() + 3)
        if (res.data.student_appeal.appeal_status !== "Approved" && res.data.student_appeal.appeal_status !== "Declined") {
          setStartDate(moment(new Date()).add(3, 'days')._d)
        } else {
          setStartDate(moment.unix(res.data.student_appeal.ass_ext_close_date)._d)
        }
        setAppealsDetails(res.data);
        setDisableNeeded(res.data.student_appeal.ass_status == "Closed-Not Submitted")
      })
      .catch((err) => console.error("err :", err));
  };

  const formik = useFormik({
    initialValues: {
      comment: comment,
    },
    validationSchema: Yup.object({
      comment: Yup.string().required("Assessment Name is required").trim(),
    }),
    onSubmit: (values) => {
      const data = new FormData();
      data.append("comment", values.comment);
      data.append("assignment_id", subId);
      data.append("stdid", submissionId);
      data.append("action_type", "save_comment");
      AddAndGetTicketComment(data)
        .then((response) => {
          $("#notifications").modal("hide");
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Posted comment successfully",
          });
        })
        .catch((error) => {
          console.error("AddAndGetTicketComment error :", error);
        });
    }
  })

  const handleSendResponse = (status, appealId) => {
    if (status === "Decline" && !studentMessage) {
      setMessageError("Message is Required")
      return;
    }
    setMessageError("")
    setAppealDisable(status)
    const tempDateObj = new Date(startDate);
    const dayOfMonth = tempDateObj.getDate();
    const month = tempDateObj.getMonth() + 1; // add 1 to month since it is 0-based
    const year = tempDateObj.getFullYear();
    const hours = tempDateObj.getHours();
    const minutes = tempDateObj.getMinutes();
    const tempDate = `${year}-${month < 10 ? "0" + month : month}-${dayOfMonth < 10 ? "0" + dayOfMonth : dayOfMonth}T${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:00.000Z`;
    const data = new FormData();
    data.append("student_id", studentId);
    data.append("student_message", studentMessage ? studentMessage : "");
    data.append("assessment_id", subId);
    data.append("assessment_close_date", tempDate);
    data.append("status", status);
    data.append("gradebookType", 1);
    data.append("appealOutcome", appealsDetails?.student_appeal?.appeal_outcome);
    ApproveOrDeclineAppeal(data)
      .then((res) => {
        handleGetAppealDetails(appealId)
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.message,
        })
        setAppealDisable("")
        getSubmissionData()
        setStudentMessage("")
        $("#notifications1").modal('hide');
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data.message,
        })
        $("#notifications1").modal('hide');
        console.error("error : ", err)
        setStudentMessage("")
        setAppealDisable("")
      });
  };

  const handleHybridResultChange = (e, id, type, maxMark) =>{
    let marks = "";
    setHGear(id)
    if (type === "inputbox") {
      if (e.target.value > maxMark) {
        setErrorMessage("Sorry you can't put mark more then assignment mark")
        return false      
      } else {
        marks = e.target.value
      }
    }else if (type === "selectbox") {
      marks = e?.value
    }

    let sendData = new FormData();
    sendData.append("submission_id", id);
    if(type === "inputbox"){
      sendData.append("hybrid_marks", marks);
    }else{
      if (marks === "0") {
        sendData.append("hybrid_marks", 0);        
      }else if (marks === "49") {
        sendData.append("hybrid_marks", 49);        
      }else if (marks === "100") {
        sendData.append("hybrid_marks", 100);        
      }else {
        sendData.append("hybrid_marks", "");        
      }
    }
    setLoading(true);
    UpdateHybridMarks(sendData).then((res)=>{
      if(res.status==200){
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Updated successfully",
        });
        setErrorMessage("")
        setupDatedata(!updatedata)
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    }).catch((err)=>{
      console.error("err : ", err?.response?.data);
      setLoading(true);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `${err?.response?.data?.data}`,
      });
    })
  }

  // for showing loading effect update below state
  let updateArray = [...updateValueArr];

  
  // mycode start
  const handlegradeChange = (e, idvalue, type, maxMark, item) => {   
    let entervalue = ""
    setGearid(idvalue)
    if (type === "inputbox") {
      if (e.target.value > maxMark) {
        setErrorMessage("Sorry you can't put mark more then assignment mark")
        return false        
      } else {
        entervalue = e.target.value
      }
    } else if (type === "selectbox") {
      entervalue = e?.value
    }


    let formData = new FormData();

    if (type === "inputbox") {
      formData.set("obtMark", entervalue)
      formData.set("id", idvalue)
      formData.set("ass_total_mark", maxMark)
      formData.set("custome_marking_deadline_date", headerData.marking_deadline_date)
      formData.set("assgrade", item.grade)
      formData.set("assessmentID", subId)
    } else {
      if(item.grade==1){
        if (entervalue === "0") {
          formData.set("obtMark", 0);
          formData.set("id", idvalue)
          formData.set("ass_total_mark", maxMark)
          formData.set("grade", "robot")
        } else if (entervalue === "49") {
          formData.set("obtMark", 49);
          formData.set("id", idvalue)
          formData.set("ass_total_mark", maxMark)
          formData.set("grade", "robot")
        } else if (entervalue === "100") {
          formData.set("obtMark", 100);
          formData.set("id", idvalue)
          formData.set("ass_total_mark", maxMark)
          formData.set("grade", "robot")
        } else {
          formData.set("obtMark", "");
          formData.set("id", idvalue)
          formData.set("ass_total_mark", maxMark)
          formData.set("grade", "robot")
  
        }
      }else if(item.grade == 2){
        formData.set("id", idvalue)
        formData.set("ass_total_mark", maxMark)
        formData.set("grade", "letter")
        formData.set("grade_id", entervalue);
      }
    }
    setLoading(true);
    UpDateMarkssubmit(formData)
      .then((res) => {
        // Swal.fire({
        //   icon: "success",
        //   title: "Success",
        //   text: "Updated successfully",4
        // });
        setErrorMessage("")
        setupDatedata(!updatedata)        
      })
      .catch((err) => {
        console.error("ey : ", err?.response?.data);
        setLoading(true);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.data}`,
        });
      }).finally(()=>{setLoading(false);});
  }

  const handleUpdateMarks = (obtMark, idvalue, type, maxMark, item) => {

    let returnIndex = userData.findIndex(obj => obj.quiz_student_id === idvalue);
    if((item.grade == 0 || item.grade == 3) && returnIndex !== -1) {
        if(userData[returnIndex].ass_mark == parseInt(obtMark) && userData[returnIndex].mark != null){
          return;
        }
    }
    maxMark = headerData.ass_total_mark;
    if ((item.grade == 0 || item.grade == 3) && obtMark > maxMark) {
      userData[returnIndex].ass_mark = obtMark;
      setErrorMessage("Sorry you can't put mark more then assignment mark");
      setStudentErrors(prev => ({ ...prev, [idvalue]: "Sorry you can't put mark more than assignment mark" }));
      return;
    } else {
      setErrorMessage("");
      setStudentErrors(prev => ({ ...prev, [idvalue]: "" })); // Clear error for this student
    }

    

    const existingIndex = updateArray.findIndex(
      (grade) => grade.student_id === idvalue
    );

    if (existingIndex !== -1) {
      updateArray[existingIndex] = {
        ...updateArray[existingIndex],
        student_id: idvalue,
        grade_type: (item.grade == 0 || item.grade == 3) ? 3 : item.grade,
        grade: obtMark ? obtMark : "",
      };
    } else {
      updateArray.push({
        student_id: idvalue,
        // assessmnet_id: item?.grade_id,
        grade_type: (item.grade == 0 || item.grade == 3) ? 3 : item.grade,
        grade: obtMark ? obtMark : "",
      });
    }

    // let tempArr = userData;
    // let isdisabledcheck = tempArr.map((item) => {
    //   if (item.student_id === stdId) {
    //     return { ...item, isDis: false };
    //   }
    //   return { ...item };
    // });
    // setUserData(isdisabledcheck);
    if (returnIndex !== -1) {
      if(item.grade == 0 || item.grade == 3){
        userData[returnIndex].ass_mark = obtMark;
      } else {
        if(item.grade && item.grade == 2){
          const selectedVal = options.find(item => item.value == obtMark);
          userData[returnIndex].ass_percentage = selectedVal?.maxValue+"%";
        } else {
          userData[returnIndex].ass_percentage = obtMark+"%";
        }
      }
    }
    setUpdateValueArr([...updateArray]);
  };

  const shouldBlockNavigation = updateValueArr.length;

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (shouldBlockNavigation) {
        event.preventDefault();
        // event.returnValue = ''; // This is necessary for Chrome/Firefox
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [shouldBlockNavigation]);

  const handleNavigation = (location, page, states) => {
    setToggle(!toggle)
    // getSubmissionData()
    if (shouldBlockNavigation) {
      if (Swal.isVisible()) {
        return false;
      }
      Swal.fire({
        title: "Warning",
        text: "You Have Unsaved Changes Left! Do you want to save changes",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No, Leave",
        confirmButtonText: "Yes, Save Changes",
      }).then((value) => {
        if (value.isConfirmed) {
          Swal.fire({
            title: "Please wait",
            text: "Saving changes...",
            allowOutsideClick: false,
            onBeforeOpen: () => {
              Swal.showLoading();
              const values = {
                type: 0,
                assessmnet_id: subId,
                grademix: updateValueArr,
              };
              updateGrademixMarkSubmission(values)
                .then((res) => {
                  getSubmissionData()
                  setUpdateValueArr([]);
                  Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Updated Successfully",
                  }).then(() => {
                    if (location) {
                      // window.location.assign(location.pathname); // Redirect to the desired location
                      history.push(location.pathname)
                    } else if (!location && page) {
                      if (page == "exempted") {
                        // handleExemption(...states)
                      } else {
                        // setTableState((prevState) => ({
                        //   ...prevState,
                        //   page:
                        //     page === "firstPage"
                        //       ? 1
                        //       : page === "prevPage"
                        //         ? paginationState.currentPage - 1
                        //         : page === "nextPage"
                        //           ? +paginationState.currentPage + 1
                        //           : paginationState.lastPage,
                        // }));
                      }
                    }
                  });
                })
                .catch((err) => {
                  Swal.close(); // Close the "Saving changes..." popup if there was an error
                  Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${err?.response?.data?.data}`,
                  });
                });
            },
          });
        } else {
          getSubmissionData()
          setUpdateValueArr([]);
          if (location) {
            // window.location.assign(location.pathname); // Redirect to the desired location
            history.push(location.pathname)
          } else if (!location && page) {
            if (page == "exempted") {
              // handleExemption(...states)
            } else {
              // setTableState((prevState) => ({
              //   ...prevState,
              //   page:
              //     page === "firstPage"
              //       ? 1
              //       : page === "prevPage"
              //         ? paginationState.currentPage - 1
              //         : page === "nextPage"
              //           ? +paginationState.currentPage + 1
              //           : paginationState.lastPage,
              // }));
            }
          }
        }
      });

      return false; // Prevent the default navigation behavior
    } else if (page == "exempted") {
      // handleExemption(...states)
    }
  };

  const handleSaveGrades = (studentID) => {
    Swal.fire({
      title: "Updating",
      onOpen: function () {
        Swal.showLoading();
      },
    });
    setSaveGradesLoading(true);
    const values = {
      type: 0,
      assessmnet_id: subId,
      grademix: updateValueArr,
    };
    if (!studentID) {
      delete values.student_id;
    }
    updateGrademixMarkSubmission(values)
      .then((res) => {     
        if (res.status == 200) {
          getSubmissionData()
          if (studentID) {
            const filteredArr = updateValueArr.filter(
              (item) => item.student_id !== studentID
            );
            setUpdateValueArr(filteredArr);
          } else {
            setUpdateValueArr([]);
          }
          Swal.close();
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated Successfully",
          });
        }
      })
      .catch((err) => {
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.data}`,
        });
      }).finally(() =>{
        setSaveGradesLoading(false);
      });
  };

  const setNotificationvalue = (e) => {
    const check = e.target.checked
    check === true ? setNotificationVal(true) : setNotificationVal(false)
  }

  const submitNotification = (e, type) => {
    const data = new FormData();
    data.append("assignmentid", subId);
    data.append("intake", id);
    data.append("act", type == "reOpen" ? 0 : 1);
    data.append("notifyToAll", notificationval)
    GetMarkasCompelete(data)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "successfully",
        });
        setNotificationVal(false)
        getSubmissionData();
        $("#exampleModal2").modal("hide");
      }).catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: 'Error',
        });
      });
  }

  // function calculateFileSize(data, maxSizePerArray) {
  //   let totalSize = 0;
  //   let urlArray = [];
  //   let checkTotal = 0;
  //   let urlArrays = [[]]; // Start with one empty array
  //   let currentArrayIndex = 0;
  //   let currentArraySize = 0;

  //   function addToCurrentArray(path, size, fileName, currentSize) {
  //     const urlInfo = {
  //       path: path,
  //       size: size,
  //       loaded_Size: 0,
  //       flname: fileName
  //     };
  //     urlArrays[currentArrayIndex].push(urlInfo);
  //     urlArray.push(urlInfo);
  //     currentArraySize += currentSize;
  //   }
  //   for (const item of data) {
  //     if (item.multipleFiles && item.multipleFiles.length) {
  //       for (const file of item.multipleFiles) {
  //         checkTotal++
  //         const size = parseFloat(file.size);
  //         if (!isNaN(size)) {
  //           totalSize += size;
  //           if (currentArraySize + size <= maxSizePerArray) {
  //             addToCurrentArray(file.path, file.size, item.flname, size);
  //           } else {
  //             if (urlArrays.length > 1) {
  //               addToCurrentArray(file.path, file.size, item.flname, size);
  //             } else {
  //             currentArrayIndex++;
  //             urlArrays.push([]);
  //             currentArraySize = 0;
  //             addToCurrentArray(file.path, file.size, item.flname, size);
  //             }
  //           }
  //           urlArray.push({ path: file.path, size: file.size,loaded_Size : 0 ,flname: item.flname});
  //         }
  //       }
  //     } else {
  //       if (item.path && item.path.trim() !== "") {
  //         checkTotal++
  //         const size = parseFloat(item.size);
  //         if (!isNaN(size)) {
  //           totalSize += size;
  //           if (currentArraySize + size <= maxSizePerArray) {
  //             addToCurrentArray(item.path, item.size, item.flname, size);
  //           } else {
  //              if (urlArrays.length > 1) {
  //               addToCurrentArray(item.path, item.size, item.flname, size);
  //             } else {
  //             currentArrayIndex++;
  //             urlArrays.push([]);
  //             currentArraySize = 0;
  //             addToCurrentArray(item.path, item.size, item.flname, size);
  //             }
  //           }
  //           urlArray.push({ path: item.path, size: item.size,loaded_Size : 0 ,flname: item.flname });
  //         }
  //       }
  //       if (item.return && item.return.trim() !== "") {
  //         checkTotal++
  //         const size = parseFloat(item.return_size);
  //         if (!isNaN(size)) {
  //           totalSize += size;
  //           if (currentArraySize + size <= maxSizePerArray) {
  //             addToCurrentArray(item.return, item.return_size, item.flname, size);
  //           } else {
  //             if (urlArrays.length > 1) {
  //               addToCurrentArray(item.return, item.return_size, item.flname, size);
  //             } else {
  //             currentArrayIndex++;
  //             urlArrays.push([]);
  //             currentArraySize = 0;
  //             addToCurrentArray(item.return, item.return_size, item.flname, size);
  //             }
  //           }
  //           urlArray.push({ path: item.return, size: item.return_size,loaded_Size : 0 ,flname: item.flname });
  //         }
  //       }
  //     }

  //     if(item.quizSubmitedDocs && item.quizSubmitedDocs.length){
  //       for(const file of item.quizSubmitedDocs){
  //         checkTotal++;
  //         const size = 0;
  //         if (!isNaN(size)) {
  //             urlArrays[currentArrayIndex].push({
  //               path: file.docs,
  //               size: 0,
  //               loaded_Size: 0,
  //               flname: item.flname,
  //             });
  //           urlArray.push({ path: file.docs, size: 0,loaded_Size : 0 ,flname: item.flname});
  //         }
  //       }
  //     }
  //   }
  //   return {
  //     totalSize,urlArray,checkTotal,urlArrays
  //   }
  // }

  function calculateFileSize(data, maxSizePerArray) {
    let totalSize = 0;
    let urlArray = [];
    let checkTotal = 0;
    let urlArrays = [[]]; // Start with one empty array
    let currentArrayIndex = 0;
    let currentArraySize = 0;

    function addToCurrentArray(path, size, fileName, currentSize,inExamWarning) {
      const urlInfo = {
        path: path,
        size: size,
        loaded_Size: 0,
        flname: fileName,
        inExamWarning : inExamWarning,
      };
      urlArrays[currentArrayIndex].push(urlInfo);
      urlArray.push(urlInfo);
      currentArraySize += currentSize;
    }

    for (const item of data) {
      if (currentArraySize > maxSizePerArray) {
        currentArrayIndex++;
        urlArrays.push([]);
        currentArraySize = 0;
      }

      if (item.multipleFiles && item.multipleFiles.length) {
        for (const file of item.multipleFiles) {
          checkTotal++;
          const size = parseFloat(file.size);
          if (!isNaN(size)) {
            totalSize += size;
            if (currentArraySize + size <= maxSizePerArray) {
              addToCurrentArray(file.path, file.size, item.flname, size);
            } else {
              currentArrayIndex++;
              urlArrays.push([]);
              currentArraySize = 0;
              addToCurrentArray(file.path, file.size, item.flname, size);
            }
            urlArray.push({ path: file.path, size: file.size, loaded_Size: 0, flname: item.flname });
          }else{
            addToCurrentArray(file.path, file.size, item.flname, size,file.inExamWarning);
          }
        }
      } else {
        if (item.path && item.path.trim() !== "") {
          checkTotal++;
          const size = parseFloat(item.size);
          if (!isNaN(size)) {
            totalSize += size;
            if (currentArraySize + size <= maxSizePerArray) {
              addToCurrentArray(item.path, item.size, item.flname, size);
            } else {
              currentArrayIndex++;
              urlArrays.push([]);
              currentArraySize = 0;
              addToCurrentArray(item.path, item.size, item.flname, size);
            }
            urlArray.push({ path: item.path, size: item.size, loaded_Size: 0, flname: item.flname });
          }
        }
        if (item.return && item.return.trim() !== "") {
          checkTotal++;
          const size = parseFloat(item.return_size);
          if (!isNaN(size)) {
            totalSize += size;
            if (currentArraySize + size <= maxSizePerArray) {
              addToCurrentArray(item.return, item.return_size, item.flname, size);
            } else {
              currentArrayIndex++;
              urlArrays.push([]);
              currentArraySize = 0;
              addToCurrentArray(item.return, item.return_size, item.flname, size);
            }
            urlArray.push({ path: item.return, size: item.return_size, loaded_Size: 0, flname: item.flname });
          }
        }
      }

      if(item.quizSubmitedDocs && item.quizSubmitedDocs.length){
        for(const file of item.quizSubmitedDocs){
          checkTotal++;
          const size = 0;
          if (!isNaN(size)) {
              urlArrays[currentArrayIndex].push({
                path: file.docs,
                size: 0,
                loaded_Size: 0,
                flname: item.flname,
              });
            urlArray.push({ path: file.docs, size: 0, loaded_Size: 0, flname: item.flname });
          }
        }
      }
    }

    return {
      totalSize, urlArray, checkTotal, urlArrays
    };
  }

  const MAX_CONCURRENT_DOWNLOADS = 5; // Limit the number of parallel downloads

  const handleDownloadAssessment = async () => {
      try {
        let data = downloadZip;
        let demoData = downloadZip;
        const header = ["ROW", "SID", "CID", "STUDENT", "SURNAME", "ASSIGNMENT", "ASSESSMENT NAME", "HIGHEST MARK ACHIEVABLE", "MARKED ACHIEVED"];
    
        data = data?.map((row) => ({
          ...row,
          ROW: row?.id ? row?.id : "",
          CID: row.intake ? row.intake : "",
          SID: row?.student ? row?.student : "",
          STUDENT: row?.flname ? row?.flname : "",
          SURNAME: row?.lname ? row?.lname : "",
          ASSIGNMENT: row?.crm_id ? row?.crm_id : "",
          // TOTALMARK: row?.assignment ? row?.assignment : "-",
          "ASSESSMENT NAME": zipfilename ? zipfilename.trim() : "",
          "HIGHEST MARK ACHIEVABLE": row.ass_total_mark>=0 ? row.ass_total_mark : "",
          // ATTACHMENT: row?.ass_weighting.toString() ? row?.ass_weighting.toString() == "0" ? "0" : row?.ass_weighting : "-",
          // "MARKED ACHIEVED": row.ass_mark ?  row.ass_mark : "-",
          // REMARK: row.path || row.return ? `${row.path ? row.path : ""} ${row.return ? row.return : ""}` : "",
          "MARKED ACHIEVED" : (row.ass_mark != null && row.ass_mark.toString() && row?.grade != 1 ) ? row.ass_mark 
          : (row?.mark != null && row?.mark.toString() && row?.grade == 1 && row?.mark.toString().replace(/%\s?/g, '') >= 49)  ? "Green" 
          : (row?.mark != null && row?.mark.toString() && row?.grade == 1 && row?.mark.toString().replace(/%\s?/g, '') <= 49) 
          && (row?.mark.toString() && row?.grade == 1 && row?.mark.toString().replace(/%\s?/g, '') >= 0) ? "Yellow" 
          : row?.mark != null && row?.mark.toString() && row?.grade == 1 && row?.mark.toString().replace(/%\s?/g, '') <= 0 ? "Red" :  "",
        }));
    
        let pending = 0;
        const maxSizePerArray = 1324 * 1024 * 1024;
        let fileDetails = calculateFileSize(demoData, maxSizePerArray);
        setCheckTotal(fileDetails.checkTotal);
        setTotalSize(fileDetails.totalSize.toFixed(1));
        setFinalTotal(fileDetails.totalSize.toFixed(1))
        let loadedArr = JSON.parse(JSON.stringify(fileDetails.urlArrays))
  
          let zipPromises = [];
          for (let i = 0; i < fileDetails.urlArrays.length; i += MAX_CONCURRENT_DOWNLOADS) {
              const batch = fileDetails.urlArrays.slice(i, i + MAX_CONCURRENT_DOWNLOADS);
              const batchPromises = batch.map(async (urlArray, index) => {
                  let zip = new JSZip();
                  let sizeArr = Array(urlArray.length).fill(0);
                  let loadedBytes = 0;
  
                  let promise = Promise.all(
                      urlArray.map(async (row, rowIndex) => {
                        const fileExt = (row.path.split("/").reverse()[0]).trim().replace(/[\t\r\n]/g, '');        
                        let fileURL = downloadURL + `${row.path.includes("s3.af-south-1.amazonaws.com") ? "" : STUDENT_FILE_DOWNLOAD.replace("https://","") + "/"}${encodeURI(row.path.replaceAll(
                          "/home/myaie/public_html/", "").replace("public/", ""))}`;
                        if(isHybrid===true){
                          fileURL = downloadURL + STUDENT_FILE_DOWNLOAD.replace("https://","") + `/${encodeURI(row.path.replaceAll(
                            "/home/myaie/public_html/", "").replace("public/", ""))}`;
                        }
                          let loadedFromArr = 0
                          try {
                            const resp = await axios({
                              url: fileURL,
                              method: 'get',
                              responseType: 'blob',
                              onDownloadProgress: (progressEvent) => {
                                const { loaded, total } = progressEvent;
                                loadedArr[index][rowIndex] = loaded
                                loadedFromArr = 0
                                loadedArr.map(item => item.map(subItem => {
                                  if (typeof subItem === "number") {
                                    loadedFromArr = loadedFromArr + subItem
                                  }
                                }))
                                loadedBytes = loaded;
                                if(fileDetails.totalSize > loadedFromArr) {
                                  setFinalLoadedData(loadedFromArr)
                                }else{
                                  setFinalLoadedData(fileDetails.totalSize)
                                }
                              }
                            });
                            // code to create exam warning folder ----------------------------
                                  let zipFolder = zip.folder(`${row.flname + "_" + zipfilename.trim().replace(/[\t\r\n]/g, '')}`)
                                  let newUrlArr = urlArray.filter(item => row.flname == item.flname)

                                  let isFolderNeeded = false;
                                  for ( let d of newUrlArr){
                                    if(d?.inExamWarning){ 
                                      isFolderNeeded = true;
                                    }
                                  }
                                  let warningsFolder = ''
                                  // Create the warnings folder in the zip
                                  if(isFolderNeeded){
                                    warningsFolder = zipFolder.folder("Exam Warnings");
                                  }

                                  if (row?.inExamWarning) {
                                    warningsFolder.file(`${fileExt}`, resp.data, { binary: true });
                                  } else {
                                    zipFolder.file(`${fileExt}`, resp.data, { binary: true });
                                  }
                              
                            //---------------------------------------------------
                              pending++;
                              setCheckPending(pending);
                          } catch (err) {
                            console.error(err,"zip generation error")
                              pending++;
                              setCheckPending(pending);
                          }
                      })
                  );
  
                  promise = promise.then(() => {
                    const textFileData = data.filter((item) => item.dataAns);
                    if(textFileData.length){
                      textFileData.map((row)=>{
                        let fileContent = ""
                        if(Array.isArray(row.dataAns) && row.dataAns.length){
                          row.dataAns.map((element)=>{
                            fileContent+= "Question : " +  element.questionName + "Answer : " +  element.UserAnswer + "\n" + "\n";
                          })
                        }
                        if(fileContent!=""){
                          zip.folder(`${row.flname + "_" + zipfilename.trim().replace(/[\t\r\n]/g, '')}`).file("question.txt", fileContent);
                        }
                      })
                    }
                    const csvString = Papa.unparse({ fields: header, data });
                    zip.file(`${zipfilename + "_" + index+1 + ".csv"}`, csvString);
                      return zip.generateAsync({ type: "blob" });
                  });
  
                  try {
                      const zipBlob = await promise;
                      const zipFileName = `${zipfilename}_${i / MAX_CONCURRENT_DOWNLOADS + index + 1}_.zip`;
                      saveAs(zipBlob, zipFileName);
                  } catch (err) {
                      console.error("Error generating or downloading zip files:", err);
                  }
              });
              zipPromises.push(Promise.all(batchPromises));
          }
  
          await Promise.all(zipPromises);
  
          setTimeout(() => {
            $("#downloadAssessment").modal("hide");
            setCheckPending(0);
            setCheckTotal(0);
            setLoaded(0);
            setTotal(0);
            setFinalLoadedData(0);
            setFinalTotal(0);
        }, 1000);
  
      } catch (error) {
          console.error("Error generating or downloading zip files:", error);
      } finally {
          setCheckPending(0);
          setCheckTotal(0);
          setLoaded(0);
          setTotal(0);
          setFinalLoadedData(0);
          setFinalTotal(0);
          setTimeout(() => {
            $("#downloadAssessment").modal("hide");
          }, 1000);
      }
  };

  const handleStudentDownload = async (rowData, st_data) => {
    let final_loaded_data = 0;
    let pending = 0;
    let total_size = 0;
    let total_loaded = 0;
    let newArr = []
    var zip = new JSZip();
    var zipVal = {};
    let checkTotal = 0;
    let totalSize = 0;
    let data = rowData;

    // Check if there is any file without submited_type in the data
    let isFolderNeeded = false;
    for ( let d of rowData){
      if(d?.inExamWarning){ 
        isFolderNeeded = true;
      }
    }
    let warningsFolder = ''
    // Create the warnings folder in the zip
    if(isFolderNeeded){
      warningsFolder = zip.folder("Exam Warnings");
    }
    
    if (Array.isArray(data) && data.length) {
      let sizeArr = Array(data.length).fill(0);
      let myPromise = Promise.all(
        data.map(async (row, index) => {
          if (row.path && row.path != null && row.path != "") {
            checkTotal++
            setCheckTotal(checkTotal)
            const size = parseFloat(row?.size);
            if (!isNaN(size)) {
              totalSize += size;
              setFinalTotal(totalSize.toFixed(1));
            }
            const fileExt = row.path.split("/").reverse()[0];
            const fileURL = downloadURL+`${row.path.includes("s3.af-south-1.amazonaws.com") ? "" : STUDENT_FILE_DOWNLOAD.replace("https://","")+"/"}${encodeURI(row.path.replaceAll(
              "/home/myaie/public_html/","").replace("public/",""))}`;
            let loadedFromArr = 0
            try {
              const resp = await axios({
                url: fileURL,
                method: 'get',
                responseType: 'blob',
                onDownloadProgress: (progressEvent) => {
                  const { loaded, total } = progressEvent;
                  sizeArr[index] = loaded
                  loadedFromArr = 0
                  sizeArr.map(item => loadedFromArr = loadedFromArr + item)
                  if(totalSize > loadedFromArr) {
                    setFinalLoadedData(loadedFromArr)
                  }else{
                    setFinalLoadedData(totalSize)
                  }
                }
              })
              
              // Store file in warnings folder if type is undefined, otherwise in root
              if (row?.inExamWarning) {
                warningsFolder.file(`${fileExt}`, resp.data, { binary: true });
              } else {
                zip.file(`${fileExt}`, resp.data, { binary: true });
              }
              
              pending++
              setCheckPending(pending)
            } catch(err) {
              pending++
              setCheckPending(pending)
            }
          }
          zipVal = zip
        })
      );
        
      myPromise.then(() => {
        zipVal.generateAsync({ type: "blob" })
          .then(function (content) {
            setTimeout(() => {
              $("#downloadAssessment").modal("hide");
              setCheckPending(0);
              setCheckTotal(0);
              setLoaded(0);
              setTotal(0);
              setFinalLoadedData(0)
              setFinalTotal(0)
            }, 1000);                 
            saveAs(content, `${assName}_${st_data?.name}_${st_data?.student_crm_id}` + ".zip");
          }).catch((errr) => { console.log(errr) });
      })
    } else {
      if (data && data != null && data != "") {
        setCheckTotal(1)
        setcheckAssignment("true")
        const fileExt = data?.path?.split("/")?.reverse()[0];
        const fileURL = downloadURL+`${data.path.includes("s3.af-south-1.amazonaws.com") ? "" : STUDENT_FILE_DOWNLOAD+"/"}${encodeURI(data.path.replaceAll(
          "/home/myaie/public_html/","").replace("public/",""))}`;
        try {
          const resp = await axios({
            url: fileURL,
            method: 'get',
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              const size = parseFloat(data?.size);
              if (!isNaN(size)) {
                totalSize = size;
              }
              setFinalTotal(totalSize.toFixed(1))
              if(totalSize > loaded) {
                setFinalLoadedData(loaded)
              }else{
                setFinalLoadedData(totalSize)
              }
            }
          })
          
          // For single file case, create a zip with warnings folder if type is undefined
          if (data?.inExamWarning) {
            const warningsZip = new JSZip();
            const warningsFolder = warningsZip.folder("Exam Warnings");
            warningsFolder.file(fileExt, resp.data, { binary: true });
            
            warningsZip.generateAsync({ type: "blob" })
              .then(function (content) {
                saveAs(content, `${assName}_${st_data?.name}_${st_data?.student_crm_id}` + ".zip");
              });
          } else {
            // Original direct download for non-warning files
            const href = URL.createObjectURL(resp.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', fileExt);
            document.body.appendChild(link);
            link.click();
          }
          
          pending++
          setCheckPending(pending)
          setTimeout(() => {
            $("#downloadAssessment").modal("hide");
            setCheckPending(0);
            setCheckTotal(0);
            setLoaded(0);
            setTotal(0);
            setPercent(0)
            setFinalLoadedData(0)
            setFinalTotal(0)
          }, 1000);
        } catch(err) {
          console.log(err)
        }
      }
    }
}

  const handlefileinput = () => {
    inputRef.current.click()
  }

  const handleFileChange = (event) => {
    setZipFileUpload(event.target.files[0])
  }
  const submitZipForm = () => {
    const data = new FormData();
    data.append("import", zipfileupload[0].file)
    setImportLoading(true)
    GetimportMarks(data)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "successfully",
        });
        setZipFileUpload("")
        setImportLoading(false)
        $("#inputfileupload").val("");
        $("#exampleModalCenter").modal("hide");
      }).catch((error) => {
        console.error("zipupload error", error)
        Swal.fire({
          icon: "error",
          title: "Error",
          text: 'Error',
        });
        setImportLoading(false)
      })
  }
  // mycode end

  const userID = useSelector(state => state.user.UserID);

  const sendInstruction = () => {
    setInstructionLoading(true)
    const sendData = {
      assessment_marker: headerData.assessment_marker,
      sender: userID,
      assignmentid: headerData.assessment_id,
      close_date_time: headerData.close_date_time,
      marking_deadline_date: headerData.marking_deadline_date,
      intake: headerData.intake
    }
    SendAssignmentInstruction(sendData).then((res) => {
      if (res.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Instructions sent successfully",
        });
        setInstructionLoading(false)
      }
    }).catch((err) => { setInstructionLoading(false); console.error(err) })
  }

 const handleDownloadResults = async () => {
     try {
       setDownloading(true);
       const parameter = { assignment: subId };
       const res = await DownloadAssessmentsStudentResults(parameter);
   
       if (res.status === 200) {
         let respData = res.data.data;
         let data1 = downloadZip;
         let demoData = downloadZip;
         const header = [
           "Student Number",
           "Student Name", 
           "Final Mark",
           "Question Name",
           "Question",
           "Student Answer",
           "Correct Answer",
           "Mark",
           "Result",
         ];
         
         const formatData = [];

         respData.map((data)=>{
           let dataItem = {
           "Student Number" : data.student_crm_id,
           "Student Name" : `${data.first_name} ${data.last_name}`,
           "Final Mark" : `${data.ass_mark}/${data.ass_total_mark}`,          
           "Question Name" : "",
           "Question" : "",
           "Student Answer" : "",
           "Correct Answer" : "",
           "Mark" : "",
           "Result" : "",
           }
           formatData.push(dataItem)
           data.result && data.result.dataAns && data.result.dataAns.map((quesData, index)=>{
             if(quesData.questionType != "Document Upload" && quesData.questionType != "Long Answer") {
               let subDataItem = {
                 "Student Number" : "",
                 "Student Name" : "",
                 "Final Mark" : "",          
                 "Question Name" : quesData.question_name.length < 32000 ? quesData.question_name : "Data length is too large",
                 "Question" : quesData.question ? (convertToPlain(quesData.question).length < 32000 ? convertToPlain(quesData.question) : "Data length is too large") : "NA",
                 "Student Answer" : convertToPlain(quesData.UserAnswer).length < 32000 ? convertToPlain(quesData.UserAnswer) : "Data length is too large",
                 "Correct Answer" : convertToPlain(quesData.correctAnswer).length < 32000 ? convertToPlain(quesData.correctAnswer) : "Data length is too large",
                 "Mark" : `${twoDecimalRound(data.result.scoreArray[index])}/${twoDecimalRound(data.result.total_score_individual[index])}`,
                 "Result" : (data.result?.total_score_individual ? data.result?.total_score_individual[index] == 0 : data.result?.scoreArray[index] == 0)  ? RenderQuizResult("0").text 
                           : data.result?.scoreArray[index] == 0 ? RenderQuizResult("0").text : (quesData.questionType == "Multiple Choice Multiple Answer" || quesData.questionType == "Match the Column") 
                           && (data.result?.total_score_individual ? data.result.scoreArray[index]/data.result?.total_score_individual[index] != 0 : data.result.scoreArray[index]/data.result.scoreArray[index] != 0 ) 
                           ? RenderQuizResult((data.result.scoreArray[index]/data.result?.total_score_individual[index]).toString()).text : parseInt(data.result.scoreArray[index]) == (data.result?.total_score_individual[index] 
                             ? data.result?.total_score_individual[index] : data.result.scoreArray[index]) ? RenderQuizResult("1").text : RenderQuizResult("0").text,
               }
               formatData.push(subDataItem);
             }
           })          
         });
         const compatibleData = formatData.map((row) => {
           const obj = {};
           header.map((col, index) => {
             obj[col] = row[col];
           });
           return obj;
         });

         // Format data similar to handleDownloadAssessment
         data1 = data1?.map((row) => ({
           ...row,
           SID: row.student_crm_id ? row.student_crm_id : "",
           STUDENT: row.first_name ? `${row.first_name} ${row.last_name}` : "",
           SURNAME: row.last_name ? row.last_name : "",
           "ASSESSMENT NAME": zipfilename ? zipfilename.trim() : "",
           "HIGHEST MARK ACHIEVABLE": row.ass_total_mark >= 0 ? row.ass_total_mark : "",
           "MARKED ACHIEVED": row.ass_mark != null && row.ass_mark.toString() ? row.ass_mark : "",
           dataAns: row.result?.dataAns || []
         }));
   
         const maxSizePerArray = 1536 * 1024 * 1024;
         let fileDetails = calculateFileSize(demoData, maxSizePerArray);
         setFinalTotal(fileDetails.totalSize.toFixed(1));
         let loadedArr = JSON.parse(JSON.stringify(fileDetails.urlArrays));
   
         let zipPromises = [];
         for (
           let i = 0;
           i < fileDetails.urlArrays.length;
           i += MAX_CONCURRENT_DOWNLOADS
         ) {
           const batch = fileDetails.urlArrays.slice(
             i,
             i + MAX_CONCURRENT_DOWNLOADS
           );
           const batchPromises = batch.map(async (urlArray, index) => {
             let zip = new JSZip();
             let sizeArr = Array(urlArray.length).fill(0);
             let loadedBytes = 0;
   
             let promise = Promise.all(
               urlArray.map(async (row, rowIndex) => {
                 const fileExt = row.path
                   .split("/")
                   .reverse()[0]
                   .trim()
                   .replace(/[\t\r\n]/g, "");
                 let fileURL =
                   downloadURL +
                   `${
                     row.path.includes("s3.af-south-1.amazonaws.com")
                       ? ""
                       : STUDENT_FILE_DOWNLOAD.replace("https://","") + "/"
                   }${row.path
                     .replaceAll("/home/myaie/public_html/", "")
                     .replace("public/", "")}`;
                 if (isHybrid === true) {
                   fileURL =
                     downloadURL +
                     STUDENT_FILE_DOWNLOAD.replace("https://","") +
                     `/${row.path
                       .replaceAll("/home/myaie/public_html/", "")
                       .replace("public/", "")}`;
                 }
                 let loadedFromArr = 0;
                 try {
                   const resp = await axios({
                     url: fileURL,
                     method: "get",
                     responseType: "blob",
                     onDownloadProgress: (progressEvent) => {
                       const { loaded, total } = progressEvent;
                       loadedArr[index][rowIndex] = loaded;
                       loadedFromArr = 0;
                       loadedArr.map((item) =>
                         item.map((subItem) => {
                           if (typeof subItem === "number") {
                             loadedFromArr = loadedFromArr + subItem;
                           }
                         })
                       );
                       loadedBytes = loaded;
                       if (fileDetails.totalSize > loadedFromArr) {
                         setFinalLoadedData(loadedFromArr);
                       } else {
                         setFinalLoadedData(fileDetails.totalSize);
                       }
                     },
                   });
   
                   // Create student folder with exam warnings subfolder
                   let zipFolder = zip.folder(`${row.flname + "_" + zipfilename.trim().replace(/[\t\r\n]/g, '')}`);
                   let newUrlArr = urlArray.filter(item => row.flname == item.flname)
   
                   let isFolderNeeded = false;
                   for (let d of newUrlArr){
                     if(d?.inExamWarning){ 
                       isFolderNeeded = true;
                     }
                   }
                   let warningsFolder = '';
                   // Create the warnings folder in the zip
                   if(isFolderNeeded){
                     warningsFolder = zipFolder.folder("Exam Warnings");
                   }
   
                   if (row?.inExamWarning) {
                     warningsFolder.file(`${fileExt}`, resp.data, { binary: true });
                   }             
                 } catch (err) {
                   console.log(err, "zip generation error");
                 }
               })
             );
   
             promise = promise.then(() => {
               // Add text files for question answers
               const textFileData = data1.filter((item) => item.dataAns);
               if (textFileData.length) {
                 textFileData.map((row) => {
                   let fileContent = "";
                   if (Array.isArray(row.dataAns) && row.dataAns.length) {
                     row.dataAns.map((element) => {
                       fileContent +=
                         "Question : " +
                         element.questionName +
                         "Answer : " +
                         element.UserAnswer +
                         "\n" +
                         "\n";
                     });
                   }
                   if (fileContent != "") {
                     zip
                       .folder(
                         `${
                           row.flname +
                           "_" +
                           zipfilename.trim().replace(/[\t\r\n]/g, "")
                         }`
                       )
                       .file("question.txt", fileContent);
                   }
                 });
               }
   
               // Generate XLSX file
               let wb = XLSX.utils.book_new();
               let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                 header,
               });
               XLSX.utils.book_append_sheet(wb, ws1, "Results Data");
               const xlsxBuffer = XLSX.write(wb, { type: 'array', bookType: 'xlsx' });
               
               zip.file(`${zipfilename + "_" + index + ".xlsx"}`, xlsxBuffer);
   
               return zip.generateAsync({ type: "blob" });
             });
   
             try {
               const zipBlob = await promise;
               const zipFileName = `${zipfilename}_${
                 i / MAX_CONCURRENT_DOWNLOADS + index + 1
               }_.zip`;
               saveAs(zipBlob, zipFileName);
             } catch (err) {
               console.error("Error generating or downloading zip files:", err);
             }
           });
           zipPromises.push(Promise.all(batchPromises));
         }
   
         await Promise.all(zipPromises);
       }
     } catch (error) {
       console.error("Error generating or downloading zip files:", error);
     } finally {
       setTimeout(() => {
         $("#downloadAssessment").modal("hide");
         setFinalLoadedData(0);
         setFinalTotal(0);
         setDownloading(false);
       }, 1000);
     }
   };

  const handleChangeAppealOutcome = (outcome) =>{
    const appealData = {...appealsDetails};
    appealData.student_appeal.appeal_outcome = outcome;
    setAppealsDetails({...appealData})
  }

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: 'View All',
  };

  const importMarks = () => {
    if (headerData && headerData.quiz_id) {
      if (headerData?.ass_type == 13 || headerData?.ass_type == 14) {
        return
      } else if (!isHybrid){
        return (
          <>
            <button className="btn btn-primary" title="Import Marks" data-toggle="modal" disabled={headerData?.notify_assessed} data-target="#exampleModalCenter">
              <i className="fal fa-arrow-up"></i>Import Marks
            </button>
            &nbsp; &nbsp;
          </>
        )
      } else {
        return
      }
    }
  }

    // Memoize filters array
    const filters = useMemo(() => [
      ...(assessmentTypes == 13 || assessmentTypes == 14
        ? [
          {
            filterName: "Assessment Status",
            optionArr: assessementResultFilterList || [],
            state: assessementStatus,
            setState: SetAssessementStatus,
            renderLabelFunction: RenderSubmissionAndResultStatus,
          },
          {
            filterName: "Assessment Method",
            optionArr: assessementMethodFilterList || [],
            state: assessementMethod,
            setState: setAssessementMethod,
            renderLabelFunction: RenderCategoriesType,
          }
          // {
          //   filterName: "Result",
          //   optionArr: assessementResultFilterList || [],
          //   state: assessementResult,
          //   setState: setAssessementResult,
          // },
        ]
        : [
            {
              filterName: "Status",
              optionArr: [
                { label: "ACTIVE", value: "ACTIVE" },
                { label: "CANCELLED", value: "Cancelled" },
                { label: "DROPOUT", value: "Drop Out" },
                { label: "FINISHED", value: "Finished" },
                { label: "INACTIVE", value: "In Active" },
                { label: "ONHOLD", value: "On Hold" },
                { label: "RESULTWITHELD", value: "Results Witheld" },
              ],
              state: status,
              setState: setStatus,
              renderLabelFunction: RenderSubmissionAndResultStatus,
            },
            {
              filterName: "Submission Status",
              optionArr: [
                { label: "Appeal-Approved", value: "Appeal-Approved" },
                { label: "Appeal-Pending", value: "Appeal-Pending" },
                { label: "Draft", value: "DRAFT" },
                { label: "Marked", value: "MARKED" },
                { label: "Not Submitted", value: "NOT SUBMITTED" },
                { label: "Submitted", value: "SUBMITTED" },
                { label: "Closed-Not Submitted", value: "closednot submitted" },
              ],
              state: submissionStatus,
              setState: setSubmissionStatus,
              renderLabelFunction: RenderSubmissionStatus,
            },
          ]),
    ], [
      assessmentTypes,
      status,
      setStatus,
      submissionStatus,
      setSubmissionStatus,
      assessementStatus,
      SetAssessementStatus,
      assessementMethod,
      setAssessementMethod,
      assessementResult,
      setAssessementResult,
      assessementStatusFilterList,
      assessementMethodFilterList,
      assessementResultFilterList
    ]);

  
  const getColumns = () => {
  
    // Prioritize conditions
    if (isHybrid === true) {
      return hybridColumns;
    }
  
    if (headerData?.ass_type == 13 || headerData?.ass_type == 14) {
      return practicalColumns;
    }
  
    if (headerData?.quiz_id) {
      return quizColumns;
    }
  
    return columns;
  };

  const handleStartStopPracticalAssessment = () => {
    setIsStartAssessmentLoading(true);
    const data = {
      assessment_id: parseInt(subId),
      action: "start"
    }
    startStopPracticalAssignment(data).then((res) => {
      if(res?.data?.data){
        history.push({
          pathname: `/courseAdministration/coursesdetails/${id}/assessments/open/assigned/assessmentMarking/${subId}`
        });
      }
    }).catch((err) => {
      console.error("err" , err);
    }).finally(() => {
      setIsStartAssessmentLoading(false);
    })
  }

//   const formatDate = (seconds) => {
//     const date = new Date(seconds * 1000);

//     return date;
//   };

  useEffect(() => {
    const checkAssessmentDates = () => {
        // const opendate = formatDate(headerData?.open_date_time);
        // const closingdate = formatDate(headerData?.close_date_time);
        
        // const currentDateTime = new Date(Date.now());

        // const totalTime = `${headerData?.timeHour} : ${headerData?.timeMin}`;
        const duration_consumed = headerData?.duration_consumed;

        const totalTimeInSeconds = (headerData?.timeHour ?? 0) * 3600 + (headerData?.timeMin ?? 0) * 60;
        
        // if (opendate > currentDateTime) {
        //     setIsStartAssessment(true);
        // } else if (closingdate < currentDateTime) {
        //     setIsStartAssessment(true);
        // } else 
        if (duration_consumed >= totalTimeInSeconds) {
            setIsStartAssessment(true);
        } else {
            setIsStartAssessment(false);
        }
    };

    checkAssessmentDates();

    const intervalId = setInterval(checkAssessmentDates, 60000);

    return () => clearInterval(intervalId);
}, [headerData, headerData?.open_date_time, headerData?.close_date_time]);

  return (
    <>
       <Prompt when={!!shouldBlockNavigation} message={handleNavigation} />
      <div className="card-new card card-profile-info-card mb-30">
        <div className="">
          <div className="card-header">{`${headerData?.ass_type == 13 || headerData?.ass_type == 14 ? "Assessment" : "Marking"} Management`} {loading && <i className="fas fa-cog fa-spin ml-2"></i>}</div>
          {(loading || isLoading) ? <SkeletonTicketList /> : <div className="card-body-inr card-body-info">
            <div className="basic-info-box">
              <div className="row">
              {headerData && 
                (headerData.quiz_id) && 
                (isHybrid === false ) &&
                (headerData?.ass_type !== 13 && headerData?.ass_type !== 14) ? (
                  <div className="col-md-6 col-lg-4">
                    <div className="basic-info-box-list">
                      <ul>
                        <li>
                          <span className="title">Mark Status :</span>
                          <span>
                            {headerData ? 
                              headerData.mark_status === "Past Due" ? 
                                <span style={{ color: "#FF0000" }}> &nbsp;Past Due</span> 
                                : headerData.mark_status 
                              : "-"}
                            <span>
                              &nbsp;
                              <button
                                onClick={() => { sendInstruction() }} 
                                type="button" 
                                className="btn-save btn btn-primary"
                              >
                                {instructionLoading ? <i className="fas fa-cog fa-spin"></i> : ""} 
                                Send Instruction
                              </button>
                            </span>
                          </span>
                        </li>
                        <li>
                          <span className="title ">Marking deadline :</span>
                          <span>{headerData?.marking_deadline_date !== '' ? moment.unix(headerData?.marking_deadline_date).format(TABLE_DATE_FORMAT)  : "-"}</span>
                          </li>
                      </ul>
                    </div>
                  </div>
                ) : null}

                {headerData?.ass_type == 13 || headerData?.ass_type == 14 ? 
                  <>
                    <div className="col-md-6 col-lg-4">
                      <div className="basic-info-box-list">
                        <ul>
                          <li>
                            <span className="title" title="Marking Status">Marking Status :</span>
                            <span title={`${headerData?.mark_status || "-"}`}>{headerData && headerData.mark_status ? headerData.mark_status : "-"}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="basic-info-box-list">
                        <ul>
                        <li>
                            <span className="title" title="Marking Deadline">Marking Deadline :</span>
                            <span title={`${headerData?.marking_deadline_date !== '' ? moment.unix(headerData?.marking_deadline_date).format(TABLE_DATE_FORMAT)  : "-"}`}>{headerData?.marking_deadline_date !== '' ? moment.unix(headerData?.marking_deadline_date).format(TABLE_DATE_FORMAT)  : "-"}</span>
                            </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="basic-info-box-list">
                        <ul>
                         <li>
                            <span className="title" title="Appeals">Appeals :</span>
                            <span title={`${headerData && headerData.TOTAL_APPEALES !== 0 ? headerData.TOTAL_APPEALES : "-"}`}>{headerData && headerData.TOTAL_APPEALES !== 0 ? headerData.TOTAL_APPEALES : "-"}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="basic-info-box-list">
                        <ul>
                          <li>
                            <span className="title" title="Self-Assessed Students">Self-Assessed Students :</span>
                            <span title={`${headerData?.self_assessed_student || "-"}`}>{headerData?.self_assessed_student || "-"}</span>
                            </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="basic-info-box-list">
                        <ul>
                        <li>
                            <span className="title" title="Lecturer-Assessed Students">Lecturer-Assessed Students :</span>
                            <span title={`${headerData?.lecturer_assessed_student || '-'}`}>{headerData?.lecturer_assessed_student || '-'}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="basic-info-box-list">
                        <ul>
                        <li>
                            <span className="title" title="Submissions" >Submissions :</span>
                            <span title={`${headerData?.TOTAL_SUBMISSION || "-"}`}>{headerData?.TOTAL_SUBMISSION || "-"}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </> :
                  <>
                    <div className="col-md-6 col-lg-4">
                      <div className="basic-info-box-list">
                        <ul>
                          <li style={{ color: "#FF0000" }}>
                            <span className="title">Submissions :</span>
                            <span>&nbsp;{headerData?.submissions || "-"}</span>
                          </li>
                          <li style={{ color: "#FF0000" }}>
                            <span className="title">Submission rate :</span>
                            <span>&nbsp;{headerData?.submissions_rate || "-"}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="basic-info-box-list">
                        <ul>
                          <li>
                            <span className="title">Appeals:</span>
                            <span>{headerData && headerData.TOTAL_APPEALES && headerData.TOTAL_APPEALES.toString() ? headerData.TOTAL_APPEALES : "-"}</span>
                          </li>
                          <li>
                            <span className="title">Average Mark:</span>
                            <span>{headerData && headerData.AvgMarks && headerData.AvgMarks.toString() ? headerData.AvgMarks : "-"}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>}

          {/* {/ button section start /} */}
          {(loading || isLoading) ? <SkeletonTicketList /> : <div className="button-reset dropdown-comman">
            {!(headerData && headerData.quiz_id && isHybrid===false) && (headerData?.ass_type != 13 && headerData?.ass_type != 14) ?
            <>
            <button type="button" className="btn btn-save btn-success" title="Download Assessments" onClick={()=>{ handleDownloadAssessment()}} data-toggle="modal" data-target="#downloadAssessment">
              <i className="fal fa-arrow-down"></i>Download Assessments
            </button>
            &nbsp; &nbsp; 
            </>: null}

            {importMarks()} 

            <>
            {headerData?.notify_assessed  ? <button title="Re-Open Marking" className="btn btn-primary" onClick={(e) => submitNotification(e, "reOpen")}>
                <i className="fal fa-bell"></i>Re-Open Marking
              </button> : <button className="btn btn-primary" title="Mark as Complete" data-toggle="modal" data-target="#exampleModal2" disabled={(headerData?.ass_type == 13 || headerData?.ass_type == 14) ? (headerData?.isShowMarkAsCompleteButton == 1 ? false : true) : false}> 
                <i className="fal fa-bell"></i>Mark as Complete
              </button>}
              &nbsp; &nbsp;
            </>
            {(headerData && headerData.quiz_id) && headerData?.ass_type != 13 && headerData?.ass_type != 14 ? 
            <button className="btn btn-primary" onClick={()=>{handleDownloadResults()}} title="Download Smart Assessment Results">
              {downloading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-arrow-down"></i>} Download Smart Assessment Results
            </button>
            :null}
            {(headerData && (headerData.quiz_id || headerData.quiz_id == 0)) && (headerData?.ass_type == 13 || headerData?.ass_type == 14) ? 
            <>
              <button className="btn btn-primary start_practical" onClick={() => handleStartStopPracticalAssessment()} title="Start Practical Assessment" disabled={headerData?.isDisableStartButton || isStartAssessmentLoading || headerData?.lecturer_assessed_student == 0} >
                {isStartAssessmentLoading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-pen"></i>} Start Practical Assessment
              </button>
              &nbsp; &nbsp;
            </>
            :null}
            {(headerData && (headerData.quiz_id || headerData.quiz_id == 0) && (headerData?.ass_type == 13 || headerData?.ass_type == 14)) ? 
            <button className="btn btn-primary review_btn" onClick={() => history.push(`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/assessmentMarkingReview/${subId}`)} title="Review Self-Assessed Submissions" disabled={headerData?.self_assessed_student == 0}>
              <i className="fal fa-clipboard-check"></i> Review Self-Assessed Submissions
            </button>
            :null}
          </div>}
          {/* {/ button end /} */}
        </div>
      </div >

      {/* {/ popup1 section start /} */}
      <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">Import csv for mark capture</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="import_capture_inputs">
                <form onSubmit={submitZipForm}>
                  {/* <label htmlFor="fileID">Study Guide</label> */}
                  {/* <div className="form-control" onClick={handlefileinput}>
                    {zipfileupload?.name ? <i className="fas fa-file-archive"></i> : ""}
                    &nbsp;
                    {zipfileupload ? zipfileupload?.name : null}
                  </div> */}
                  <div className="drag_card_btn">
                                            <FilePond
                                                name="CSVFILE"
                                                allowMultiple={false}
                                                // maxFiles={1}
                                                ref={inputRef}
                                                files={zipfileupload}
                                                onupdatefiles={setZipFileUpload}
                                                acceptedFileTypes='application/csv,text/csv,.csv'
                                            />
                                        </div>
                  {/* <input type="file" id="inputfileupload" ref={inputRef} onChange={handleFileChange} accept=".csv" style={{ display: "none" }} /> */}
                </form>
                {/* {zipfileupload && zipfileupload?.name ? <button className="btn btn-primary" onClick={handlefileinput}>Change</button> : <button className="btn btn-primary" onClick={handlefileinput}>Browse</button>} */}
              </div>
            </div>
            <div className="modal-footer">
              <button type="submit" disabled={!zipfileupload} className="btn btn-primary" onClick={submitZipForm}>Import</button>
              <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={()=>setZipFileUpload("")}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
      {/*popup1 section end */}


      {/* / popup2 section start / */}
      <div className="modal fade" id="exampleModal2" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Notification !</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" disabled={notificationval}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <input type="checkbox" id="checkboxid" name="notificationval" checked={notificationval} onChange={(e) => setNotificationvalue(e)} />
              &nbsp;&nbsp;
              <label htmlFor="checkboxid">Notify All Students</label>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" onClick={submitNotification}><i className="fal fa-check"></i>Send</button>
              <button type="button" className="btn btn-danger" data-dismiss="modal"><i className="fal fa-times"></i>Close</button>
            </div>
          </div>
        </div>
      </div>
      {(isLoading || loading) ? <SkeletonTicketList /> : <DataTableComponentFrontPagination
        data={(assessmentTypes == 13 || assessmentTypes == 14) ? renderPracticalData() : dataToRender()}
        onSort={handleSort}
        // columns={(headerData && headerData.quiz_id) ? (isHybrid===true ? hybridColumns : quizColumns) : columns}
        columns={getColumns()}
        loading={isLoading}
        changePage={handleNavigation}
        search={search}
        setSearch={setSearch}
        exportData={(assessmentTypes == 13 || assessmentTypes == 14) ? exportPracticalData : exportData}
        conditionalRowStyles={conditionalRowStyles}
        exportFileName={"Submission-And-Result"}
        errorMessageMargin={(userData?.length && updateValueArr?.length) && errorMessage}
        defaultSort={{ defaultSortColumn: "name", defaultSortAsc: true }}
        filters={filters}
        
        
        
        bottomButton={[
          <div>
          {errorMessage ? (
            <div className="invalid-feedback d-block">{errorMessage}</div>
          ) : (
            ""
          )}
          </div>
        ]}
        otherTableProps={{paginationComponentOptions:paginationComponentOptions}}
      />}
      
      {userData?.length && updateValueArr?.length ? <GradeBookSaveBar handleClick={() => handleSaveGrades()} saveGradesLoading={saveGradesLoading} disabled={isSaveGradesDisabled?.includes(1)}
 /> : <></>}
      <PopupComponent
        id="notifications"
        type="form"
        header={{
          iconName: "fa-comment",
          heading: "Add Comment"
        }}
        inputs={[
          <div className="col-md-12">
            <div className="form-group-blk mb-3">
              <label>Comment *</label>
              <textarea
                name="comment"
                type="text"
                className={"form-control" + (formik.errors.comment && formik.touched.comment ? " is-invalid" : "")}
                value={formik.values.comment}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
        ]}
        bottomButton={[
          <PermissionsGate scopes={["cedit"]} errorProps={{ disabled: true }}>
            <button type="button" className="btn btn-primary" onClick={formik.handleSubmit}>
              <i className="fal fa-save"></i>
              Post
            </button>
          </PermissionsGate>,
          <button type="button" className="btn btn-danger" data-dismiss="modal">
            <i className="fal fa-times"></i>
            Close
          </button>,
          formik.touched.comment && formik.errors.comment ? <div className="invalid-feedback d-block">{formik.errors.comment}</div> : null
        ]}
      />
      <PopupComponent
        closeFunction={()=> setStudentMessage("")}
        id="notifications1"
        type="multiple_details_blocks"
        header={{
          iconName: "fa-comment",
          heading: "Appeals"
        }}
        detailsBlocks={[
          {
            heading: "Assessment Detail",
            columns: [
              <>
                <div className="col-md-6">
                  <div className="basic-info-box-list modal-info-list">
                    <ul>
                      <li>
                        <span className="title">Assessment Name:</span>
                        <span>
                          {appealsDetails &&
                            appealsDetails.assignment.name}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="basic-info-box-list modal-info-list">
                    <ul>
                      <li>
                        <span className="title">Assessment Status:</span>
                        <span>
                          {appealsDetails &&
                            appealsDetails.student_appeal &&
                            appealsDetails.student_appeal?.ass_status}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="basic-info-box-list modal-info-list">
                    <ul>
                      <li>
                        <span className="title ">Opening Date:</span>
                        <span>
                          {appealsDetails &&
                            appealsDetails.assignment.open_date_time &&
                            moment
                              .unix(appealsDetails.assignment.open_date_time)
                              .format(TABLE_DATE_TIME_FORMAT)}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="basic-info-box-list modal-info-list">
                    <ul>
                      <li>
                        <span className="title">Code:</span>
                        <span>
                          {appealsDetails && appealsDetails.assignment.code}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="basic-info-box-list modal-info-list">
                    <ul>
                      <li>
                        <span className="title ">Due Date:</span>
                        <span>
                          {appealsDetails &&
                            appealsDetails.assignment.close_date_time &&
                            moment
                              .unix(appealsDetails.assignment.close_date_time)
                              .format(TABLE_DATE_TIME_FORMAT)}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="basic-info-box-list modal-info-list">
                    <ul>
                      <li>
                        <span className="title ">Your Result:</span>
                        <span>{appealsDetails && appealsDetails.student_appeal && appealsDetails.student_appeal.assessment_mark_per}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="basic-info-box-list modal-info-list">
                    <ul className="course-name-merge">
                      <li>
                        <span className="title ">
                          Appeal Submission Date:
                        </span>
                        <span>
                          {appealsDetails &&
                            appealsDetails.student_appeal &&
                            moment
                              .unix(
                                appealsDetails?.student_appeal?.submit_date
                              )
                              .format(TABLE_DATE_TIME_FORMAT)}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
               { appealsDetails && appealsDetails.student_appeal && appealsDetails.student_appeal.timeLimitActive == 1 ?<div className="col-md-6">
                  <div className="basic-info-box-list modal-info-list">
                    <ul className="course-name-merge">
                      <li>
                        <span className="title ">Assessment Launched:</span>
                        <span>
                          {appealsDetails &&
                            appealsDetails.assignment.close_date_time &&
                            moment
                              .unix(appealsDetails.assignment.close_date_time)
                              .format(TABLE_DATE_TIME_FORMAT)}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div> : ""}
                <div className="col-md-6">
                  <div className="basic-info-box-list modal-info-list">
                    <ul>
                      <li>
                        <span className="title ">Submission Status:</span>
                        <span>{appealsDetails && appealsDetails.student_appeal ? appealsDetails.student_appeal.appeal_status == "Declined" ? "Appeal-Declined" : appealsDetails.student_appeal.appeal_status : "NA"}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            ]
          },
          {
            heading: "Registered Appeal",
            columns: [
              <div className="col-md-12">
                <div className="basic-info-box-list modal-info-list">
                  <ul>
                    <li>
                      <span className="title">
                        Reason for not submitting assessment:{" "}
                      </span>
                      <span>
                        {appealsDetails &&
                          appealsDetails.student_appeal &&
                          appealsDetails.student_appeal.ass_reason}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>,
              <div className="col-md-12">
                <div className="basic-info-box-list modal-info-list">
                  <ul>
                    <li>
                      <span className="title">Description: </span>
                      <span className="text-break">
                        {appealsDetails &&
                          appealsDetails.student_appeal &&
                          appealsDetails.student_appeal.description}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>,
              <div className="col-md-12">
                <div className="basic-info-box-list modal-info-list">
                  <ul className="appeal-submission-list">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <li>
                            <span className="title ">
                              Supporting File :
                            </span>
                            <div className="d-block">
                              {appealsDetails &&
                                appealsDetails.student_appeal &&
                                appealsDetails.student_appeal.files &&
                                appealsDetails.student_appeal.files.length ?
                                appealsDetails.student_appeal.files.map(item => {
                                  return <a href={`${downloadURL}${item.file_url.replace(/(^\w+:|^)\/\//, '')}`} target="_blank" className="d-block" title={item.file}>{item.file.toString().split("/").reverse()[0]}&nbsp;</a>
                                }) : "-"}
                            </div>
                          </li>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <li>
                            <span className="title ">
                              Message Student :
                            </span>
                            <textarea
                              disabled={
                                (appealsDetails?.student_appeal?.appeal_status == "Approved" || appealsDetails?.student_appeal?.appeal_status == "Declined")
                                  ? true
                                  : false
                              }
                              type="text"
                              className="form-control"
                              value={studentMessage}
                              onChange={(e) =>
                                setStudentMessage(e.target.value)
                              }
                              cols={10}
                              id="message"
                              placeholder="Enter Message"
                              title="Enter Message"
                            />
                          </li>
                        </div>
                      </div>
                      {appealsDetails?.student_appeal?.appeal_status !=="Declined" ? <div className="col-md-12 mb-2">
                        <div className="form-group-blk">
                          <li>
                            <span className="title ">
                              Appeal Outcome :
                            </span>
                            <div className="form-icon-group" title="">
                              <div className="prefix-sufix-box">
                                <div
                                  style={{ zIndex: "inherit" }}
                                  className="custom-control custom-radio custom-control-inline"
                                  title="Grant Extension"
                                >
                                  <input
                                    type="radio"
                                    id="grantExtension"
                                    name="appealOutcome"
                                    disabled={!(appealsDetails?.student_appeal?.appeal_status != 'Approved' && appealsDetails?.student_appeal?.appeal_status != 'Declined')}
                                    checked={appealsDetails && appealsDetails?.student_appeal && appealsDetails?.student_appeal?.appeal_outcome == 0}
                                    onChange={() => {handleChangeAppealOutcome(0)}}
                                    className="custom-control-input"
                                    // onBlur={formik.handleBlur}
                                  />
                                  <label
                                    className="custom-control-label custom-control-inline"
                                    htmlFor="grantExtension"
                                  >
                                    Grant Extension
                                  </label>
                                </div>
                                <div
                                  style={{ zIndex: "inherit" }}
                                  className="custom-control custom-radio custom-control-inline"
                                  title="Move to Sick Test"
                                >
                                  <input
                                    type="radio"
                                    id="movetosick"
                                    disabled={(!(appealsDetails?.student_appeal?.appeal_status != 'Approved' && appealsDetails?.student_appeal?.appeal_status != 'Declined'))}
                                    name="appealOutcome"
                                    checked={appealsDetails && appealsDetails?.student_appeal && appealsDetails?.student_appeal?.appeal_outcome == 1}
                                    onChange={() => {handleChangeAppealOutcome(1)}}
                                    className="custom-control-input"
                                    // onBlur={formik.handleBlur}
                                  />
                                  <label
                                    className="custom-control-label custom-control-inline"
                                    htmlFor="movetosick"
                                  >
                                    Move to Sick Test
                                  </label>
                                </div>
                              </div>
                            </div>
                          </li>
                        </div>
                      </div> : ""}
                      {(appealsDetails?.student_appeal?.appeal_status=="Declined" || appealsDetails?.student_appeal?.appeal_outcome == 1) ? <></> :
                        <div className="col-md-12">
                          <div className="form-group-blk">
                            <li>
                              <span className="title ">
                                Extended assessment date :
                              </span>
                              <span className="form-group-blk w-100">
                                <DatePicker
                                  disabled={!(appealsDetails?.student_appeal?.appeal_status != 'Approved' && appealsDetails?.student_appeal?.appeal_status != 'Declined')}
                                  selected={startDate}
                                  onChange={(date) => {
                                    setStartDate(date);
                                  }}
                                  minDate={new Date()}
                                  className="form-control w-100"
                                  showTimeSelect
                                  dateFormat={FORM_DATE_TIME_FORMAT}
                                />
                              </span>
                            </li>
                          </div>
                        </div>
                      }
                    </div>
                  </ul>
                </div>
              </div>
            ]
          },
        ]}
        bottomButton={appealsDetails?.student_appeal?.appeal_status && appealsDetails?.student_appeal?.appeal_status !== "Approved" && appealsDetails?.student_appeal?.appeal_status !== "Declined" ? [
          <button
            type="button"
            className="btn btn-success"
            // data-dismiss="modal"
            // data-target="#approve"
            // data-toggle="modal"
            title="Approve"
            onClick={() => handleSendResponse("Approve", appealsDetails.student?.student_id)}
            disabled={appealDisable === "Approve"}
          >
            {appealDisable === "Approve" ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-check"></i>}
            Approve
          </button>,
          <button
            type="button"
            className="btn btn-danger"
            // data-dismiss="modal"
            title="Decline"
            onClick={() => handleSendResponse("Decline", appealsDetails.student?.student_id)}
            // data-target="#decline"
            // data-toggle="modal"
            disabled={appealDisable === "Decline"}
          >
            {appealDisable === "Decline" ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-times"></i>}
            Decline
          </button>
        ] : []}
        validationErrorMessage={[
          messageError
        ]}
      />
      <div className="downloading-progress modal fade" id="downloadAssessment" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={downloadRef} data-backdrop="static">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="swal2-header">
                <div className="swal2-icon swal2-info swal2-icon-show d-flex">
                  <div className="swal2-icon-content">i</div>
                </div>
                <h2 className="swal2-title d-flex mb-4">Downloading...</h2>
                <div className="progress w-100 mb-2">
                  {/* <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={checkTotal == 1 ? percent : ((checkPending / checkTotal) * 100).toFixed(2)} aria-valuemin="0" aria-valuemax="100" style={{ width: checkTotal == 1 ? `${percent}%` : `${((checkPending / checkTotal) * 100).toFixed(2)}%` }}>
                    {checkTotal == 1 ? percent : ((checkPending / checkTotal) * 100).toFixed(2)}%</div> */}
                    <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={FinalLoadedData && finalTotal > 0 ? (FinalLoadedData/finalTotal * 100).toFixed(1) : 0} aria-valuemin="0" aria-valuemax="100" style={{ width: FinalLoadedData && finalTotal > 0 ? `${(FinalLoadedData/finalTotal * 100).toFixed(1)}%` : `0%` }}>
                    {FinalLoadedData && finalTotal > 0 ? (FinalLoadedData/finalTotal * 100).toFixed(2) : 0}%</div>
                </div>
                {/* <>File Size : {loaded.toFixed(1)}/{total}  Pending Files : {checkPending}/{checkTotal}</> */}
                <>File Size : {FinalLoadedData && finalTotal > 0 ? `${formatBytes(FinalLoadedData,1)} / ${formatBytes(finalTotal,1)}` : `0 / 0`}    
                {/* Pending Files : {checkPending}/{checkTotal} */}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(SubmissionAndResults);

import React, { useState, useEffect } from "react";
import {  useFormikContext } from "formik";
import { STUDENT_FILE_DOWNLOAD, IMAGE_URL, downloadURL } from "../../../../utils/Constants";
import { RenderAttachList } from "../../../common/RenderAttachList";
import { deletePdfLetter, StudentFileUploads } from "../../../../services/RegistrationService";
import Swal from "sweetalert2";
const Documents = (props) => {
  const [studentData, setStudentData] = useState();
  const [applicationFormList, setApplicationFormList] = useState([]);
  const [invoiceFormList, setInvoiceFormList] = useState([]);
  const [otherDocumentFormList, setOtherDocumentFormList] = useState([]);
  const [matricFormList, setMatricFormList] = useState([]);
  const [furtherFormList, setFurtherFormList] = useState([]);
  const [documentFileUrl, setDocumentFileUrl] = useState("");
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const studentId = params.get('studentId');

  useEffect(() => {
    setStudentData(props.studentData);
    let applicationForm = props.studentData?.application_form
    setApplicationFormList(props.studentData?.application_form)
    setInvoiceFormList(props.studentData?.invoice_form)
    setOtherDocumentFormList(props.studentData?.personal_doc)
    setMatricFormList(props.studentData?.metric_certificate)
    setFurtherFormList(props.studentData?.qualification_certificate)
  }, [props]);

  const formik = useFormikContext()


  const handledocApplication = (e) => {
    formik.setFieldValue("Application", e.target.files[0])
    const fileName = e.target.files[0].name;
    const formData = new FormData();
    formData.append("student_id", studentId)
    formData.append("type", "app_form")
    formData.append("file_input", e.target.files[0])
    formData.append("file_name", fileName);
    StudentFileUploads(formData)
      .then((res) => {
        // setAssemblyID(res?.data?.inserted_record);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `${res?.data?.message || "Updated Successfully"}`,
        });
      }).then(() => {
        props.setrefresh(!(props.refresh));
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.message}`,
        });
      });
    // setSelectedDocumentName(e.target.files[0].name)
  };


  const handledocInvoice = (e) => {
    formik.setFieldValue("Invoice", e.target.files[0])
    const fileName = e.target.files[0].name;
    const formData = new FormData();
    formData.append("student_id", studentId)
    formData.append("type", "inv_form")
    formData.append("file_input", e.target.files[0])
    formData.append("file_name", fileName);
    StudentFileUploads(formData)
      .then((res) => {
        // setAssemblyID(res?.data?.inserted_record);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `${res?.data?.message || "Updated Successfully"}`,
        });
      }).then(() => {
        props.setrefresh(!(props.refresh));
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.message}`,
        });
      });
    // setSelectedDocumentName(e.target.files[0].name)
  };


  const handledocIdAndOther = (e) => {
    formik.setFieldValue("Docs", e.target.files[0])
    const fileName = e.target.files[0].name;
    const formData = new FormData();
    formData.append("student_id", studentId)
    formData.append("type", "personal_doc")
    formData.append("file_input", e.target.files[0])
    formData.append("file_name", fileName);
    StudentFileUploads(formData)
      .then((res) => {
        // setAssemblyID(res?.data?.inserted_record);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `${res?.data?.message || "Updated Successfully"}`,
        });
      }).then(() => {
        props.setrefresh(!(props.refresh));
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.message}`,
        });
      });
    // setSelectedDocumentName(e.target.files[0].name)
  };


  const handledocMatric = (e) => {
    formik.setFieldValue("Matric", e.target.files[0])
    const fileName = e.target.files[0].name;
    const formData = new FormData();
    formData.append("student_id", studentId)
    formData.append("type", "metric_certificate")
    formData.append("file_input", e.target.files[0])
    formData.append("file_name", fileName);
    StudentFileUploads(formData)
      .then((res) => {
        // setAssemblyID(res?.data?.inserted_record);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `${res?.data?.message || "Updated Successfully"}`,
        });
      }).then(() => {
        props.setrefresh(!(props.refresh));
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.message}`,
        });
      });
    // setSelectedDocumentName(e.target.files[0].name)
  };

  const handledocFurther = (e) => {
    formik.setFieldValue("Qualifications", e.target.files[0])
    const fileName = e.target.files[0].name;
    const formData = new FormData();
    formData.append("student_id", studentId)
    formData.append("type", "qualification_certificate")
    formData.append("file_input", e.target.files[0])
    formData.append("file_name", fileName);
    StudentFileUploads(formData)
      .then((res) => {
        // setAssemblyID(res?.data?.inserted_record);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `${res?.data?.message || "Updated Successfully"}`,
        });
      }).then(() => {
        props.setrefresh(!(props.refresh));
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.message}`,
        });
      });
    // setSelectedDocumentName(e.target.files[0].name)
  };

  const basename = (path) => {
    return path.split("/").reverse()[0];
  };

  const handleDelete = (id) => {
    // console.log(ID);
    // let ID = props.studentData.welcome_letter[0].id
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          deletePdfLetter({ id: id }).then((res) => {
            setDocumentFileUrl("");
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          });
        }
      })
      .then(() => {
        props.setrefresh(!props.refresh);
      });
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">Documents</div>
      </div>
      <div>
        <>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="form-group atttach-file mb-2">
                <label className={formik.errors.Application && formik.touched.Application && "file-req is-invalid"}>
                  <i className="fal fa-paperclip"></i>
                  <span>Application Form</span>
                  <input type="file" name="Application" className="form-control  form-control-aatch-file" onChange={handledocApplication} />
                </label>
              </div>
              {/* {formik.values.Application && (
                <>
                  <div className="frm-group">
                    <ul className="list-unstyled attached-file-ul m-0">
                      <li>
                        {formik.values.Application.name.toString()}
                        <button className="btn btn-danger">
                          <i className="fal fa-trash-alt"></i>
                        </button>
                      </li>
                    </ul>
                  </div>
                </>
              )} */}
              <div className="frm-group">
                <ul className="list-unstyled attached-file-ul m-0">
                  {applicationFormList &&
                    applicationFormList.map((item) => {
                      return (
                        <li key={item.id} className="mb-2 d-flex">
                          {RenderAttachList({
                            fileName: basename(item.url),
                            // size: "1205",
                            filePath: (!item.url.includes('https') ? downloadURL + STUDENT_FILE_DOWNLOAD + "/" : downloadURL + "/") + encodeURI(item.url.replace("home/myaie/public_html/", "").replace("public", "").replace(/(^\w+:|^)\/\//, '')),
                            showClose: false,
                          })}
                          <button title="Delete" type="button" className="btn btn-danger rounded-circle" onClick={() => handleDelete(item.id)}>
                            <i className="fal fa-trash-alt"></i>
                          </button>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="form-group atttach-file mb-2">
                <label className={formik.errors.Invoice && formik.touched.Invoice && "file-req is-invalid"}>
                  <i className="fal fa-paperclip"></i>
                  <span>Invoice Form</span>
                  <input type="file" name="Invoice" className="form-control  form-control-aatch-file" onChange={handledocInvoice} />
                </label>
              </div>
              {/* {formik.values.Invoice && (
                <>
                  <div className="frm-group">
                    <ul className="list-unstyled attached-file-ul m-0">
                      <li>
                        {formik.values.Invoice.name.toString()}
                        <button className="btn btn-danger">
                          <i className="fal fa-trash-alt"></i>
                        </button>
                      </li>
                    </ul>
                  </div>
                </>
              )} */}
              <div className="frm-group">
                <ul className="list-unstyled attached-file-ul m-0">
                  {invoiceFormList &&
                    invoiceFormList.map((item) => {
                      return (
                        <li key={item.id} className="mb-2  d-flex">
                            {RenderAttachList({
                              fileName: basename(item.url),
                              // size: "1205",                              
                              filePath: ((!item.url.includes('https') ? downloadURL + STUDENT_FILE_DOWNLOAD + "/" : downloadURL + "/") + encodeURI(item.url.replace("home/myaie/public_html/", "").replace(/(^\w+:|^)\/\//, '').replace("public", "")).replace('//Library', '/Library')),
                              showClose: false,
                            })}
                          <button title="Delete" type="button" className="btn btn-danger rounded-circle" onClick={() => handleDelete(item.id)}>
                            <i className="fal fa-trash-alt"></i>
                          </button>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="form-group atttach-file mb-2">
                <label className={formik.errors.Docs && formik.touched.Docs && "file-req is-invalid"}>
                  <i className="fal fa-paperclip"></i>
                  <span>ID & Other Documents</span>
                  <input type="file" name="Docs" className="form-control  form-control-aatch-file" onChange={handledocIdAndOther} />
                </label>
              </div>
              {/* {formik.values.Docs && (
                <>
                  <div className="frm-group">
                    <ul className="list-unstyled attached-file-ul m-0">
                      <li>
                        {formik.values.Docs.name.toString()}
                        <button className="btn btn-danger">
                          <i className="fal fa-trash-alt"></i>
                        </button>
                      </li>
                    </ul>
                  </div>
                </>
              )} */}
              <div className="frm-group">
                <ul className="list-unstyled attached-file-ul m-0">
                  {otherDocumentFormList &&
                    otherDocumentFormList.map((item) => {
                      return (
                        <li key={item.id} className="mb-2 d-flex">
                            {RenderAttachList({
                              fileName: basename(item.url),
                              // size: "1205",
                              filePath: (!item.url.includes('https') ? downloadURL + STUDENT_FILE_DOWNLOAD + "/" : downloadURL + "/") + encodeURI(item.url.replace("home/myaie/public_html/", "").replace("public", "").replace(/(^\w+:|^)\/\//, '')),                              
                              showClose: false,
                            })}
                          <button title="Delete" type="button" className="btn btn-danger rounded-circle" onClick={() => handleDelete(item.id)}>
                            <i className="fal fa-trash-alt"></i>
                          </button>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="form-group atttach-file my-2">
                <label className={formik.errors.Matric && formik.touched.Matric && "file-req is-invalid"}>
                  <i className="fal fa-paperclip"></i>
                  <span>Matric Certificate</span>
                  <input type="file" name="Matric" className="form-control  form-control-aatch-file" onChange={handledocMatric} />
                </label>
              </div>
              {/* {formik.values.Matric && (
                <>
                  <div className="frm-group">
                    <ul className="list-unstyled attached-file-ul m-0">
                      <li>
                        {formik.values.Matric.name.toString()}
                        <button className="btn btn-danger">
                          <i className="fal fa-trash-alt"></i>
                        </button>
                      </li>
                    </ul>
                  </div>
                </>
              )} */}
              <div className="frm-group">
                <ul className="list-unstyled attached-file-ul m-0">
                  {matricFormList &&
                    matricFormList.map((item) => {
                      return (
                        <li key={item.id} className="mb-2 d-flex">
                            {RenderAttachList({
                              fileName: basename(item.url),
                              // size: "1205",
                              filePath: (!item.url.includes('https') ? downloadURL + STUDENT_FILE_DOWNLOAD + "/" : downloadURL + "/") + encodeURI(item.url.replace("home/myaie/public_html/", "").replace("public", "").replace(/(^\w+:|^)\/\//, '')),                              
                              showClose: false,
                            })}
                          <button title="Delete" type="button" className="btn btn-danger rounded-circle" onClick={() => handleDelete(item.id)}>
                            <i className="fal fa-trash-alt"></i>
                          </button>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="form-group atttach-file my-2">
                <label className={formik.errors.Qualifications && formik.touched.Qualifications && "file-req is-invalid"}>
                  <i className="fal fa-paperclip"></i>
                  <span>Further Qualifications</span>
                  <input type="file" name="Qualifications" className="form-control  form-control-aatch-file" onChange={handledocFurther} />
                </label>
              </div>
              {/* local selected images */}
              {/* {formik.values.Qualifications && (
                <>
                  <div className="frm-group">
                    <ul className="list-unstyled attached-file-ul m-0">
                      <li>
                        {formik.values.Qualifications.name.toString()}
                        <button className="btn btn-danger" type="button" title="Delete" >
                          <i className="fal fa-trash-alt"></i>
                        </button>
                      </li>
                    </ul>
                  </div>
                </>
              )} */}
              {/* api selected images */}
              <div className="frm-group">
                <ul className="list-unstyled attached-file-ul m-0">
                  {furtherFormList &&
                    furtherFormList.map((item) => {
                      return (
                        <li key={item.id} className="mb-2 d-flex">
                            {RenderAttachList({
                              fileName: basename(item.url),
                              // size: "1205",
                              filePath: (!item.url.includes('https') ? downloadURL + STUDENT_FILE_DOWNLOAD + "/" : downloadURL + "/") + encodeURI(item.url.replace("home/myaie/public_html/", "").replace("public", "").replace(/(^\w+:|^)\/\//, '')),                              
                              showClose: false,
                            })}
                          <button title="Delete" type="button" className="btn btn-danger rounded-circle" onClick={() => handleDelete(item.id)}>
                            <i className="fal fa-trash-alt"></i>
                          </button>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default Documents;

import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { IMAGE_URL, NAME_REGEX, FORM_DATE_FORMAT } from "../../../utils/Constants";
import Select from "react-select";
import { Day, Month, Year } from "../../systemadministration/DataReactSelect";
import {
  CreateProgramme,
  GetCommonContent,
  GetProgramsDetails,
  GetProgramsNotificationDetails,
  PreviewStudentNotification,
  UpdateProgramme, 
  UpdateStudentNotification,
} from "../../../services/ProgrammeServices";
import moment from "moment";
import { setHours, setMinutes } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import HtmlInputEditor from "../../common/HtmlInputEditor";
import { EditorState, convertToRaw, RichUtils, CompositeDecorator, ContentState, convertFromHTML, convertFromRaw } from "draft-js";
import "draft-js/dist/Draft.css";
import "draft-js-mention-plugin/lib/plugin.css";
import hasPermission from "../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import PermissionsGate from "../../../utils/permissionGate";

function AddNotification({ programmeName }) {
  const { id, type, subtype, subId } = useParams();
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [previewDisabled, setPreviewDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [attachmentName, setAttachmentName] = useState();
  const [tempFile, setFile] = useState();
  const [editorState, setEditorState] = React.useState();
  const [isDataRecieved, setIsDataRecieved] = useState(false);
  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({ scopes: ["pnadd"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }
    if (subtype == "editNotification") {
    let editResponse = hasPermission({ scopes: ["pnview"], permissions: givenPermsisions });
    if (!editResponse) {
      history.push("/noaccess")
    }
      GetProgramsNotificationDetails(subId)
        .then((res) => {
          formik.setFieldValue("IncludeAccountPayer", res.data.notifications.isPayerActive);
          formik.setFieldValue("EmailNotification", res.data.notifications.isEmailActive);
          formik.setFieldValue("PushNotification", res.data.notifications.isPushnotificationActive);
          formik.setFieldValue("Title", res.data.notifications.subject);
          formik.setFieldValue("SendWhen", res.data.notifications.publish);
          formik.setFieldValue("SendWhenDate", res.data.notifications.publish_date);
          formik.setFieldValue("sentGroups.EmailNotification", res.data.notifications.isEmailActive);
          formik.setFieldValue("sentGroups.PushNotification", res.data.notifications.isPushnotificationActive);
          formik.setFieldValue("FileAttachment", res.data.notifications.attachment ? res.data.notifications.attachment : "");
          setAttachmentName(res.data.notifications.attachment);
          // const blocksFromHTML = convertFromRaw(JSON.parse(res.data.notifications.content));
          // setEditorState(EditorState.createWithContent(blocksFromHTML));
          setEditorState(res.data.notifications.content)
          // formik.setFieldValue("Content", res.data.notifications.content)
          formik.setFieldValue("Important", res.data.notifications.ish_importanceActive);
          setIsDataRecieved(true);
        })
        .catch((err) => console.log("err :", err));
    }
  }, []);

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const fixFilePath = (path) => {
    let filePath = path;
    let fileName = path.split("/").reverse()[0];

    if (path.includes("public/")) {
      filePath = path.replace("public/", "");
    } else if (path.includes("home/myaie/public_html/")) {
      filePath = path.replace("home/myaie/public_html/", "");
    } else {
      filePath = path;
    }
    setAttachmentName(fileName);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Name: programmeName ? programmeName : "",
      IncludeAccountPayer: "",
      Important: "",
      // SendVia: {email: false, push: false},
      SendVia: [{email: false}, {push: false}],
      sentGroups:{
        EmailNotification: false,
        PushNotification: false,
      },
      SendWhen: 1,
      SendWhenDate: "",
      IsDraft: "",
      Title: "",
      Content: "",
      FileAttachment: "",
      isNewFile: false,
    },
    validationSchema: Yup.object().shape({
      // Name: Yup.string().required("Programme Name is required"),
      SendWhen: Yup.string().required("Send When is required"),
      Title: Yup.string().required("Title is required").trim(),
      // SendVia: Yup.array().is: (mobile) => !mobile, then: Yup.boolean().required("Please Select Atleast one Send Via Value"),
      // SendVia: Yup.array().oneOf([true], 'Pleasesss Select Atleast one Send Via Value'),

      // SendVia: Yup.object().shape(
      //   {
      //     email: Yup.bool().when("push", {
      //       is: (push) => !push,
      //       then: Yup.bool().oneOf([true], "At least one needs to be checked")
      //     }),
      //     push: Yup.bool().when("email", {
      //       is: (email) => !email,
      //       then: Yup.bool().oneOf([true], "At least one needs to be checked")
      //     })
      //   },
      //   [
      //     ["email", "push"],
      //     ["push", "email"]
      //   ]
      // ),

      // SendVia: Yup.array().test("aldksjfaldfjsal","Pksese Sebd A cliasd", (item) => {
      //   console.log("firstss", item[0])
      //   return item}),
      // .when("PushNotification", {
      //   is: (mobile) => !mobile,
      //   then: Yup.boolean().required("Please Select Atleast one Send Via Value"),
      // }),
      // PushNotification: Yup.boolean().oneOf([true], 'Please Select Atleast one Send Via Value'),


      // Content: Yup.string().required("Content is required"),
      SendWhen: Yup.number(),
      SendWhenDate: Yup.string()
        // .email()
        .when("SendWhen", {
          is: 2,
          then: Yup.string().required("Date And Time is required"),
        }),
        sentGroups: Yup.object().shape(
          {
            PushNotification: Yup.bool().when("EmailNotification", {
              is: (EmailNotification) => !EmailNotification,
              then: Yup.bool().oneOf([true], "At least one needs to be checked EmailNotification or PushNotification")
            }),
            EmailNotification: Yup.bool().when("PushNotification", {
              is: (PushNotification) => !PushNotification,
              then: Yup.bool().oneOf([true], "At least one needs to be checked EmailNotification or PushNotification")
            })
          },
          [
            ["PushNotification", "EmailNotification"],
            ["EmailNotification", "PushNotification"]
          ]
        )
    }),
    onSubmit: (values, { resetForm }) => {
      let tempFullStartDate;
      if(values.SendWhenDate!="" && values.SendWhenDate!=null){
        tempFullStartDate = new Date(values.SendWhenDate);
      }else{
        tempFullStartDate = new Date();
      }
      // 2022-06-16 10:50
      let tempStart =
        tempFullStartDate.getFullYear() + "-" + (tempFullStartDate.getMonth() + 1) + "-" + tempFullStartDate.getDate() + " " + tempFullStartDate.getHours() + ":" + tempFullStartDate.getMonth();
      // const overViewValue = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
      // console.log("values email", values.EmailNotification);
      // if (!values.EmailNotification) {
      //   console.log("values email");
      //   if (!values.PushNotification) {
      //     console.log("values push");
      //     formik.setErrors({ EmailNotification: "Please Select Atleast one Send Via Value" });
      //     return;
      //   }
      // }
      // return;
      setDisabled(true)
      let formData = new FormData();
      subtype == "editNotification" && formData.append("id", subId);
      formData.append("notification_type", "qualification_notification");
      formData.append("title", values?.Title);
      formData.append("content", editorState);
      formData.append("publish_date", parseInt(tempFullStartDate.getTime().toString()/1000));
      formData.append("student_id", 0);
      formData.append("publish", values?.SendWhen);
      formData.append("is_push_notification", values?.sentGroups.PushNotification ? 1 : 0);
      formData.append("is_email", values?.sentGroups.EmailNotification ? 1 : 0);
      formData.append("is_draft", values.IsDraft);
      formData.append("attachment", values?.FileAttachment);
      formData.append("is_payer", values?.IncludeAccountPayer ? 1 : 0);
      formData.append("is_importance", values?.Important ? 1 : 0);
      formData.append("regarding", "qualification_notifications");
      formData.append("intake", id);
      // if (type == "open") {
      //   UpdateProgramme(formData)
      //     .then((res) => {
      //       Swal.fire({
      //         icon: "success",
      //         title: "Success",
      //         text: "Updated successfully",
      //       });
      //       setDisabled(false);
      //     })
      //     .catch((err) => console.log("error :", err));
      // } else {
      UpdateStudentNotification(formData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Added successfully",
          });
          setDisabled(false);
          history.push(`/courseAdministration/Programmes/programme/open/${id}/notification`);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err.message,
          });
          console.log("error :", err)
          setDisabled(false);
        });
      // }
    },
  });

  const handlePreview = () => {
    setPreviewDisabled(true);
    // const overViewValue = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    const formData = new FormData();
    formData.append("title", formik.values.Title);
    formData.append("content", editorState);
    formData.append("attachment", "formik.values.FileAttachment");
    PreviewStudentNotification(formData)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Preview Send Successfully",
        });
        setPreviewDisabled(false);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data.error.message,
        });
        console.log("error :", err.response.data.error.message);
        setPreviewDisabled(false);
      });
  };

  return (
    <div className="card card-body-inr">
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Name">Programme Name *</label>
            <div className="form-icon-group mb-4" title="Programme Name">
              <input
                type="text"
                className={"form-control" + (formik.errors.Name && formik.touched.Name ? " is-invalid" : "")}
                name="Name"
                id="Name"
                placeholder="Name"
                title="Name"
                value={formik.values.Name}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Title">Title *</label>
            <div className="form-icon-group mb-4" title="Title">
              <input
                type="text"
                className={"form-control" + (formik.errors.Title && formik.touched.Title ? " is-invalid" : "")}
                name="Title"
                id="Title"
                placeholder="Title"
                title="Title"
                value={formik.values.Title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="form-group-blk mb-3">
              <label>&nbsp;</label>
              <div className="custom-check custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="IncludeAccountPayer"
                  name="select"
                  checked={formik.values.IncludeAccountPayer}
                  onChange={(e) => formik.setFieldValue("IncludeAccountPayer", e.target.checked)}
                />
                <label className="custom-control-label" htmlFor="IncludeAccountPayer">
                  Include Account Payer
                </label>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label>&nbsp;</label>
            <div className="form-group-blk mb-3">
              <label></label>
              <div className="custom-check custom-control custom-checkbox custom-control-inline">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="Important"
                  name="select"
                  checked={formik.values.Important}
                  onChange={(e) => formik.setFieldValue("Important", e.target.checked)}
                />
                <label className="custom-control-label" htmlFor="Important">
                  Important
                </label>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label>Send Via *</label>
            <div className="form-group-blk mb-3">
              <label></label>
              <div className="custom-check custom-control custom-checkbox custom-control-inline">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="EmailNotification"
                  name="select"
                  checked={formik.values.sentGroups.EmailNotification}
                  onChange={(e) => formik.setFieldValue("sentGroups.EmailNotification", e.target.checked)}
                //   onChange={async(e) => {
                //     await formik.setFieldValue("SendVia[0].email", e.target.checked)
                //     console.log("SendVia", formik.values.SendVia)
                // }}
                />
                <label className="custom-control-label" htmlFor="EmailNotification">
                  Email
                </label>
              </div>
              <div className="custom-check custom-control custom-checkbox custom-control-inline">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="PushNotification"
                  name="select"
                  checked={formik.values.sentGroups.PushNotification}
                  onChange={(e) => formik.setFieldValue("sentGroups.PushNotification", e.target.checked)}
                //   onChange={async(e) => {
                //     await formik.setFieldValue("SendVia[1].push", e.target.checked)
                //     console.log("SendVia", formik.values.SendVia)
                // }}
                />
                <label className="custom-control-label" htmlFor="PushNotification">
                  Push Notification
                </label>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label title="SendWhen">Send When *</label>
            <div className="form-icon-group" title="SendWhen">
              <div className="">
                <div class="custom-control custom-radio custom-control-inline" title="Now">
                  <input
                    type="radio"
                    id="customRadioInline1"
                    name="customRadioInline"
                    class="custom-control-input"
                    checked={formik.values.SendWhen == 1}
                    onChange={() => formik.setFieldValue("SendWhen", 1)}
                    onBlur={formik.handleBlur}
                  />
                  <label class="custom-control-label" for="customRadioInline1">
                    Now
                  </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline" title="Later">
                  <input
                    type="radio"
                    id="customRadioInline2"
                    name="customRadioInline"
                    class="custom-control-input"
                    title="SendWhen"
                    checked={formik.values.SendWhen == 0}
                    onChange={() => formik.setFieldValue("SendWhen", 0)}
                    onBlur={formik.handleBlur}
                  />
                  <label class="custom-control-label" for="customRadioInline2">
                    Later
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="form-group-blk mb-3">
              <label>Upload Attachment</label>
              <div className="form-group atttach-file m-0">
                <label className={formik.errors.FileAttachment && formik.touched.FileAttachment && "file-req is-invalid"}>
                  <i className="fal fa-paperclip"></i>
                  <span>Add Attachment</span>
                  <input
                    type="file"
                    className="form-control  form-control-aatch-file"
                    onChange={(e) => {
                      if (e.target.files.length) {
                        formik.setFieldValue("FileAttachment", e.target.files[0]);
                        formik.setFieldValue("isNewFile", true);
                        let reader = new FileReader();
                        reader.onload = (e) => {
                          setFile(e.target.result);
                        };
                        reader.readAsDataURL(e.target.files[0]);
                        setAttachmentName(e.target.files[0].name);
                      }
                    }}
                  />
                </label>
              </div>
              {attachmentName && (
                <>
                  <div class="frm-group">
                    <ul class="list-unstyled attached-file-ul m-0">
                      <li>
                        <a href={!formik.values.isNewFile ? IMAGE_URL + "/" + encodeURI(attachmentName.replace("home/myaie/public_html/", "").replace("public/", "")) : tempFile} target="_blank">
                          <i class="fal fa-file"></i>
                          &nbsp;{formik.values.isNewFile ? attachmentName : attachmentName.split("/").reverse()[0]}
                        </a>
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
          {!formik.values.SendWhen == 1 ? (
            <div className="col-md-6 col-lg-4">
              <label>Date *</label>
              <div className="form-group-blk mb-3">
                <DatePicker
                  selected={formik.values.SendWhenDate}
                  onChange={(date) => {
                    formik.setFieldValue("SendWhenDate", date);
                  }}
                  // showTimeSelect
                  filterTime={filterPassedTime}
                  minDate={new Date()}
                  dateFormat={FORM_DATE_FORMAT}
                  // className="form-control"
                  className={"form-control cursor-pointer" + (formik.errors.SendWhenDate && formik.touched.SendWhenDate ? " is-invalid" : "")}
                  title="Date"
                  onBlur={formik.handleBlur}
                  placeholderText="Enter Date"
                  DatePicker
                />
              </div>
              {formik.errors.SendWhenDate && formik.touched.SendWhenDate ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          ) : (
            ""
          )}
          <div className="col-md-12">
            <label>Content</label>
            <div className="form-icon-group mb-4 d-block">
              {(isDataRecieved || subtype == "addNotification") && <HtmlInputEditor editorState={editorState} setEditorState={setEditorState} isInValid={editorState} isCKEditor={true} hideSign={true} />}
            </div>
          </div>
        </div>

        <div className="form-group form-group-save-cancel">
          {subtype !== "editNotification" ? (
            <>
              <button className="btn btn-save btn-success" type="submit" title="Save" disabled={isLoading || disabled} onClick={() => formik.setFieldValue("IsDraft", 0)}>
                {disabled && formik.values.IsDraft == 0 ? <i className="fas fa-cog fa-spin"></i> : <i className="fa fa-paper-plane"></i>}
                Send
              </button>
              <button className="btn btn-save btn-success" type="submit" title="Save As Draft" disabled={isLoading || disabled} onClick={() => formik.setFieldValue("IsDraft", 1)}>
                {disabled && formik.values.IsDraft == 1 ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-plus"></i>}
                Save As Draft
              </button>
              <button className="btn btn-save btn-success" type="button" title="Preview" disabled={isLoading || previewDisabled} onClick={handlePreview}>
                {previewDisabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-file-alt"></i>}
                Preview
              </button>
              <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => history.goBack()}>
                <i className="fal fa-times"></i>
                Cancel
              </button>
            </>
          ) : (
            <>
          <PermissionsGate scopes={["pnedit"]} errorProps={{disabled:true}}>
              <button className="btn btn-save btn-success" type="submit" title="Save" disabled={isLoading || !formik.dirty} onClick={() => formik.setFieldValue("IsDraft", 0)}>
                {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fa fa-paper-plane"></i>}
                Send
              </button>
              </PermissionsGate>
          <PermissionsGate scopes={["pnedit"]} errorProps={{disabled:true}}>
              <button className="btn btn-save btn-success" type="submit" title="Save As Draft" disabled={isLoading || !formik.dirty} onClick={() => formik.setFieldValue("IsDraft", 1)}>
                {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-plus"></i>}
                Save As Draft
              </button></PermissionsGate>
              <button className="btn btn-save btn-success" type="button" title="Preview" disabled={isLoading || !formik.dirty || previewDisabled} onClick={handlePreview}>
                {previewDisabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-file-alt"></i>}
                Preview
              </button>
              <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => history.goBack()}>
                <i className="fal fa-times"></i>
                Cancel
              </button>
            </>
          )}

          {formik.touched.Name && formik.errors.Name ? <div className="invalid-feedback d-block">{formik.errors.Name}</div> : null}
          {formik.touched.SendWhen && formik.errors.SendWhen ? <div className="invalid-feedback d-block">{formik.errors.SendWhen}</div> : null}
          {formik.touched.Title && formik.errors.Title ? <div className="invalid-feedback d-block">{formik.errors.Title}</div> : null}
          {formik.touched.SendWhenDate && formik.errors.SendWhenDate ? <div className="invalid-feedback d-block">{formik.errors.SendWhenDate}</div> : null}
          {formik.touched?.Content && formik.errors?.Content ? <div className="invalid-feedback d-block">{formik.errors?.Content}</div> : null}
          {formik.touched?.sentGroups?.EmailNotification && formik.errors?.sentGroups?.EmailNotification ? <div className="invalid-feedback d-block">{formik.errors?.sentGroups?.EmailNotification}</div> : null}
          {formik.touched.SendVia && formik.errors.SendVia ? <div className="invalid-feedback d-block">{formik.errors.SendVia}</div> : null}
        </div>
      </form>
    </div>
  );
}

export default AddNotification;

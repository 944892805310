import React, { useState, useMemo, useEffect } from 'react';
import { Link, Prompt, useHistory, useParams } from 'react-router-dom';
import { InitialRender } from './Helper';
import Hover from './Hover';
import { IMAGE_URL } from '../../utils/Constants';
import DataTableComponentFrontPagination from './DataTableComponentFrontPagination';
import { useFormik } from 'formik';
import Select from "react-select"
import { addStudentEvidence, confrimedPSQuestion, getResultDropDownList, markPracticalAssessmentQuestion, updateAbsentMarking, updateLecturerObservation } from '../../services/CourseService';
import * as Yup from 'yup';
import CriteriaSubmission from '../courses/DetailCoursesBlocks/AssessmentBlocks/CriteriaSubmission';
import Swal from 'sweetalert2';
import { RenderSubmissionAndResultStatus } from '../../utils/CommonStatusItems';

const PracticalStudentList = ({ quizQuestion, studentList, totalRows, loading, setLoading, fetchQuizQuestion, nextPrevious, fetchQuizQuestionDetails , setStudentLoading, prevIdRef}) => {
    const { openTabs, subId, subType, subSubId } = useParams();
    const history = useHistory();

    const [searchStudentList, setSearchStudentList] = useState("");
    const [uploadedFiles, setUploadedFiles] = useState({});
    // const [templatePassMarkArr, setTemplatePassMarkArr] = useState([]);
    const [studentDetailList, setStudentDetailList] = useState(null);
    // const [errorMessage, setErrorMessage] = useState("")
    // const [confirm, setConfirm] = useState({});
    const [absent, setAbsent] = useState({});
    const [updateValueArr, setUpdateValueArr] = useState([]);
    const [studentErrors, setStudentErrors] = useState({});
    const [studentData, setStudentData] = useState([]);
    // const [subsubId, setSubsubId] = useState();
    const [qId, setQId] = useState();

    
    const handleResultSubmit = (data , render=true) => {

        markPracticalAssessmentQuestion(data)
        .then(res => {
            if(render){
                fetchQuizQuestion();
                fetchQuizQuestionDetails();
            }

            if(render){
                Swal.fire({
                    title: "Success",
                    text: "Result updated successfully",
                    icon: "success",
                    confirmButtonText: "OK"
                });
                setUpdateValueArr([]);
            } else {
                Swal.hideLoading();
                Swal.fire({
                    title: "Success",
                    text: "Result updated successfully",
                    icon: "success",
                    confirmButtonText: "OK"
                });
                setUpdateValueArr([]);
            }
        })
        .catch(err => {
            console.error("Error sending data:", err);
            if(render){
                Swal.fire({
                    title: "Error",
                    text: "Something went wrong",
                    icon: "error",
                    confirmButtonText: "OK"
                });
            } else {
                Swal.hideLoading();
            }
        }).finally(() => {
            setLoading(false);
            setStudentLoading(false);
        })
    }

    const formik = useFormik({
        enableReinitialize: true, 
        initialValues: studentList?.reduce((acc, student) => {
            acc[student.student_id] = {
                marked: student.question_id === parseInt(subSubId) ? null : undefined,
            };
            return acc;
        }, {}),
        // validate: (values) => {
        //     const errors = {};
            
        //     studentList.forEach(student => {
        //         const marked = values[student.student_id]?.marked;
        //         if (student.question_id === parseInt(subSubId) && marked !== null && marked !== undefined) {
        //             if (Number(marked) > quizQuestion?.criteria_score) {
        //                 if (!errors[student.student_id]) {
        //                     errors[student.student_id] = {};
        //                 }
        //                 errors[student.student_id].marked = 
        //                     `Sorry, you can't put a mark more than ${quizQuestion?.criteria_score} for ${student.full_name}`;
        //             }
        //         }
        //     });
            
        //     return errors;
        // },
        onSubmit: (values) => {
            setLoading(true);

            const grademix = studentList.map(student => ({
           student_id: student.student_id,
           grade: student.question_id === parseInt(subSubId) ? 
               (values[student.student_id]?.marked === "" || values[student.student_id]?.marked === null ? 
                   null : Number(values[student.student_id]?.marked)) : null,
           submission_id: student.submission_id
       }));

            const data = {
                "assessment_id": parseInt(subId),
                "question_id": parseInt(subSubId),
                "grademix": grademix
            };

            handleResultSubmit(data);
        },
    });

    const handleToggleLecturerObservation = (e, row) => {
        const newValue = e.target.checked ? 1 : 0;
        formik.setFieldValue(row?.student_id, {
            ...formik.values[row?.student_id],
            lecturer: newValue,
        });

        const data = {
            practical_submission_question_id: row?.practical_submission_question_id ? row?.practical_submission_question_id : 0,
            lecturer_observation_toggle: newValue,
            submission_id: row?.submission_id,
            question_id: parseInt(subSubId)
        }

        const formData = new FormData();

        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });

        updateLecturerObservation(formData)
            .then(res => {
                Swal.fire({
                    title: "Success",
                    text: "Lecturer Observation updated successfully",
                    icon: "success",
                    confirmButtonText: "OK"
                });
                fetchQuizQuestion();
            })
            .catch(err => {
                console.error(err);
            })
    }

    const formatTime = (seconds) => {
        const date = new Date(seconds * 1000);
        const hours = date.getHours();
        const minutes = date.getMinutes();

        const formattedHours = hours % 12 || 12;
        return `${formattedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    };

    const formatDate = (seconds) => {
        const date = new Date(seconds * 1000);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const formattedDate = `${String(date.getDate()).padStart(2, '0')} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;

        return formattedDate;
    };

    const renderEvidences = (data, lecturer, row) => {
        return (
            <div className='evidence__box'>
                {/* Evidence Photo */}
                {quizQuestion?.evidence_photo == 1 && <span className='evidence__item' title='Photo'>
                    {data?.photo?.length > 0 ? (
                        <i className="fal fa-check-circle" style={{ fontSize: 18 }} />
                    ) : (
                        <i className="fal fa-times-circle" style={{ fontSize: 18 }} />
                    )}
                    <i className="fal fa-camera evidence" />
                    <span style={{ fontSize: 16 }}>
                        Photo
                    </span>
                </span>}

                {/* Evidence Video */}
                {quizQuestion?.evidence_video == 1 && <span className='evidence__item' title='Video'>
                    {data?.video?.length > 0 ? (
                        <i className="fal fa-check-circle" style={{ fontSize: 18 }} />
                    ) : (
                        <i className="fal fa-times-circle" style={{ fontSize: 18 }} />
                    )}
                    <i className="fal fa-video evidence" />
                    <span style={{ fontSize: 16 }}>
                        Video
                    </span>
                </span>}

                {/* Evidence Document */}
                {quizQuestion?.evidence_document == 1 && <span className='evidence__item' title='Document'>
                    {data?.document?.length > 0 ? (
                        <i className="fal fa-check-circle" style={{ fontSize: 18 }} />
                    ) : (
                        <i className="fal fa-times-circle" style={{ fontSize: 18 }} />
                    )}
                    <i className="fal fa-file-alt evidence" />
                    <span style={{ fontSize: 16 }}>
                        Document
                    </span>
                </span>}

                {/* Evidence Lecturer Observation */}
                {quizQuestion?.evidence_lecturer_observation == 1 && <div className='my-2'>
                    <span className='evidence__item' title='Lecturer Observation'>
                        {lecturer == 1 ? (
                            <i className="fal fa-check-circle" style={{ fontSize: 18 }} />
                        ) : (
                            <i className="fal fa-times-circle" style={{ fontSize: 18 }} />
                        )}
                        <i className="fal fa-user-check evidence" />
                        <span style={{ fontSize: 16 }}>
                            Lecturer Observation
                        </span>
                    </span>

                    {
                        lecturer == 1 && row?.observation_date != 0 && row?.observation_date != null ? <p style={{ marginTop: 15 }} title={`Confirmed on ${row?.observation_date != 0 && row?.observation_date != null ? formatDate(row.observation_date) : ""} at ${row?.observation_date != 0 && row?.observation_date != null ? formatTime(row.observation_date) : ""} by ${row?.observationBy?.fullname}`}><i className="fal fa-history" style={{ color: "var(--topbar-color)" }}></i> {`Confirmed on ${row?.observation_date != 0 && row?.observation_date != null ? formatDate(row.observation_date) : ""} at ${row?.observation_date != 0 && row?.observation_date != null ? formatTime(row.observation_date) : ""} by ${row?.observationBy ? row?.observationBy?.fullname : ""}`}</p> : null
                    }
                </div>
                }
            </div>
        );
    };

    const handleResult = (row) => {
        const options = [
            { value: quizQuestion?.criteria_score, label: "Competent" },
            { value: 0, label: "Not Yet Competent" },
        ];

        if (row?.is_marked == 1 || row?.marked_absent) {
            // let marked;
            // if(row?.is_marked == 1){
            //     marked = options?.find(option => option.value == row?.marked)?.label;
            // }
            return <div>
                {RenderSubmissionAndResultStatus(row?.marked_absent ? "INCOMPLETE" : row?.marked >= 1 ? "Competent" : "Not Yet Competent", "programme")?.html}
            </div>
        } else {
            return (
                <div className="form-group-blk" style={{ width: "200px" }}>
                    {/* <PermissionsGate scopes={["overidersmart"]}  errorProps={{ isDisabled: true }}> */}
                    <Select
                        className="form-control custom-select-box"
                        options={options}
                        onChange={(value) => {
                            formik.setFieldValue(row.student_id, {
                                ...formik.values[row.student_id],
                                marked: value ? value.value : null
                            });
                            setUpdateValueArr(prev => [...prev, row.student_id]);
                        }}
                        value={row?.question_id === parseInt(subSubId) && options.find(option => option.value === formik.values[row.student_id]?.marked) || null}
                        maxMenuHeight={108}
                        isClearable
                    />
                    {/* </PermissionsGate> */}
                </div>
            )
        }
    }

    const studentColumns = useMemo(() => {
        const columns = [
            {
                name: "Student",
                selector: "full_name",
                sortField: "full_name",
                sortable: true,
                cell: (row) => (
                    <div className="assigned-title-block-new">
                        <div className="assigned-title-itm">
                            <span className="assigned-title-blk name-icon cat-dark-red">
                                {row?.photo ? (
                                    <img
                                        src={`${IMAGE_URL}/${row?.picture_me.replace(/\/home\/myaie\/public_html\//, "").replace("public/", "")}`}
                                        alt="AIE"
                                    />
                                ) : (
                                    InitialRender([row.first_name, row.last_name])
                                )}
                                <span className={`profile-box-2-status ${row.activity_status?.toLowerCase() || "offline"}`}>
                                    <i className="fas fa-circle"></i>
                                </span>
                                <Hover
                                    firstName={row.first_name}
                                    lastName={row.last_name}
                                    photo={row.picture_me}
                                    email={row.email}
                                    mobile={row.mobile}
                                    right={true}
                                    activity_status={row.activity_status}
                                    showNumber={true}
                                    number={row.student_crm_id}
                                />
                            </span>
                            <Link
                                to={`/studentAdministration/students/open/${row.student_id}/general`}
                                target="_blank"
                                className="as-text-blue curser feature-name width-155"
                                title={row.full_name}
                            >
                                <span className="textLimit100">{row.full_name}</span>
                            </Link>
                        </div>
                    </div>
                ),
            },
            {
                name: "Photo",
                selector: "Photo",
                sortable: false,
                cell: (row) => {
                    const isLoadingPhoto = studentErrors[row.student_id]?.[subSubId]?.photo;
                    return (
                        <div>
                            <div className="mb-1 evidence__box">
                                <span className='evidence__item assessment_student_list_data' title='Criteria Captured'>
                                    <i className={`fal fa-${uploadedFiles[row.student_id]?.photo?.length > 0 ? "check-circle" : "times-circle"} ${uploadedFiles[row.student_id]?.photo?.length > 0 ? "text-success" : "text-danger"} mr-0`}></i> <span className='m-0'>Criteria Captured</span>
                                </span>
                            </div>
                            <p className='my-1 mb-2' style={{ fontFamily: "Segoe UI", fontSize: "13px", fontWeight: "600", lineHeight: "10px", color: uploadedFiles[row.student_id]?.photo?.length > 0 ? "#467FCF" : "#C2C2C2" }} title={`${uploadedFiles[row.student_id]?.photo?.length || 0} files uploaded`}>
                                {uploadedFiles[row.student_id]?.photo?.length || 0} files uploaded
                            </p>
                            <div className={`form-group atttach-file practical_attach_file mr-1`}>
                                <label title="Upload Photo">
                                    {isLoadingPhoto ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-paperclip"></i>}
                                    <span>Upload Photo</span>
                                    <input
                                        type="file"
                                        id="photos"
                                        className="form-control form-control-aatch-file"
                                        name="photos"
                                        accept="image/*"
                                        multiple
                                        disabled={isLoadingPhoto}
                                        onChange={(event) => handleUpload(event, 'photo', row.student_id)}
                                    />
                                </label>
                            </div>
                        </div>
                    )
                },
            },
            {
                name: "Video",
                selector: "Video",
                sortable: false,
                cell: (row) => {
                    const isLoadingVideo = studentErrors[row.student_id]?.[subSubId]?.video;
                    return (
                        <div>
                            <div className="mb-1 evidence__box">
                                <span className='evidence__item assessment_student_list_data' title='Criteria Captured'>
                                <i className={`fal fa-${uploadedFiles[row.student_id]?.video?.length > 0 ? "check-circle" : "times-circle"} ${uploadedFiles[row.student_id]?.video?.length > 0 ? "text-success" : "text-danger"} mr-0`}></i> <span className='m-0'>Criteria Captured</span>
                            </span>
                        </div>
                        <p className='my-1 mb-2' style={{ fontFamily: "Segoe UI", fontSize: "13px", fontWeight: "600", lineHeight: "10px", color: uploadedFiles[row.student_id]?.video?.length > 0 ? "#467FCF" : "#C2C2C2" }} title={`${uploadedFiles[row.student_id]?.video?.length || 0} files uploaded`}>{uploadedFiles[row.student_id]?.video?.length || 0} files uploaded</p>
                        <div className={`form-group atttach-file practical_attach_file mr-1`}>
                            <label title="Upload Video">
                                {isLoadingVideo ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-paperclip"></i>}
                                <span>Upload Video</span>
                                <input
                                    type="file"
                                    id="videos"
                                    className="form-control form-control-aatch-file"
                                    name="videos"
                                    accept="video/*"
                                    multiple
                                    disabled={isLoadingVideo}
                                    onChange={(event) => {
                                        handleUpload(event, 'video', row.student_id);
                                    }}
                                />
                                </label>
                            </div>
                        </div>
                    )
                },
            },
            {
                name: "Document",
                selector: "Document",
                sortable: false,
                cell: (row) => {
                    const isLoadingDocument = studentErrors[row.student_id]?.[subSubId]?.document;
                    return (
                    <div>
                        <div className="mb-1 evidence__box">
                            <span className='evidence__item assessment_student_list_data' title='Criteria Captured'>
                                <i className={`fal fa-${uploadedFiles[row.student_id]?.document?.length > 0 ? "check-circle" : "times-circle"} ${uploadedFiles[row.student_id]?.document?.length > 0 ? "text-success" : "text-danger"} mr-0`}></i> <span className='m-0'>Criteria Captured</span>
                            </span>
                        </div>
                        <p className='my-1 mb-2' style={{ fontFamily: "Segoe UI", fontSize: "13px", fontWeight: "600", lineHeight: "10px", color: uploadedFiles[row.student_id]?.document?.length > 0 ? "#467FCF" : "#C2C2C2" }} title={`${uploadedFiles[row.student_id]?.document?.length || 0} files uploaded`}>{uploadedFiles[row.student_id]?.document?.length || 0} files uploaded</p>
                        <div className={`form-group atttach-file practical_attach_file mr-1`}>
                            <label title="Upload Document">
                                {isLoadingDocument ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-paperclip"></i>}
                                <span>Upload Document</span>
                                <input
                                    type="file"
                                    id="documents"
                                    className="form-control form-control-aatch-file"
                                    name="documents"
                                    accept=".pdf,.doc,.docx,.txt,.zip,.otf,.xls,.xlsx,.csv,.ppt,.pptx,.rtf,.odt,.ods,.odp"
                                    multiple
                                    disabled={isLoadingDocument}
                                    onChange={(event) => {
                                        handleUpload(event, 'document', row.student_id);
                                    }}
                                />
                            </label>
                        </div>
                    </div>
                )},
            },
            {
                name: "Lecturer Observation",
                selector: "Lecturer Observation",
                sortable: false,
                cell: (row) => (
                    <>
                        <div className="d-flex" title="Lecturer Observation">
                            <label className="mb-0">Lecturer Observation</label>
                            <div className="toggle-switch practical_toggle_switch ml-2">
                                <label className="switch">
                                    <input
                                        name="lecturer"
                                        id="lecturer"
                                        type="checkbox"
                                        // disabled={row?.marked_absent}
                                        checked={formik.values[row.student_id]?.lecturer == 1}
                                        onChange={(e) => handleToggleLecturerObservation(e, row)}
                                    />
                                    <span className="slider slider-round"></span>
                                </label>
                            </div>
                        </div>
                        {formik.values[row.student_id]?.lecturer == 1 && row?.observation_date != 0 && row?.observation_date != null ? <p style={{ marginTop: 15 }} title={`Confirmed on ${row?.observation_date != 0 && row?.observation_date != null ? formatDate(row.observation_date) : ""} at ${row?.observation_date != 0 && row?.observation_date != null ? formatTime(row.observation_date) : ""} by Portal Developers`}>
                            <i className="fal fa-history" style={{ color: "var(--topbar-color)" }}></i> {`Confirmed on ${row?.observation_date != 0 && row?.observation_date != null ? formatDate(row.observation_date) : ""} at ${row?.observation_date != 0 && row?.observation_date != null ? formatTime(row.observation_date) : ""} by ${row?.observationBy ? row?.observationBy?.fullname : ""}`}
                        </p> : null}
                    </>
                ),
            },
            {
                name: "Result",
                selector: "Result",
                sortable: false,
                style: {
                    borderLeft: "1px solid #EF8700 !important",
                    paddingLeft: "25px !important",
                },
                cell: (row) => handleResult(row)
            },
            {
                name: "Actions",
                cell: (row) => {
                    const uploadFiles = uploadedFiles[row.student_id];
                    const isUploaded = (quizQuestion?.evidence_document == 1 && quizQuestion?.evidence_photo == 1 && quizQuestion?.evidence_video == 1) ? (uploadFiles?.video?.length > 0 && uploadFiles?.photo?.length > 0 && uploadFiles?.document?.length > 0) : (quizQuestion?.evidence_photo == 1 && quizQuestion?.evidence_video == 1) ? (uploadFiles?.video?.length > 0 && uploadFiles?.photo?.length > 0) : (quizQuestion?.evidence_video == 1) ? (uploadFiles?.video?.length > 0) : (quizQuestion?.evidence_photo == 1) ? (uploadFiles?.photo?.length > 0) : (quizQuestion?.evidence_document == 1) ? (uploadFiles?.document?.length > 0) : null;



                    const lecturer = formik.values[row.student_id];
                    const isConfirmed = lecturer?.lecturer == 1;

                    return (
                        <div className="assessment-08 btn-dropdown-grp">
                            <div className="as-buttons d-flex">
                                {/* <button
                                    className={`btn btn-success rounded-circle ${confirm[row.student_id]?.confirm == 0 || confirm[row.student_id]?.confirm == null ? "" : "btn-save"}`}
                                    disabled={(quizQuestion?.evidence_lecturer_observation && !isConfirmed || !isUploaded)}
                                    title={`${confirm[row.student_id]?.confirm == 0 || confirm[row.student_id]?.confirm == null ? "Confirmed" : "Edit"}`}
                                    onClick={confirm[row.student_id]?.confirm == 0 || confirm[row.student_id]?.confirm == null ? () => handleConfirm(row) : null}
                                >
                                    <i className={`fal ${confirm[row.student_id]?.confirm == 0 || confirm[row.student_id]?.confirm == null ? "fa-check-circle" : "fa-pen"}`}></i>
                                </button> */}
                                <button
                                    className={`btn ${absent[row.student_id]?.absent == 0 || absent[row.student_id]?.absent == null ? "btn-danger" : "btn-success"} rounded-circle`}
                                    // disabled={absent[row.student_id]?.absent == 0 || absent[row.student_id]?.confirm == null}
                                    // style={{ cursor: absent[row.student_id]?.absent == 0 || absent[row.student_id]?.confirm == null ? "not-allowed" : "pointer" }}
                                    title={`${absent[row.student_id]?.absent == 0 || absent[row.student_id]?.absent == null ? "Absent" : "Present"}`}
                                    onClick={() => handleAbsent(row, absent[row.student_id]?.absent)}
                                >
                                    <i className="fal fa-calendar-times"></i>
                                </button>
                                <button
                                    className="btn btn-save btn-success rounded-circle"
                                    title="Open"
                                    data-toggle="modal"
                                    data-target="#criteriaSubmission"
                                    onClick={() => setStudentDetailList(row?.student_id)}
                                    // disabled={row?.marked_absent}
                                >
                                    <i className="fal fa-clipboard-list"></i>
                                </button>
                            </div>
                        </div>
                    )
                },
            },
        ];

        const filteredColumns = columns.filter(column => {
            if (column.name === "Photo" && quizQuestion?.evidence_photo === 0) return false;
            if (column.name === "Video" && quizQuestion?.evidence_video === 0) return false;
            if (column.name === "Document" && quizQuestion?.evidence_document === 0) return false;
            if (column.name === "Lecturer Observation" && quizQuestion?.evidence_lecturer_observation === 0) return false;
            return true;
        });

        return filteredColumns;
    });

    // const handleConfirm = (row) => {
    //     const data = {
    //         isConfirmed: 1,
    //         submission_id: row.submission_id,
    //         question_id: parseInt(subSubId),
    //     }

    //     confrimedPSQuestion(data)
    //         .then(res => {
    //             Swal.fire({
    //                 title: "Success",
    //                 text: "Confirmed successfully",
    //                 icon: "success",
    //                 confirmButtonText: "OK"
    //             });
    //             setConfirm(prevState => ({ ...prevState, [row.student_id]: { confirm: 1 } }));
    //         })
    //         .catch(err => {
    //             console.error(err);
    //             Swal.fire({
    //                 title: "Error",
    //                 text: "Something went wrong",
    //                 icon: "error",
    //                 confirmButtonText: "OK"
    //             });
    //         })
    // }

    const reviewStudentColumns = useMemo(() => [
        {
            name: "Student",
            selector: "full_name",
            sortField: "full_name",
            sortable: true,
            cell: (row) => (
                <div className="assigned-title-block-new">
                    <div className="assigned-title-itm">
                        <span className="assigned-title-blk name-icon cat-dark-red">
                            {row?.photo ? (
                                <img
                                    src={`${IMAGE_URL}/${row?.picture_me.replace(/\/home\/myaie\/public_html\//, "").replace("public/", "")}`}
                                    alt="AIE"
                                />
                            ) : (
                                InitialRender([row.first_name, row.last_name])
                            )}
                            <span className={`profile-box-2-status ${row.activity_status?.toLowerCase() || "offline"}`}>
                                <i className="fas fa-circle"></i>
                            </span>
                            <Hover
                                firstName={row.first_name}
                                lastName={row.last_name}
                                photo={row.picture_me}
                                email={row.email}
                                mobile={row.mobile}
                                right={true}
                                activity_status={row.activity_status}
                                showNumber={true}
                                number={row.student_crm_id}
                            />
                        </span>
                        <Link
                            to={`/studentAdministration/students/open/${row.student_id}/general`}
                            target="_blank"
                            className="as-text-blue curser feature-name width-155"
                            title={row.full_name}
                        >
                            <span className="textLimit100">{row.full_name}</span>
                        </Link>
                    </div>
                </div>
            ),
        },
        {
            name: "Evidence",
            selector: "Evidence",
            sortable: false,
            cell: (row) => {
                const evidenceData = uploadedFiles[row.student_id];
                return evidenceData && Object.keys(evidenceData).length > 0
                    ? renderEvidences(evidenceData, formik.values[row.student_id]?.lecturer, row)
                    : 'N/A';
            }
        },
        {
            name: "Result",
            selector: "Result",
            sortable: false,
            cell: (row) => handleResult(row)
        },
        {
            name: "Actions",
            cell: (row) => {
                return (
                    <div className="assessment-08 btn-dropdown-grp">
                        <div className="as-buttons d-flex">
                            {/* <button
                                className={`btn btn-success rounded-circle ${row.isConfirmed == 0 ? "" : "btn-save"}`}
                                title="Confirmed"
                                onClick={row.isConfirmed == 0 ? () => handleConfirm(row) : null}
                            >
                                <i className={`fal ${row.isConfirmed == 0 ? "fa-check-circle" : "fa-pen"}`}></i>
                            </button> */}
                            <button
                                className="btn btn-save btn-success rounded-circle"
                                title="Open"
                                data-toggle="modal"
                                data-target="#criteriaSubmission"
                                onClick={() => setStudentDetailList(row.student_id)}
                                // disabled={row?.marked_absent}
                            >
                                <i className="fal fa-clipboard-list"></i>
                            </button>
                        </div>
                    </div>
                )
            },
        },
    ]);

    const shouldBlockNavigation = updateValueArr.length;

    useEffect(() => {
      const handleBeforeUnload = (event) => {
        if (shouldBlockNavigation) {
          event.preventDefault();
        }
      };
  
      window.addEventListener("beforeunload", handleBeforeUnload);
  
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }, [shouldBlockNavigation]);
  
    const handleNavigation = (location) => {
      if (shouldBlockNavigation) {
        if (Swal.isVisible()) {
          return false;
        }
        Swal.fire({
          title: "Warning",
          text: "You Have Unsaved Changes Left! Do you want to save changes",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "No, Leave",
          confirmButtonText: "Yes, Save Changes",
        }).then((value) => {
          if (value.isConfirmed) {
            Swal.fire({
              title: "Please wait",
              text: "Saving changes...",
              allowOutsideClick: false,
              onBeforeOpen: () => {
                Swal.showLoading();

                const grademix = studentList.map(student => ({
                    student_id: student.student_id,
                    grade: (qId == prevIdRef) ? 
                        (formik.values[student.student_id]?.marked === "" || formik.values[student.student_id]?.marked === null ? 
                            null : Number(formik.values[student.student_id]?.marked)) : null,
                    submission_id: student.submission_id
                }));
        
                const data = {
                    "assessment_id": parseInt(subId),
                    "question_id": prevIdRef,
                    "grademix": grademix
                };
                handleResultSubmit(data , false);
                setUpdateValueArr([]);
                if (location) {
                    history.push(location.pathname)
                }           
              },
            });
          } else {
            setUpdateValueArr([]);
            if (location) {
              history.push(location.pathname)
            }
          }
        });
  
        return false;
      }
    };

    const handleAbsent = (row, absent) => {
        const data = {
            marked_absent: absent == 0 ? 1 : 0,
            submission_id: row?.submission_id,
            question_id: parseInt(subSubId),
        }

        updateAbsentMarking(data)
            .then(res => {
                Swal.fire({
                    title: "Success",
                    text: absent == 0 ? "Absent marked successfully" : "Present marked successfully",
                    icon: "success",
                    confirmButtonText: "OK"
                });
                setAbsent(prevState => ({ ...prevState, [row.student_id]: { absent: absent == 0 ? 1 : 0 } }));
                fetchQuizQuestion();
                fetchQuizQuestionDetails();
            })
            .catch(err => {
                console.error(err);
                Swal.fire({
                    title: "Error",
                    text: "Something went wrong",
                    icon: "error",
                    confirmButtonText: "OK"
                });
            })
    }

    const handleUpload = (event, type, studentId) => {
        const files = Array.from(event.target.files);

        const fileType = type === 'photo' ? 'image' : 
                         type === 'video' ? 'video' : 
                         type === 'document' ? ['.pdf', '.doc', '.docx', '.txt', '.zip', '.otf', '.xls', '.xlsx', '.csv', '.ppt', '.pptx', '.rtf', '.odt', '.ods', '.odp'] : null;

        const filteredFiles = type === 'document' 
            ? files.filter(file => fileType.some(ext => file.name.endsWith(ext)))
            : files.filter(file => file.type.startsWith(fileType));

        formik.setFieldValue(studentId, {
            ...formik.values[studentId],
            [type]: [...(formik.values[studentId]?.[type] || []), ...filteredFiles]
        });

        const data = {
            assessment_id: parseInt(subId),
            student_id: studentId,
            question_id: parseInt(subSubId),
        }

        const formData = new FormData();

        filteredFiles.forEach(file => {
            formData.append(`${type}[]`, file);
        });

        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });

        setStudentErrors(prev => ({
            ...prev,
            [studentId]: {
                ...prev[studentId],
                [subSubId]: { ...prev[studentId]?.[subSubId], [type]: true }
            }
        }));

        addStudentEvidence(formData)
            .then(res => {
                setUploadedFiles(prevState => ({
                    ...prevState,
                    [studentId]: {
                        ...prevState[studentId],
                        [type]: [...(prevState[studentId]?.[type] || []), ...filteredFiles]
                    }
                }));

                Swal.fire({
                    title: "Success",
                    text: `${type.charAt(0).toUpperCase() + type.slice(1)} Uploaded Successfully`,
                    icon: "success",
                    confirmButtonText: "OK"
                });
            })
            .catch(err => {
                console.error(err);
                Swal.fire({
                    title: "Error",
                    text: `${type.charAt(0).toUpperCase() + type.slice(1)} Upload Failed`,
                    icon: "error",
                    confirmButtonText: "OK"
                });
            })
            .finally(() => {
                setStudentErrors(prev => ({
                    ...prev,
                    [studentId]: {
                        ...prev[studentId],
                        [subSubId]: { ...prev[studentId]?.[subSubId], [type]: false }
                    }
                }));
            });
    };

    const dataToRenderStudentList = () => {
        if (!searchStudentList.length) return studentList;

        const filteredStudents = studentList.filter(item => {
            const fullNameMatch = item.full_name?.toLowerCase().includes(searchStudentList.toLowerCase());
            const studentIdMatch = item.student_crm_id?.toString().toLowerCase().includes(searchStudentList.toLowerCase());
            return fullNameMatch || studentIdMatch;
        });

        return [...new Set(filteredStudents)];
    };

    const exportPracticalData = () => {
        let data = studentList;
        let header = ["Student"];
        let pdfData;

        if (quizQuestion?.evidence_photo === 1) {
            header.push("Photo");
        }
        if (quizQuestion?.evidence_video === 1) {
            header.push("Video");
        }
        if (quizQuestion?.evidence_document === 1) {
            header.push("Document");
        }
        if (quizQuestion?.evidence_lecturer_observation === 1) {
            header.push("Lecturer Observation");
        }

        header.push("Result");

        data = data?.map((row) => {
            const rowData = {
                Student: row?.full_name,
                Result: `${row?.marked} / ${quizQuestion?.criteria_score}`
            };

            if (quizQuestion?.evidence_photo === 1) {
                rowData.Photo = uploadedFiles[row.student_id]?.photo?.length;
            }
            if (quizQuestion?.evidence_video === 1) {
                rowData.Video = uploadedFiles[row.student_id]?.video?.length;
            }
            if (quizQuestion?.evidence_document === 1) {
                rowData.Document = uploadedFiles[row.student_id]?.document?.length;
            }
            if (quizQuestion?.evidence_lecturer_observation === 1) {
                rowData["Lecturer Observation"] = row?.lecturer_observation_toggle == 1 ? "Yes" : "No";
            }

            return rowData;
        });

        pdfData = data.map((row) => {
            return header.map(col => row[col]);
        });

        return {
            data,
            header,
            pdfData
        }
    };

    const exportReviewData = () => {
        let data = studentList;
        let header = ["Student", "Evidence" , "Result"];
        let pdfData;

        data = data?.map((row) => {
            const rowData = {
                Student: row?.full_name,
                Result: `${row?.marked} / ${quizQuestion?.criteria_score}`
            };

            const evidenceData = uploadedFiles[row.student_id];

            if (evidenceData && Object.keys(evidenceData).length > 0) {
                const evidenceArray = [];

                if (quizQuestion?.evidence_photo === 1) {
                    evidenceArray.push(`Photo: ${uploadedFiles[row.student_id]?.photo?.length || 0}`);
                }
                if (quizQuestion?.evidence_video === 1) {
                    evidenceArray.push(`Video: ${uploadedFiles[row.student_id]?.video?.length || 0}`);
                }
                if (quizQuestion?.evidence_document === 1) {
                    evidenceArray.push(`Document: ${uploadedFiles[row.student_id]?.document?.length || 0}`);
                }
                if (quizQuestion?.evidence_lecturer_observation === 1) {
                    evidenceArray.push(`Lecturer Observation: ${row?.lecturer_observation_toggle == 1 ? "Yes" : "No"}`);
                }

                rowData.Evidence = evidenceArray.join(', ');
            }

            return rowData;
        });

        pdfData = data.map((row) => {
            return header.map(col => row[col]);
        });

        return {
            data,
            header,
            pdfData
        }
    };

    const studentDataRender = () =>{
        studentList.map(item => {
            setStudentData(item)
            const lecturer_observation_toggle = item.lecturer_observation_toggle;
            const percentage = item.question_id == parseInt(subSubId) && item?.is_marked == 1 ? parseInt(item?.marked) : "";

            const videoFiles = [];
            const photoFiles = [];
            const documentFiles = [];

            item.studentFiles.map(file => {
                if (file.type == 0) {
                    photoFiles.push(file);
                } else if (file.type == 1) {
                    videoFiles.push(file);
                } else if (file.type == 2) {
                    documentFiles.push(file);
                }
            })

            setUploadedFiles(prevState => ({
                ...prevState,
                [item.student_id]: {
                    video: videoFiles,
                    photo: photoFiles,
                    document: documentFiles,
                }
            }));

            // setConfirm(prevState => ({ ...prevState, [item.student_id]: { confirm: item?.isConfirmed } }));
            setAbsent(prevState => ({ ...prevState, [item.student_id]: { absent: item?.marked_absent == 0 || item?.marked_absent == null ? 0 : 1 } }));

            formik.setFieldValue(item.student_id, {
                ...formik.values[item.student_id],
                lecturer: lecturer_observation_toggle == 1 ? 1 : 0,
                video: videoFiles,
                photo: photoFiles,
                document: documentFiles,
                marked: percentage
            });
        })
    }

    const handleReset = () => {
        formik.setErrors({});
        formik.resetForm();
        setTimeout(() => {
            studentDataRender();
        }, 0);
    }

    useEffect(() => {
        if(updateValueArr?.length == 0){
            setStudentLoading(false);
        }
    }, [updateValueArr , subSubId])

    useEffect(() => {
        studentDataRender();
    }, [studentList])

    useEffect(() => {
        setQId(studentData?.question_id)
    }, [studentData])
    return (
        <>
            <Prompt when={!!shouldBlockNavigation} message={handleNavigation} />

            {subType == "questionDetails" && (
                <div className="card-new card card-profile-info-card mb-30">
                    <div className={`${openTabs == "assessmentMarkingReview" ? "assessment_student_list_review" : "assessment_student_list"}`}>
                        <div className="card-header" title="Student List">Student List</div>
                        <DataTableComponentFrontPagination
                            isInsidePopUp
                            data={dataToRenderStudentList()}
                            columns={openTabs == "assessmentMarkingReview" ? reviewStudentColumns : studentColumns}
                            loading={loading}
                            totalRows={totalRows}
                            search={searchStudentList}
                            defaultSortField="full_name"
                            defaultSortAsc={true}
                            setSearch={setSearchStudentList}
                            exportData={openTabs == "assessmentMarkingReview" ? exportReviewData : exportPracticalData}
                            exportFileName="Student List"
                        />
                    </div>

                    <div className="form-group form-group-save-cancel mt-4">
                        {/* <PermissionsGate RenderError={() => (
                            <button className="btn btn-save btn-success" disabled>
                                <i className="fal fa-save"></i> Save
                            </button>
                        )} scopes={[type == "create" ? 'wbsemailadd' : 'wbsemailedit']}> */}

                        <button
                            className="btn btn-save btn-success"
                            type="submit"
                            title="Save"
                            disabled={loading || !formik.dirty}
                            onClick={formik.handleSubmit}
                        >
                            {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                            Save
                        </button>
                        {/* </PermissionsGate> */}

                        <button className="btn btn-close btn-danger" type="reset" title="Cancel" onClick={() => handleReset() } >
                            <i className="fal fa-times"></i>
                            Cancel
                        </button>
                    </div>

                    {studentList.map(student => (
                        formik.errors[student.student_id]?.marked && (
                            <div key={student.student_id} className="invalid-feedback d-block">
                                {`${formik.errors[student.student_id].marked}`}
                            </div>
                        )
                    ))}
                </div>
            )}

            <div className="topic-add-modal modal fade able_modal01" id="criteriaSubmission" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered available-assessment-modal" role="document">

                    <div className="modal-content">
                        {/* <div className="modal-body"> */}
                        <div className="modal-header modal-header-custom">
                            <h5 className="modal-title" title="Criteria Evidence"><i className="fal fa-clipboard-list-check"></i> Criteria Evidence</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" title="Close"><span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <CriteriaSubmission studentDetailList={studentDetailList} setStudentDetailList={setStudentDetailList} fetchQuizQuestion={fetchQuizQuestion} nextPrevious={nextPrevious} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PracticalStudentList;
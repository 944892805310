import React, { useEffect, useState } from 'react';
import { TABLE_DATE_FORMAT } from '../../../utils/Constants';
import moment from 'moment';
import { IMAGE_URL } from '../../../utils/Constants';
import { InitialRender } from '../../common/Helper';
import { ColorRender } from '../../systemadministration/usermanagement/CheckRole';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { GetListofSchedules } from '../../../services/ScheduleManagementService';
import { GetBookingById } from '../../../services/BookingServices';

export const BookingModal = ({
  isOpen,
  onClose,
  eventDetails,
  position,
  startDate_,
  endDate_,
  customDay_,
}) => {
  const history = useHistory();
  const [studentCount_, setStudentCount_] = useState();
  const [bookingCreatedBy, setBookingLogsCreatedBy] = useState({});
  const booking_class_id = eventDetails.raw.id;
  const mode = eventDetails.raw.class_type;
  const type = eventDetails.raw.type;

  useEffect(() => {
    const scheduleList = async () => {
      try {
        const scheduleListResponse = await GetListofSchedules(
          1,
          10,
          customDay_,
          0,
          0,
          0,
          0,
          startDate_,
          endDate_
        );

        const studentCountResponse =
          scheduleListResponse?.data?.classCount ?? [];
        const studentCount = studentCountResponse.filter(
          (item) => item.class_id === booking_class_id
        );
        setStudentCount_(studentCount[0].total);
      } catch (error) {
        console.log('error', error);
      }
    };
    scheduleList();
  }, [startDate_, endDate_]);

  useEffect(() => {
    if (type === 'booking' && booking_class_id) {
      const getBookingLogs = async () => {
        const logs = await GetBookingById(booking_class_id);
        const booking_logs = logs.data?.booking_logs;
        const filterBookingCreatedBy = booking_logs?.filter(
          (item) => item.type === 'create'
        );
        console.log('filterBook', filterBookingCreatedBy);
        setBookingLogsCreatedBy(filterBookingCreatedBy[0]);
      };
      getBookingLogs();
    }
  }, [booking_class_id]);

  if (!isOpen || !eventDetails) return null;

  const modalStyle = {
    position: 'absolute',
    top: `${position.y}px`,
    left: `${position.x}px + 300px `,
    zIndex: 1000,
    background: 'white',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    maxWidth: '600px',
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" style={modalStyle}>
        <div className="modal-booking-container-1">
          <div>
            {type === 'class' && (
              <span
                className={
                  mode === 'bbb_class' ||
                  mode == 'braincert_class' ||
                  mode == 'joinme_class'
                    ? 'cat-blue'
                    : mode === 'on_campus'
                    ? 'cat-green'
                    : 'cat-red'
                }
              >
                {mode === 'bbb_class' ||
                mode == 'braincert_class' ||
                mode == 'joinme_class' ? (
                  <div
                    className="as-virtual-bg as-widget text-white no-text-transformation"
                    title="Virtual"
                  >
                    <i className="fal fa-house-signal" />
                    &nbsp;Virtual
                  </div>
                ) : mode === 'on_campus' ? (
                  <div
                    className="as-campus-bg as-widget no-text-transformation"
                    title="On-campus"
                  >
                    <i className="fal fa-university" />
                    &nbsp;On Campus
                  </div>
                ) : (
                  <div
                    className="as-hybrid-bg as-widget no-text-transformation"
                    title="Hybrid"
                  >
                    <i className="fal fa-university" />
                    <i className="fal fa-plus" />
                    <i className="fal fa-house-signal" />
                    &nbsp;Hybrid
                  </div>
                )}
              </span>
            )}
            {eventDetails.raw.type === 'booking' && (
              <div className="d-flex">
                <div
                  className="as-booking-bg as-widget no-text-transformation mr-2"
                  title="Booking"
                >
                  <i className="fal fa-calendar-day" />
                  <span style={{ fontWeight: 700, fontSize: '12px' }}>
                    Booking
                  </span>
                </div>
                {/* <div> */}
                <span
                  style={{
                    backgroundColor:
                      eventDetails.raw.booking_status === 'APPROVED'
                        ? '#469B58'
                        : eventDetails.raw.booking_status === 'REJECTED'
                        ? '#E45149'
                        : '#EBA400',
                    color: '#fff',
                    padding: '4px 6px 4px',
                    borderRadius: '2px',
                    fontWeight: 600,
                    fontSize: '10px',
                  }}
                >
                  {eventDetails.raw.booking_status
                    ? eventDetails.raw.booking_status
                    : '-'}
                </span>
                {/* </div> */}
              </div>
            )}
          </div>
          <div>
            <i className="fal fa-users mr-2" title="Student attendance">
              &nbsp;
              {type === 'booking' &&
                eventDetails.raw.booking_for &&
                eventDetails.raw.booking_for.split(',').length}
              {type === 'class' && studentCount_}
            </i>
          </div>
        </div>
        <div
          className="pb-3 mb-3"
          style={{
            borderBottom: `1px solid #E0E0E0`,
          }}
        >
          <div className="mb-2">
            <span
              style={{
                fontWeight: 700,
                fontSize: '20px',
              }}
            >
              {eventDetails.raw && type === 'class'
                ? eventDetails.raw?.class_title
                : eventDetails.raw?.booking_reason}
            </span>
          </div>
          <div className="modal-booking-grid">
            <div>
              <i className="fal fa-calendar-day">
                {type === 'class' && eventDetails.raw.class_date && (
                  <span
                    className='booking-modal-date-time'
                  >
                    {moment(eventDetails.raw.class_date).format(
                      `dddd, ${TABLE_DATE_FORMAT}`
                    )}
                    , &nbsp;
                    {moment(eventDetails.raw.class_from, 'HH:mm:ss').format(
                      'HH:mm'
                    )}{' '}
                    -{' '}
                    {moment(eventDetails.raw.class_to, 'HH:mm:ss').format(
                      'HH:mm'
                    )}
                    {/* `${moment(eventDetails.raw.class_date).format(
                    `dddd, ${TABLE_DATE_FORMAT}`
                  )}, ${eventDetails.raw.class_from} - ${
                    eventDetails.raw.class_to
                    }` */}
                  </span>
                )}
                {type === 'booking' && eventDetails.raw.booking_date && (
                  <span
                    className='booking-modal-date-time'
                  >
                    {moment(eventDetails.raw.booking_date).format(
                      `dddd, ${TABLE_DATE_FORMAT}`
                    )}
                    , &nbsp;
                    {moment(
                      eventDetails.raw.booking_start_time,
                      'HH:mm:ss'
                    ).format('HH:mm')}{' '}
                    -
                    {' ' +
                      moment(
                        eventDetails.raw.booking_end_time,
                        'HH:mm:ss'
                      ).format('HH:mm')}
                  </span>
                )}
              </i>
            </div>
            <div>
              <i class="fal fa-university"></i>
              {type === 'class' && (
                <span className='booking-modal-date-time'>
                  {eventDetails.raw.multiple_room.length > 0
                    ? eventDetails.raw?.multiple_room[0].campus_room
                        ?.room_name +
                      ', ' +
                      eventDetails.raw?.multiple_room[0].campus_room
                        ?.class_campus?.campus_name
                    : '-'}
                </span>
              )}
              {type === 'booking' && (
                <span className='booking-modal-date-time'>
                  {eventDetails.raw
                    ? eventDetails.raw?.room_no +
                      ', ' +
                      eventDetails.raw?.campus
                    : '-'}
                </span>
              )}
            </div>
          </div>
          <div className="d-flex ">
            <i class="fal fa-books mr-1"></i>
            <div
              className="as-text-blue curser textLimit2"
              onClick={() =>
                history.push(
                  `/courseAdministration/coursesdetails/${
                    type === 'class'
                      ? eventDetails.raw?.intake_id
                      : eventDetails.raw?.linked_course_id
                  }/detailCourses/show`
                )
              }
            >
              {type === 'booking' && eventDetails.raw
                ? eventDetails.raw?.linked_course
                : eventDetails.raw?.course_name}
            </div>
          </div>
        </div>
        <div>
          {type === 'class' && (
            <div className="mb-4">
              <div className="modal-booking-container-2">Lecturer</div>
              <div>
                {eventDetails.raw.lecturer && (
                  <>
                    <div className="reports-profile-view assigned-title-block-new">
                      <div className="assigned-title-itm">
                        <span
                          className={
                            'assigned-title-blk  name-icon cat-dark-red' +
                            ColorRender(
                              eventDetails.raw.lecturer.fullname ?? '-'
                            )
                          }
                        >
                          {eventDetails.raw.lecturer.picture_me ? (
                            <img
                              src={`${IMAGE_URL}/${eventDetails.raw.lecturer.picture_me.replace(
                                '/home/myaie/public_html/',
                                ''
                              )}`}
                              alt="AIE"
                            />
                          ) : (
                            InitialRender([
                              eventDetails.raw.lecturer.Firstname,
                              eventDetails.raw.lecturer.Lastname,
                            ])
                          )}
                          <span
                            className={`profile-box-2-status ${eventDetails.raw.lecturer.Status}`}
                          >
                            <i className="fas fa-circle"></i>
                          </span>
                        </span>
                        <span
                          className="as-text-blue curser"
                          onClick={() =>
                            history.push(
                              `/systemAdministration/userManagement/open/${eventDetails.raw.lecturer.UserID}/aboutYou`
                            )
                          }
                        >
                          &nbsp;{eventDetails.raw.lecturer.fullname}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          {type === 'class' && eventDetails.raw.multiple_lecture.length > 0 && (
            <div className="mb-4">
              <div className="modal-booking-container-2">Guest Lecturer</div>
              <div className="d-flex">
                {eventDetails.raw.multiple_lecture.map((item) => (
                  <div className="reports-profile-view assigned-title-block-new">
                    <div className="assigned-title-itm">
                      <span
                        className={
                          'assigned-title-blk  name-icon cat-dark-red' +
                          ColorRender(item.get_lecture_details.fullname ?? '-')
                        }
                      >
                        {item.get_lecture_details.picture_me ? (
                          <img
                            src={`${IMAGE_URL}/${item.get_lecture_details.picture_me.replace(
                              '/home/myaie/public_html/',
                              ''
                            )}`}
                            alt="AIE"
                          />
                        ) : (
                          InitialRender([
                            item.get_lecture_details.Firstname,
                            item.get_lecture_details.Lastname,
                          ])
                        )}

                        <span className={`profile-box-2-status Online`}>
                          <i className="fas fa-circle"></i>
                        </span>
                      </span>
                      <span>{item.get_lecture_details.fullname ?? '-'}</span>
                      &nbsp;&nbsp;&nbsp;
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {type === 'booking' && (
            <>
              <div className="mb-4">
                <div className="modal-booking-container-2">
                  Booking Created By
                </div>
                <div>
                  {
                    // bookingLogs.length > 0 &&
                    bookingCreatedBy && bookingCreatedBy.log_user_first_name ? (
                      <div className="reports-profile-view assigned-title-block-new">
                        <div className="assigned-title-itm">
                          <span
                            className={
                              'assigned-title-blk  name-icon cat-dark-red ' +
                              ColorRender(bookingCreatedBy.log_user_full_name)
                            }
                          >
                            {bookingCreatedBy.log_user_picture_me ? (
                              <img
                                src={`${IMAGE_URL}/${bookingCreatedBy.log_user_picture_me.replace(
                                  '/home/myaie/public_html/',
                                  ''
                                )}`}
                                alt="AIE"
                              />
                            ) : (
                              InitialRender([
                                bookingCreatedBy.log_user_first_name,
                                bookingCreatedBy.log_user_last_name,
                              ])
                            )}
                            <span className={`profile-box-2-status Online`}>
                              <i className="fas fa-circle"></i>
                            </span>
                          </span>
                          <span
                            className="as-text-blue curser"
                            onClick={() =>
                              history.push(
                                `/systemAdministration/userManagement/open/${bookingCreatedBy.user_id}/aboutYou`
                              )
                            }
                          >
                            {bookingCreatedBy.log_user_full_name ?? '-'}
                          </span>
                        </div>
                      </div>
                    ) : (
                      '-'
                    )
                  }
                </div>
              </div>

              <div>
                <button
                  class="btn-view-modal-booking"
                  onClick={() =>
                    history.push(
                      `/campusAdministration/bookings/edit/${eventDetails.raw.id}`
                    )
                  }
                >
                  <i class="fal fa-folder-open"></i>&nbsp;View Booking
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import HtmlInputEditor from "../common/HtmlInputEditor";
// import HtmlInputEditor from "../../common/HtmlInputEditor";
import { EditorState, convertToRaw, RichUtils, CompositeDecorator, ContentState, convertFromHTML, convertFromRaw } from "draft-js";
import "draft-js/dist/Draft.css";
import "draft-js-mention-plugin/lib/plugin.css";
import { SendQuickNotification, CreateNotification, GetNotificationDetails } from "../../services/CourseService";
import $ from 'jquery';
import { PreviewStudentNotification } from "../../services/ProgrammeServices";
import { IMAGE_URL, FORM_DATE_FORMAT, FORM_DATE_TIME_FORMAT, FORM_TIME_FORMAT, STUDENT_FILE_DOWNLOAD, downloadURL } from "../../utils/Constants";
import moment from 'moment';

function SendNotification(props) {

    const { id, subTab, openTabs } = useParams();
    let history = useHistory();
    const [disabled, setDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [attachmentName, setAttachmentName] = useState(false);
    const [attachment, setAttachment] = useState("")
    const [editorState2, setEditorState2] = React.useState("");
    const [notificationData, setNotificationData] = React.useState();
    const [previewLoading, setPreviewLoading] = useState(false);
    const [gearLoading, setGearLoading] = useState("")

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    useEffect(() => {
        if (subTab) {
            let obj = { id: subTab, type: "course_notification" };
            GetNotificationDetails(obj).then(res => {
                setAttachmentName(res?.data?.notifications?.attachment)
                setAttachment(res?.data?.notifications?.attachment.split("/").reverse()[0])
                setNotificationData(res && res.data && res.data.notifications ? res.data.notifications : {});
                // const blocksFromHTML = convertFromRaw(JSON.parse(res?.data?.notifications?.content));
                // setEditorState2(EditorState.createWithContent(blocksFromHTML));
                setEditorState2(res?.data?.notifications?.content)
            }).catch(error => {
                console.log("error ---> ", error);
            })
        }
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            Title: notificationData && notificationData.subject ? notificationData.subject : "",
            FileAttachment: notificationData && notificationData.attachment ? notificationData.attachment : "",
            sentGroups: {
                PushNotification: notificationData && notificationData.isPushnotificationActive ? true : false,
                EmailNotification: notificationData && notificationData.isEmailActive ? true : false,
            },
            IncludeAccountPayer: notificationData && notificationData.isPayerActive ? true : false,
            Important: notificationData && notificationData.ish_importanceActive ? true : false,
            SendWhen: notificationData ? notificationData.publish == 0 ? true : false : true,
            SendWhenDate: notificationData && notificationData.publish_date ? new Date(moment.unix(notificationData.publish_date)) : "",
            // IsDraft: notificationData && notificationData.subject ? notificationData.subject : "",
            id: notificationData && notificationData.id ? notificationData.id : "",

            // dummy : ""
        },
        validationSchema: Yup.object({
            Title: Yup.string().required("Title is required").trim(),
            SendWhenDate: Yup.string()
                .when("SendWhen", {
                    is: false,
                    then: Yup.string().required("Date is required"),
                }),
            sentGroups: Yup.object().shape(
                {
                    PushNotification: Yup.bool().when("EmailNotification", {
                        is: (EmailNotification) => !EmailNotification,
                        then: Yup.bool().oneOf([true], "At least one needs to be checked EmailNotification or PushNotification")
                    }),
                    EmailNotification: Yup.bool().when("PushNotification", {
                        is: (PushNotification) => !PushNotification,
                        then: Yup.bool().oneOf([true], "At least one needs to be checked EmailNotification or PushNotification")
                    })
                },
                [
                    ["PushNotification", "EmailNotification"],
                    ["EmailNotification", "PushNotification"]
                ]
            )
        }),
        onSubmit: (values, { resetForm }) => {
            setDisabled(true);
            setIsLoading(true);
            let SendWhenDate = values.SendWhenDate;
            let formData = new FormData();
            formData.append("isPushnotificationActive", values.sentGroups.PushNotification ? 1 : 0);
            formData.append("subject", values.Title);
            formData.append("message", editorState2);
            formData.append("attachment", values.FileAttachment);
            formData.append("intake", id);
            formData.append("isEmailActive", values.sentGroups.EmailNotification ? 1 : 0);
            formData.append("isPayerActive", values?.IncludeAccountPayer ? 1 : 0);
            formData.append("ish_importanceActive", values?.Important ? 1 : 0);
            formData.append("draft_notification", values.IsDraft ? 1 : 0);
            formData.append("publish", values.SendWhen);
            if (values.SendWhen == false) {
                SendWhenDate = new Date(SendWhenDate).getTime().toString() / 1000;
                formData.append("publish_date", SendWhenDate);
            }
            if (subTab) {
                formData.append("notification_type", "intake_notification ");
                formData.append("regarding", "student");
                formData.append("id", subTab);
            }

            previewLoading ? 
            PreviewStudentNotification(formData)
            .then((res) => {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Preview Send Successfully",
                });
                history.push(`/courseAdministration/coursesdetails/${id}/notifications/show`);
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: err.response.data[0].message,
                });
                console.log("error :", err.response.data[0].message);
            }) 
            :
            CreateNotification(formData).then(data => {
                if (data.status == 200) {
                    setDisabled(false);
                    setIsLoading(false);
                    Swal.fire("Success!", "Successfully created", "success");
                    props.getData();
                    formik.resetForm();
                    setGearLoading("")
                    history.push(`/courseAdministration/coursesdetails/${id}/notifications/show`);
                }
            }).catch(error => {
                setIsLoading(false);
                setDisabled(false);
                console.log("error ---> ", error);
            })
        },

    });

    return (
        <div className="card card-profile-info-card">
        <div>
        <div className="card-body-inr">
            <form onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-md-6 col-lg-4">
                        <label htmlFor="Title">Title *</label>
                        <div className="form-icon-group mb-4" title="Title">
                            <input type="text" className={"form-control" + (formik.errors.Title && formik.touched.Title ? " is-invalid" : "")} name="Title" id="Title" placeholder="Title" title="Title" value={formik.values.Title} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <div className="form-group-blk mb-3">
                            <label></label>
                            <div className="custom-check custom-control custom-checkbox mt-2">
                                <input type="checkbox" className="custom-control-input" id="IncludeAccountPayer" name="select" checked={formik.values.IncludeAccountPayer} onChange={(e) => formik.setFieldValue("IncludeAccountPayer", e.target.checked)} />
                                <label className="custom-control-label" htmlFor="IncludeAccountPayer">
                                    Include Account Payer
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <div className="form-group-blk mb-3">
                            <label></label>
                            <div className="custom-check custom-control custom-checkbox mt-2">
                                <input type="checkbox" className="custom-control-input" id="Important" name="select" checked={formik.values.Important} onChange={(e) => formik.setFieldValue("Important", e.target.checked)} />
                                <label className="custom-control-label" htmlFor="Important">
                                    Important
                                </label>
                            </div>
                        </div>
                    </div>
                {/* </div> */}
                {/* <div className="row"> */}
                    <div className="col-md-6 col-lg-4">
                        <label>Send Via *</label>
                        <div className="form-group-blk mb-3">
                            <label></label>
                            <div className="custom-check custom-control custom-checkbox custom-control-inline">
                                <input type="checkbox" className="custom-control-input" id="EmailNotification" name="select" checked={formik.values.sentGroups.EmailNotification} onChange={(e) => formik.setFieldValue("sentGroups.EmailNotification", e.target.checked)} />
                                <label className="custom-control-label" htmlFor="EmailNotification">
                                    Email
                                </label>
                            </div>
                            <div className="custom-check custom-control custom-checkbox custom-control-inline">
                                <input type="checkbox" className="custom-control-input" id="PushNotification" name="select" checked={formik.values.sentGroups.PushNotification} onChange={(e) => formik.setFieldValue("sentGroups.PushNotification", e.target.checked)} />
                                <label className="custom-control-label" htmlFor="PushNotification">
                                    Push Notification
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <div className="form-group-blk mb-3">
                            <label>Upload Attachment</label>
                            <div className="form-group atttach-file m-0">
                                <label className={formik.errors.FileAttachment && formik.touched.FileAttachment && "file-req is-invalid"}>
                                    <i className="fal fa-paperclip"></i>
                                    <span>Add Attachment</span>
                                    <input type="file" className="form-control  form-control-aatch-file" onChange={(e) => {
                                        if (e.target.files.length) {
                                            formik.setFieldValue("FileAttachment", e.target.files[0]);
                                            setAttachment(e.target.files[0].name)
                                            setAttachmentName(URL.createObjectURL(e.target.files[0]));
                                        }
                                    }}
                                    />
                                </label>
                            </div>
                            {attachmentName && (
                                <>
                                    <div class="frm-group">
                                        <ul class="list-unstyled attached-file-ul m-0">
                                            <li>
                                                <a target="_blank" href={attachmentName.includes("blob") ? attachmentName : `${downloadURL}${STUDENT_FILE_DOWNLOAD}/${encodeURI(attachmentName.replaceAll(
                    "/home/myaie/public_html/",
                    ""
                  ).replace("public/", ""))}`}>
                                                    <i class="fal fa-file"></i>
                                                    &nbsp;{attachment}
                                                </a>
                                                <button
                                                    class="btn btn-danger"
                                                    onClick={() => {
                                                        formik.setFieldValue("FileAttachment", "");
                                                        setAttachmentName(false);
                                                        setAttachment("");
                                                    }}
                                                    ><i class="fal fa-trash-alt"></i>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <label title="SendWhen">Send When</label>
                        <div className="form-icon-group" title="SendWhen">
                            <div className="custon_rd_new">
                                <div class="custom-control custom-radio custom-control-inline" title="Now">
                                    <input type="radio" id="customRadioInline1" name="customRadioInline" class="custom-control-input" checked={formik.values.SendWhen == true} onChange={() => formik.setFieldValue("SendWhen", true)} onBlur={formik.handleBlur} />
                                    <label class="custom-control-label" htmlFor="customRadioInline1">
                                        Now
                                    </label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline" title="Later">
                                    <input type="radio" id="customRadioInline2" name="customRadioInline" class="custom-control-input" title="SendWhen" checked={formik.values.SendWhen == false} onChange={() => formik.setFieldValue("SendWhen", false)} onBlur={formik.handleBlur} />
                                    <label class="custom-control-label" htmlFor="customRadioInline2">
                                        Later
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">

                    {!formik.values.SendWhen && (
                        <div className="col-md-6 col-lg-4">
                            <label>Date *</label>
                            <div className="form-group-blk mb-3">
                                <DatePicker
                                    selected={formik.values.SendWhenDate}
                                    onChange={(date) => {
                                        formik.setFieldValue("SendWhenDate", date);
                                    }}
                                    filterTime={filterPassedTime}
                                    className={"form-control cursor-pointer" + (formik.errors.SendWhenDate && formik.touched.SendWhenDate ? " is-invalid" : "")}
                                    title="Date"
                                    onBlur={formik.handleBlur}
                                    placeholderText="Enter Date"
                                    onChangeRaw={(e) => e.preventDefault()}
                                    minDate={new Date()}
                                    showYearDropdown
                                    showTimeSelect
                                    scrollableYearDropdown
                                    dateFormatCalendar="MMMM"
                                    dateFormat={FORM_DATE_TIME_FORMAT}
                                    timeFormat={FORM_TIME_FORMAT}
                                />
                            </div>
                            {formik.errors.SendWhenDate && formik.touched.SendWhenDate ? (
                                <span className="exclamation">
                                    <i className="fal fa-exclamation-circle"></i>
                                </span>
                            ) : null}
                        </div>
                    )}
                    <div className="col-md-12">
                        <label>Content</label>
                        <div className="form-icon-group mb-4 d-block">
                            {(notificationData || !subTab) && <HtmlInputEditor hideSign={true} editorState={editorState2} setEditorState={setEditorState2} isInValid={editorState2} isCKEditor={true} />}
                        </div>
                    </div>
                </div>

                <div className="form-group form-group-save-cancel mt-3">
                    <button className="btn btn-save btn-success" type="submit" title="Send" disabled={disabled && gearLoading=="send" ? true : false} onClick={() => {setGearLoading("send"); setPreviewLoading(false); formik.setFieldValue("IsDraft", 0)}}>
                        {disabled && gearLoading=="send" ? <i className="fas fa-cog fa-spin"></i> : <i className="fa fa-paper-plane"></i>}
                        Send
                    </button>
                    <button className="btn btn-save btn-success" type="submit" title="Save As Draft" disabled={disabled && gearLoading=="save" ? true : false} onClick={() => {setGearLoading("save"); setPreviewLoading(false); formik.setFieldValue("IsDraft", 1)}}>
                        {disabled && gearLoading=="save" ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-plus"></i>}
                        Save As Draft
                    </button>
                    <button className="btn btn-save btn-success" type="submit" title="Preview" disabled={disabled && previewLoading} onClick={() => {setGearLoading("preview") ;setPreviewLoading(true); formik.setFieldValue("IsDraft", 0)}}>
                        {disabled && previewLoading && gearLoading=="preview" ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-file-alt"></i>}
                        Preview
                    </button>
                    <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => { history.push(`/courseAdministration/coursesdetails/${id}/notifications/show`); }}>
                        <i className="fal fa-times"></i>
                        Cancel
                    </button>
                    {formik.touched.Title && formik.errors.Title ? <div className="invalid-feedback d-block">{formik.errors.Title}</div> : null}
                    {formik.touched.SendWhenDate && formik.errors.SendWhenDate ? <div className="invalid-feedback d-block">{formik.errors.SendWhenDate}</div> : null}
                    {formik.touched?.sentGroups?.EmailNotification && formik.errors?.sentGroups?.EmailNotification ? <div className="invalid-feedback d-block">{formik.errors.sentGroups.EmailNotification}</div> : null}
                </div>
            </form>
        </div>
        </div>
        </div>
    );
}

export default SendNotification;

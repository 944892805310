import React, { createRef, useEffect, useState } from "react";
import FeedMessage from "./FeedMessage";
import $ from 'jquery'
import ReactDynamicModal from "react-draggable-resizable-modal";
import SkeletonMessageFeed from "../../../loaders/SkeletonMessageFeed";
import { GetSupportTeamService } from "../../../services/PostsAndFeedService";
import { useRef } from "react";
import moment from "moment";
import { TABLE_DATE_FORMAT } from "../../../utils/Constants";

const FeedMessages = (props) => {

  const [videoArr, setVideoArr] = useState([]);
  const [currentVideo, setCurrentVideo] = useState('');
  const [modalOpen, setModalOpen] = useState(false)
  const [loader, setLoader] = useState(true);
  const [dateArr, setDateArr] = useState([]);
  const [noOfLoader, setNoOfLoader] = useState(2)
  const [hideList, setHideList] = useState("");
  const [team, setTeam] = useState([]);
  const [teamObj, setTeamObj] = useState([]);
  const [classData, setClassData] = useState([]);

  const closeModal = () => {
    setModalOpen(false);
    setCurrentVideo('')
  };

  useEffect(() => {
    setLoader(true)
    dateMethod();
    const t = setTimeout(() => {
      setLoader(false)
    }, 1000);
    return () => {clearTimeout(t)}
  }, [props.feeds.length])
  
  const dateMethod = () => {
    let tempArr = []
    props.feeds.map((item) => {
      let dateOnly = moment(item.updated_at, "Do MMMM YYYY, h:mm:ss a").format(TABLE_DATE_FORMAT)
      if (!tempArr.includes(dateOnly)) {
        tempArr.push(dateOnly)
      } else {
        tempArr.push("")
      }      
    })
    setDateArr(tempArr)
  }

  useEffect(() => {
    let mounted = true
    GetSupportTeamService({ room_id: props.roomId })
      .then((res) => {
        if (mounted) {
          setTeam(res.data.team);
          if (res.data.team) {
            let tempObj = Object.keys(res.data.team).map(item => {
              return {
                ...res.data.team[item], role: item
              }
            });
          setTeamObj(tempObj)
          }
        }
      })
      .catch((error) => { });

    return function cleanup() {
      mounted = false
    }
  }, []);

  const childRef = useRef(props.feeds.map((feed)=>createRef(feed.id)));
  let openId;
  const clickOutsideHandle = (event) =>{            
    const clickedElemClass = event.target.classList[0];
    let id;
    if (clickedElemClass === "reply-static") {
      id = event.target.classList[1];           
    }
    if(id!==null && id!==undefined){
      childRef.current[id].handleClickOutside(event);
      // setOpenId(id);
      openId = id;
    }else
    if(openId!==null && openId!==undefined){
      childRef.current[openId].handleClickOutside(event);
    }
  }
  useEffect(() => {
    $("body").on("mousedown", clickOutsideHandle);
    return () => {
      $("body").off("mousedown", clickOutsideHandle);
    };
  }, []);
  

  return (
    <>
      <div className="chat-msg-container ps-fd-card-body chat-space-lt" id='contenttwo'>
        {(props.loadMore) && <SkeletonMessageFeed count={noOfLoader} />}
        {props.feeds.map((feed, index) => {
          return (
            <FeedMessage
              ind={index}
              islastIndex={(props.feeds.length-1)==index}
              key={`${feed.id}-${feed.room_id}-${index}`}
              feed={feed}
              showDate={dateArr[index] ? true : false}
              online={props.online}
              taggingUsers={props.taggingUsers}
              setCurrentVideo={(link) => { setCurrentVideo(link); setModalOpen(true); }}
              updateMsg={props.updateMsg}
              setOpenReplyId={props.setOpenReplyId}
              openReplyId={props.openReplyId}
              setShowHideEdit={props.setShowHideEdit}
              showHideEditArr={props.showHideEditArr}
              setShowHidePostEdit={props.setShowHidePostEdit}
              showHidePostEdit={props.showHidePostEdit}
              resetEditor={props.resetEditor}
              deleteHandle={props.deleteHandle}
              likeDislikeHandle={props.likeDislikeHandle}
              updatePostImportant={props.updatePostImportant}
              sendClick={props.sendClick}
              deletedFile={props.deletedFile}
              reduceCount={props.reduceCount}
              hideList={hideList}
              setHideList={setHideList}
              updateReadMessages={props.updateReadMessages}
              roomId={props.roomId}
              teamObj={teamObj}
              ref={elem => (childRef.current[index]=elem)}
              setReRender={props.setReRender}
              setRefreshPinnedMsg={props.setRefreshPinnedMsg}
              totalSubmission={props?.totalSubmission}
              classData={classData}
              setClassData={setClassData}
            />
          );
        })}
        <div id='content' />
      </div>

      <div className="modal-update iframe_modal_full">
        <ReactDynamicModal
          data-backdrop="static"
          initWidth={640}
          initHeight={440}
          onRequestClose={closeModal}
          isOpen={modalOpen}

          data={
            <div className="body">
              {currentVideo != '' ?
                <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" src={`https://${currentVideo}`} frameborder="0"></iframe>
                :
                null
              }
            </div>
          }
        />
      </div>
    </>
  );
};

export default React.memo(FeedMessages);

import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory, useParams } from "react-router";
import {
  AddEditModuleResources,
  DeleteProgrammeResources,
  GetProgrammeResourcesListCancelToken,
} from "../../../services/ProgramService";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import * as Yup from "yup";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { TrimText } from "../../common/TrimText";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import PermissionsGate from "../../../utils/permissionGate";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import $ from "jquery";
import { IMAGE_URL, TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";
import { FileUploadField } from "../../common/FormFields";
import Select from 'react-select';
import { Link } from "@material-ui/core";
import PopupComponent from "../../common/PopupComponent";

const Resources = () => {
  const history = useHistory();
  const { id } = useParams();
  const [search, setSearch] = useState("");
  const [loading, setloading] = useState(false);
  const [resourceData, setResourceData] = useState([]);
  const [disabledResource, setDisabledResource] = useState(false);
  const [resourceEdit, setResourceEdit] = useState({})
  const [typeArr,setTypeArr] = useState([
    {value : 0,label : "File"},
    {value : 1,label : "Link"},
    {value : 2,label : "Text"},
  ])
  const [modalIcon, setModalIcon] = useState(false)
  const [updateData, setUpdateData] = useState(false)
  const [message, setMessage] = useState('')
  const urlOrPathRegex = /^(https?:\/\/.*|\/.*)/;


  

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    const cancelTokenSources = [];

    const getResourcesList = async () => {
      setloading(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      try {
        const res = await GetProgrammeResourcesListCancelToken(id, source.token);
        if (res.status === 200) {
          setResourceData(res.data.data);
          setloading(false);
          setUpdateData(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
          setUpdateData(false);
        }
      }
    };
  
    getResourcesList();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [updateData]);

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({
      scopes: ["mrview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  const myNameFilter = (a, b) => {
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
  };

  const viewResource = (resource,type) => {
    if(type != null && type != undefined){
      if(type == 0){
        let url = IMAGE_URL + encodeURI(resource.replaceAll("home/myaie/public_html/", "").replaceAll("public", ""));
        window.open(url, "_blank");
      }else if(type == 1){
        window.open(encodeURI(resource),"_blank")
      }else{
        $("#resource_file").modal("show");
        setMessage(resource)
      }
    }
  }
  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      wrap: true,
      sortFunction: myNameFilter,
      cell: (row) => (
        <PermissionsGate
          scopes={["mredit"]}
          RenderError={() => <span title={row.name} className="feature-name"> <span className="textLimit100">{row.name}</span></span>}
        >
          <span
            to={`#`}
            data-toggle="modal"
            data-target="#resource-modal"
            onClick={()=>{setResourceEdit(row) 
                          setModalIcon(true)}

                    }
            title={row.name}
            className="as-text-blue curser feature-name"
          >
            <span title={row.name} className="textLimit100">{row.name}</span>
          </span>
        </PermissionsGate>
      ),
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      cell: (row) =>
        row.type != null && row.type != undefined ? (
          <span>{row.type == 0 ? 'File' : row.type == 1 ? 'Link' : 'Text'}</span>
        ) : (
          "-"
        ),
    },
    {
      name: "Actions",
      selector: "",
      cell: (row) => (
        row.resource_file != null && row.resource_file != undefined ?
        (
          <div className="assessment-08 btn-dropdown-grp">
            <div className="as-buttons">
              <PermissionsGate
                scopes={["mredit"]}
                RenderError={() => (
                  <button
                    className="btn btn-primary rounded-circle"
                    title="Open"
                    disabled
                  >
                    <i className="fal fa-folder-open"></i>
                  </button>
                )}
              >
                <button
                  data-toggle="modal"
                  data-target="#resource-modal"
                  className="btn btn-primary rounded-circle"
                  title="Open"
                  onClick={()=>{setResourceEdit(row)
                    setModalIcon(true)}}
                >
                  <i className="fal fa-folder-open"></i>
                </button>
              </PermissionsGate>
            <div className="dropdown btn-dropdown-item">
                <button
                  className="btn btn-primary rounded-circle dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  title="More"
                >
                  <i className="fal fa-ellipsis-h-alt"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  
                    <button
                      to={`/program-management-center/exportProgramme/${row.id}`}
                      target="_blank"
                      onClick={() => viewResource(row.resource_file,row.type)}
                      className="btn btn-primary rounded-circle"
                      title="View Resource"
                    >
                      <i className="fal fa-eye"></i>
                    </button>
                  <PermissionsGate
                    scopes={["mrdelete"]}
                    RenderError={() => (
                      <button
                        className="btn btn-danger rounded-circle"
                        title="Delete"
                        disabled
                      >
                        <i className="fal fa-trash-alt"></i>
                      </button>
                    )}
                  >
                    <button
                      title="Delete"
                      className="btn btn-danger rounded-circle"
                      onClick={() => handleDelete(row.id)}
                    >
                      <i className="fal fa-trash-alt"></i>
                    </button>
                  </PermissionsGate>
                </div>
              </div>
            </div>
          </div>
        ) : <div className="assessment-08 btn-dropdown-grp">
        <div className="as-buttons">
          <PermissionsGate
            scopes={["mredit"]}
            RenderError={() => (
              <button
                className="btn btn-primary rounded-circle"
                title="Open"
                disabled
              >
                <i className="fal fa-folder-open"></i>
              </button>
            )}
          >
            <button
              data-toggle="modal"
              data-target="#resource-modal"
              className="btn btn-primary rounded-circle"
              title="Open"
              onClick={()=>{setResourceEdit(row)
                setModalIcon(true)}}
            >
              <i className="fal fa-folder-open"></i>
            </button>
          </PermissionsGate>
          <PermissionsGate
            scopes={["mrdelete"]}
            RenderError={() => (
              <button
                className="btn btn-danger rounded-circle"
                title="Delete"
                disabled
              >
                <i className="fal fa-trash-alt"></i>
              </button>
            )}
          >
            <button
              title="Delete"
              className="btn btn-danger rounded-circle"
              onClick={() => handleDelete(row.id)}
            >
              <i className="fal fa-trash-alt"></i>
            </button>
          </PermissionsGate>
        </div>
      </div>
      ),
    },
  ], []);
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteProgrammeResources({ id: id })
          .then((res) => {
            Swal.fire("Deleted!", "Resource has been deleted.", "success");
            setUpdateData(prev => !prev);
          })
          .catch((error) => console.log(error));
      }
    });
  };
  const dataToRender = () => {
    let updatedData = [];
    let allData = resourceData;
    if (search.length) {
      let tempName = allData.filter((item) => {
        let includes =
          item.name &&
          item.name.toString()
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempLink = allData.filter((item) => {
        let includes =
          item.saqa_link &&
          item.saqa_link.toString()
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempdata = [...tempName, ...tempLink];
      let unique = [...new Set(tempdata)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (search.length) {
      return updatedData;
    } else {
      return resourceData;
    }
  };

  const resetFilter = () => {
    setSearch("");
  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };
  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = ["Name", "Type"];

    data = data?.map((row) => ({
      ...row,
      Name: row.name ? row.name : "-",
      Type: row.type != null && row.type != undefined ? row.type == 0 ? 'File' : row.type == 1 ? 'Link' : 'Text' : "-",
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.forEach((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row["Name"], row["Type"]];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
      type : resourceEdit?.type == null ? "" : resourceEdit?.type,
      name: resourceEdit?.name ? resourceEdit?.name : "",
      resource_file: resourceEdit?.resource_file ? resourceEdit?.resource_file : ""
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required").max(255, "Name should not be greater than 255 Character").trim(),
      resource_file: Yup.string().when('type', {
        is: (type) => type === 1,
        then: Yup.string()
          .required("Resource is required.")
          .matches(urlOrPathRegex, "Must be either a valid URL or start with /"),
        otherwise: Yup.string().when('type', {
          is: (type) => type !== "" && type !== undefined,
          then: Yup.string().required("Resource is required."),
          otherwise: Yup.string()
        })
      }) 
    }),
    onSubmit: (values) => {
      setDisabledResource(true);
      let formData = new FormData();
      formData.set("name", values.name);
      formData.set("module_id", id);
      formData.set('type', values.type)
      formData.set("resource_file", values.resource_file);
      if(resourceEdit){
        formData.set("id",resourceEdit.id ? resourceEdit.id :"")
      }
      AddEditModuleResources(formData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Added Successfully",
          });
          setDisabledResource(false);
          $("#resource-modal").modal("hide");
          setUpdateData(!updateData)
          setResourceEdit({});
          
        })
        .catch((err) => {
          console.log(err)
          setDisabledResource(false);
        });
    },
  });

  const handleReset=()=>{
    formik.resetForm();
    setResourceEdit({});
    
  }

  useEffect(() => {
    console.log("PPPPPPPPP",formik.values.type);
  },[formik.values.type])

  return (
    <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
      <div className="custom-table-div filter-search-icon card card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      id="assessment-table-main_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          className=""
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={handleSearchFilter}
                          value={search}
                        />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                    </div>
                </div>
              </div>
              <div className="filter-button-group">
                  <div className="reset-btn-group">
                    <div className="button-reset dropdown-comman">
                        <button
                          className="btn btn-primary"
                          onClick={() => resetFilter()}
                          title="Reset"
                        >
                          <i className="fal fa-redo"></i>Reset
                        </button>
                    </div>
                    <div className="files-export-group">
                        <button
                          type="button"
                          className="btn btn-files"
                          onClick={() => {
                            exportData("xlsx", "Resource");
                          }}
                          title="Export spreadsheet"
                        >
                          <i className="fal fa-file-excel icon"></i>
                        </button>

                        <button
                          type="button"
                          className="btn btn-files"
                          onClick={() => {
                            exportData("csv", "Resource");
                          }}
                          title="Export CSV"
                        >
                          <i className="fal fa-file-csv icon"></i>
                        </button>

                        <button
                          type="button"
                          className="btn btn-files"
                          onClick={() => {
                            exportData("pdf", "Resource");
                          }}
                          title="Export PDF"
                        >
                          <i className="fal fa-file-pdf icon"></i>
                        </button>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
                <div className=" filter-search-bar-blk"> 
                  <div className="add-ticket-blk button-reset dropdown-comman">
                  <PermissionsGate
                    RenderError={() => (
                      <button className="btn btn-primary" title="Create New" disabled>
                        <i className="fal fa-plus"></i>Create New
                      </button>
                    )}
                    scopes={["mradd"]}
                  >
                    <button
                      className="btn btn-primary"
                      title="Create New"
                      data-toggle="modal"
                      data-target="#resource-modal"
                      onClick={()=>{handleReset()
                                  setModalIcon(false) }}
                    >
                      <i className="fal fa-plus"></i>Create New
                    </button>
                  </PermissionsGate>
                  </div>
                </div>
          </div>
        </div>
        <DataTable
          defaultSortField="Name"
          data={dataToRender()}
          columns={columns}
          pagination={true}
          progressComponent={<SkeletonTicketList />}
          progressPending={loading}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />
      </div>
      <div
        // ref={editorRef}
        className="topic-add-modal modal fade show"
        id="resource-modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content modal-border-update">
            <form onSubmit={formik.handleSubmit}>
              <div className="modal-body p-0">
                <div className="modal-header modal-header-custom">
                  <h5 className="modal-title">
                  {modalIcon ? <><i className="fal fa-folder-open"></i> Edit</> : <><i className="fal fa-plus"></i> Add</>} Resource
                  </h5>
                  <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div className="t p-30px">
                  <div className="topic-add-modal-content">
                    <div className="row center-label">
                      <div className="col-md-6 col-lg-12">
                        <div className="form-group-blk mb-3">
                          <label>Name *</label>
                          <input
                            type="text"
                            className={
                              "form-control" +
                              (formik.errors.name && formik.touched.name
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Enter Name"
                            name="name"
                            id="name"
                            title="Name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-12">
                        <div className="form-group-blk mb-3">
                          <label htmlFor="link">Type</label>
                          <Select
                            className={
                              "form-control custom-select-box" +
                              (formik.errors.type && formik.touched.type ? " is-invalid" : "")
                            }
                            value={
                              formik.values.type !== "" 
                                ? typeArr.find(type => type.value === formik.values.type) 
                                : ""
                            }
                            name="type"
                            onChange={(selectedOption) => {
                              formik.setFieldValue("type", selectedOption ? selectedOption.value : "");
                              formik.setFieldValue("resource_file","")
                            }}
                            options={typeArr}
                            isClearable
                          />
                        </div>
                      </div>
                      {
                        formik.values.type === 0 && formik.values.type !== "" && 
                        (
                          <FileUploadField
                            name="resource_file"
                            label="Upload Resource"
                            formik={formik}
                            col_md={6}
                            col_lg={3}
                          />
                        )
                      }
                      {
                        formik.values.type == 1 && formik.values.type != "" &&
                        (
                          <div className="col-md-6 col-lg-12">
                            <div className="form-group-blk mb-3">
                              <label> URL input</label>
                              <input
                                type="text"
                                className={
                                  "form-control" +
                                  (formik.errors.resource_file && formik.touched.resource_file
                                  ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter Link"
                                name="resource_file"
                                id="link"
                                title="Link"
                                value={formik.values.resource_file}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </div>
                        )
                      }
                      {
                        formik.values.type == 2 && formik.values.type != "" &&
                        (
                          <div className="col-md-6 col-lg-12">
                            <div className="form-group-blk mb-3">
                              <label>Text Input</label>
                              <input
                                type="text"
                                className={
                                  "form-control" +
                                  (formik.errors.resource_file &&
                                  formik.touched.resource_file
                                  ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter Text"
                                name="resource_file"
                                id="link"
                                title="Text"
                                value={formik.values.resource_file}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </div>
                  <div className="form-group form-group-save-cancel mt-3">
                    <button
                      className="btn btn-primary"
                      title="Save"
                      onClick={formik.handleSubmit}
                      disabled={disabledResource}
                    >
                      {disabledResource ? (
                        <i className="fas fa-cog fa-spin"></i>
                      ) : (
                        <i className="fal fa-save"></i>
                      )}{" "}
                      Save
                    </button>
                    <button
                      className="btn btn-close btn-danger"
                      type="button"
                      title="Cancel"
                      onClick={() => {
                        formik.resetForm();
                        $("#resource-modal").modal("hide");
                      }}
                    >
                      <i className="fal fa-times"></i>Cancel
                    </button>
                    {Object.keys(formik.values).map((key) => {
                      if (formik.touched[key] && formik.errors[key]) {
                        return (
                          <div className="invalid-feedback d-block" key={key}>
                            {formik.errors[key]}
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <PopupComponent
        // quiz = {id}
        id="resource_file"
        type="form"
        header={{
          iconName: "fa-solid fa-eye",
          heading: "View Resource"
        }}
        inputs={[
          <>
          {message ? message : "No message found"}

          </>,
        ]}
      />
    </div>
  );
};

export default Resources;

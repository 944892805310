import { IMAGE_URL, downloadURL } from '../../utils/Constants';

function formatUrl(fileUrl) {
  if (!fileUrl) return "";

  // Ensure the URL is correctly encoded without altering its structure
  let encodedUrl = encodeURI(fileUrl);

  return encodedUrl.startsWith("http") || encodedUrl.startsWith("https")
    ? downloadURL !== ""
      ? downloadURL.replace(/\/+$/, "") + "/" + encodedUrl.replace("/home/myaie/public_html/", "").replace("public/", "").replace(/^\/+/, "")
      : encodedUrl.replace("/home/myaie/public_html/", "").replace("public/", "")
    : (downloadURL !== "" 
        ? downloadURL.replace(/\/+$/, "") + "/" + IMAGE_URL.replace(/https?:\/\//, "").replace(/\/+$/, "") 
        : IMAGE_URL.replace(/\/+$/, "")) +
      "/" +
      encodedUrl.replace("/home/myaie/public_html/", "").replace("public/", "").replace(/^\/+/, "");
}

export default formatUrl;

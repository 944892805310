import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import { FormField } from "../common/FormFields";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import hasPermission from "../../utils/hasMultiplePermission";
import { RenderPMCStatus } from "../../utils/CommonGroupingItem";
import HtmlInputEditor from "../common/HtmlInputEditor";
import { AddcreateEmail, getEmailProgrammeShortCourseList, getEmailsDropDownList, updateEmail } from "../../services/EmailsPageServer";
import { getFromEmails } from "../../services/BrandTemplateServices";
import { FileUploadField } from "../common/FormFields";
import EmailAssignedProgrammes from "./assignedProgrammes/EmailAssignedProgrammes";
import axios from "axios";
import PermissionsGate from "../../utils/permissionGate";


const EmailsPagesOpt = ({ updateData,refresh,setRefresh }) => {
    const history = useHistory();
    const { type, id } = useParams();
    const [emailType, setEmailType] = useState([]);
    const [description, setDescription] = useState("")
    const [footer, setFooter] = useState("")
    const [loading, setLoading] = useState(false);
    const [assignedRec, setAssignedRec] = useState([]);
    const [assignloading, setAssignLoading] = useState(false);
    const [emailsOptions, setEmailsOptions] = useState([]);
    const givenPermissions = useSelector((state) => state.givenPermission);
    
    useEffect(() => {
        const cancelTokenSources = [];
        const getProgramsList = async () => {
            setAssignLoading(true);
            cancelTokenSources.forEach(source => {
                source.cancel('New request made');
            });
            const source = axios.CancelToken.source();
            cancelTokenSources.push(source);
            
            const values = {
                exportStatus: true,
                EmailID: type == "update" ?  Number(id) : 0
            }
            
            try {
                let res = await getEmailProgrammeShortCourseList(values, source.token);
                
                if (res.status === 200) {
                    setAssignedRec(res.data && res?.data?.data ? res?.data?.data : []);
                    setAssignLoading(false);
                }
                await getFromEmails()
                .then(res => {
                    if (res.status === 200) {
                        setEmailsOptions(res?.data);
                    }
                })
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.log("error:", error)
                    setAssignLoading(false)
                }
            }
            
        }
        getProgramsList()
        return () => {
            cancelTokenSources.forEach(source => {
                source.cancel('Component unmounted');
            });
        };
    }, [id,refresh])
    
    useEffect(() => {
        const checkPermissions = () => {
            const hasAccess = hasPermission({ scopes: ["pmcmedit"], permissions: givenPermissions });
            if (!hasAccess) {
                history.push("/noaccess");
            }
        };
        checkPermissions();
    }, [givenPermissions, history]);
    
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            getEmailsDropDownList()
            .then(res => setEmailType(res?.data?.emailTypes))
            .catch(error => console.error("Error fetching status data", error))
            .finally(() => setLoading(false));
        };
        fetchData();
    }, [refresh]);
    
    const formik = useFormik({
        initialValues: {
            id: "",
            name:  "",
            subject:  "",
            type:  "",
            tags:  "",
            isOverrideBrand:  0,
            header: '',
            footer: '',
            attachment:"",
            // courses: assignedRec.map((item) => {
                //     return {
            //         course_id: item?.id,
            //         type: item?.assigned_type || item?.page_type,
            //     };
            // }) || [],
            courses: [],
            from_title : "",
            isOverrideBrand : 0
            
            
        },
        validationSchema: Yup.object({
            // header: Yup.string().required("Email Content is required"),
            name: Yup.string().required("Name is required"),
            subject: Yup.string().required("Email Subject is required"),
            from_title: Yup.string().nullable().when('isOverrideBrand', {
                is: 1,
                then: Yup.string().required('System Sent Email From Address is required'),
                otherwise: Yup.string().notRequired(),
              }),
            
            // from_email : Yup.string().email('Invalid email address')
        }),
        // onSubmit: async (values, actions) => {
            //     const formData = new FormData();
        
        //     // Trim and clean values
        //     const trimmedValues = Object.fromEntries(
        //         Object.entries(values).map(([key, value]) => [
            //             key,
        //             typeof value === "string" ? value.trim().replace(/ +/g, " ") : value,
        //         ])
        //     );
        
        //     // Append values to FormData
        //     Object.keys(trimmedValues).forEach((key) => {
            //         if (Array.isArray(trimmedValues[key])) {
        //             if (key === "courses") {
        //                 // Serialize array of objects to JSON
        //                 formData.append(key, JSON.stringify(trimmedValues[key]));
        //             } else {
        //                 trimmedValues[key].forEach((val, idx) => {
            //                     formData.append(`${key}[${idx}]`, val);
        //                 });
        //             }
        //         } else {
        //             formData.append(key, trimmedValues[key]);
        //         }
        //     });
        //     if (type === "update") {
        //         setLoading(true);
        //         updateEmail(formData)
        //             .then(res => {
            //                 Swal.fire({
        //                     icon: "success",
        //                     title: "Success",
        //                     text: "updated Successfully",
        //                 });
        //             })
        //             .catch(err => console.error(err))
        //             .finally(() => setLoading(false))
        //     } else {
        //         setLoading(true);
        //         try {
        //             await AddcreateEmail(formData);
        //             Swal.fire({
        //                 icon: "success",
        //                 title: "Success",
        //                 text: "Added Successfully",
        //             });
        //             actions.resetForm();
        //             history.push("/website_management/pages/emailspages/table");
        //             setLoading(false);
        //         } catch (error) {
        //             Swal.fire({
        //                 icon: "error",
        //                 title: "Error",
        //                 text: error.response?.data?.message || "An error occurred",
        //             });
        //             setLoading(false);
        //         }
        //     }
        // }
        onSubmit: async (values, actions) => {
            const formData = new FormData();
            if(values.isOverrideBrand == 0){
                values.from_title = '';
                values.from_email = '';
            }
            // Trim and clean values
            const trimmedValues = Object.fromEntries(
                Object.entries(values).map(([key, value]) => [
                    key,
                    typeof value === "string" ? value.trim().replace(/ +/g, " ") : value,
                ])
            );
            
            // Append values to FormData
            
            Object.keys(trimmedValues).forEach((key) => {
                if (Array.isArray(trimmedValues[key])) {
                    if (key === "courses") {
                        const coursesData = JSON.stringify(trimmedValues[key]);
                        formData.append(key, coursesData);
                    } else {
                        trimmedValues[key].forEach((val, idx) => {
                            formData.append(`${key}[${idx}]`, val);
                        });
                    }
                } else {
                    formData.append(key, trimmedValues[key]);
                }
            });

            
            setLoading(true);
            
            if (type === "update") {
                updateEmail(formData)
                .then(res => {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Updated Successfully",
                    });
                })
                .catch(err => console.error(err))
                .finally(() => {
                    setLoading(false);
                    setRefresh(Date.now())
                })
            } else {
                try {
                    await AddcreateEmail(formData);
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Added Successfully",
                    });
                    actions.resetForm();
                    history.push("/website_management/pages/emailspages/table");
                    setLoading(false);
                } catch (error) {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.response?.data?.message || "An error occurred",
                    });
                    setLoading(false);
                }
            }
        }
        
        
        
    });
    useEffect(() => {
        let data = assignedRec?.length ? assignedRec?.map(item => ({
            course_id: item?.id,
            type: item?.assigned_type || item?.page_type,
        })) : []
        formik.setFieldValue("courses", data)
    }, [assignedRec])
    
    
    useEffect(() => {
        if (type === "update" || type === "open") {
            formik.setFieldValue("id", updateData?.id ?? "");
            formik.setFieldValue("name", updateData?.name ?? "");
            formik.setFieldValue("subject", updateData?.subject ?? "");
            formik.setFieldValue("type", updateData?.type ?? "");
            formik.setFieldValue("tags", updateData?.tags ?? "");
            formik.setFieldValue("isOverrideBrand", updateData?.isOverrideBrand ?? 0);
            formik.setFieldValue("header", updateData?.header ?? "");
            formik.setFieldValue("footer", updateData?.footer ?? "");
            formik.setFieldValue("from_title", updateData?.from_title ?? "");
            formik.setFieldValue("from_email", updateData?.from_email ?? "");
            formik.setFieldValue("attachment", updateData?.attachment === "null" ? null : updateData?.attachment ?? "");
        }
    }, [updateData, refresh]);
    
    
    
    useEffect(() => {
        setDescription(updateData?.header)
        setFooter(updateData?.footer)
    }, [updateData,refresh])
    
    return (
        <div className="card card-profile-info-card">
        <div>
        <div className="new-card-header">
        <div className="card-header pt-0">Email Details</div>
        </div>
        <div className="card-body-inr card-body-info">
        <form onSubmit={formik.handleSubmit}>
        <div className="row">
        <FormField
              field={{ name: "name", label: "Name" }}
              formik={formik}
              col_lg={4}
              col_md={4}
            />
            <div className="col-md-6 col-lg-4">
            <div className="form-group-blk mb-3" title="Email Subject">
            <label>Email Subject*</label>
            <input
            type="text"
            className={
                "form-control" +
                (formik.errors.subject && formik.touched.subject
                    ? " is-invalid"
                    : "")
                }
                placeholder="Enter Email Subject"
                name="subject"
                id="subject"
                value={formik.values.subject}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                </div>
                </div>
                <div className="col-md-6 col-lg-4">
                <div className="form-group-blk mb-3" title="Email Type">
                <label>Email Type</label>
                <Select
                className={
                    "form-control custom-select-box" +
                    (formik.errors.type && formik.touched.type
                        ? " is-invalid"
                        : "")
                    }
                    name="type"
                    value={emailType.find(val => val.value === formik.values.type)}
                    onChange={(option) => {
                        formik.setFieldValue("type", option ? option.value : "");
                    }}
                    onBlur={formik.handleBlur}
                    options={emailType}
                    maxMenuHeight={175}
                    placeholder={"Qualification Enquiry"}
                    isClearable
                    />
                    </div>
                    </div>
                    <div className="col-md-12">
                    <div className="form-group-blk mb-3" title="Useful Tags">
                    <label htmlFor="tags">Useful Tags <i className="fal fa-info-circle"></i></label>
                    
                    <textarea
                    className={"form-control " + (formik.errors.tags && formik.touched.tags ? " is-invalid" : "")}
                    rows="5"
                    placeholder="Useful Tags"
                    value={formik.values.tags}
                    name="tags"
                    id="tags"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    ></textarea>
                    </div>
                    </div>
                    <div className="col-md-6 col-lg-3 d-flex align-items-center mb-2">
                    <label className="mb-0" title="Override Brand From Email & Title">
                    Override Brand From Email & Title <i className="fal fa-info-circle"></i>
                    </label>
                    <div className="toggle-switch ml-2" title="Override Brand From Email & Title">
                    <label className="switch">
                    <input
                    type="checkbox"
                    checked={formik.values?.isOverrideBrand === 1}
                    onChange={(e) =>
                        formik.setFieldValue(
                            "isOverrideBrand",
                            e.target.checked ? 1 : 0
                        )
                    }
                    />
                    <span className="slider slider-round"></span>
                    </label>
                    </div>
                    </div>
                    {formik.values.isOverrideBrand ? (
                        <>
                        {/* <div className="col-md-6 col-lg-4">
                        <div className="form-group-blk mb-3" title="From Email">
                        <label>From Email</label>
                        <input
                        type="text"
                        className={
                            "form-control" +
                            (formik.errors.from_email && formik.touched.from_email
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Enter From Email"
                            name="from_email"
                            id="from_email"
                            value={formik.values.from_email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            />
                            </div>
                            </div> */}
                            <div className="col-md-6 col-lg-4">
                            <div className="form-group-blk mb-4" title="Email address used to send emails from the system, ex. email used for email sentto user when filling in form">
                            <label>System Sent Email From Address * <i className="fal fa-info-circle"></i></label>
                            <Select
                            className={
                                "form-control custom-select-box" +
                                (formik.errors.from_title && formik.touched.from_title ? " is-invalid" : "")
                            }
                            name="from_title"
                            value={
                                formik.values.from_title !== ""
                                ? emailsOptions.find((val) => val?.value == formik.values.from_title) 
                                : ""
                            }
                            onChange={(option) => {
                                formik.setFieldValue("from_title", option ? option.value : "");
                            }}
                            onBlur={formik.handleBlur}
                            options={emailsOptions}
                            maxMenuHeight={175}
                            placeholder={"Select Email"}
                            isClearable
                            />
                            
                            </div>
                            </div>                             
                                </>
                            ) : ""}
                            <div className="col-md-12">
                            <div className="form-group-blk mb-3" title="Email Content">
                            <label>Email Content*</label>
                            <HtmlInputEditor
                            editorState={description}
                            setEditorState={(editorState) => {
                                // setDescription(editorState);
                                formik.setFieldValue('header', editorState);
                            }}
                            hideSign
                            isCKEditor
                            />
                            </div>
                            </div>
                            <div className="col-md-12">
                            <div className="form-group-blk mb-3" title="Email Footer">
                            <label>Email Footer</label>
                            <HtmlInputEditor
                            editorState={footer}
                            setEditorState={(editorState) => {
                                // setDescription(editorState);
                                formik.setFieldValue('footer', editorState);
                            }}
                            hideSign
                            isCKEditor
                            />
                            </div>
                            </div>
                            <FileUploadField
                            name="attachment"
                            label="Email Attachment"
                            formik={formik}
                            accept=".png,.jpg,.jpeg"
                            col_md={12}
                            col_lg={4}
                            />
                            <div className="col-md-12">
                            <div className="edit-icon new-card-header mb-4 mt-1">
                            <h4 className="card-header">
                            Assigned Programmes & Short Courses
                            </h4>
                            </div>
                            </div>
                            <div className="col-md-12">
                            <EmailAssignedProgrammes
                            id={id}
                            assignedRec={assignedRec}
                            setAssignedRec={setAssignedRec}
                            assignloading={assignloading}
                            setAssignLoading={setAssignLoading}
                            type={type}
                            />
                            </div>
                            </div>
                            <div className="form-group form-group-save-cancel mt-4">
                            <PermissionsGate RenderError={() => (
                                <button className="btn btn-save btn-success" disabled>
                                <i className="fal fa-save"></i> Save
                                </button>
                            )} scopes={[type == "create" ? 'wbsemailadd' : 'wbsemailedit']}>
                            
                            <button
                            className="btn btn-save btn-success"
                            type="submit"
                            title="Save"
                            disabled={loading}
                            >
                            {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                            Save
                            </button>
                            </PermissionsGate>
                            
                            <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => {
                                formik.resetForm();
                                setRefresh(Date.now())
                            }}>
                            <i className="fal fa-times"></i>
                            Cancel
                            </button>
                            </div>
                            {Object.keys(formik.errors).map((key) => {
                                if (formik.touched[key]) {
                                    return (
                                        <div className="invalid-feedback d-block" key={key}>
                                        {formik.errors[key]}
                                        </div>
                                    );
                                }
                                return null;
                            })}
                            </form>
                            </div>
                            </div>
                            </div>
                        );
                    };
                    
                    export default EmailsPagesOpt;
                    
import React, { useEffect, useState, useRef } from 'react';
import { GlobalIconNames } from '../../utils/GlobalIconNames';
import { getWebsiteListByBrand } from '../../services/ProgramService';

function PreviewDropdown({ updateData, status, isCampusPage = false, isProgrammePage = false, pageUrl, id, saveChanges }) {
    const [previewDomain, setPreviewDomain] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [activeItem, setActiveItem] = useState(null);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const getPreviewUrl = (item) => {
        let baseUrl = `${item?.siteDomain}`;

        if (pageUrl && pageUrl !== '') {
            if (pageUrl == "blogandnews") {
                return `${baseUrl}/blog/${id}`;
            } else if (pageUrl == "campuspages") {
                return `${baseUrl}/campusLanding/${id}`;
            } else if (pageUrl == "categorylandingpages") {
                return `${baseUrl}/schoolLanding/${id}`;
            } else if (pageUrl == "courselandingpages") {
                return `${baseUrl}/courseLanding/${id}`;
            } else if (isProgrammePage) {
                return `${baseUrl}/${pageUrl}/${id}`;
            } else {
                return `${baseUrl}/${pageUrl}`;
            }
        } else {
            return `${baseUrl}/page/${id}`;
        }
    };

    const formatSiteDomain = (domain) => {
        return domain.replace("https://", "").replace("http://", "");
    };

    useEffect(() => {
        const previewUrl = () => {
            const brandId = !isProgrammePage && isCampusPage ? updateData?.brandTemplete?.map(item => item.BrandID) : updateData?.brandTemplete?.map(item => item.brandID);
            const brandIdData = { brandId: isProgrammePage ? updateData?.brand_ids : brandId }

            getWebsiteListByBrand(brandIdData)
                .then(res => {
                    if (status == 1 || status == "Published") {
                        const website = res?.data?.data?.filter(item => item.branch == "development" || item.branch == "production")
                            .sort((a, b) => formatSiteDomain(a.siteDomain).localeCompare(formatSiteDomain(b.siteDomain)));
                        setPreviewDomain(website)
                    } else {
                        const website = res?.data?.data?.filter(item => item.branch == "development")
                            .sort((a, b) => formatSiteDomain(a.siteDomain).localeCompare(formatSiteDomain(b.siteDomain)));
                        setPreviewDomain(website)
                    }
                })
                .catch(err => {
                    console.error(err, "err")
                })
        }

        if (updateData?.length != 0) {
            previewUrl()
        }

        return () => {
            setPreviewDomain([])
        }
    }, [updateData]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if(saveChanges){
            setActiveItem([])
        }
    }, [saveChanges])

    return (
        <>
            {previewDomain.length > 0 ? (
                <div className="text-md-right action2-blk">
                    <div className="btn-type-filter dropdown-comman">
                        <div className="dropdown" ref={dropdownRef}>
                            <button
                                className={`btn ${isOpen ? 'btn-dark' : 'btn-white-bordered'}`}
                                type="button"
                                id="dropdownMenuPreview"
                                onClick={toggleDropdown}
                                aria-haspopup="true"
                                aria-expanded={isOpen}
                                title="Preview"
                            >
                                <span>
                                    <i className="fal fa-eye"></i> Preview {GlobalIconNames('downarrow')}
                                </span>
                            </button>
                            {isOpen && (
                                <form className="dropdown-menu dropdown-link-menu" style={{ display: 'block' }}>
                                    <ul aria-labelledby="dropdownMenuPreview">
                                        {previewDomain.map((item, index) => (
                                            <li
                                                key={item.siteName + "preview" + index}
                                                title={formatSiteDomain(item.siteDomain)}
                                                className={activeItem == item.id ? 'active' : ''}
                                            >
                                                <a
                                                    href={getPreviewUrl(item)}
                                                    target="_blank"
                                                    onClick={() => {
                                                        setActiveItem(item.id);
                                                        setIsOpen(false);
                                                    }}
                                                >
                                                    {formatSiteDomain(item.siteDomain)}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}

export default PreviewDropdown;
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import $ from "jquery";
import { downloadURL, STUDENT_FILE_DOWNLOAD, FORM_DATE_TIME_FORMAT, FORM_TIME_FORMAT, IMAGE_URL, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_TIME_FORMAT } from "../../../../utils/Constants";
import Select from "react-select";
import {
  AddAssessment,
  GetAssessmentDetails,
  GetAssessmentsData,
  GetCourseDetails,
  GetPastAssessments,
  GetQuestionsByQuiz,
  GetQuiz,
  SendAssessmentInstruction,
  getAssignedGrademixViaAssessment,
  getCampusesList,
  getPracticalAssessmentStudentList,
  getSickAssessmentStudentList,
  getSupplementaryStdList,
} from "../../../../services/CourseService";
import Swal from "sweetalert2";
import { Link, useHistory, useParams } from "react-router-dom";
import HtmlInputEditor from "../../../common/HtmlInputEditor";
import {
  ContentState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
  EditorState,
} from "draft-js";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import PermissionsGate from "../../../../utils/permissionGate";
import { setHours, setMinutes } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { myCompare } from "../../../common/MyCompare";
import { CheckJSONString } from "../../../common/CheckJSONString";
import draftToHtml from "draftjs-to-html";
import { TrimText } from "../../../common/TrimText";
import AddQuizzTable from "./AddQuizzTable";
import { setAssessmentName, updateAssessementType } from "../../../../store/actions";
import ListOfTemplateModal from "../../CourseGradeSettings/ListOfTemplateModal";
import { GradeLetterTemplateList } from "../../../../services/GradeSettingService";
import ProgrammeGradeMixModal from "../../Programs/ProgrammeGradeMixModal";
import PopupComponent from "../../../common/PopupComponent";
import { InitialRender } from "../../../common/Helper";
import { RegStudentColorComb } from "../../../systemadministration/usermanagement/CheckRole";
import Hover from '../../../common/Hover';
import { RenderAssessmentStatus, RenderQuizAttemptResultStatus, RenderStudentStatus } from "../../../../utils/CommonStatusItems";
import DataTableComponentFrontPagination from "../../../common/DataTableComponentFrontPagination";
import { RenderAssessmentType, RenderCategoriesType } from "../../../../utils/CommonGroupingItem";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faGear } from "@fortawesome/free-solid-svg-icons";
import CategoryAssignedProgrammes from "../../../pages/CategoryLandingpages/assignedProgrammes/CategoryAssignedProgrammes";
import CourseBookingForm from "./CourseBookingForm";
import formatUrl from "../../../common/formatUrl";

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const AssessmentForm = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);
  const { id, tab, type, subId, subTab } = useParams();
  const [assessmentType, setAssessmentType] = useState([]);
  const [moderatorAndMarker, setModeratorAndMarker] = useState([]);
  const [campusList, setCampusList] = useState([]);
  const [moderatorSelected, setModeratorSelected] = useState([]);
  const [assessmentTypeSelected, setassessmentTypeSelected] = useState();
  const [gradeSelected, setGradeSelected] = useState();
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [assessmentFile, setAssessmentFile] = useState();
  const [gradeType, setGradeType] = useState([]);
  const [gradeMixGradeType, setGradeMixGradeType] = useState([]);
  const [assessmentFileName, setAssessmentFileName] = useState();
  const [memoFile, setMemoFile] = useState(null);
  const [memofileName, setMemofileName] = useState();
  const [isEditable, setIsEditable] = useState(true);
  const [showAttachment, setShowAttachment] = useState(true);
  const [editorState, setEditorState] = useState("");
  const [assessmentDetails, setAssessmentDetails] = useState({});
  const [selectedDomain, setSelectedDomain] = useState([]);
  const [openDate, setOpenDate] = useState(
    setHours(setMinutes(new Date(), 0), 9)
  );
  const [loading, setLoading] = useState(false);
  const [quizList, setQuizList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [quizTotal, setQuizTotal] = useState("");
  const [quizLabel, setQuizLabel] = useState("");
  const [quizLoading, setQuizLoading] = useState(false);
  const [assessmentLoad, setAssessmentLoad] = useState(false);
  const [quizLoad, setquizLoad] = useState(false);
  const [apiDataLoaded, setApiDataLoaded] = useState(false);
  const [isDurationDisabled, setIsDurationDisabled] = useState(false);
  const [isAppealDisabled, setIsAppealDisabled] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [passMarkValue, setPassMarkValue] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [selectedGradeMixTemplate, setSelectedGradeMixTemplate] = useState({});
  const [selectedTemplateObj, setSelectedTemplateObj] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState(false);
  const [statusAvailableList, setStatusAvailableList] = useState([]);
  const [courseResultAvailableList, setCourseResultAvailableList] = useState([]);
  const [assessedMethodAssignedList, setAssessedMethodAssignedList] = useState([]);
  const [assessedMethodAvailableList, setAssessedMethodAvailableList] = useState([]);
  const [statusAvailableAssessmentList, setStatusAvailableAssessmentList] = useState([]);
  const [statusAssignedAssessmentList, setStatusAssignedAssessmentList] = useState([]);

  const [statusAssignedList, setStatusAssignedList] = useState([]);
  const [courseResultAssignedList, setCourseResultAssignedList] = useState([])
  const [statusAssigned, setStatusAssigned] = useState({arr: [], checkObj: {}})
  const [courseResultAssigned, setCourseResultAssigned] = useState({arr: [], checkObj: {}})
  const [assessedMethodAssigned, setAssessedMethodAssigned] = useState({arr: [], checkObj: {}})
  const [statusAvailable, setStatusAvailable] = useState({arr: [], checkObj: {}});
  const [courseResultAvailable, setCourseResultAvailable] = useState({arr: [], checkObj: {}});
  const [assessedMethodAvailable, setAssessedMethodAvailable] = useState({arr: [], checkObj: {}});
  const [searchAssigned, setSearchAssigned] = useState("");
  const [availableStudentList, setAvailableStudentList] = useState([])
  const [availableAssessmentList, setAvailableAssessmentList] = useState([])
  const [availableSearch, setAvailableSearch] = useState("");
  const [availableLoading, setAvailableLoading] = useState(false);
  const [assignedStudentList, setAssignedStudentList] = useState([])
  const [assignedAssessmentList, setAssignedAssessmentList] = useState([])
  const [assignloading, setAssignloading] = useState(false);
  const [overrideAssessList, setOverrideAssessList] = useState([]);
  const [overrideSearch, setOverrideSearch] = useState("");
  const [overrideLoading, setOverrideLoading] = useState(false);
  const [overrideStatusList, setOverrideStatusList] = useState([]);
  const [overrideStatus, setOverrideStatus] = useState({arr: [], checkObj: {}});
  const [overrideAssessTypeList, setOverrideAssessTypeList] = useState([]);
  const [overrideAssessType, setOverrideAssessType] = useState({arr: [], checkObj: {}});  
  const [refresh, setRefresh] = useState(false);
  const [checkedAllRows, setCheckedAllRows] = useState([]);
  const [tempCheckedAllData, setTempCheckedAllData] = useState([]);
  const [lettertemplateIndex, setLettertemplateIndex] = useState();
  const [reload, setReload] = useState(false);
  const [gradingStandard, setGradingStandard] = useState();
  const [gradeMixError, setGradeMixError] = useState([]);
  const [assignLoading, setAssignLoading] = useState(false)
  const [overrideMarkFor, setOverrideMarkFor] = useState("")
  const [selectedStudentId, setSelectedStudentId] = useState();
  const [appealReason, setAppealReason] = useState('');
  const [studentMessage, setStudentMessage] = useState('');
  const [selectedFileForAppeal, setSelectedFileForAppeal] = useState([]);
  const [appealError, setAppealError] = useState("")
  const [newAssignee, setNewAssignee] = useState([])
  const [overRideId, setOverRideId] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [overrideSelected, setOverrideSelected] = useState({});


  useEffect(() => {
    if (quizTotal) {
      let arr = tempCheckedAllData.map(item => ({...item, assessment_total: quizTotal}))
      setTempCheckedAllData([...arr])
    }
  }, [checkedAllRows])

  useEffect(() => {
    let newData = tempCheckedAllData;
    if (newData.length && newData[lettertemplateIndex]?.selectedTemplate) {
      setTempCheckedAllData((prevData) => {
        const newData = [...prevData];
        newData[lettertemplateIndex].selectedTemplate =
          selectedGradeMixTemplate;
        let templateGrdaeMixPassMarkArr = [];
        let templateGrdaeMixArray =
          newData[lettertemplateIndex].selectedTemplate?.gradeOptions?.length &&
          newData[lettertemplateIndex].selectedTemplate?.gradeOptions.map(
            (item) => {
              templateGrdaeMixPassMarkArr.push({
                value: item?.id,
                label: item?.text,
              });
              newData[lettertemplateIndex].templateOptions =
                templateGrdaeMixPassMarkArr;
            }
          );
        return newData;
      });
    } else if (newData.length) {
      setTempCheckedAllData((prevData) => {
        const newData = [...prevData];
        newData[lettertemplateIndex] = {
          ...newData[lettertemplateIndex],
          selectedTemplate: selectedGradeMixTemplate,
        };
        let templateGrdaeMixPassMarkArr = [];
        let templateGrdaeMixArray =
          newData[lettertemplateIndex].selectedTemplate?.gradeOptions?.length &&
          newData[lettertemplateIndex].selectedTemplate?.gradeOptions.map(
            (item) => {
              templateGrdaeMixPassMarkArr.push({
                value: item?.id,
                label: item?.text,
              });
              newData[lettertemplateIndex].templateOptions =
                templateGrdaeMixPassMarkArr;
            }
          );
        return newData;
      });
      // newData[lettertemplateIndex] = {...newData[lettertemplateIndex], selectedTemplate}
    }
  }, [selectedGradeMixTemplate, reload]);
  let templatePassMarkArr = [];
  let templateArray =
    selectedTemplate?.gradeOptions?.length &&
    selectedTemplate?.gradeOptions.map((item) => {
      templatePassMarkArr.push({
        value: item?.id,
        label: item?.text,
      });
    });

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };


  const sortingName = (rowA, rowB) => {
    const name1 = rowA.name ? rowA.name.toString() : "";
    const name2 = rowB.name ? rowB.name.toString() : "";
    return name1.localeCompare(name2);
  };


  const sortingStudentName = (rowA, rowB) => {
    const name1 = rowA.full_name ? rowA.full_name.toString() : "";
    const name2 = rowB.full_name ? rowB.full_name.toString() : "";
    return name1.localeCompare(name2);
  };


  const stdNoSort = (rowA, rowB) => {
    const name1 = rowA?.student_crm_id ? +rowA.student_crm_id : 0;
    const name2 = rowB?.student_crm_id ? +rowB.student_crm_id : 0;
    return name1 - name2;
  };


  const passMarkRobotArr = [
    {
      label: "Red",
      value: 0,
    },
    {
      label: "Yellow",
      value: 49,
    },
    {
      label: "Green",
      value: 100,
    },
  ]

  useEffect(() => {
    let data = formik.values.AssessmentType == 14 && overrideSelected && type !== "open" ? {
      intake_id : id,
      assessment_id: overrideSelected.id,
      override_mark_ass_id: overRideId
    } : {
      intake_id : id,
      assessment_id: subId,
      override_mark_ass_id: overRideId
    }
    if (assessmentTypeSelected == 8) {
      setAvailableLoading(true)
      getSupplementaryStdList(data).then(res => {
        setAvailableStudentList(res.data.list)
        setCourseResultAvailableList(res.data.course_result)
        setStatusAvailableList(res.data.status)
        setAvailableLoading(false)
      }
      ).catch(err => {
        console.error("error", err)
        setAvailableLoading(false)
      })
    } else if (assessmentTypeSelected == 13 || assessmentTypeSelected == 14) {
      setAvailableLoading(true)
      getPracticalAssessmentStudentList(data).then(res => {
        setAvailableAssessmentList(res.data.list)
        setAssessedMethodAvailableList(res.data.assessed_method)
        setStatusAvailableAssessmentList(res.data.status)
        setAvailableLoading(false)
      }
      ).catch(err => {
        setAvailableLoading(false)
        console.error("error", err)
      })
    }
  }, [refresh, assessmentTypeSelected , overrideSelected])

  useEffect(() => {
    setAssignloading(true)
    let data = formik.values.AssessmentType == 14 && overrideSelected && type !== "open" ? {
      intake_id : id,
      assessment_id: overrideSelected.id,
      type: "assigned",
    } : {
      intake_id : id,
      assessment_id: subId,
      type: "assigned", 
      override_mark_ass_id: formik.values.AssessmentType == 14 ? overRideId : null
    }
    if (assessmentTypeSelected == 8) {
      getSupplementaryStdList(data).then(res => {
        setAssignedStudentList(res.data.list)
        setCourseResultAssignedList(res.data.course_result)
        setStatusAssignedList(res.data.status)
        setAssignloading(false)
      }
      ).catch(err => {
        setAssignloading(false)
        console.error("error", err)
      })
    } else if (assessmentTypeSelected == 9) {
      getSickAssessmentStudentList(data).then(res => {
        setAssignedStudentList(type === "duplicate" ? [] : res.data.list.map(item => ({...item, isAssigned: true})))
        setCourseResultAssignedList(res.data.course_result)
        setStatusAssignedList(res.data.status)
        setAssignloading(false)
      }
      ).catch(err => {
        setAssignloading(false)
        console.error("error", err)
      })
    }
    else if (((assessmentTypeSelected == 13) || assessmentTypeSelected == 14)) {
      getPracticalAssessmentStudentList(data).then(res => {
        setAssignedAssessmentList(res.data.list)
        setAssessedMethodAssignedList(res.data.assessed_method)
        setStatusAssignedAssessmentList(res.data.status)
        setAssignloading(false)
      }
      ).catch(err => {
        setAssignloading(false)
        console.error("error", err)
      })
    }
  }, [refresh, assessmentTypeSelected , overrideSelected])


  useEffect(()=>{
    setOverrideLoading(true);
    const values = {
      intake_id: id,
      assessment_id: subId,
      duplicate: type == "duplicate" ? true : false,
      is_practical_assessment : assessmentTypeSelected == 14 ? 1 : 0,
      praticalAttempt: assessmentTypeSelected == 14 && "praticalAttempt"
    }
    GetPastAssessments(values).then((res)=>{
      if(res.status==200){
        setOverrideAssessList(res.data.result);
        setOverrideStatusList(res.data.statusFilter);
        setOverrideAssessTypeList(res.data.assessmentTypeFilter);
      }
    }).catch((err)=>{
      console.log(err);
    }).finally(()=>{
      setOverrideLoading(false);
    })
  },[refresh , assessmentTypeSelected == 14])

  const overrideAssessColumns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      sortFunction: sortingName,
      cell: (row) => {
        return (
          <span
            title={row.name}
            className="overflow-ellipsis2 ">
            {row.name ?
              <Link className="as-text-blue curser feature-name"
                target="_blank"
                to={row.Assmenttype == "no" ? `/courseAdministration/coursesdetails/${id}/${tab}/open/assigned/Details/${row.id}` : `/courseAdministration/assessment/quizzes/open/details/${row.smart_assignment_id}`}
              >
                <span className="textLimit100">{row.name}</span>
              </Link> :
              "-"}
          </span>
        )
      }
    },
    {
      name: "Assessment Type",
      selector: "type",
      sortable: true,
      cell: (row) => (
        row.type ? RenderAssessmentType(row.type).html : "-"
      )
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      cell: (row) => row.status ? RenderAssessmentStatus(row.status).html : "-",
    },
    {
      name: "Due Date",
      selector: "close_date_time_mili",
      sortable: true,
      cell: (row) => (
        row.close_date_time_mili ?
          <>
            <p className="right-space">{moment.unix(row.close_date_time_mili).format(TABLE_DATE_FORMAT)}</p>
            <p>{moment.unix(row.close_date_time_mili).format(TABLE_TIME_FORMAT)}</p>
          </>
          : "-"
      )
    },
    {
      name: "Actions",
      selector: "Action",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
            <Link
              title="Open"
              className="btn btn-primary rounded-circle"
              target="_blank"
              to={row.Assmenttype == "no" ? `/courseAdministration/coursesdetails/${id}/${tab}/open/assigned/Details/${row.id}` : `/courseAdministration/assessment/quizzes/open/details/${row.smart_assignment_id}`}
            >
              <i className="fal fa-folder-open"></i>
            </Link>
            {row.id==formik.values.override_mark_ass_id ? <button
              title="Remove"
              type="button"
              className="btn btn-danger rounded-circle"
              onClick={() => {
                formik.setFieldValue("override_mark_ass_id", "");
                formik.setFieldValue("override_mark_ass_type", "");
                formik.setFieldValue("maxGradeAchievable", "");
                setCheckedAllRows([]);
                setTempCheckedAllData([]);
              }}
            >
              <i className="fal fa-minus"></i>
            </button> :
              <button
                title="Add"
                type="button"
                className="btn btn-primary rounded-circle"
                onClick={() => {
                  formik.setFieldValue("override_mark_ass_id", row.id);
                  formik.setFieldValue("override_mark_ass_type", row.ass_type);
                  formik.setFieldValue("maxGradeAchievable", row.maximum_grade_achievable);
                  getOverrideGrademixList(row.id)
                  setOverRideId(row.id)
                }}
              >
                <i className="fal fa-plus"></i>
              </button>}
          </div>
        </div>
      ),
    }
  ])


  const studentColumns = useMemo(() => [
    {
      name: "Student",
      selector: "full_name",
      sortable: true,
      sortFunction: sortingStudentName,
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <span className={"assigned-title-blk  name-icon cat-dark-red "}>
              {row?.photo ? (
                <img
                  src={`${IMAGE_URL}/${row?.photo
                    .replaceAll("/home/myaie/public_html/", "")
                    .replace("public/", "")}`}
                  alt="AIE"
                />
              ) : (
                InitialRender([
                  row.first_name,
                  row.last_name,
                ])
              )}
              <span
                className={`profile-box-2-status ${
                  row.activity_status
                    ? row.activity_status.toLowerCase() == "online"
                      ? "Online"
                      : row.activity_status.toLowerCase() == "away"
                        ? "Away"
                        : "Offline"
                    : "Offline"
                  }`}
              >
                <i className="fas fa-circle"></i>
              </span>
              <Hover
                firstName={row.first_name}
                lastName={row.last_name}
                photo={row.photo}
                email={row.email}
                mobile={row.mobile}
                right={true}
                // role="Sales"
                status={row.status}
                activity_status={row.activity_status}
                showNumber={true}
                number={row.student_crm_id}
              />
            </span>
            <Link
              to={`/studentAdministration/students/open/${row.student_id}/general`}
              target="_blank"
              className="as-text-blue curser feature-name width-155"
            >
              <span className="textLimit100">{row.full_name}</span>
            </Link>
          </div>
        </div>
      ),
    },
    {
      name: "Student No.",
      selector: "student_crm_id",
      sortFunction: stdNoSort,
      sortable: true,
      cell: (row) =>
        row.student_crm_id ? (
          <span className="feature-name">
            <span className="textLimit100">{row.student_crm_id}</span>
          </span>
        ) : (
          "-"
        ),
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      cell: (row) => row.status ? RenderStudentStatus(row.status).html : "-",
    },
    {
      name: "Course Grade",
      selector: "course_grade",
      sortable: true,
      cell: (row) => row.course_grade ? row.course_grade : "-",
    },
    {
      name: "Course Result",
      selector: "pass_status",
      // sortFunction: dateSort,
      sortable: true,
      cell: (row) => row.pass_status ? RenderQuizAttemptResultStatus(row.pass_status).html : "-",
    },
    {
      name: "Actions",
      selector: "Action",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
            <Link
              title="Open"
              className="btn btn-primary rounded-circle"
              to={`/studentAdministration/students/open/${row.student_id}/general`}
              target="_blank"
            >
              <i className="fal fa-folder-open"></i>
            </Link>
            {assessmentTypeSelected == 8 || assessmentTypeSelected == 14 ? row.isAssigned ? <button
              title="Remove"
              type="button"
              className="btn btn-danger rounded-circle"
              onClick={() => handleRemoveStd(row)}
            >
              <i className="fal fa-user-minus"></i>
            </button> :
              <button
                title="Add"
                type="button"
                className="btn btn-primary rounded-circle"
                onClick={() => handleAddStd(row)}
              >
                <i className="fal fa-user-plus"></i>
              </button> : assessmentTypeSelected == 9 && !row.isAssigned ? <button
                title="Add"
                type="button"
                className="btn btn-primary rounded-circle"
                data-toggle="modal"
                data-target="#assignStudent"
                onClick={() => setSelectedStudentId(row.student_id)}
              >
                <i className="fal fa-user-plus"></i>
              </button> : ""}
          </div>
        </div>
      ),
    },
  ]);

  const selfAssessdColumns = useMemo(() => [
    {
      name: "Student",
      selector: "full_name",
      sortable: true,
      sortFunction: sortingStudentName,
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <span className={"assigned-title-blk  name-icon cat-dark-red "}>
              {row?.photo ? (
                <img
                  src={`${IMAGE_URL}/${row?.photo
                    .replaceAll("/home/myaie/public_html/", "")
                    .replace("public/", "")}`}
                  alt="AIE"
                />
              ) : (
                InitialRender([
                  row.first_name,
                  row.last_name,
                ])
              )}
              <span
                className={`profile-box-2-status ${
                  row.activity_status
                    ? row.activity_status.toLowerCase() == "online"
                      ? "Online"
                      : row.activity_status.toLowerCase() == "away"
                        ? "Away"
                        : "Offline"
                    : "Offline"
                  }`}
              >
                <i className="fas fa-circle"></i>
              </span>
              <Hover
                firstName={row.first_name}
                lastName={row.last_name}
                photo={row.photo}
                email={row.email}
                mobile={row.mobile}
                right={true}
                // role="Sales"
                status={row.status}
                activity_status={row.activity_status}
                showNumber={true}
                number={row.student_crm_id}
              />
            </span>
            <Link
              to={`/studentAdministration/students/open/${row.student_id}/general`}
              target="_blank"
              className="as-text-blue curser feature-name width-155"
            >
              <span className="textLimit100">{row.full_name}</span>
            </Link>
          </div>
        </div>
      ),
    },
    {
      name: "Student Number",
      selector: "student_crm_id",
      sortFunction: stdNoSort,
      sortable: true,
      cell: (row) =>
        row.student_crm_id ? (
          <span className="feature-name">
            <span className="textLimit100">{row.student_crm_id}</span>
          </span>
        ) : (
          "-"
        ),
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      cell: (row) => row.status ? RenderStudentStatus(row.status).html : "-",
    },
    // {
    //   name: "Learning Method",
    //   selector: "type",
    //   // sortFunction: dateSort,
    //   sortable: true,
    //   // cell: (row) => row.pass_status ? RenderQuizAttemptResultStatus(row.pass_status).html : "-",
    //   cell: (row) => row.type ? row.type : "-",
    // },
    {
      name: "Actions",
      selector: "Action",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
            <Link
              title="Open"
              className="btn btn-primary rounded-circle"
              to={`/studentAdministration/students/open/${row.student_id}/general`}
              target="_blank"
            >
              <i className="fal fa-folder-open"></i>
            </Link>
            {(assessmentTypeSelected == 13) ? row.isAssigned ? <button
              title="Remove"
              type="button"
              className="btn btn-danger rounded-circle"
              onClick={() => handleRemoveAssessment(row)}
            >
              <i className="fal fa-user-minus"></i>
            </button> :
              <button
                title="Add"
                type="button"
                className="btn btn-primary rounded-circle"
                onClick={() => handleAddAssessment(row)}
              >
                <i className="fal fa-user-plus"></i>
              </button> : ""}
          </div>
        </div>
      ),
    },
  ]);

  const lecturerAssessdColumns = useMemo(() => [
    {
      name: "Student",
      selector: "full_name",
      sortable: true,
      sortFunction: sortingStudentName,
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <span className={"assigned-title-blk  name-icon cat-dark-red "}>
              {row?.photo ? (
                <img
                  src={`${IMAGE_URL}/${row?.photo
                    .replaceAll("/home/myaie/public_html/", "")
                    .replace("public/", "")}`}
                  alt="AIE"
                />
              ) : (
                InitialRender([
                  row.first_name,
                  row.last_name,
                ])
              )}
              <span
                className={`profile-box-2-status ${
                  row.activity_status
                    ? row.activity_status.toLowerCase() == "online"
                      ? "Online"
                      : row.activity_status.toLowerCase() == "away"
                        ? "Away"
                        : "Offline"
                    : "Offline"
                  }`}
              >
                <i className="fas fa-circle"></i>
              </span>
              <Hover
                firstName={row.first_name}
                lastName={row.last_name}
                photo={row.photo}
                email={row.email}
                mobile={row.mobile}
                right={true}
                // role="Sales"
                status={row.status}
                activity_status={row.activity_status}
                showNumber={true}
                number={row.student_crm_id}
              />
            </span>
            <Link
              to={`/studentAdministration/students/open/${row.student_id}/general`}
              target="_blank"
              className="as-text-blue curser feature-name width-155"
            >
              <span className="textLimit100">{row.full_name}</span>
            </Link>
          </div>
        </div>
      ),
    },
    {
      name: "Student Number",
      selector: "student_crm_id",
      sortFunction: stdNoSort,
      sortable: true,
      cell: (row) =>
        row.student_crm_id ? (
          <span className="feature-name">
            <span className="textLimit100">{row.student_crm_id}</span>
          </span>
        ) : (
          "-"
        ),
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      cell: (row) => row.status ? RenderStudentStatus(row.status).html : "-",
    },
    {
      name: "Assessment Method",
      selector: "type",
      sortable: true,
      cell: (row) => row.is_self_assessed ? RenderCategoriesType(row.is_self_assessed, "programme")?.html : "-",
    },
    {
      name: "Actions",
      selector: "Action",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
            <Link
              title="Open"
              className="btn btn-primary rounded-circle"
              to={`/studentAdministration/students/open/${row.student_id}/general`}
              target="_blank"
            >
              <i className="fal fa-folder-open"></i>
            </Link>
            {/* {assessmentTypeSelected != 14 ? (assessmentTypeSelected == 13) ? row.isAssigned ? <button
              title="Remove"
              type="button"
              className="btn btn-danger rounded-circle"
              onClick={() => handleRemoveAssessment(row)}
            >
              <i className="fal fa-user-minus"></i>
            </button> :
              <button
                title="Add"
                type="button"
                className="btn btn-primary rounded-circle"
                onClick={() => handleAddAssessment(row)}
              >
                <i className="fal fa-user-plus"></i>
              </button> : "" : null} */}
          </div>
        </div>
      ),
    },
  ]);

  // const lecturerAvailableColumns = useMemo(() => [
  //   {
  //     name: "Student",
  //     selector: "full_name",
  //     sortable: true,
  //     sortFunction: sortingStudentName,
  //     cell: (row) => (
  //       <div className="assigned-title-block-new">
  //         <div className="assigned-title-itm">
  //           <span className={"assigned-title-blk  name-icon cat-dark-red "}>
  //             {row?.photo ? (
  //               <img
  //                 src={`${IMAGE_URL}/${row?.photo
  //                   .replaceAll("/home/myaie/public_html/", "")
  //                   .replace("public/", "")}`}
  //                 alt="AIE"
  //               />
  //             ) : (
  //               InitialRender([
  //                 row.first_name,
  //                 row.last_name,
  //               ])
  //             )}
  //             <span
  //               className={`profile-box-2-status ${
  //                 row.activity_status
  //                   ? row.activity_status.toLowerCase() == "online"
  //                     ? "Online"
  //                     : row.activity_status.toLowerCase() == "away"
  //                       ? "Away"
  //                       : "Offline"
  //                   : "Offline"
  //                 }`}
  //             >
  //               <i className="fas fa-circle"></i>
  //             </span>
  //             <Hover
  //               firstName={row.first_name}
  //               lastName={row.last_name}
  //               photo={row.photo}
  //               email={row.email}
  //               mobile={row.mobile}
  //               right={true}
  //               // role="Sales"
  //               status={row.status}
  //               activity_status={row.activity_status}
  //               showNumber={true}
  //               number={row.student_crm_id}
  //             />
  //           </span>
  //           <Link
  //             to={`/studentAdministration/students/open/${row.student_id}/general`}
  //             target="_blank"
  //             className="as-text-blue curser feature-name width-155"
  //           >
  //             <span className="textLimit100">{row.full_name}</span>
  //           </Link>
  //         </div>
  //       </div>
  //     ),
  //   },
  //   {
  //     name: "Student Number",
  //     selector: "student_crm_id",
  //     sortFunction: stdNoSort,
  //     sortable: true,
  //     cell: (row) =>
  //       row.student_crm_id ? (
  //         <span className="feature-name">
  //           <span className="textLimit100">{row.student_crm_id}</span>
  //         </span>
  //       ) : (
  //         "-"
  //       ),
  //   },
  //   {
  //     name: "Status",
  //     selector: "status",
  //     sortable: true,
  //     cell: (row) => row.status ? RenderStudentStatus(row.status).html : "-",
  //   },
  //   {
  //     name: "Previous Attempt Result",
  //     selector: "previous_result",
  //     // sortFunction: dateSort,
  //     sortable: true,
  //     cell: (row) => row.previous_result ? RenderQuizAttemptResultStatus(row.previous_result).html : "-",
  //   },
  //   {
  //     name: "Assessment Method",
  //     selector: "type",
  //     sortable: true,
  //     cell: (row) => row.is_self_assessed ? RenderCategoriesType(row.is_self_assessed, "programme")?.html : "-",
  //   },
  //   {
  //     name: "Actions",
  //     selector: "Action",
  //     cell: (row) => (
  //       <div className="assessment-08 btn-dropdown-grp">
  //         <div className="as-buttons d-flex">
  //           <Link
  //             title="Open"
  //             className="btn btn-primary rounded-circle"
  //             to={`/studentAdministration/students/open/${row.student_id}/general`}
  //             target="_blank"
  //           >
  //             <i className="fal fa-folder-open"></i>
  //           </Link>
  //           {(assessmentTypeSelected == 13 || assessmentTypeSelected == 14) ? row.isAssigned ? <button
  //             title="Remove"
  //             type="button"
  //             className="btn btn-danger rounded-circle"
  //             onClick={() => handleRemoveAssessment(row)}
  //           >
  //             <i className="fal fa-user-minus"></i>
  //           </button> :
  //             <button
  //               title="Add"
  //               type="button"
  //               className="btn btn-primary rounded-circle"
  //               onClick={() => handleAddAssessment(row)}
  //             >
  //               <i className="fal fa-user-plus"></i>
  //             </button> : ""}
  //         </div>
  //       </div>
  //     ),
  //   },
  // ]);

  const getOverrideGrademixList = (id) => {
    getAssignedGrademixViaAssessment({assessment_id: id})
    .then((response)=>{
        const data = {
          exportStatus: true,
        };
        GradeLetterTemplateList(data)
          .then((res) => {
            let ls = [...checkedAllRows];
            let newData = response?.data?.getGradeMix.map(
              (gradeMix, index) => {
                ls.push(Number(gradeMix?.grade_id));
                let templateGrdaeMixPassMarkArr = [];
                let tempMixObj = res.data.list_data?.find(
                  (item) => item.id === gradeMix?.grading_template_id
                );
                let templateGrdaeMixArray =
                  tempMixObj?.gradeOptions?.length &&
                  tempMixObj?.gradeOptions.map((item) => {
                    templateGrdaeMixPassMarkArr.push({
                      value: item?.id,
                      label: item?.text,
                    });
                  });
                return {
                  ...gradeMix,
                  selectedTemplate: tempMixObj ? tempMixObj : {},
                  templateOptions: templateGrdaeMixPassMarkArr,
                };
              }
            );
            setCheckedAllRows([...ls]);
            setTempCheckedAllData([...newData]);
          })
          .catch((err) => {
            console.log(err);
          });
    }).catch((err)=>{
      console.log(err,"error")
      })
  }

  useEffect(() => {
    if (props.lmType === "selfplaced") {
      formik.setFieldValue("allowAppeal", false);
    } else {
      formik.setFieldValue("allowAppeal", true);
    }
    setDataLoading(true)
    GetAssessmentsData(id, subId)
      .then((res) => {
        let x = {
          value: "",
          label: "",
        };
        x = res?.data?.assessment_moderator_marker.map((data) => {
          return { ...x, value: `${data.UserID}`, label: `${data.name}` };
        });
        setModeratorAndMarker(x);
        //setting AssessmentType
        let tempAssessmentTypeArr = [];
        const assArr = res?.data?.assessment_type;
        Object.keys(assArr).map((key, index) => {
          tempAssessmentTypeArr.push({
            value: key,
            label: assArr[key],
            key: key,
          });
        });
        setAssessmentType(tempAssessmentTypeArr);
        //setting Grade
        let tempGradeArr = [
          {
            value: "2",
            label: "Letter",
            key: 2,
          },
          {
            value: "3",
            label: "Value",
            key: 3,
          },
        ];
        const gradeArr = res?.data?.grade;
        Object.keys(gradeArr).map((key, index) => {
          tempGradeArr.push({ value: key, label: gradeArr[key], key: key });
        });
        setGradeType(tempGradeArr);
        setGradeMixGradeType(tempGradeArr);
        setDataLoading(false);
        setApiDataLoaded(true);
      })
      .catch((error) => {
        setDataLoading(false);
        setApiDataLoaded(true);
        console.log("error: ", error);
      });
    if (type !== "add") {
      setAssignLoading(true)
      GetAssessmentDetails(subId)
        .then((response) => {
          const tempAssessmentDetails = response?.data;
          // const tempDate2 = new Date(tempAssessmentDetails?.open_date_time).getDate();
          setAssessmentDetails(response?.data);
          dispatch(updateAssessementType(response.data?.ass_type))
          setOverRideId(response.data.override_mark_ass_id)
          formik.setFieldValue("isHybrid", response.data.isHybrid);
          // const blocksFromHTML = isJsonString(response?.data?.details)
          //   ? convertFromRaw(JSON.parse(response?.data?.details))
          //   : "";
          setEditorState(response?.data?.details);
          dispatch(setAssessmentName(response.data.name));
          setTempCheckedAllData(response.data.getGradeMix);

          setAssessmentFile(response.data?.path);
          setAssessmentFileName(response.data?.path);
          setMemoFile(response.data?.path_marking);
          setMemofileName(response.data?.path_marking);
          setGradeSelected(tempAssessmentDetails?.grade);
          setModeratorSelected(
            tempAssessmentDetails && tempAssessmentDetails.assessment_moderator
              ? tempAssessmentDetails.assessment_moderator
              : []
          );
          setassessmentTypeSelected(response?.data?.ass_type);
          response?.data.assessment_marker_multiple &&
            setSelectedDomain(
              response?.data.assessment_marker_multiple?.length > 0 ?  response?.data.assessment_marker_multiple?.split(",") : []
            );
          quizFormik.setFieldValue("quiz_id", response.data?.quiz_id);
          // formik.setFieldValue("isDuration", true)
          // formik.setFieldValue("timeHour", response.data.timeHour)
          // formik.setFieldValue("timeMin", response.data.timeMin)
          formik.setFieldValue("allowAppeal", response.data?.allowAppeal ? true : false);
          if (response.data?.quiz_id) {
            formik.setFieldValue("quiz_id", true);
          }
          setQuizLoading(true);
          let check = CheckJSONString(response?.data?.details);
          if (check) {
            const blocksFromHTML2 = convertFromRaw(
              JSON.parse(response?.data?.details)
            );
            let dataDescr = EditorState.createWithContent(blocksFromHTML2);
            const detail = draftToHtml(
              convertToRaw(dataDescr.getCurrentContent())
            );
            setEditorState(detail ? detail : "<p><p/>");
            setApiDataLoaded(true);
          } else {
            setEditorState(response?.data?.details);
            setApiDataLoaded(true);
          }

          formik.setFieldValue(
            "maximum_attempts",
            response?.data?.maximum_attempts == 0
              ? "0"
              : response?.data?.maximum_attempts
          );
          // setQuizLabel(quizData)

          // response?.data.assessment_marker && setSelectedDomain([...selectedDomain, response?.data.assessment_marker_multiple.split(",")]);
          const data = {
            exportStatus: true,
          };

          GradeLetterTemplateList(data)
            .then(async (res) => {
              let tempObj = res.data.list_data?.find(
                (item) => item.id === response.data.grading_template_id
              );
              setSelectedTemplate(tempObj);
              let ls = [...checkedAllRows];
              let newData = response?.data?.getGradeMix.map(
                (gradeMix, index) => {
                  ls.push(Number(gradeMix?.grade_id));
                  let templateGrdaeMixPassMarkArr = [];
                  let tempMixObj = res.data.list_data?.find(
                    (item) => item.id === gradeMix?.grading_template_id
                  );
                  let templateGrdaeMixArray =
                    tempMixObj?.gradeOptions?.length &&
                    tempMixObj?.gradeOptions.map((item) => {
                      templateGrdaeMixPassMarkArr.push({
                        value: item?.id,
                        label: item?.text,
                      });
                    });
                  return {
                    ...gradeMix,
                    selectedTemplate: tempMixObj ? tempMixObj : {},
                    templateOptions: templateGrdaeMixPassMarkArr,
                  };
                }
              );
              await setTempCheckedAllData([...newData]);
              await setCheckedAllRows([...ls]);
              setAssignLoading(false)
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
              setAssignLoading(false)
            });
          // setSelectedTemplateId(response.data.grading_template_id)
        })
        .catch((error) => {
          console.log("error :", error);
        });
    } else {
      setApiDataLoaded(true);
    }
  }, [quizList, refresh]);

  const getTimeMinutes = (data) => {
    if (`${data}`.length < 2) {
      return "0" + data;
    } else {
      return `${data}`;
    }
  };

  const quizFormik = useFormik({
    initialValues: {
      quiz_id: "",
    },
    validationSchema: Yup.object({
      quiz_id: Yup.string().required("Quiz is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setquizLoad(true);
      $("#quiz").modal("hide");
      GetQuestionsByQuiz(values.quiz_id)
        .then((res) => {
          setQuizTotal(res.data.totalQuizScore);
          let arr = tempCheckedAllData.map(item => {
            return {
              ...item,
              assessment_total: res.data.totalQuizScore
            }
          })
          setTempCheckedAllData([...arr])
          formik.setFieldValue(
            "AssessmentTotal",
            res.data.totalQuizScore.toString()
          );
          // setTempCheckedAllData((prevData) => {
          //   const newData = prevData.map((item) => ({
          //     ...item,
          //     assessment_total: res.data.totalQuizScore || "",
          //   }));
          //   return newData;
          // });
          formik.setFieldValue(
            "isDuration",
            res.data.totalQuizScore.toString()
          );
          formik.setFieldValue(
            "TimeLimitHours",
            res.data.description.time_limit_hours != null &&
              res.data.description.time_limit_hours > 0
              ? res.data.description.time_limit_hours
              : res.data.description.time_limit_minutes > 0
                ? "00"
                : ""
          );
          formik.setFieldValue(
            "TimeLimitMinutes",
            res.data.description.time_limit_minutes != null &&
              res.data.description.time_limit_minutes > 0
              ? res.data.description.time_limit_minutes
              : res.data.description.time_limit_hours > 0
                ? "00"
                : ""
          );
          // formik.setFieldValue("isDuration", 1);
          setShowAttachment(false);
          setQuizLoading(true);
          formik.setFieldValue("quiz_id", values.quiz_id);
          formik.setFieldValue(
            "maximum_attempts",
            res.data.description.maximum_attempts
          );
          formik.setFieldValue("isHybrid", res.data.description.isHybrid);

          setquizLoad(false);
        })
        .catch((err) => {
          console.log(err);
          setquizLoad(false);
        });
    },
  });

  useEffect(() => {
    // formik.setFieldValue("appealOpenDate", formik.values.closeDateAndTime);
    if (quizFormik.values.quiz_id != "") {
      GetQuestionsByQuiz(quizFormik.values.quiz_id).then((res)=>setQuizTotal(res.data.totalQuizScore));
      GetQuiz({ getPaginate: "true", quizId: quizFormik.values.quiz_id }).then(
        (res) => {
          if(Array.isArray(res?.data) && res?.data.length){
            res.data.map((val) => {
              if (val.quid == quizFormik.values.quiz_id) {
                // formik.setFieldValue("AssessmentTotal", val.quid);
                formik.setFieldValue("quiz_id", true);
                setIsDurationDisabled(true);
                setQuizLabel(val.quiz_name.trim());
                setSelectedRows([quizFormik.values.quiz_id])
              }
            });
          }
        }
      );
    }
  }, [quizFormik.values.quiz_id]);


  useEffect(() => {
    setAvailableLoading(true)
    let data = {
      intake_id : id,
      assessment_id: subId,
      override_mark_ass_id: overRideId
    }
    getSickAssessmentStudentList(data).then(res => {
      setAvailableStudentList(res.data.list)
      setAssignedStudentList([])
      setCourseResultAvailableList(res.data.course_result)
      setStatusAvailableList(res.data.status)
      setAvailableLoading(false)
    }
    ).catch(err => {
      console.error("error", err)
      setAvailableLoading(false)
    })
  }, [overRideId])

  useEffect(() => {
    if (formik.values.AssessmentType == 14) {
      quizFormik.setFieldValue("quiz_id", overrideSelected?.quiz_id)
      formik.setFieldValue("quiz_id", overrideSelected?.quiz_id)
      formik.setFieldValue("isDuration", overrideSelected?.timeLimitActive)
      formik.setFieldValue("TimeLimitHours", overrideSelected?.timeHour)
      formik.setFieldValue("TimeLimitMinutes", overrideSelected?.timeMin)
      formik.setFieldValue("AssessmentTotal", overrideSelected ? overrideSelected?.ass_total_mark == 0
          ? "0" : overrideSelected?.ass_total_mark : "")
      formik.setFieldValue("is_Allow_Student", overrideSelected?.is_Allow_Student)
      formik.setFieldValue("is_Moderated", overrideSelected?.is_Moderated)
      formik.setFieldValue("Marker", overrideSelected && overrideSelected?.assessment_marker
          ? overrideSelected?.assessment_marker_multiple
            ? (
              overrideSelected?.assessment_marker +
              "," +
              overrideSelected?.assessment_marker_multiple
            ).split(",")
            : [overrideSelected?.assessment_marker.toString()]
          : [])
    }
  }, [overrideSelected])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      AssessmentName:
        assessmentDetails && assessmentDetails?.name
          ? assessmentDetails?.name
          : "",
      AssessmentType: assessmentDetails
        ? assessmentDetails?.ass_type == 0
          ? "0"
          : assessmentDetails?.ass_type
        : "",
      AssessmentTotal: assessmentDetails
        ? assessmentDetails.ass_total_mark == 0
          ? "0"
          : assessmentDetails?.ass_total_mark
        : "",
      Weight:
        assessmentDetails && assessmentDetails?.ass_weighting
          ? assessmentDetails?.ass_weighting
          : "",
      Code:
        assessmentDetails && assessmentDetails?.code
          ? assessmentDetails?.code
          : "",
      GradeType: assessmentDetails && assessmentDetails?.grade,
      Marker:
        assessmentDetails && assessmentDetails?.assessment_marker
          ? assessmentDetails?.assessment_marker_multiple
            ? (
              assessmentDetails?.assessment_marker +
              "," +
              assessmentDetails?.assessment_marker_multiple
            ).split(",")
            : [assessmentDetails?.assessment_marker]
          : [],
      Moderator:
        assessmentDetails && assessmentDetails?.assessment_moderator
          ? assessmentDetails?.assessment_moderator
          : "",
      Moderated:
        assessmentDetails && assessmentDetails?.notify_moderator
          ? assessmentDetails?.notify_moderator
          : "0",
      AssessmentFile:
        assessmentDetails && assessmentDetails?.path
          ? assessmentDetails?.path
          : "",
      MemoFile:
        assessmentDetails && assessmentDetails?.path_marking
          ? assessmentDetails?.path_marking
          : "",
      TimeLimitHours:
        assessmentDetails.timeHour > 0 && assessmentDetails.timeHour != null
          ? `${assessmentDetails?.timeHour}`.length
            ? getTimeMinutes(assessmentDetails?.timeHour)
            : ""
          : assessmentDetails?.timeMin > 0
            ? "00"
            : "",
      TimeLimitMinutes:
        assessmentDetails?.timeMin > 0 && assessmentDetails?.timeMin != null
          ? `${assessmentDetails?.timeMin}`.length
            ? getTimeMinutes(assessmentDetails?.timeMin)
            : ""
          : assessmentDetails.timeHour > 0
            ? "00"
            : "",
      Identification:
        assessmentDetails && assessmentDetails?.identification ? true : false,
      Camera:
        assessmentDetails && assessmentDetails?.camera
          ? assessmentDetails?.camera
          : "",
      ScreenShare:
        assessmentDetails && assessmentDetails?.screen_share
          ? assessmentDetails?.screen_share
          : "",
      Audio:
        assessmentDetails && assessmentDetails?.audio
          ? assessmentDetails?.audio
          : "",
      openDateAndTime:
        assessmentDetails &&
          assessmentDetails.openDateAndTime &&
          assessmentDetails.openDateAndTime !== "NaN"
          ? new Date(parseInt(assessmentDetails.openDateAndTime))
          : "",
      closeDateAndTime:
        assessmentDetails &&
          assessmentDetails.closeDateAndTime &&
          assessmentDetails.closeDateAndTime !== "NaN"
          ? new Date(parseInt(assessmentDetails.closeDateAndTime))
          : "",
      deadlineDateAndTime:
        assessmentDetails &&
          assessmentDetails.deadlineDateAndTime &&
          assessmentDetails.deadlineDateAndTime !== "NaN"
          ? new Date(parseInt(assessmentDetails.deadlineDateAndTime))
          : "",
      appealCloseDate:
        assessmentDetails &&
          assessmentDetails.appealCloseDate &&
          assessmentDetails.appealCloseDate !== "NaN"
          ? new Date(parseInt(assessmentDetails.appealCloseDate))
          : "",
      appealOpenDate:
        assessmentDetails &&
          assessmentDetails.appealOpenDate &&
          assessmentDetails.appealOpenDate !== "NaN"
          ? new Date(parseInt(assessmentDetails.appealOpenDate))
          : "",
      allowAppeal:
        assessmentDetails && assessmentDetails.allowAppeal ? true : false,
      // deadlineDateAndTime: new Date(),
      // assessmentDetails && assessmentDetails.deadlineDateAndTime ? new Date(parseInt(assessmentDetails.deadlineDateAndTime)) : "",
      isDuration:
        assessmentDetails && assessmentDetails.timeLimitActive ? true : false,
      multiplicator:
        assessmentDetails && assessmentDetails?.multiplicator
          ? assessmentDetails?.multiplicator
          : "",
      offset:
        assessmentDetails && assessmentDetails?.offset
          ? assessmentDetails?.offset
          : "",
      passMark:
        assessmentDetails && assessmentDetails?.pass_mark
          ? assessmentDetails?.pass_mark
          : "",
      details: "",
      quiz_id: "",
      maximum_attempts: assessmentDetails && assessmentDetails?.maximum_attempts ? assessmentDetails?.maximum_attempts : "",
      quizCheck: assessmentDetails && assessmentDetails.quiz_id
        ? true
        : false,
      isHybrid : assessmentDetails && assessmentDetails?.isHybrid ? assessmentDetails?.isHybrid : false, 
      gradeMix: [],
      grading_standard: props?.gradingStandard ? props?.gradingStandard : 4,
      maxGradeAchievable : assessmentDetails && assessmentDetails?.maximum_grade_achievable ? assessmentDetails?.maximum_grade_achievable : 50,
      override_mark_ass_id :  type == "duplicate" ? "" : assessmentDetails && assessmentDetails?.override_mark_ass_id ? assessmentDetails?.override_mark_ass_id : "",
      override_mark_ass_type : "",
      shareWith : assessmentDetails && assessmentDetails?.share_with ? assessmentDetails?.share_with : [],
      isDownloadAllowed : assessmentDetails && assessmentDetails?.is_download_allowed==1 ? true : false,
      is_Moderated : assessmentDetails && assessmentDetails?.is_Moderated==1 ? 1 : 0,
      is_Allow_Student : assessmentDetails && assessmentDetails?.is_Allow_Student==1 ? 1 : 0,
      is_Student_Signature_Required : assessmentDetails && assessmentDetails?.is_Student_Signature_Required==1 ? 1 : 0,
          },
    validationSchema: Yup.object({
      AssessmentName: Yup.string()
        .required("Assessment Name is required")
        .trim(),
      AssessmentType: Yup.string().required("Assessment Type is required"),
      quiz_id: Yup.string().when(["quizCheck", "AssessmentType"], {
        is: (quizCheck, AssessmentType) => quizCheck == true || AssessmentType == 13,
        then: Yup.string().required("Quiz is required"),
        otherwise: Yup.string().notRequired(),
      }),
      // AssessmentTotal: Yup.number()
      //   .required("Assessment Total is required")
      //   .min(0, "Assessment Total cannot be negative"),
      AssessmentTotal: Yup.number().when("grading_standard", {
        is: 2,
        then: Yup.number().notRequired(),
        otherwise: Yup.number()
          .required("Assessment Total is required")
          .min(0, "Assessment Total cannot be Negative"),
      }),
      //Weight: Yup.number().required("Weight is required").min(0, "Weight cannot be negative"),
      // Code: Yup.string().required("Code is required").trim(),
      // GradeType: Yup.string().required("Grade Type is required"),
      GradeType: Yup.string().when("grading_standard", {
        is: 2,
        then: Yup.string().notRequired(),
        otherwise: Yup.string().required("Grade Type is required"),
      }),
      //Marker: Yup.string().required("Marker Name is required"),
      openDateAndTime: Yup.string().when("isRequired", {
        is: () => props.lmType !== "selfplaced",
        then: Yup.string().required("Open Date & Time is required"),
      }),
      closeDateAndTime: Yup.string().when("isRequired", {
        is: () => props.lmType !== "selfplaced",
        then: Yup.string()
          .required("Close Date & Time is required")
          .test(
            "is-greater",
            "Close Date & Time should be greater than open date time",
            function (value) {
              const { openDateAndTime } = this.parent;
              return moment(value).isSameOrAfter(moment(openDateAndTime));
            }
          ),
      }),
      maximum_attempts: Yup.string().when(
        ["quizCheck", "AssessmentType"], 
        {
          is: (quizCheck, AssessmentType) => quizCheck === true && !(AssessmentType == 13 || AssessmentType == 14),
          then: Yup.string().required("Maximum Attempts should be at least 1"),
        }
      ),
      // passMark: Yup.number().when("grading_standard", {
      //       is: 2,
      //       then: Yup.number().notRequired(),
      //       otherwise: Yup.number().when(["GradeType", "AssessmentTotal"], {
      //         is: (grade, assessmentTotal) => grade == 0 && assessmentTotal,
      //         then: Yup.number()
      //           .required("Pass mark is required")
      //           .max(100, "Pass mark cannot be more than 100%"),
      //           // .max(Yup.ref("AssessmentTotal"), "Pass mark cannot be more than Assessment Total or 100"),
      //         otherwise: Yup.number().when(["GradeType", "AssessmentTotal", "maxGradeAchievable"], {
      //           is: (grade, assessmentTotal, maxGrade) => grade == 3 && assessmentTotal,
      //           then: Yup.number()
      //           .required("Pass mark is required")
      //           .max(Yup.ref("AssessmentTotal"), "Pass mark cannot be more than Assessment Total"),         
      //           otherwise: Yup.number().required("Pass mark is required"),
      //         }) 
      //     }),
      //   }),
      passMark: Yup.number().when("grading_standard", {
        is: 2,
        then: Yup.number().notRequired(),
        otherwise: Yup.number().when(["AssessmentType", "maxGradeAchievable"], {
          is: (ass_type, maxGrade) => ass_type == 8 || overrideMarkFor > 0,
          then: Yup.number()
            .required("Pass mark is required")
            .max(Yup.ref("maxGradeAchievable"), "Pass mark cannot be more than Maximum Grade Achievable"),
          otherwise: Yup.number().when(["GradeType", "AssessmentTotal"], {
            is: (grade, assessmentTotal) => grade == 0 && assessmentTotal,
            then: Yup.number()
              .required("Pass mark is required")
              .max(100, "Pass mark cannot be more than 100%"),
            // .max(Yup.ref("AssessmentTotal"), "Pass mark cannot be more than Assessment Total or 100"),
            otherwise: Yup.number().when(["GradeType", "AssessmentTotal"], {
              is: (grade, assessmentTotal) => grade == 3 && assessmentTotal,
              then: Yup.number()
                .required("Pass mark is required")
                .max(Yup.ref("AssessmentTotal"), "Pass mark cannot be more than Assessment Total"),
              otherwise: Yup.number().required("Pass mark is required"),
            })
          })
        }),
      }),
      gradeMix: Yup.mixed().test(
        "required",
        "Please Assign at least One Grade Mix",
        (value) => {
          if (!tempCheckedAllData.length && props.gradingStandard == 2) {
            return false;
          } else {
            let isValid = true;
            let errorArr = [];

            tempCheckedAllData.forEach((item, index) => {
              let errorObj = {
                field_name: [],
                errorMessage: [],
              };

              if (
                item.grading_type != 0 &&
                item.grading_type != 1 &&
                item.grading_type != 2 &&
                item.grading_type != 3
              ) {
                errorObj[`field_name`].push("Grade Type");
                errorObj[`errorMessage`].push(
                  `${item.name}'s Grade Type is required`
                );
                isValid = false;
              }

              if (
                !item.assessment_total ||
                item.assessment_total == "" ||
                item.assessment_total == undefined ||
                item.assessment_total == null
              ) {
                errorObj[`field_name`].push("Assessment Total");
                errorObj[`errorMessage`].push(
                  `${item.name}'s Assessment Total is required`
                );
                isValid = false;
              }

              if (
                item?.aggregation_method_id == 6 && (!item.ass_weighting ||
                  item.ass_weighting == "" ||
                  item.ass_weighting == undefined ||
                  item.ass_weighting == null)
              ) {
                errorObj[`field_name`].push("Weight");
                errorObj[`errorMessage`].push(
                  `${item.name}'s Weight is required`
                );
                isValid = false;
              }

              if (
                item.assessment_total <= 0
              ) {
                errorObj[`field_name`].push("Assessment Total");
                errorObj[`errorMessage`].push(
                  `${item.name}'s Assessment Total Cannot be Negative or 0`
                );
                isValid = false;
              }

              if (
                item.pass_mark === "" ||
                item.pass_mark == undefined ||
                item.pass_mark == null
              ) {
                errorObj[`field_name`].push("Pass Mark");
                errorObj[`errorMessage`].push(
                  `${item.name}'s Pass Mark is required`
                );
                isValid = false;
              }

              if (
                (item.grading_type == 3) && item.assessment_total && item.pass_mark && +item.pass_mark >= +item.assessment_total
              ) {
                errorObj[`field_name`].push("Pass Mark");
                errorObj[`errorMessage`].push(
                  `${item.name}'s Pass Mark cannot be more than Assessment Total`
                );
                isValid = false;
              }

              if (
                item.grading_type == 0 && 100 < +item.pass_mark
              ) {
                errorObj[`field_name`].push("Pass Mark");
                errorObj[`errorMessage`].push(
                  `${item.name}'s Pass Mark cannot be more than 100%`
                );
                isValid = false;
              }

              if (Object.keys(errorObj.field_name).length) {
                errorArr.push(errorObj);
              } else {
                errorArr.push(false)
              }
            });
            setGradeMixError(errorArr);

            return isValid;
          }
        }
      ),
      deadlineDateAndTime: Yup.string()
        .nullable()
        .when("isHybrid", {
          is: (isHybrid) =>
            (isHybrid == true || isHybrid == "true") &&
            props.lmType !== "selfplaced",
          then: Yup.string()
            .nullable()
            .required("Marking Deadline is required")
            .test(
              "is-greater",
              "Marking Date & Time should be greater than closing date time",
              function (value) {
                const { closeDateAndTime } = this.parent;
                return moment(value).isSameOrAfter(moment(closeDateAndTime));
              }
            ),
        }),
      TimeLimitHours: Yup.string().when(("quizCheck", "isDuration"), {
        is: (quizCheck, isDuration) => quizCheck == true || isDuration == true,
        then: Yup.string().required("Time Limit Hours is required"),
      }),
      TimeLimitMinutes: Yup.string().when(("quizCheck", "isDuration"), {
        is: (quizCheck, isDuration) => quizCheck == true || isDuration == true,
        then: Yup.string().required("Time Limit Minutes is required"),
      }),
      maxGradeAchievable: Yup.number().nullable().when(["AssessmentType", "GradeType"], {
        is: (ass_type, grade) => (ass_type == 8 || overrideMarkFor > 0) && grade == 0,
        then: Yup.number()
          .required("Maximum Grade Achievable is required")
          .max(100, "Maximum Grade Achievable cannot be more than 100%"),
        otherwise: Yup.number().when(["AssessmentType", "GradeType", "AssessmentTotal"], {
          is: (ass_type, grade, assessmentTotal) => (ass_type == 8 || overrideMarkFor > 0) && grade == 3 && assessmentTotal,
          then: Yup.number()
            .required("Maximum Grade Achievable is required")
            .max(Yup.ref("AssessmentTotal"), "Maximum Grade Achievable cannot be more than Assessment Total"),
        })
      }),
      override_mark_ass_id : Yup.string().when("AssessmentType", {
        is : "9" || "14",
        then : Yup.string().required("Please select a assessment to override mark for")
      })
    }),
    onSubmit: (values, { resetForm }) => {
      const gradeMix =
        tempCheckedAllData?.length &&
        tempCheckedAllData?.map((data) => ({
          id: data.grade_id ? data.grade_id : data.id,
          offset: parseInt(data?.offset) ? parseInt(data.offset) : "",
          grading_type: parseInt(data?.grading_type),
          multiplicator: parseInt(data?.multiplicator)
            ? parseInt(data.multiplicator)
            : "",
          pass_mark: parseInt(data?.pass_mark),
          grading_template_id: data?.selectedTemplate?.id,
          assessment_total: parseInt(data?.assessment_total),
          ass_weighting: parseInt(data?.ass_weighting)
        }));
      if (
        (values.quizCheck === true || values.isDuration === true) &&
        values.TimeLimitHours == 0 &&
        values.TimeLimitMinutes == 0
      ) {
        formik.setFieldError(
          "TimeLimitHours",
          "Hours must be greater than zero."
        );
        formik.setFieldError(
          "TimeLimitMinutes",
          "Minutes must be greater than zero."
        );
        return;
      } else {
        setAssessmentLoad(true);
        setLoading(true);
        let formData = new FormData();
        type === "open" && formData.set("assessment_id", subId);
        formData.set("intake", id);
        formData.set("name", values?.AssessmentName?.trim());
        formData.set("due", 1);
        formData.set("code", values?.Code);
        formData.set(
          "open_date_time",
          !isNaN(Date.parse(values.openDateAndTime) / 1000)
            ? Date.parse(values.openDateAndTime) / 1000
            : ""
        );
        formData.set(
          "appeal_open_date",
          !isNaN(Date.parse(values.appealOpenDate) / 1000)
            ? Date.parse(values.appealOpenDate) / 1000
            : ""
        );
        formData.set(
          "appeal_close_date",
          !isNaN(Date.parse(values.appealCloseDate) / 1000)
            ? Date.parse(values.appealCloseDate) / 1000
            : ""
        );
        formData.set("allow_appeal", values.allowAppeal ? 1 : 0);
        if (values.allowAppeal === false) {
          formData.set("appeal_open_date", 0);
          formData.set("appeal_close_date", 0);
        }
        formData.set(
          "close_date_time",
          !isNaN(Date.parse(values.closeDateAndTime) / 1000)
            ? Date.parse(values.closeDateAndTime) / 1000
            : ""
        );
        formData.set(
          "marking_deadline_date",
          !isNaN(Date.parse(values.deadlineDateAndTime) / 1000)
            ? Date.parse(values.deadlineDateAndTime) / 1000
            : ""
        );
        formData.set("ass_total_mark", values?.AssessmentTotal);
        if (props?.gradingStandard != 2) {
          formData.set("grade", values.GradeType);
          formData.set("pass_mark", values.passMark);
          formData.set("grading_template_id", selectedTemplate?.id);
          formData.set("multiplicator", values.multiplicator);
          formData.set("offset", values.offset);
        }
        if (props?.gradingStandard == 2) {
          formData.set("gradeMix", JSON.stringify(gradeMix));
        }
        formData.set("ass_weighting", values?.Weight);
        formData.set(
          "ass_type",
          assessmentTypeSelected || formik.values.AssessmentType
        );

        formData.set("share_with", JSON.stringify(values.shareWith));
        formData.set("ass_marker_sent", 0);
        formData.set("ass_moderator_sent", 1);
        formData.set("timeHour", values?.TimeLimitHours);
        formData.set("timeMin", values?.TimeLimitMinutes);
        formData.set("audio", values?.Audio ? 1 : 0);
        formData.set("camera", values?.Camera ? 1 : 0);
        formData.set("identification", values?.Identification ? 1 : 0);
        formData.set("screen_share", values?.ScreenShare ? 1 : 0);
        formData.append("path_marking", memoFile);
        formData.append("timeLimitActive", values.isDuration ? 1 : 0);
        formData.set("details", editorState);
        if (formik.values.quizCheck == true && formik.values.AssessmentType != 13 && formik.values.AssessmentType != 14) {
          formData.append("quiz_id", quizFormik.values.quiz_id);
          formData.append("maximum_attempts", values.maximum_attempts);
        }

        if (formik.values.AssessmentType == 13 || formik.values.AssessmentType == 14) {
          formData.append("quiz_id", quizFormik.values.quiz_id);
          formData.append("is_Moderated", values.is_Moderated);
          formData.append("is_Allow_Student", values.is_Allow_Student);
          formData.append("is_Student_Signature_Required", values.is_Student_Signature_Required);
        }

        if (formik.values.quizCheck == false) {
          formData.append("path", values?.AssessmentFile);
        }
        formData.set("assessment_marker", [...new Set(values.Marker)]);
        formData.set("assessment_moderator", values?.Moderator);
        if (type == "open") {
          formData.append("notify_moderator", values.Moderated);
        }
        if (formik.values.AssessmentType == 8 || overrideMarkFor > 0) {
          formData.set("assigned_std", JSON.stringify(assignedStudentList.map(item => item.student_id)))
          formData.set("maximum_grade_achievable", values.maxGradeAchievable)
        }
        if (formik.values.AssessmentType == 13 || formik.values.AssessmentType == 14) {
          formData.set("assigned_std", JSON.stringify(assignedAssessmentList.map(item => item.student_id)))
        }
        if(formik.values.AssessmentType == 9 || formik.values.AssessmentType == 14){
          formData.set("override_mark_ass_id", values.override_mark_ass_id)
          newAssignee.map((item) => {
            item.files.map((file, ind) => {
              formData.set(`file_${item.student_id}[${ind}]`, file)
            })
            delete item.files
          })
          formData.set(`assigned_std_to_sick`, JSON.stringify(newAssignee));
        }

        formData.set("is_download_allowed", values.isDownloadAllowed ? 1 : 0);

        AddAssessment(formData)
          .then((res) => {
            setLoading(false);
            if(type == "add"){
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Added successfully",
              });
            }else{
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Updated successfully",
              });
            }
            // resetForm();
            // setImage(user);
            setAssessmentLoad(false)
            if(type == "add" || type == "duplicate"){
              history.push(`/courseAdministration/coursesdetails/${id}/assessments/show`);
            }else{
              setRefresh(!refresh)
            }
          })
          .catch((err) => {
            console.log("err :", err);
            setAssessmentLoad(false);
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `${err?.response?.data?.message}`,
            });
          });
      }
    },
  });

  const handleSendInstruction = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("assessment_marker", selectedDomain);
    data.append("intake", id);
    data.append("marking_deadline_date", moment(formik.values.deadlineDateAndTime).format("YYYY-MM-DD HH:mm"));
    data.append("close_date_time", moment(formik.values.closeDateAndTime).format("YYYY-MM-DD HH:mm"));
    data.append("assignmentid", subId);
    data.append("sender", user?.UserID);
    SendAssessmentInstruction(data)
      .then((res) => {
        Swal.fire("Send!", "", "success");
      })
      .catch((error) => {
        console.log("error :", error);
        Swal.fire("Error!", "", "error");
      });
  };

  function markerNameSelected(value) {
    const markerNames = value?.map((obj) => {
      return obj.value;
    });
    setSelectedDomain(markerNames);
    formik.setFieldValue("Marker", markerNames);
  }

  const handleMemoDelete = () => {
    setMemoFile("");
    setMemofileName("");
    formik.setFieldValue("MemoFile", "");
  };

  const handleQuizDelete = (id) => {
    quizFormik.setFieldValue("quiz_id", "");
    setQuizLabel("");
    setIsDurationDisabled(false);
    formik.setFieldValue(
      "AssessmentTotal",
      assessmentDetails
        ? assessmentDetails.ass_total_mark == 0
          ? "0"
          : assessmentDetails?.ass_total_mark
        : ""
    );
    setTempCheckedAllData((prevData) => {
      const newData = prevData.map((item) => ({
        ...item,
        assessment_total: "",
      }));
      return newData;
    });
    formik.setFieldValue(
      "TimeLimitHours",
      assessmentDetails?.timeHour && `${assessmentDetails?.timeHour}`.length
        ? getTimeMinutes(assessmentDetails?.timeHour)
        : "00"
    );
    formik.setFieldValue(
      "TimeLimitMinutes",
      assessmentDetails?.timeMin && `${assessmentDetails?.timeMin}`.length
        ? getTimeMinutes(assessmentDetails?.timeMin)
        : "00"
    );
    // formik.resetForm();
    formik.setFieldValue(
      "isDuration",
      assessmentDetails && assessmentDetails.timeLimitActive ? true : false
    );
    formik.setFieldValue(
      "isAppeal",
      assessmentDetails && assessmentDetails.allowAppeal ? true : false
    );
    formik.setFieldValue(
      "isAppeal",
      assessmentDetails && assessmentDetails.allowAppeal ? true : false
    );
    formik.setFieldValue("quiz_id", "");
    setQuizLoading(true);
    quizFormik.resetForm();
    setSelectedRows(selectedRows.filter(rowId => rowId !== id));
    addQuizzHandler("", "", {});
  };

  const hourHandle = () => {
    let hourArr = [];
    for (let i = 0; i <= 23; i++) {
      hourArr.push({
        value: i.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }),
        label:
          i.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) + "h",
      });
    }
    return hourArr;
  };

  const MinutesHandle = () => {
    let minutesArray = [];

    for (let i = 0; i <= 59; i++) {
      minutesArray.push({
        value: i.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }),
        label:
          i.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) + "min",
      });
    }
    // ()=>{setMinuteArray(minutesArray)}
    return minutesArray;
  };

  const handleAssessment = () => {
    setAssessmentFile("");
    setAssessmentFileName("");
    formik.setFieldValue("AssessmentFile", "");
  };

  const sort_by = (field, reverse, primer) => {
    const key = primer
      ? function (x) {
        return primer(x[field]);
      }
      : function (x) {
        return x[field];
      };
    reverse = !reverse ? 1 : -1;
    return function (a, b) {
      return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a));
    };
  };

  const addQuizzHandler = (qid, qlabel, quizData) => {
    quizFormik.setFieldValue("quiz_id", qid);
    formik.setFieldValue("quiz_id", qid);
    setQuizLabel(qlabel);
    setIsDurationDisabled(true);
    quizFormik.handleSubmit();
    $("#quiz").modal("hide");
  };

  // const clearAppeal = (e) => {	
  //   e.preventDefault();	
  //   formik.setFieldValue("appealCloseDate", '');	
  //   formik.setFieldValue("appealOpenDate", false);	
  // }	
  const appealOlddate = new Date(formik.values.closeDateAndTime)
  const defaultAppealCloseDate = appealOlddate.setDate(appealOlddate.getDate() + 7)


  const handleRemoveStd = (row) => {
    let tempAssign = assignedStudentList
    let tempAvailable = availableStudentList;
    tempAssign.splice(tempAssign.indexOf(row), 1)
    let ab = tempAvailable.map(item => {
      if (item.student_id === row.student_id) {
        return {...item, isAssigned: false}
      }
      return item
    })
    setAssignedStudentList([...tempAssign])
    setAvailableStudentList([...ab])
  }

  const handleRemoveAssessment = (row) => {
    let tempAssign = assignedAssessmentList
    let tempAvailable = availableAssessmentList;
    tempAssign = tempAssign.filter(student => student.student_id != row.student_id);
    let ab = tempAvailable.map(item => {
      if (item.student_id === row.student_id) {
        return { ...item, isAssigned: false }
      }
      return item
    })
    setAssignedAssessmentList([...tempAssign])
    setAvailableAssessmentList([...ab])
  }

  const handleAddAssessment = (row) => {
    let tempAssign = assignedAssessmentList
    let tempAvailable = availableAssessmentList;
    tempAssign.push({ ...row, isAssigned: true })
    let ab = tempAvailable.map(item => {
      if (item.student_id == row.student_id) {
        return { ...item, isAssigned: true }
      }
      return item
    })
    setAssignedAssessmentList([...tempAssign])
    setAvailableAssessmentList([...ab])
  }

  const handleAddStd = (row) => {
    let tempAssign = assignedStudentList
    let tempAvailable = availableStudentList;
    tempAssign.push({...row, isAssigned: true})
    let ab = tempAvailable.map(item => {
      if (item.student_id == row.student_id) {
        return {...item, isAssigned : true}
      }
      return item
    })
    setAssignedStudentList([...tempAssign])
    setAvailableStudentList([...ab])
  }

  const dataToRenderAvailableStd = () => {
    let updatedData = [];
    let allData = availableStudentList;
    if (availableSearch.length) {
      let tempStudent = allData.filter((item) => {
        let includes = item.full_name?.toLowerCase().toString().includes(availableSearch.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempStudentNo = allData.filter((item) => {
        let includes = item.student_crm_id?.toString().toLowerCase().includes(availableSearch.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [...tempStudent, ...tempStudentNo];
      let unique = [...new Set(data)];
      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (statusAvailable.arr?.length) {
      let tempAction = updatedData;
      let tempResult = tempAction.filter((item) => {
        const startsWith = statusAvailable.arr?.find((post, index) => {
          if (post?.toString() === item?.status.toString()) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (courseResultAvailable.arr?.length) {
      let tempMonth = updatedData;
      let tempResult = tempMonth.filter((item) => {
        const startsWith = courseResultAvailable.arr.find((post, index) => {
          let courseResult = item.pass_status
          if (post.toString() === courseResult.toString()) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (availableSearch?.length || statusAvailable.arr?.length || courseResultAvailable.arr?.length) {
      return updatedData;
    } else {
      return availableStudentList;
    }
  };

  const dataToRenderAvailableAss = () => {
    let updatedData = [];
    let allData = availableAssessmentList;
    if (availableSearch.length) {
      let tempStudent = allData.filter((item) => {
        let includes = item.full_name?.toLowerCase().toString().includes(availableSearch.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempStudentNo = allData.filter((item) => {
        let includes = item.student_crm_id?.toString().toLowerCase().includes(availableSearch.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [...tempStudent, ...tempStudentNo];
      let unique = [...new Set(data)];
      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (statusAvailable.arr?.length) {
      let tempAction = updatedData;
      let tempResult = tempAction.filter((item) => {
        const startsWith = statusAvailable.arr?.find((post, index) => {
          if (post?.toString() === item?.status.toString()) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (assessedMethodAvailable.arr?.length) {
      let tempMonth = updatedData;
      let tempResult = tempMonth.filter((item) => {
        const startsWith = assessedMethodAvailable.arr.find((post, index) => {
          let courseResult = item.is_self_assessed
          if (post.toString() === courseResult.toString()) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (availableSearch?.length || statusAvailable.arr?.length || assessedMethodAvailable.arr?.length) {
      return updatedData;
    } else {
      return availableAssessmentList;
    }
  };

  const dataToRenderAssignedStd = () => {
    let updatedData = [];
    let allData = assignedStudentList;
    if (searchAssigned.length) {
      // {console.log("assessmentDetails", assessmentDetails)}
      let tempStudent = allData.filter((item) => {
        let includes = item.full_name?.toLowerCase().toString().includes(searchAssigned.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempStudentNo = allData.filter((item) => {
        let includes = item.student_crm_id?.toString().toLowerCase().includes(searchAssigned.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [...tempStudent, ...tempStudentNo];
      let unique = [...new Set(data)];
      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (statusAssigned.arr?.length) {
      let tempAction = updatedData;
      let tempResult = tempAction.filter((item) => {
        const startsWith = statusAssigned.arr?.find((post, index) => {
          if (post?.toString() === item?.status.toString()) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (courseResultAssigned.arr?.length) {
      let tempMonth = updatedData;
      let tempResult = tempMonth.filter((item) => {
        const startsWith = courseResultAssigned.arr.find((post, index) => {
          let courseResult = item.pass_status
          if (post.toString() === courseResult.toString()) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (searchAssigned?.length || statusAssigned.arr?.length || courseResultAssigned.arr?.length) {
      return updatedData;
    } else {
      return assignedStudentList;
    }
  };

  const dataToRenderAssignedAssessment = () => {
    let updatedData = [];
    let allData = assignedAssessmentList;
    if (searchAssigned.length) {
      let tempStudent = allData.filter((item) => {
        let includes = item.full_name?.toLowerCase().toString().includes(searchAssigned.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempStudentNo = allData.filter((item) => {
        let includes = item.student_crm_id?.toString().toLowerCase().includes(searchAssigned.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [...tempStudent, ...tempStudentNo];
      let unique = [...new Set(data)];
      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (statusAssigned.arr?.length) {
      let tempAction = updatedData;
      let tempResult = tempAction.filter((item) => {
        const startsWith = statusAssigned.arr?.find((post, index) => {
          if (post?.toString() === item?.status.toString()) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (assessedMethodAssigned.arr?.length) {
      let tempMonth = updatedData;
      let tempResult = tempMonth.filter((item) => {
        const startsWith = assessedMethodAssigned.arr.find((post, index) => {
          let courseResult = item.is_self_assessed
          if (post.toString() === courseResult.toString()) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (searchAssigned?.length || statusAssigned.arr?.length || assessedMethodAssigned.arr?.length) {
      return updatedData;
    } else {
      return assignedAssessmentList;
    }
  };


  const dataToRenderOverrideAssess = () =>{
    let updatedData = [];
    let allData = overrideAssessList;
    if(overrideSearch.length){
      let tempAssessment = allData.filter((item)=>{
        let includes = item.name?.toLowerCase().toString().includes(overrideSearch.toLowerCase());
        if(includes){
          return includes;
        }else return null;
      });
      let data = [...tempAssessment];
      let unique = [...new Set(data)];
      updatedData = unique;
    }else{
      updatedData = allData;
    }

    if(overrideStatus.arr?.length){
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item)=>{
        const startsWith = overrideStatus.arr.find(function (post, index) {
          if (post.toString().toLowerCase() === item.status.toString().toLowerCase() ? item.status.toString().toLowerCase() : "") return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      })

      updatedData = tempResult;
    }

    if (overrideAssessType.arr.length) {
      let tempType = updatedData;
      let tempResult = tempType.filter((item) => {
        const startsWith = overrideAssessType.arr.find(function (post, index) {
          if (post.toString().toLowerCase() === item.type.toString().toLowerCase()) {
            return item;
          }
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (overrideSearch?.length || overrideStatus.arr?.length || overrideAssessType.arr?.length) {
      return updatedData;
    }else{
      return overrideAssessList
    }
  }

  const SelectedOverride = () =>{

    useEffect(()=>{
      if(overrideAssessList.length && formik.values.override_mark_ass_id!=""){
        setOverrideSelected(overrideAssessList.find((item) => item.id==formik.values.override_mark_ass_id))
        setOverrideMarkFor(overrideAssessList.find((item) => item.id==formik.values.override_mark_ass_id)?.override_mark_for)
      } else {
        setOverrideSelected({})
        setOverrideMarkFor("")
      }
    },[formik.values.override_mark_ass_id, overrideAssessList])

    return(
      <div className="d-flex select-delete">
        <Link
          className="as-text-blue curser"
          title={overrideSelected?.name}
          target="_blank"
          to={overrideSelected?.Assmenttype == "no" ? `/courseAdministration/coursesdetails/${id}/${tab}/open/assigned/Details/${overrideSelected?.id}` : `/courseAdministration/assessment/quizzes/open/details/${overrideSelected?.smart_assignment_id}`}
        >
          <span className="textLimit100">
            {TrimText(overrideSelected?.name,20)}
          </span>
        </Link>
        <button
          className="btn btn-danger rounded-circle btn-dropdown-item"
          title="Delete"
          onClick={() => {
            formik.setFieldValue("override_mark_ass_id", "");
            formik.setFieldValue("override_mark_ass_type", "");
            formik.setFieldValue("maxGradeAchievable", "")
            setTempCheckedAllData([])
            setCheckedAllRows([])
            setOverrideSelected({})
          }}
          disabled={(formik.values.AssessmentType == 9 && assignedStudentList.length && type === "open") || (formik.values.AssessmentType == 14 && type === "open")}
        >
          <i className="fal fa-trash-alt"></i>
        </button>
      </div>
    )
  }

  const exportDataAvailableStd = () => {
    let data = dataToRenderAvailableStd();
    const header = ["Student", "Student No", "Status", "Course Grade", "Course Result"];

    data = data?.map((row) => {
      return ({
        ...row,
        "Student": row.full_name ? row.full_name : "-",
        "Student No": row.student_crm_id ? row.student_crm_id : "-",
        "Status": row.status ? RenderStudentStatus(row.status).text : "-",
        "Course Grade": row.course_grade ? row.course_grade : "-",
        "Course Result":row.pass_status ? RenderQuizAttemptResultStatus(row.pass_status).text : "-"
      })
    });
    const pdfData = data.map((row) => {
      return [row["Student"], row["Student No"], row["Status"], row["Course Grade"], row["Course Result"]];
    });
    return {
      header,
      data,
      pdfData,
      columnStyles: {}
    }
  };

  const exportDataAvailableAssessment = () => {
    let data = dataToRenderAvailableAss();
    const header = ["Student", "Student Number", "Status", "Assessment Method"];

    data = data?.map((row) => {
      return ({
        ...row,
        "Student": row.full_name ? row.full_name : "-",
        "Student Number": row.student_crm_id ? row.student_crm_id : "-",
        "Status": row.status ? RenderStudentStatus(row.status).text : "-",
        "Assessment Method": row.is_self_assessed ? row.is_self_assessed : "-",
      })
    });
    const pdfData = data.map((row) => {
      return [row["Student"], row["Student Number"], row["Status"], row["Assessment Method"]];
    });
    return {
      header,
      data,
      pdfData,
      columnStyles: {}
    }
  };

  const exportDataAssignedStd = () => {
    let data = dataToRenderAssignedStd();
    const header = ["Student", "Student No", "Status", "Course Grade", "Course Result"];

    data = data?.map((row) => {
      return ({
        ...row,
        "Student": row.full_name ? row.full_name : "-",
        "Student No": row.student_crm_id ? row.student_crm_id : "-",
        "Status": row.status ? RenderStudentStatus(row.status).text : "-",
        "Course Grade": row.course_grade ? row.course_grade : "-",
        "Course Result":row.pass_status ? RenderQuizAttemptResultStatus(row.pass_status).text : "-"
      })
    });
    const pdfData = data.map((row) => {
      return [row["Student"], row["Student No"], row["Status"], row["Course Grade"], row["Course Result"]];
    });
    return {
      header,
      data,
      pdfData,
      columnStyles: {}
    }
  };
  const exportDataAssignedAssessment = () => {
    let data = dataToRenderAssignedAssessment();
    const header = ["Student", "Student Number", "Status", "Assessment Method"];

    data = data?.map((row) => {
      return ({
        ...row,
        "Student": row.full_name ? row.full_name : "-",
        "Student Number": row.student_crm_id ? row.student_crm_id : "-",
        "Status": row.status ? RenderStudentStatus(row.status).text : "-",
        "Assessment Method": row.is_self_assessed ? row.is_self_assessed : "-",
      })
    });
    const pdfData = data.map((row) => {
      return [row["Student"], row["Student Number"], row["Status"], row["Assessment Method"]];
    });
    return {
      header,
      data,
      pdfData,
      columnStyles: {}
    }
  };

  const exportDataOverrideFor = () => {
    let data = dataToRenderOverrideAssess();
    const header = ["Name", "Assessment Type", "Status", "Due Date"];

    data = data?.map((row) => {
      return ({
        ...row,
        "Name": row.name ? row.name : "-",
        "Assessment Type": row.type ? RenderAssessmentType(row.type).text : "-",
        "Status": row.status ? RenderAssessmentStatus(row.status).text : "-",
        "Due Date":row.close_date_time_mili ? `${moment.unix(row.close_date_time_mili).format(TABLE_DATE_FORMAT)} ${moment.unix(row.close_date_time_mili).format(TABLE_TIME_FORMAT)}` : "-"
      })
    });
    const pdfData = data.map((row) => {
      return [row["Name"], row["Assessment Type"], row["Status"], row["Due Date"]];
    });
    return {
      header,
      data,
      pdfData,
      columnStyles: {}
    }
  };

  function handleRemoveFile(file) {
    const selectedFiles = [...selectedFileForAppeal].filter(item => item !== file)
    setSelectedFileForAppeal([...selectedFiles])
  }

  const handleAssignAllStudents = () => {
    const availableStudents = dataToRenderAvailableAss().filter(student => !student.isAssigned);
    
    if (availableStudents.length > 0) {
      let tempAssign = [...assignedAssessmentList];
      let tempAvailable = [...availableAssessmentList];
      
      availableStudents.forEach(student => {
        tempAssign.push({ ...student, isAssigned: true });
      });
  
      tempAvailable = tempAvailable.map(item => {
        if (availableStudents.some(student => student.student_id === item.student_id)) {
          return { ...item, isAssigned: true };
        }
        return item;
      });
  
      setAssignedAssessmentList([...tempAssign]);
      setAvailableAssessmentList([...tempAvailable]);

      // $("#assessmentStudents").modal("hide");
    }
  };

  const closeFunction = () => {
    setAppealReason("Sick")
    setStudentMessage("")
    setSelectedFileForAppeal([])
    setSelectedStudentId()
    $('#assignStudent').modal("hide")
  }

  const handleSaveAppeal = () => {
    if (!studentMessage) {
      setAppealError("Student message is required")
      return
    } else {
      setAppealError("")
    }
    const tempNewAssignee = [...newAssignee]
    tempNewAssignee.push({
      student_id: selectedStudentId,
      studentMessage,
      files: selectedFileForAppeal,
      reason: appealReason
    })
    setNewAssignee(tempNewAssignee)
    const tempAvailableStudent = [...availableStudentList]
    const selectedStudent = tempAvailableStudent.find(item => item.student_id === selectedStudentId)
    tempAvailableStudent.splice(tempAvailableStudent.indexOf(selectedStudent), 1)
    setAvailableStudentList(tempAvailableStudent)
    const tempAssignStd = [...assignedStudentList]
    tempAssignStd.push({...selectedStudent, isAssigned: true})
    setAssignedStudentList(tempAssignStd)
    closeFunction()
  }

  return (
    <div className="card card-profile-info-card">
      <div className="">
        <div className="card-body-inr card-body-info">
          <form onSubmit={formik.handleSubmit}>
            <div className="edit-icon new-card-header justify-content-between">
              <div className="card-header">
                Details{" "}
                {dataLoading ? <i className="fas fa-cog fa-spin"></i> : ""}
              </div>
              {/* {!isEditable && type == "open" ? (
                <PermissionsGate scopes={["cedit"]} errorProps={{ disabled: true }}>
                  <button
                    className="btn btn-save btn-success"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsEditable(true);
                    }}
                    title="Edit"
                  >
                    <i className="fal fa-edit"></i>Edit
                  </button>
                </PermissionsGate>
              ) : (
                ""
              )} */}
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-2">
                <div className="form-group-blk mb-3">
                  <label htmlFor="AssessmentName">Assessment Name *</label>
                  <input
                    disabled={!isEditable && type === "open"}
                    type="text"
                    title="Assessment Name"
                    className={
                      "form-control" +
                      (formik.errors.AssessmentName &&
                        formik.touched.AssessmentName
                        ? " is-invalid"
                        : "")
                    }
                    placeholder="Enter Assessment Name"
                    name="AssessmentName"
                    id="AssessmentName"
                    value={formik.values.AssessmentName || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.AssessmentName &&
                    formik.touched.AssessmentName ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6 col-lg-2">
                <div className="form-group-blk mb-3">
                  <label htmlFor="Weight">Weight</label>
                  <input
                    // disabled={!isEditable && type === "open"}
                    type="number"
                    className={
                      "form-control" +
                      (formik.errors.Weight && formik.touched.Weight
                        ? " is-invalid"
                        : "")
                    }
                    placeholder="Enter Weight"
                    name="Weight"
                    title="Weight"
                    id="Weight"
                    value={formik.values.Weight || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.Weight && formik.touched.Weight ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6 col-lg-2">
                <div className="form-group-blk mb-3">
                  <label htmlFor="Code">Code</label>
                  <input
                    disabled={!isEditable && type === "open"}
                    type="text"
                    className={
                      "form-control" +
                      (formik.errors.Code && formik.touched.Code
                        ? " is-invalid"
                        : "")
                    }
                    placeholder="Enter Code"
                    name="Code"
                    title="Code"
                    id="Code"
                    value={formik.values.Code || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.Code && formik.touched.Code ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>

              {props.gradingStandard != 2 && (
                <div className="col-md-6 col-lg-2">
                  <div className="form-group-blk mb-3">
                    <label htmlFor="AssessmentTotal">Assessment Total *</label>
                    <input
                      disabled={quizLabel}
                      type="number"
                      className={
                        "form-control" +
                        (formik.errors.AssessmentTotal &&
                          formik.touched.AssessmentTotal
                          ? " is-invalid"
                          : "")
                      }
                      placeholder="Enter Assessment Total"
                      name="AssessmentTotal"
                      title="Assessment Total"
                      id="AssessmentTotal"
                      value={
                        formik.values.AssessmentTotal ||
                        quizList.filter((val) => {
                          if (val.label == quizLabel) {
                            return val.value;
                          } else {
                            return "";
                          }
                        }) ||
                        ""
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      min={0}
                    />
                    {/* {formik.errors.AssessmentTotal &&
                    formik.touched.AssessmentTotal ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null} */}
                  </div>
                </div>
              )}
              {props.gradingStandard != 2 && (
                <div className="col-md-6 col-lg-2">
                  <div
                    className={
                      "form-group-blk mb-3" +
                      (formik.errors.GradeType && formik.touched.GradeType
                        ? " is-invalid"
                        : "")
                    }
                  >
                    <label htmlFor="GradeType">Grade Type *</label>
                    <Select
                      isDisabled={!isEditable && type === "open"}
                      className={
                        "form-control custom-select-box" +
                        (formik.errors.GradeType && formik.touched.GradeType
                          ? " is-invalid"
                          : "")
                      }
                      name="GradeType"
                      id="GradeType"
                      title="Grade Type"
                      placeholder={
                        formik.values.GradeType
                          ? formik.values.GradeType
                          : "Grade Type"
                      }
                      options={gradeType}
                      isClearable
                      onBlur={formik.handleBlur}
                      onChange={(value) => {
                        if (value) {
                          formik.setFieldValue("GradeType", value.value);
                          setGradeSelected(value.value);
                          formik.setFieldValue("passMark", "");
                          setSelectedTemplate({});
                        } else {
                          formik.setFieldValue("GradeType", "");
                          formik.setFieldValue("passMark", "");
                          setGradeSelected("");
                        }
                      }}
                      value={gradeType.filter(function (option) {
                        return option.value == formik.values.GradeType;
                      })}
                    />
                  </div>
                </div>
              )}

              {props.lmType !== "selfplaced" ? (
                <>
                  { props.gradingStandard != 2 ? <div className="col-md-6 col-lg-2">
                    <div className="form-group-blk mb-3">
                      <label htmlFor="OpenDate">Open Date &#38; Time *</label>
                      <div className={"datepicker-wrapper"}>
                        <DatePicker
                          selected={formik.values.openDateAndTime}
                          onChange={(date) => {
                            formik.setFieldValue("openDateAndTime", date);
                            setOpenDate(date);
                          }}
                          showTimeSelect
                          filterTime={filterPassedTime}
                          dateFormat={FORM_DATE_TIME_FORMAT}
                          timeFormat={FORM_TIME_FORMAT}
                          showYearDropdown
                          scrollableYearDropdown
                          dateFormatCalendar="MMMM"
                          // className="form-control"
                          className={
                            "form-control cursor-pointer" +
                            (formik.errors.openDateAndTime &&
                              formik.touched.openDateAndTime
                              ? " is-invalid"
                              : "")
                          }
                          disabled={!isEditable && type === "open"}
                          title="Open Date & Time"
                          onBlur={formik.handleBlur}
                          placeholderText="Select Date & Time"
                          timeIntervals={15}
                          onChangeRaw={(e) => e.preventDefault()}
                          popperPlacement={"auto"}
                          popperModifiers={[
                            {
                              name: 'offset',
                              options: {
                                offset: [0, 10], // You can adjust the offset if needed
                              },
                            },
                            {
                              name: 'flip',
                              enabled: false, // Disable flipping if it's causing issues
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                    : <div className="col-md-6 col-lg-2">
                      <div className="form-group-blk mb-3">
                        <label htmlFor="OpenDate">Open Date &#38; Time *</label>
                        <DatePicker
                          selected={formik.values.openDateAndTime}
                          onChange={(date) => {
                            formik.setFieldValue("openDateAndTime", date);
                            setOpenDate(date);
                          }}
                          showTimeSelect
                          filterTime={filterPassedTime}
                          dateFormat={FORM_DATE_TIME_FORMAT}
                          timeFormat={FORM_TIME_FORMAT}
                          showYearDropdown
                          scrollableYearDropdown
                          dateFormatCalendar="MMMM"
                          // className="form-control"
                          className={
                            "form-control cursor-pointer" +
                            (formik.errors.openDateAndTime &&
                              formik.touched.openDateAndTime
                              ? " is-invalid"
                              : "")
                          }
                          disabled={!isEditable && type === "open"}
                          title="Open Date & Time"
                          onBlur={formik.handleBlur}
                          placeholderText="Select Date & Time"
                          timeIntervals={15}
                          onChangeRaw={(e) => e.preventDefault()}
                        />
                      </div>
                    </div>
                  }
                  <div className="col-md-6 col-lg-2">
                    <div className="form-group-blk mb-3">
                      <label htmlFor="CloseDate">Close Date &#38; Time *</label>

                      <DatePicker
                        // popperPlacement="auto" // or "bottom-end"
                        // popperModifiers={{ flip: { behavior: ['right'] }, preventOverflow: { enabled: true }, hide: { enabled: false }, arrow: { enabled: true, placement: 'auto' } }}
                        selected={formik.values.closeDateAndTime}
                        onChange={(date) => {
                          formik.setFieldValue("closeDateAndTime", date);
                          formik.setFieldValue("appealOpenDate", date);
                          let d = new Date(date);
                          let dAppeal = new Date(date);
                          d.setDate(d.getDate() + 14);
                          formik.setFieldValue("deadlineDateAndTime", d);
                          dAppeal.setDate(dAppeal.getDate() + 7);
                          formik.setFieldValue("appealCloseDate", dAppeal);
                          setOpenDate(date);
                        }}
                        showTimeSelect
                        filterTime={filterPassedTime}
                        dateFormat={FORM_DATE_TIME_FORMAT}
                        timeFormat={FORM_TIME_FORMAT}
                        showYearDropdown
                        scrollableYearDropdown
                        dateFormatCalendar="MMMM"
                        // className="form-control"
                        className={
                          "form-control cursor-pointer" +
                          (formik.errors.closeDateAndTime &&
                            formik.touched.closeDateAndTime
                            ? " is-invalid"
                            : "")
                        }
                        disabled={
                          (!isEditable && type === "open") ||
                          (type === "add" && !formik.values.openDateAndTime)
                        }
                        title="Close Date & Time"
                        onBlur={formik.handleBlur}
                        placeholderText="Select Date & Time"
                        timeIntervals={15}
                        onChangeRaw={(e) => e.preventDefault()}
                        minDate={formik.values.openDateAndTime}
                      />
                    </div>
                  </div>
                </>
              ) : null}

              <div className="col-md-6 col-lg-2">
                <div className="form-group-blk mb-3">
                  <label htmlFor="AssessmentType">Type *</label>
                  <Select
                    isDisabled={(!isEditable && type === "open")||(type==="open" && (formik.values.AssessmentType===9 || formik.values.AssessmentType===13 || formik.values.AssessmentType===14))}
                    className={
                      "form-control custom-select-box" +
                      (formik.errors.AssessmentType &&
                        formik.touched.AssessmentType
                        ? " is-invalid"
                        : "")
                    }
                    name="AssessmentType"
                    id="AssessmentType"
                    title="Assessment Type"
                    value={assessmentType.filter(function (option) {
                      return option.value == formik.values.AssessmentType;
                    })}
                    onChange={(value) => {
                      if (value) {
                        formik.setFieldValue("AssessmentType", value.value);
                        formik.setFieldValue("override_mark_ass_id", "");
                        setassessmentTypeSelected(value.value);
                        if(value.value == 9 || (value.value != 9 && formik.values.AssessmentType == 9)){
                          setCheckedAllRows([]);
                          setTempCheckedAllData([]);
                        } else if ((value.value != 13 && formik.values.AssessmentType == 13) || (value.value != 14 && formik.values.AssessmentType == 14)) {
                          formik.setFieldValue("quiz_id" , "")
                          setQuizLabel("");
                          setIsDurationDisabled(false);
                          formik.setFieldValue(
                            "AssessmentTotal",
                            assessmentDetails
                              ? assessmentDetails.ass_total_mark == 0
                                ? "0"
                                : assessmentDetails?.ass_total_mark
                              : ""
                          );
                          setTempCheckedAllData((prevData) => {
                            const newData = prevData.map((item) => ({
                              ...item,
                              assessment_total: "",
                            }));
                            return newData;
                          });
                          formik.setFieldValue(
                            "TimeLimitHours",
                            assessmentDetails?.timeHour && `${assessmentDetails?.timeHour}`.length
                              ? getTimeMinutes(assessmentDetails?.timeHour)
                              : "00"
                          );
                          formik.setFieldValue(
                            "TimeLimitMinutes",
                            assessmentDetails?.timeMin && `${assessmentDetails?.timeMin}`.length
                              ? getTimeMinutes(assessmentDetails?.timeMin)
                              : "00"
                          );
                          // formik.resetForm();
                          formik.setFieldValue(
                            "isDuration",
                            assessmentDetails && assessmentDetails.timeLimitActive ? true : false
                          );
                          formik.setFieldValue(
                            "isAppeal",
                            assessmentDetails && assessmentDetails.allowAppeal ? true : false
                          );
                          formik.setFieldValue(
                            "isAppeal",
                            assessmentDetails && assessmentDetails.allowAppeal ? true : false
                          );
                          setQuizLoading(true);
                          quizFormik.resetForm();
                          addQuizzHandler("", "", {});
                          setSelectedRows(selectedRows.filter(rowId => rowId !== quizFormik.values.quiz_id));
                        }
                      } else {
                        formik.setFieldValue("AssessmentType", "");
                        formik.setFieldValue("override_mark_ass_id", "");
                        setCheckedAllRows([]);
                        setTempCheckedAllData([]);
                        setassessmentTypeSelected("");

                        // pratical assessment 
                        formik.setFieldValue("quiz_id" , "")
                        setQuizLabel("");
                        setIsDurationDisabled(false);
                        formik.setFieldValue(
                          "AssessmentTotal",
                          assessmentDetails
                            ? assessmentDetails.ass_total_mark == 0
                              ? "0"
                              : assessmentDetails?.ass_total_mark
                            : ""
                        );
                        setTempCheckedAllData((prevData) => {
                          const newData = prevData.map((item) => ({
                            ...item,
                            assessment_total: "",
                          }));
                          return newData;
                        });
                        formik.setFieldValue(
                          "TimeLimitHours",
                          assessmentDetails?.timeHour && `${assessmentDetails?.timeHour}`.length
                            ? getTimeMinutes(assessmentDetails?.timeHour)
                            : "00"
                        );
                        formik.setFieldValue(
                          "TimeLimitMinutes",
                          assessmentDetails?.timeMin && `${assessmentDetails?.timeMin}`.length
                            ? getTimeMinutes(assessmentDetails?.timeMin)
                            : "00"
                        );
                        // formik.resetForm();
                        formik.setFieldValue(
                          "isDuration",
                          assessmentDetails && assessmentDetails.timeLimitActive ? true : false
                        );
                        formik.setFieldValue(
                          "isAppeal",
                          assessmentDetails && assessmentDetails.allowAppeal ? true : false
                        );
                        formik.setFieldValue(
                          "isAppeal",
                          assessmentDetails && assessmentDetails.allowAppeal ? true : false
                        );
                        setQuizLoading(true);
                        quizFormik.resetForm();
                        addQuizzHandler("", "", {});
                        setSelectedRows(selectedRows.filter(rowId => rowId !== quizFormik.values.quiz_id));
                      }
                    }}
                    placeholder={
                      formik.values.AssessmentType
                        ? formik.values.AssessmentType
                        : "Select Type"
                    }
                    options={assessmentType.sort(
                      sort_by("label", false, (a) => a.toUpperCase())
                    )}
                    isClearable
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.AssessmentType &&
                    formik.touched.AssessmentType ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
              { props.lmType !== "selfplaced" ? (
                <div className="col-md-6 col-lg-2">
                  <div className="form-group-blk mb-3">
                    <label htmlFor="MarkingDeadLineDate">
                      Marking Deadline Date &#38; Time
                    </label>

                    <DatePicker
                      selected={formik.values.deadlineDateAndTime}
                      onChange={(date) => {
                        formik.setFieldValue("deadlineDateAndTime", date);
                        setOpenDate(date);
                      }}
                      showTimeSelect
                      filterTime={filterPassedTime}
                      dateFormat={FORM_DATE_TIME_FORMAT}
                      timeFormat={FORM_TIME_FORMAT}
                      showYearDropdown
                      scrollableYearDropdown
                      dateFormatCalendar="MMMM"
                      className={
                        "form-control cursor-pointer" +
                        (formik.errors.deadlineDateAndTime &&
                          formik.touched.deadlineDateAndTime
                          ? " is-invalid"
                          : "")
                      }
                      disabled={
                        (!isEditable && type === "open") ||
                        !formik.values.closeDateAndTime
                      }
                      title="Deadline Date & Time"
                      onBlur={formik.handleBlur}
                      placeholderText="Select Date & Time"
                      timeIntervals={15}
                      onChangeRaw={(e) => e.preventDefault()}
                      isClearable={
                        formik.values.quizCheck == true &&
                        formik.values.deadlineDateAndTime
                      }
                      minDate={formik.values.closeDateAndTime}
                    />
                  </div>
                </div>
              ) : null}
              {props.gradingStandard != 2 && (
                <>
                  <div className="col-md-6 col-lg-2">
                    <div className="form-group-blk mb-3">
                      <label htmlFor="multiplicator">
                        Multiplicator{" "}
                        <i
                          className="fal fa-info-circle grade-icon"
                          title="Specify the factor by which the raw score of the assignment will be multiplied. This can be used to adjust the weight of the assignment in the overall grading scheme. For example, a multiplicator of 2 would double the score of this assignment in the context of overall grading. The default value is typically 1."
                        ></i>
                      </label>
                      <input
                        disabled={!isEditable && type === "open"}
                        type="number"
                        className={
                          "form-control" +
                          (formik.errors.multiplicator &&
                            formik.touched.multiplicator
                            ? " is-invalid"
                            : "")
                        }
                        placeholder="Multiplicator"
                        name="multiplicator"
                        title="Multiplicator"
                        id="Multiplicator"
                        value={formik.values.multiplicator || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      // placeholderText="Select Date & Time"
                      // timeIntervals={15}
                      // onChangeRaw={(e) => e.preventDefault()}
                      />
                      {formik.errors.multiplicator &&
                        formik.touched.multiplicator ? (
                        <span className="exclamation">
                          <i className="fal fa-exclamation-circle"></i>
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-2">
                    <div className="form-group-blk mb-3">
                      <label htmlFor="Offset">
                        Offset{" "}
                        <i
                          className="fal fa-info-circle grade-icon"
                          title="Enter a value to be added to each student's raw score for this assignment. This can be used to adjust the difficulty of the assignment or to provide a bonus. For example, an offset of 5 would add 5 points to every student's score. A negative value can be used to subtract points. The default value is typically 0."
                        ></i>
                      </label>
                      <input
                        disabled={!isEditable && type === "open"}
                        type="number"
                        className={
                          "form-control" +
                          (formik.errors.offset && formik.touched.offset
                            ? " is-invalid"
                            : "")
                        }
                        placeholder="Offset"
                        name="offset"
                        title="Offset"
                        id="Offset"
                        value={formik.values.offset || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.offset && formik.touched.offset ? (
                        <span className="exclamation">
                          <i className="fal fa-exclamation-circle"></i>
                        </span>
                      ) : null}
                    </div>
                  </div>
                </>
              )}
              {/* {gradeSelected == "2" || gradeSelected == 1 ? (
                  <div className="col-md-6 col-lg-2">
                  <div
                    className={
                      "form-group-blk mb-3" +
                      (formik.errors.passMark && formik.touched.passMark
                        ? " is-invalid"
                        : "")
                    }
                  >
                      <label>
                        Pass Mark <i className="fal fa-info-circle grade-icon"></i>
                      </label>
                      <Select
                        className={
                          "form-control custom-select-box" +
                          (formik.errors.passMark && formik.touched.passMark
                            ? " is-invalid"
                            : "")
                        }
                        name="pass_mark"
                        value={
                          gradeSelected == "2" ? (
                          templatePassMarkArr.filter(function (option) {
                          return option.value == formik.values.passMark;
                        })
                          ) : (
                          passMarkRobotArr.filter(function (option) {
                              return option.value == formik.values.passMark;
                            })
                          )
                        }
                        onChange={(value) => {
                          if (value) {
                            formik.setFieldValue("passMark", value.value)
                          } else {
                            formik.setFieldValue("passMark", "")
                          }
                        }}
                        isClearable
                        //   onBlur={formik.handleBlur}
                        options={gradeSelected == "2" ? templatePassMarkArr : passMarkRobotArr}
                        maxMenuHeight={175}
                        placeholder={"Pass Mark"}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="col-md-6 col-lg-2">
                <div className="form-group-blk mb-3">
                  <label htmlFor="passMark">Pass Mark <i className="fal fa-info-circle grade-icon"></i></label>
                  <input
                    disabled={!isEditable && type === "open"}
                    type="number"
                    className={
                      "form-control" +
                      (formik.errors.passMark && formik.touched.passMark
                        ? " is-invalid"
                        : "")
                    }
                    placeholder="Pass Mark"
                    name="passMark"
                    title="Pass Mark"
                    id="passMark"
                    value={formik.values.passMark || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholderText="Select Date & Time"
                    timeIntervals={15}
                    onChangeRaw={(e) => e.preventDefault()}
                    minDate={formik.values.appealOpenDate}
                  />
                  {formik.errors.passMark && formik.touched.passMark ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
                )} */}
              {props.lmType !== "selfplaced" ? (
                <div className="col-md-6 col-lg-2">
                  <div className="form-group-blk">
                    <div className="">
                      <label>&nbsp;</label>
                      <div className="custom-check-group">
                        <div
                          className="custom-check custom-control custom-checkbox mt-2"
                          style={{ width: "100px" }}
                        >
                          <input
                            disabled={isAppealDisabled}
                            type="checkbox"
                            className="custom-control-input"
                            id="appealCheckbox"
                            name="select"
                            checked={formik.values.allowAppeal || false}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "allowAppeal",
                                e.target.checked
                              )
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="appealCheckbox"
                          >
                            <div className="mr-3"></div> Appeal
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {formik.values.allowAppeal == true && (
                <>
                  <div className="col-md-6 col-lg-2">
                    <div className="form-group-blk mb-3">
                      <label htmlFor="MarkingDeadLineDate">
                        Appeal Open Date
                      </label>
                      <DatePicker
                        selected={
                          formik.values.appealOpenDate
                            ? formik.values.appealOpenDate
                            : formik.values.closeDateAndTime
                        }
                        onChange={(date) => {
                          let dAppeal = new Date(date || formik.values.closeDateAndTime);
                          let d = new Date(dAppeal);
                          formik.setFieldValue("appealOpenDate", dAppeal);

                          d.setDate(d.getDate() + 7);
                          formik.setFieldValue("appealCloseDate", d);
                          setOpenDate(date);
                        }}
                        showTimeSelect
                        filterTime={filterPassedTime}
                        dateFormat={FORM_DATE_TIME_FORMAT}
                        timeFormat={FORM_TIME_FORMAT}
                        showYearDropdown
                        scrollableYearDropdown
                        dateFormatCalendar="MMMM"
                        className={
                          "form-control cursor-pointer" +
                          (formik.errors.appealOpenDate &&
                            formik.touched.appealOpenDate
                            ? " is-invalid"
                            : "")
                        }
                        disabled={
                          (!isEditable && type === "open") ||
                          !formik.values.closeDateAndTime
                        }
                        title="Close Date & Time"
                        onBlur={formik.handleBlur}
                        placeholderText="Select Date & Time"
                        timeIntervals={15}
                        isClearable={
                          formik.values.closeDateAndTime &&
                          formik.values.appealOpenDate &&
                          new Date(formik.values.closeDateAndTime).getTime() !==
                            new Date(formik.values.appealOpenDate).getTime()
                        }                        
                        minDate={formik.values.closeDateAndTime}
                        onChangeRaw={(e) => e.preventDefault()}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-2">
                    <div className="form-group-blk mb-3">
                      <label htmlFor="MarkingDeadLineDate">
                        Appeal Close Date
                      </label>
                      <DatePicker
                        selected={
                          formik.values.appealCloseDate
                            ? formik.values.appealCloseDate
                            : defaultAppealCloseDate
                        }
                        onChange={(date) => {
                          let d = new Date(date || formik.values.appealOpenDate);
                          if (!date) {d.setDate(d.getDate() + 7); }
                          formik.setFieldValue("appealCloseDate", d);
                          setOpenDate(date);
                        }}
                        showTimeSelect
                        filterTime={filterPassedTime}
                        dateFormat={FORM_DATE_TIME_FORMAT}
                        timeFormat={FORM_TIME_FORMAT}
                        showYearDropdown
                        scrollableYearDropdown
                        dateFormatCalendar="MMMM"
                        className={
                          "form-control cursor-pointer" +
                          (formik.errors.deadlineDateAndTime &&
                            formik.touched.deadlineDateAndTime
                            ? " is-invalid"
                            : "")
                        }
                        disabled={
                          (!isEditable && type === "open") ||
                          (type === "add" && !formik.values.closeDateAndTime)
                        }
                        title="Appeal Date & Time"
                        onBlur={formik.handleBlur}
                        placeholderText="Select Date & Time"
                        timeIntervals={15}
                        isClearable={
                          formik.values.appealCloseDate &&
                          formik.values.appealOpenDate &&
                          new Date(formik.values.appealCloseDate).getTime() !==
                            new Date(formik.values.appealOpenDate).setDate(
                              new Date(formik.values.appealOpenDate).getDate() + 7
                            )
                        }
                        onChangeRaw={(e) => e.preventDefault()}
                        minDate={formik.values.appealOpenDate}
                      />
                    </div>
                  </div>
                </>
              )}
              {formik.values.AssessmentType == 8 || overrideMarkFor > 0 ? <div className="col-md-6 col-lg-2">
                <div className="form-group-blk mb-3">
                  <label htmlFor="maxGradeAchievable"> Maximum Grade Achievable *</label>
                  <input
                    type="number"
                    className={
                      "form-control" +
                      (formik.errors.maxGradeAchievable && formik.touched.maxGradeAchievable
                        ? " is-invalid"
                        : "")
                    }
                    placeholder="Maximum Grade Achievable"
                    name="maxGradeAchievable"
                    title="Maximum Grade Achievable"
                    id="maxGradeAchievable"
                    value={formik.values.maxGradeAchievable || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div> : ""}
              {props.gradingStandard != 2 && (
                <>
                  {gradeSelected == "1" ? (
                    <div className="col-md-6 col-lg-2">
                      <div
                        className={
                          "form-group-blk mb-3" +
                          (formik.errors.passMark && formik.touched.passMark
                            ? " is-invalid"
                            : "")
                        }
                      >
                        <label>
                          Pass Mark{" "}
                          <i
                            className="fal fa-info-circle grade-icon"
                            title="Enter or select the minimum grade a student must achieve to pass this assignment."
                          ></i>
                        </label>
                        <Select
                          className={
                            "form-control custom-select-box" +
                            (formik.errors.passMark && formik.touched.passMark
                              ? " is-invalid"
                              : "")
                          }
                          name="pass_mark"
                          value={passMarkRobotArr.filter(function (option) {
                            return (
                              option.value === parseInt(formik.values.passMark)
                            );
                          })}
                          onChange={(value) => {
                            if (value) {
                              formik.setFieldValue("passMark", value.value);
                            } else {
                              formik.setFieldValue("passMark", "");
                            }
                          }}
                          isClearable
                          //   onBlur={formik.handleBlur}
                          options={passMarkRobotArr}
                          maxMenuHeight={175}
                          placeholder={"Mark"}
                        />
                      </div>
                    </div>
                  ) : gradeSelected == "2" ? (
                    <div className="col-md-6 col-lg-2">
                      <div
                        className={
                          "form-group-blk mb-3" +
                          (formik.errors.passMark && formik.touched.passMark
                            ? " is-invalid"
                            : "")
                        }
                      >
                        <label>
                          Pass Mark{" "}
                          <i
                            className="fal fa-info-circle grade-icon"
                            title="Enter or select the minimum grade a student must achieve to pass this assignment."
                          ></i>
                        </label>
                        <Select
                          className={
                            "form-control custom-select-box" +
                            (formik.errors.passMark && formik.touched.passMark
                              ? " is-invalid"
                              : "")
                          }
                          name="pass_mark"
                          value={templatePassMarkArr.filter(function (option) {
                            return option.value == formik.values.passMark;
                          })}
                          onChange={(value) => {
                            if (value) {
                              formik.setFieldValue("passMark", value.value);
                            } else {
                              formik.setFieldValue("passMark", "");
                            }
                          }}
                          isClearable
                          //   onBlur={formik.handleBlur}
                          options={templatePassMarkArr}
                          maxMenuHeight={175}
                          placeholder={"Mark"}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-6 col-lg-2">
                      <div className="form-group-blk mb-3">
                        <label htmlFor="passMark">
                          {gradeSelected == "0" ? "Pass Mark % " : gradeSelected == "3" ? "Pass Mark Value " : "Pass Mark "}
                          {/* Pass Mark */}
                          <i
                            className="fal fa-info-circle grade-icon"
                            title={gradeSelected == "0" ? "Pass Mark as Percentage" : gradeSelected == "3" ? "Pass Mark as Value" : "Enter or select the minimum grade a student must achieve to pass this assignment."}
                          ></i>
                        </label>
                        <input
                          disabled={!isEditable && type === "open"}
                          type="number"
                          className={
                            "form-control" +
                            (formik.errors.passMark && formik.touched.passMark
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="Pass Mark"
                          name="passMark"
                          title="Pass Mark"
                          id="passMark"
                          value={formik.values.passMark || ""}
                          // onChange={formik.handleChange}
                          onChange={(e) => {
                            formik.setFieldValue("passMark", e.target.value)
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {gradeSelected == "0" ? <span className="percentage-sign">%</span> : ""}
                        {formik.errors.passMark && formik.touched.passMark ? (
                          <span className="exclamation">
                            <i className="fal fa-exclamation-circle"></i>
                          </span>
                        ) : null}
                      </div>
                    </div>
                  )}
                </>
              )}
              {props.gradingStandard != 2 && gradeSelected == "2" && (
                <div className="search-filter-div-right mb-4">
                  <label>&nbsp;</label>
                  <div className="filter-search-bar-blk">
                    <div className="add-ticket-blk dropdown-comman">
                      <button
                        // className="btn btn-primary"
                        data-toggle="modal"
                        data-target="#letterTemplatemodal"
                        className="btn btn-save btn-success"
                        type="button"
                        title="Select Template"
                      // onClick={() =>
                      //   history.push(
                      //     `/courseAdministration/coursesdetails/${id}/${tab}/add`
                      //   )
                      // }
                      // title="Select Template"
                      >
                        <i className="fal fa-file-alt"></i>Select Template
                      </button>
                      {selectedTemplate && (
                        <>
                          {selectedTemplate && selectedTemplate?.name && (
                            <div className="d-flex select-delete">
                              <Link
                                className="as-text-blue curser"
                                title={selectedTemplate?.name}
                                to={`/courseAdministration/gradingTemplate/edit/${selectedTemplate?.id}`}
                                target="_blank"
                              // onClick={() => {
                              //   $("#letterTemplatemodal").modal("hide");
                              // }}
                              >
                                <span className="textLimit100">
                                  {TrimText(selectedTemplate?.name,20)}
                                </span>
                              </Link>
                              <button
                                className="btn btn-danger rounded-circle btn-dropdown-item"
                                title="Delete"
                                onClick={() => setSelectedTemplate({})}
                              >
                                <i className="fal fa-trash-alt"></i>
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {formik.values.AssessmentType == 9 || formik.values.AssessmentType == 14 ?
                <div className="search-filter-div-right mb-4">
                  <label>&nbsp;</label>
                  <div className="filter-search-bar-blk">
                    <div className="add-ticket-blk dropdown-comman">
                      <button data-toggle="modal" data-target="#overrideMarkFor" className={"btn btn-save btn-success " + (formik.errors.override_mark_ass_id && formik.touched.override_mark_ass_id ? " file-req is-invalid" : "")} type="button" title="Override Mark For" disabled={(formik.values.AssessmentType == 9 && assignedStudentList.length && type == "open") || (formik.values.AssessmentType == 14 && type == "open")}>
                        <i className="fal fa-history"></i>Override Mark For
                      </button>
                      {overrideAssessList.length && formik.values.override_mark_ass_id && formik.values.override_mark_ass_id!="" ? 
                        <SelectedOverride />
                        : <></>}
                    </div>
                  </div>
                </div> : null}
              {formik.values.AssessmentType == 13 || formik.values.AssessmentType == 14 || formik.values.quizCheck != true && <div className="col-md-6 col-lg-2">
                <div className="form-group-blk">
                  <div className="">
                    <label>&nbsp;</label>
                    <div className="custom-check-group">
                      <div
                        className="custom-check custom-control custom-checkbox mt-2"
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="downloadCheckbox"
                          name="select"
                          checked={
                            formik.values.isDownloadAllowed
                              ? formik.values.isDownloadAllowed
                              : ""
                          }
                          onChange={(e) =>
                            formik.setFieldValue(
                              "isDownloadAllowed",
                              e.target.checked
                            )
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="downloadCheckbox"
                        >
                          <div className="mr-3"></div> Allow brief download after completion
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
            </div>
            {props.gradingStandard == 2 && (
              <>
                <hr />
                <div className="assessment-grade-setting-header">
                  <div className="card-header">
                    Grade Settings{" "}
                    {dataLoading ? <i className="fas fa-cog fa-spin"></i> : ""}
                  </div>
                  <button
                    data-toggle="modal"
                    data-target="#grademixmodal"
                    className="btn btn-save btn-success"
                    type="button"
                    title="Assign Grade Mix"
                    // disabled={assessmentLoad ? true : false}
                    disabled={formik.values.AssessmentType == 9}
                  >
                    {assignLoading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-plus"></i>} Assign Grade Mix
                  </button>
                </div>
                <div>
                  <p>
                    <i className="fal fa-info-circle "></i> Assessments created
                    within Programmes using the "Grade Mix" Grading standard
                    require Grade Mix's to be selected and grade settings to be
                    assigned individually to each Grade Mix to make up the total
                    assessment grade.
                  </p>
                </div>
                {tempCheckedAllData?.length ? (
                  tempCheckedAllData.map((gradeMix, index) => {
                    return (
                      <>
                        <div className="assessment-grade-setting-header">
                          <div className="card-header">
                            <Link
                              className="gradeMix-sidebar-color"
                              to={`/courseAdministration/gradeMix/${gradeMix?.qualification_id}/details/${type !== "add" ? gradeMix.grade_id : gradeMix.id}/open`}
                            >
                              <i className="fal fa-layer-group"></i>{" "}
                              {gradeMix?.name}
                            </Link>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 col-lg-2">
                            <div
                              className={
                                "form-group-blk mb-3" +
                                (gradeMixError[index]?.field_name?.includes("Grade Type")
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              <label htmlFor={`GradeType_${gradeMix.id}`}>
                                Grade Type *
                              </label>
                              <Select
                                // isDisabled={!isEditable && type === "open"}
                                // isDisabled={props.gradingStandard == 2}
                                isDisabled={formik.values.AssessmentType == 9}
                                className={
                                  "form-control custom-select-box" +
                                  (gradeMixError[index]?.field_name?.includes("Grade Type")
                                    ? " is-invalid"
                                    : "")
                                }
                                name={`GradeType_${gradeMix.id}`}
                                id={`GradeType_${gradeMix.id}`}
                                title="Grade Type"
                                placeholder={"Grade Type"}
                                options={gradeMixGradeType}
                                isClearable
                                // onBlur={formik.handleBlur}
                                onChange={(value) => {
                                  setTempCheckedAllData((prevData) => {
                                    const newData = [...prevData];
                                    newData[index].grading_type =
                                      value?.value || null;
                                    return newData;
                                  });
                                  // tempCheckedAllData[index].gradeType_value = value?.value || null;
                                  // setSelectedGrades((prevSelectedGrades) => {
                                  //   const updatedGrades = [
                                  //     ...prevSelectedGrades,
                                  //   ];
                                  //   updatedGrades[index] = value?.value || null;
                                  //   return updatedGrades;
                                  // });
                                  tempCheckedAllData[index].pass_mark = "";
                                  setSelectedGradeMixTemplate({});
                                }}
                                value={gradeMixGradeType.filter(function (option) {
                                  return option.value == gradeMix.grading_type;
                                })}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-2">
                            <div className="form-group-blk mb-3">
                              <label htmlFor={`AssessmentTotal_${gradeMix.id}`}>
                                Assessment Total *
                              </label>
                              <input
                                disabled={quizLabel || formik.values.AssessmentType == 9}
                                type="number"
                                className={
                                  "form-control" +
                                  (gradeMixError[index]?.field_name?.includes("Assessment Total")
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter Assessment Total"
                                name={`AssessmentTotal_${gradeMix.id}`}
                                title="Assessment Total"
                                id={`AssessmentTotal_${gradeMix.id}`}
                                value={
                                  gradeMix.assessment_total ||
                                  quizList.filter((val) => {
                                    if (val.label == quizLabel) {
                                      return val.value;
                                    } else {
                                      return "";
                                    }
                                  }) ||
                                  ""
                                }
                                onChange={(event) => {

                                  if (
                                    event.target &&
                                    event.target.value !== undefined
                                  ) {
                                    event.persist();
                                    setTempCheckedAllData((prevData) => {
                                      const newData = [...prevData];
                                      newData[index].assessment_total =
                                        event?.target?.value || ""
                                      return newData;
                                    });
                                  }
                                  // formik.setFieldTouched("gradeMix",true,true)
                                }}
                                min={0}
                              />
                            </div>
                          </div>
                          {gradeMix?.aggregation_method_id == 6 && <div className="col-md-6 col-lg-1">
                            <div className="form-group-blk mb-3">
                              <label htmlFor={`weight_${gradeMix.id}`}>
                                Weight *
                              </label>
                              <input
                                // disabled={quizLabel}
                                type="number"
                                className={
                                  "form-control" +
                                  (gradeMixError[index]?.field_name?.includes("Weight")
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter Weight"
                                name={`weight_${gradeMix.id}`}
                                title="Weight"
                                id={`weight_${gradeMix.id}`}
                                value={gradeMix?.ass_weighting || ""}
                                onChange={(event) => {
                                  if (
                                    event.target &&
                                    event.target.value !== undefined
                                  ) {
                                    event.persist();
                                    setTempCheckedAllData((prevData) => {
                                      const newData = [...prevData];
                                      newData[index].ass_weighting =
                                        event?.target?.value || ""
                                      return newData;
                                    });
                                  }
                                  // formik.setFieldTouched("gradeMix",true,true)
                                }}
                                min={0}
                              />
                            </div>
                          </div>}
                          <div className={`col-md-6 ${gradeMix.aggregation_method_id == 6 ? "col-lg-1" : "col-lg-2"}`}>
                            <div className="form-group-blk mb-3">
                              <label htmlFor={`Offset_${gradeMix.id}`}>
                                Offset{" "}
                                <i
                                  className="fal fa-info-circle grade-icon"
                                  title="Enter a value to be added to each student's raw score for this assignment. This can be used to adjust the difficulty of the assignment or to provide a bonus. For example, an offset of 5 would add 5 points to every student's score. A negative value can be used to subtract points. The default value is typically 0."
                                ></i>
                              </label>
                              <input
                                disabled={!isEditable && type === "open"}
                                type="number"
                                className={
                                  "form-control" +
                                  (gradeMixError[index]?.field_name?.includes("Offset")
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Offset"
                                name={`Offset_${gradeMix.id}`}
                                title="Offset"
                                id={`Offset_${gradeMix.id}`}
                                value={gradeMix?.offset || ""}
                                onChange={(event) => {
                                  if (
                                    event.target &&
                                    event.target.value !== undefined
                                  ) {
                                    event.persist();
                                    setTempCheckedAllData((prevData) => {
                                      const newData = [...prevData];
                                      newData[index].offset =
                                        event?.target?.value;
                                      return newData;
                                    });
                                  }
                                }}
                              // onBlur={formik.handleBlur}
                              />
                              {gradeMixError[index]?.field_name?.includes("Offset") ? (
                                <span className="exclamation">
                                  <i className="fal fa-exclamation-circle"></i>
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-2">
                            <div className="form-group-blk mb-3">
                              <label htmlFor={`multiplicator_${gradeMix.id}`}>
                                Multiplicator{" "}
                                <i
                                  className="fal fa-info-circle grade-icon"
                                  title="Specify the factor by which the raw score of the assignment will be multiplied. This can be used to adjust the weight of the assignment in the overall grading scheme. For example, a multiplicator of 2 would double the score of this assignment in the context of overall grading. The default value is typically 1."
                                ></i>
                              </label>
                              <input
                                disabled={!isEditable && type === "open"}
                                type="number"
                                className={
                                  "form-control" +
                                  (gradeMixError[index]?.field_name?.includes("Multiplicator")
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Multiplicator"
                                name={`multiplicator_${gradeMix.id}`}
                                title="Multiplicator"
                                id={`multiplicator_${gradeMix.id}`}
                                value={gradeMix.multiplicator || ""}
                                onChange={(event) => {
                                  if (
                                    event.target &&
                                    event.target.value !== undefined
                                  ) {
                                    event.persist();
                                    setTempCheckedAllData((prevData) => {
                                      const newData = [...prevData];
                                      newData[index].multiplicator =
                                        event?.target?.value;
                                      return newData;
                                    });
                                  }
                                }}
                              // onBlur={formik.handleBlur}
                              />
                              {gradeMixError[index]?.field_name?.includes("Multiplicator")? (
                                <span className="exclamation">
                                  <i className="fal fa-exclamation-circle"></i>
                                </span>
                              ) : null}
                            </div>
                          </div>
                          {gradeMix.grading_type == "1" ? (
                            <div className="col-md-6 col-lg-2">
                              <div
                                className={
                                  "form-group-blk mb-3" +
                                  (gradeMixError[index]?.field_name?.includes("Pass Mark")
                                    ? " is-invalid"
                                    : "")
                                }
                              >
                                <label htmlFor={`pass_mark_${gradeMix.id}`}>
                                  Pass Mark{" "}
                                  <i
                                    className="fal fa-info-circle grade-icon"
                                    title="Enter or select the minimum grade a student must achieve to pass this assignment."
                                  ></i>
                                </label>
                                <Select
                                  // isDisabled={props.gradingStandard == 2}
                                  className={
                                    "form-control custom-select-box" +
                                    (gradeMixError[index]?.field_name?.includes("Pass Mark")
                                      ? " is-invalid"
                                      : "")
                                  }
                                  name={`pass_mark_${gradeMix.id}`}
                                  id={`pass_mark_${gradeMix.id}`}
                                  title="Pass Mark"
                                  value={passMarkRobotArr.filter(function (
                                    option
                                  ) {
                                    return (
                                      option.value ==
                                      parseInt(gradeMix.pass_mark)
                                    );
                                  })}
                                  onChange={(value) => {
                                    setTempCheckedAllData((prevData) => {
                                      const newData = [...prevData];
                                      newData[index].pass_mark =
                                        value?.value == 0
                                          ? 0
                                          : value?.value || "";
                                      return newData;
                                    });
                                  }}
                                  isClearable
                                  // onBlur={formik.handleBlur}
                                  options={passMarkRobotArr}
                                  maxMenuHeight={175}
                                  placeholder={"Pass Mark"}
                                />
                              </div>
                            </div>
                          ) : gradeMix.grading_type == "2" ? (
                            <div className="col-md-6 col-lg-2">
                              <div
                                className={
                                  "form-group-blk mb-3" +
                                  (gradeMixError[index]?.field_name?.includes("Pass Mark")
                                    ? " is-invalid"
                                    : "")
                                }
                              >
                                <label htmlFor={`pass_mark_${gradeMix.id}`}>
                                  Pass Mark{" "}
                                  <i
                                    className="fal fa-info-circle grade-icon"
                                    title="Enter or select the minimum grade a student must achieve to pass this assignment."
                                  ></i>
                                </label>
                                <Select
                                  // isDisabled={props.gradingStandard == 2}
                                  className={
                                    "form-control custom-select-box" +
                                    (gradeMixError[index]?.field_name?.includes("Pass Mark")
                                      ? " is-invalid"
                                      : "")
                                  }
                                  name={`pass_mark_${gradeMix.id}`}
                                  id={`pass_mark_${gradeMix.id}`}
                                  title="Pass Mark"
                                  value={gradeMix.templateOptions?.filter(
                                    function (option) {
                                      return option.value == gradeMix.pass_mark;
                                    }
                                  )}
                                  onChange={(value) => {
                                    setTempCheckedAllData((prevData) => {
                                      const newData = [...prevData];
                                      newData[index].pass_mark =
                                        value?.value || "";
                                      return newData;
                                    });
                                  }}
                                  isClearable
                                  //   onBlur={formik.handleBlur}
                                  options={gradeMix?.templateOptions}
                                  maxMenuHeight={175}
                                  placeholder={"Mark"}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="col-md-6 col-lg-2">
                              <div className="form-group-blk mb-3">
                                <label htmlFor={`pass_mark_${gradeMix.id}`}>
                                  {gradeMix.grading_type == "0" ? "Pass Mark % " : gradeMix.grading_type == "3" ? "Pass Mark Value " : "Pass Mark "}
                                  {/* Pass Mark{" "} */}
                                  <i
                                    className="fal fa-info-circle grade-icon"
                                    title={gradeMix.grading_type == "0" ? "Pass Mark as Percentage" : gradeMix.grading_type == "3" ? "Pass Mark as Value" : "Enter or select the minimum grade a student must achieve to pass this assignment."}
                                  ></i>
                                </label>
                                <input
                                  // disabled={!isEditable && type === "open"}
                                  // disabled={props.gradingStandard == 2}
                                  type="number"
                                  className={
                                    "form-control" +
                                    (gradeMixError[index]?.field_name?.includes("Pass Mark")
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Pass Mark"
                                  name={`pass_mark_${gradeMix.id}`}
                                  title="Pass Mark"
                                  id={`pass_mark_${gradeMix.id}`}
                                  value={gradeMix.pass_mark || ""}
                                  onChange={(event) => {
                                    if (
                                      event.target &&
                                      event.target.value !== undefined
                                    ) {
                                      event.persist();
                                      setTempCheckedAllData((prevData) => {
                                        const newData = [...prevData];
                                        newData[index].pass_mark =
                                          event?.target?.value;
                                        return newData;
                                      });
                                    }
                                  }}
                                // onBlur={formik.handleBlur}
                                />
                                {gradeMix.grading_type == "0" ? <span className="percentage-sign">%</span> : ""}
                                {(gradeMixError[index]?.field_name?.includes("Pass Mark")) ? (
                                  <span className="exclamation">
                                    <i className="fal fa-exclamation-circle"></i>
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          )}
                          {gradeMix.grading_type == "2" && (
                            <div className="search-filter-div-right mb-4">
                              <label>&nbsp;</label>
                              <div className="filter-search-bar-blk">
                                <div className="add-ticket-blk dropdown-comman">
                                  <button
                                    id={`lettertemplate_${gradeMix.id}`}
                                    data-toggle="modal"
                                    data-target="#letterTemplatemodal"
                                    className="btn btn-save btn-success"
                                    type="button"
                                    title="Select Template"
                                    // disabled={props.gradingStandard == 2}
                                    onClick={() => {
                                      setLettertemplateIndex(index);
                                      setReload(!reload);
                                    }}
                                  >
                                    <i className="fal fa-file-alt"></i>Select
                                    Template
                                  </button>
                                  {gradeMix?.selectedTemplate && (
                                    <>
                                      {gradeMix.selectedTemplate &&
                                        gradeMix.selectedTemplate.name && (
                                          <div className="d-flex select-delete">
                                            <Link
                                              className="as-text-blue curser"
                                              title="Open"
                                              to={`/courseAdministration/gradingTemplate/edit/${gradeMix?.selectedTemplate?.id}`}
                                            // onClick={() => {
                                            //   $("#letterTemplatemodal").modal("hide");
                                            // }}
                                            >
                                              <span className="textLimit100">
                                                {gradeMix.selectedTemplate.name}
                                              </span>
                                            </Link>
                                            <button
                                              className="btn btn-danger rounded-circle btn-dropdown-item"
                                              type="button"
                                              title="Delete"
                                              // disabled={
                                              //   props.gradingStandard == 2
                                              // }
                                              onClick={() => {
                                                setLettertemplateIndex(index);
                                                setSelectedGradeMixTemplate({});
                                                setTempCheckedAllData(
                                                  (prevData) => {
                                                    const newData = [
                                                      ...prevData,
                                                    ];
                                                    newData[
                                                      index
                                                    ].templateOptions = [];
                                                    newData[
                                                      index
                                                    ].pass_mark = "";
                                                    return newData;
                                                  }
                                                );
                                              }}
                                            >
                                              <i className="fal fa-trash-alt"></i>
                                            </button>
                                          </div>
                                        )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    );
                  })
                ) : (
                  <></>
                )}
              </>
            )}
            <hr />
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group-blk mb-3">
                  <label>Detailed instructions</label>
                  {(apiDataLoaded || type == "add") && (
                    <HtmlInputEditor
                      editorState={editorState}
                      setEditorState={setEditorState}
                      readOnly={!isEditable && type === "open"}
                      isInValid={editorState}
                      isCKEditor={true}
                      hideSign={true}
                    />
                  )}
                  {/* <input
              disabled={!isEditable && type === "open"}
                type="text"
                className={"form-control" + (formik.errors.DetailedInstruction && formik.touched.DetailedInstruction ? " is-invalid" : "")}
                placeholder="Text editor here"
                name="DetailedInstruction"
                value={formik.values.DetailedInstruction || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              /> */}
                  {formik.errors.DetailedInstruction &&
                    formik.touched.DetailedInstruction ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
            </div>

            {type === "open" && formik.values.AssessmentType != 14 && formik.values.AssessmentType != 13 && (
              <div className="custom-check custom-control custom-checkbox mt-2 ">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheckModerated"
                  name="select"
                  checked={formik.values.Moderated == "1"}
                  disabled={!isEditable && type === "open"}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "Moderated",
                      e.target.checked == true ? "1" : "0"
                    )
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor="customCheckModerated"
                >
                  Moderated
                </label>
              </div>
            )}
            {formik.values.AssessmentType == 8 || formik.values.AssessmentType == 9 ? <>
              <hr />
              <div className="edit-icon new-card-header">
                <div className="card-header">Assigned Students</div>
              </div>

              <DataTableComponentFrontPagination
                isInsidePopUp
                data={dataToRenderAssignedStd()}
                columns={studentColumns}
                loading={assignloading}
                // totalRows={assignTotalRows}
                exportData={exportDataAssignedStd}
                search={searchAssigned}
                setSearch={setSearchAssigned}
                exportFileName="Assigned_Students"
                tableButton={[
                  <button className="btn btn-primary" title="Assign Students" type="button" data-toggle="modal" data-target="#availableStudents" disabled={(formik.values.AssessmentType == 9 && !formik.values.override_mark_ass_id)}>
                    <i className="fal fa-plus"></i>Assign Students
                  </button>
                ]}
                filters={[
                  {
                    filterName: "Status",
                    optionArr: statusAssignedList,
                    state: statusAssigned,
                    setState: setStatusAssigned,
                    renderLabelFunction: RenderStudentStatus,
                    uniqueId: "assignedStatus"
                  },
                  {
                    filterName: "Course Result",
                    optionArr: courseResultAssignedList,
                    state: courseResultAssigned,
                    setState: setCourseResultAssigned,
                    renderLabelFunction: RenderQuizAttemptResultStatus,
                    uniqueId: "assigned"
                  }
                ]}
              />
            </> : ""}

            <hr />
            <div className="edit-icon new-card-header">
              <div className="card-header">Resources</div>
            </div>
            {(formik.values.AssessmentType != 13 && formik.values.AssessmentType != 14) && <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="form-icon-group" title="">
                  <div className="prefix-sufix-box">
                    {/* { props.gradingStandard != 2 && */}
                    <div
                      style={{ zIndex: "inherit" }}
                      className="custom-control custom-radio custom-control-inline"
                      title="Quiz"
                    >
                      <input
                        type="radio"
                        id="quiz1"
                        name="quizCheck"
                        checked={formik.values.quizCheck == true}
                        onChange={() => {
                          formik.setFieldValue("quizCheck", true);
                          setQuizLabel("");
                          setIsDurationDisabled(true);
                        }}
                        className="custom-control-input"
                        disabled={type !== "add" && assessmentDetails.getSubmissionCount > 0}
                      // onBlur={formik.handleBlur}
                      />
                      <label
                        className="custom-control-label custom-control-inline"
                        htmlFor="quiz1"
                      >
                        Quiz
                      </label>
                    </div>
                    {/* } */}
                    <div
                      style={{ zIndex: "inherit" }}
                      className="custom-control custom-radio custom-control-inline"
                      title="Document"
                    >
                      <input
                        type="radio"
                        id="document"
                        name="quizCheck"
                        checked={formik.values.quizCheck == false}
                        onChange={() => {
                          formik.setFieldValue("quizCheck", false);
                          setQuizLabel("");
                          setIsDurationDisabled(false);
                        }}
                        className="custom-control-input"
                        // onBlur={formik.handleBlur}
                        disabled={type !== "add" && assessmentDetails.getSubmissionCount > 0}
                      />
                      <label
                        className="custom-control-label custom-control-inline"
                        htmlFor="document"
                      >
                        Document
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {/* {formik.values.quizCheck == true ? 
                <div className="col-md-6 col-lg-4">
                  <div className="form-group-blk mb-4">
                    <label htmlFor="shareWith">Share With</label>                  
                      <Select
                        isDisabled={!isEditable && type === "open"}
                        className={"form-control color-drop custom-select-box is-multi"}
                        name="shareWith"
                        title="shareWith"
                        placeholder={
                          formik.values.shareWith.length
                            ? formik.values.shareWith
                            : "Share With"
                        }
                        maxMenuHeight={175}
                        options={moderatorAndMarker.sort(myCompare)}
                        onBlur={formik.handleBlur}
                        onChange={(value) => {
                          if (value && value.length) {
                            formik.setFieldValue("shareWith", value.map((item => parseInt(item.value))));                          
                          } else {
                            formik.setFieldValue("shareWith", "");                          
                          }                        
                        }}
                        value={moderatorAndMarker.filter((option)=>formik.values.shareWith.includes(parseInt(option.value)))}
                        isClearable
                        isMulti={true}
                      />
                  </div>
                </div>
              :null} */}
            </div>}
            {/* <br /> */}

            {formik.values.quizCheck == true || formik.values.AssessmentType == 13 || formik.values.AssessmentType == 14
              // && props.gradingStandard != 2 
              ? (
                <>
                  <div className="form-group-blk assest-custom-left">
                    <label>{(formik.values.AssessmentType != 13 && formik.values.AssessmentType != 14) ? "Add Quiz" : "Practical Assessment"} *</label>
                    <div className="form-group atttach-file m-0">
                      <label
                        className={`
                        ${formik.errors.quiz_id && formik.touched.quiz_id ? "file-req is-invalid" : ""}
                        ${formik.values.AssessmentType == 14 ? "disabled" : ""}
                      `}
                        data-toggle={(type !== "add" && assessmentDetails.getSubmissionCount > 0) ? "" : "modal"}
                        type="button"
                        data-target="#quiz"
                        title={(formik.values.AssessmentType != 13 && formik.values.AssessmentType != 14) ? "Add Quiz" : "Add Assessment"}
                        onClick={() => {
                          if (formik.values.AssessmentType == 14) {
                            return false;
                          }
                          if (type !== "add" && assessmentDetails.getSubmissionCount > 0) {
                            return false;
                          } else {
                            setShowModal(true);
                          }
                        }}
                        style={{
                          pointerEvents: formik.values.AssessmentType == 14 ? 'none' : 'auto',
                          opacity: formik.values.AssessmentType == 14 ? 0.5 : 1,
                          cursor: formik.values.AssessmentType == 14 ? 'not-allowed' : 'pointer'
                        }}
                      >
                        <i className="fal fa-plus"></i>
                        {(formik.values.AssessmentType != 13 && formik.values.AssessmentType != 14) ? "Add Quiz" : "Add Assessment"}
                      </label>
                    </div>
                  </div>

                  <div className="row assest-custom">
                    <div className="col-md-4">
                      <div className="d-flex">
                        <div className="mb-3">
                          {quizLabel && quizFormik.values.quiz_id > 0 && (
                            <>
                              <div className="frm-group">
                                <ul className="list-unstyled attached-file-ul m-0">
                                  <li>
                                    <a
                                      href={`/courseAdministration/assessment/quizzes/open/details/${quizFormik.values.quiz_id}`}
                                      target="_blank"
                                    >
                                      <i className="fal fa-file"></i>
                                      &nbsp;{" "}
                                      <span title={quizLabel}>
                                        {TrimText(quizLabel, 40)}
                                      </span>
                                    </a>
                                    <button
                                      className="btn btn-danger rounded-circle btn-dropdown-item"
                                      title="Delete"
                                      disabled={type !== "add" && assessmentDetails.getSubmissionCount > 0 || formik.values.AssessmentType == 14}
                                      onClick={() => handleQuizDelete(quizFormik.values.quiz_id)}
                                    >
                                      <i className="fal fa-trash-alt"></i>
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}


            <>
              {(formik.values.quizCheck == true && (formik.values.isHybrid == true || formik.values.isHybrid == "true")) || formik.values.quizCheck == false ?
                <div className="row">
                  {formik.values.AssessmentType != 13 && formik.values.AssessmentType != 14 && <div className="col-md-6 col-lg-4">
                    <div className="form-group-blk mb-3">
                      <label htmlFor="Marker">Marker</label>
                      <Select
                        isDisabled={!isEditable && type === "open"}
                        className={
                          "form-control custom-select-box is-multi color-drop " +
                          (formik.errors.Marker && formik.touched.Marker
                            ? " is-invalid"
                            : "")
                        }
                        name="Marker"
                        title="Marker"
                        // placeholder={formik.values.Marker ? formik.values.Marker : "Marker"}
                        onChange={(value) => {
                          if (value) {
                            let arr = [];
                            value.map((item) => {
                              arr.push(item.value);
                            });
                            formik.setFieldValue("Marker", arr);
                          } else {
                            formik.setFieldValue("Marker", []);
                          }
                        }}
                        // value={moderatorAndMarker?.filter(val=>val?.value === formik.values.Marker)}
                        value={moderatorAndMarker.filter((obj) => {
                          return formik.values.Marker.includes(obj.value);
                        })}
                        // value={formik.values.Marker}
                        isMulti={true}
                        options={moderatorAndMarker.sort(
                          sort_by("label", false, (a) => a.toUpperCase())
                        )}
                        maxMenuHeight={175}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>}
                  {formik.values.AssessmentType != 13 && formik.values.AssessmentType != 14 && type === "open" && (
                    <div className="col-md-2">
                      <div className="form-group-blk mb-3">
                        <label>&nbsp;</label>
                        <div className="form-group form-group-save-cancel">
                          <button
                            disabled={!formik.values.Marker}
                            className="btn btn-save btn-success"
                            type= 'button'
                            // type="submit" title="Save"
                            onClick={(e) => handleSendInstruction(e)}
                          >
                            <i className="fal fa-paper-plane"></i>Send
                            Instruction
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {formik.values.AssessmentType != 13 && formik.values.AssessmentType != 14 && <div className="col-md-6 col-lg-4">
                    <div className="">
                      <label htmlFor="Moderator">Moderator</label>
                      <div className="form-icon-group mb-4 ">
                        <Select
                          isDisabled={!isEditable && type === "open"}
                          className={"form-control custom-select-box"}
                          name="Moderator"
                          title="Moderator"
                          placeholder={
                            formik.values.Moderator
                              ? formik.values.Moderator
                              : "Moderator"
                          }
                          maxMenuHeight={175}
                          options={moderatorAndMarker.sort(myCompare)}
                          onBlur={formik.handleBlur}
                          onChange={(value) => {
                            if (value) {
                              formik.setFieldValue("Moderator", value.value);
                              setModeratorSelected(value.value);
                            } else {
                              formik.setFieldValue("Moderator", "");
                              setModeratorSelected("");
                            }
                          }}
                          value={moderatorAndMarker.filter(function (option) {
                            // option.value == formik.values.Moderator && setSelectedDomain([...selectedDomain, option.value])
                            // if (option.value.toString() == formik.values.Moderator.toString()) console.log("matched: ", option);
                            return option.value == formik.values.Moderator;
                          })}
                          isClearable
                        />
                      </div>
                    </div>
                  </div>}
                </div> : null}
              <div className="row assest-custom">
                {formik.values.quizCheck == true || formik.values.AssessmentType == 13 || formik.values.AssessmentType == 14 ? null : (
                  <>
                    <div className="col-md-6 col-lg-3">
                      <div className="d-flex">
                        <div className="form-group-blk assest-custom-left">
                          <label>Assessment</label>
                          <div className="form-group atttach-file m-0">
                            <label
                              className={
                                formik.errors.AssessmentFile &&
                                  formik.touched.AssessmentFile
                                  ? "file-req is-invalid"
                                  : ""
                              }
                            >
                              <i className="fal fa-paperclip"></i>
                              <span>Add Attachment</span>
                              <input
                                disabled={!isEditable && type === "open"}
                                type="file"
                                title="Add Attachment"
                                className="form-control  form-control-aatch-file"
                                onChange={(e) => {
                                  if (e.target.files.length) {
                                    formik.setFieldValue(
                                      "AssessmentFile",
                                      e.target.files[0]
                                    );
                                    setAssessmentFile(e.target.files[0]);
                                    setAssessmentFileName(
                                      URL.createObjectURL(e.target.files[0])
                                    );
                                  }
                                }}
                              />
                            </label>
                          </div>
                          {assessmentFileName && (
                            <>
                              <div className="frm-group">
                                <ul className="list-unstyled attached-file-ul m-0">
                                  <li>
                                    <a
                                      title={assessmentFileName}
                                      href={
                                        assessmentFileName.includes("blob")
                                          ? assessmentFileName : formatUrl(assessmentFileName)
                                          // : `${downloadURL}/${STUDENT_FILE_DOWNLOAD}/${assessmentFileName}`
                                          //   .replaceAll("/public/", "")
                                          //   .replaceAll(
                                          //     "home/myaie/public_html/",
                                          //     ""
                                          //   )
                                      }
                                      target="_blank"
                                    >
                                      <i className="fal fa-file"></i>
                                      &nbsp;
                                      {assessmentFile?.name
                                        ? TrimText(assessmentFile?.name, 40)
                                        : TrimText(
                                          formik.values.AssessmentFile.split(
                                            "/"
                                          ).reverse()[0],
                                          40
                                        )}
                                    </a>
                                    <button
                                      className="btn btn-danger rounded-circle btn-dropdown-item"
                                      title="Delete"
                                      onClick={() => handleAssessment()}
                                    >
                                      <i className="fal fa-trash-alt"></i>
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                      <div className="form-group-blk mb-3">
                        <label>Memo</label>
                        <div className="form-group atttach-file m-0">
                          <label
                            className={
                              formik.errors.MemoFile &&
                              formik.touched.MemoFile &&
                              "file-req is-invalid"
                            }
                          >
                            <i className="fal fa-paperclip"></i>
                            <span>Add Attachment</span>
                            <input
                              disabled={!isEditable && type === "open"}
                              type="file"
                              className={"form-control form-control-aatch-file"}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "MemoFile",
                                  e.target.files[0]
                                );
                                setMemoFile(e.target.files[0]);
                                setMemofileName(
                                  URL.createObjectURL(e.target.files[0])
                                );
                              }}
                            />
                          </label>
                        </div>
                        {memofileName &&
                          memofileName != "null" &&
                          memofileName != undefined && (
                            <>
                              <div className="frm-group">
                                <ul className="list-unstyled attached-file-ul m-0">
                                  <li>
                                    <a
                                      title={memofileName}
                                      href={
                                        memofileName.includes("blob")
                                          ? memofileName : formatUrl(memofileName)
                                          // : `${downloadURL}${STUDENT_FILE_DOWNLOAD}/${memofileName
                                          //   .replaceAll(
                                          //     "/home/myaie/public_html/",
                                          //     ""
                                          //   )
                                          //   .replace("/public/", "")}`
                                      }
                                      target="_blank"
                                    >
                                      <i className="fal fa-file"></i>
                                      &nbsp;
                                      {TrimText(
                                        memoFile?.name
                                          ? memoFile?.name
                                          : formik.values.MemoFile.split(
                                            "/"
                                          ).reverse()[0],
                                        40
                                      )}
                                    </a>
                                    <button
                                      className="btn btn-danger rounded-circle btn-dropdown-item"
                                      title="Delete"
                                      onClick={() => handleMemoDelete()}
                                    >
                                      <i className="fal fa-trash-alt"></i>
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                  </>
                )}

                {formik.values.AssessmentType != 13 && formik.values.AssessmentType != 14 && <div className="col-md-6 col-lg-4">
                  <div className="form-group-blk mb-4">
                    <label htmlFor="shareWith">Share With</label>
                    <Select
                      isDisabled={!isEditable && type === "open"}
                      className={"form-control color-drop custom-select-box is-multi"}
                      name="shareWith"
                      title="shareWith"
                      placeholder={
                        formik.values.shareWith.length
                          ? formik.values.shareWith
                          : "Share With"
                      }
                      maxMenuHeight={175}
                      options={moderatorAndMarker.sort(myCompare)}
                      onBlur={formik.handleBlur}
                      onChange={(value) => {
                        if (value && value.length) {
                          formik.setFieldValue("shareWith", value.map((item => parseInt(item.value))));
                        } else {
                          formik.setFieldValue("shareWith", "");
                        }
                      }}
                      value={moderatorAndMarker.filter((option)=>formik.values.shareWith.includes(parseInt(option.value)))}
                      isClearable
                      isMulti={true}
                    />
                  </div>
                </div>}
              </div>
            </>

            {(formik.values.AssessmentType == 13 || formik.values.AssessmentType == 14) &&
              <>
                <hr />
                <div className="edit-icon new-card-header" title="Assessment Settings">
                  <h4 className="card-header">
                    <FontAwesomeIcon icon={faGear} color="var(--topbar-color)" /> Assessment Settings
                  </h4>
                </div>

                <div className="row">
                  <div className="col-md-6 col-lg-4">
                    <div className="form-group-blk mb-3">
                      <label htmlFor="Marker">Marker</label>
                      <Select
                        isDisabled={!isEditable && type === "open"}
                        className={
                          "form-control custom-select-box is-multi color-drop " +
                          (formik.errors.Marker && formik.touched.Marker
                            ? " is-invalid"
                            : "")
                        }
                        name="Marker"
                        title="Marker"
                        // placeholder={formik.values.Marker ? formik.values.Marker : "Marker"}
                        onChange={(value) => {
                          if (value) {
                            let arr = [];
                            value.map((item) => {
                              arr.push(item.value);
                            });
                            formik.setFieldValue("Marker", arr);
                          } else {
                            formik.setFieldValue("Marker", []);
                          }
                        }}
                        // value={moderatorAndMarker?.filter(val=>val?.value === formik.values.Marker)}
                        value={moderatorAndMarker.filter((obj) => {
                          return formik.values.Marker.includes(obj.value);
                        })}
                        // value={formik.values.Marker}
                        isMulti={true}
                        options={moderatorAndMarker.sort(
                          sort_by("label", false, (a) => a.toUpperCase())
                        )}
                        maxMenuHeight={175}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-2 d-flex" style={{ marginTop: 37 }} title="Moderated">
                    <label className="mb-0">
                      Moderated <i className="fal fa-info-circle"></i>
                    </label>
                    <div className="toggle-switch ml-2">
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={formik.values.is_Moderated === 1}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "is_Moderated",
                              e.target.checked ? 1 : 0
                            )
                          }
                        />
                        <span className="slider slider-round"></span>
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3 d-flex" style={{ marginTop: 37 }} title="Allow Student Evidence Submission">
                    <label className="mb-0">
                      Allow Student Evidence Submission <i className="fal fa-info-circle"></i>
                    </label>
                    <div className="toggle-switch ml-2">
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={formik.values.is_Allow_Student === 1}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "is_Allow_Student",
                              e.target.checked ? 1 : 0
                            )
                          }
                          }
                        />
                        <span className="slider slider-round"></span>
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3 d-flex" style={{ marginTop: 37 }} title="Require Student Signature">
                    <label className="mb-0">
                      Require Student Signature <i className="fal fa-info-circle"></i>
                    </label>
                    <div className="toggle-switch ml-2">
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={formik.values.is_Student_Signature_Required === 1}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "is_Student_Signature_Required",
                              e.target.checked ? 1 : 0
                            )
                          }
                          }
                        />
                        <span className="slider slider-round"></span>
                      </label>
                    </div>
                  </div>
                </div>

                {/* <hr />
                
                  <CourseBookingForm assessmentTypeSelected={assessmentTypeSelected} assessmentDetails={assessmentDetails} /> */}
              </>
            }

            {formik.values.AssessmentType == 13 &&
              <>
                <hr />
                <div className="edit-icon new-card-header" title="Self Assessed Students">
                  <div className="card-header">Self Assessed Students</div>
                </div>

                <p title="These students will be allowed to upload their own evidence for the practical and will not be assessed by lecturer on campus">
                  <i className="fal fa-info-circle "></i> These students will be allowed to upload their own evidence for the practical and will not be assessed by lecturer on campus
                </p>

                <DataTableComponentFrontPagination
                  isInsidePopUp
                  data={dataToRenderAssignedAssessment()}
                  columns={selfAssessdColumns}
                  loading={assignloading}
                  // totalRows={assignTotalRows}
                  exportData={exportDataAssignedAssessment}
                  search={searchAssigned}
                  setSearch={setSearchAssigned}
                  exportFileName="Assigned_Students"
                  tableButton={[
                    <button className="btn btn-primary" title="Assign Students" type="button" data-toggle="modal" data-target="#assessmentStudents">
                      <i className="fal fa-plus"></i>Assign Students
                    </button>
                  ]}
                  filters={[
                    {
                      filterName: "Status",
                      optionArr: statusAssignedAssessmentList,
                      state: statusAssigned,
                      setState: setStatusAssigned,
                      renderLabelFunction: RenderStudentStatus,
                      uniqueId: "assignedStatusAssessment"
                    }
                  ]}
                />
              </>
            }

            {formik.values.AssessmentType == 14 ? <>
              <hr />
              <div className="edit-icon new-card-header">
                <div className="card-header">Assigned Students</div>
              </div>

              {formik.values.AssessmentType == 14 && <p title="These students will be allowed to reattempt the assigned practical assessment">
                <i className="fal fa-info-circle "></i> These students will be allowed to reattempt the assigned practical assessment
              </p>}

              <DataTableComponentFrontPagination
                isInsidePopUp
                data={dataToRenderAssignedAssessment()}
                columns={lecturerAssessdColumns}
                loading={assignloading}
                // totalRows={assignTotalRows}
                exportData={exportDataAssignedAssessment}
                search={searchAssigned}
                setSearch={setSearchAssigned}
                exportFileName="Assigned_Students"
                // tableButton={[
                //   <button className="btn btn-primary" title="Assign Students" type="button" data-toggle="modal" data-target="#assessmentStudents" disabled={(!formik.values.override_mark_ass_id)}>
                //     <i className="fal fa-plus"></i>Assign Students
                //   </button>
                // ]}
                filters={[
                  {
                    filterName: "Status",
                    optionArr: statusAssignedAssessmentList,
                    state: statusAssigned,
                    setState: setStatusAssigned,
                    renderLabelFunction: RenderStudentStatus,
                    uniqueId: "assignedStatus"
                  },
                  {
                    filterName: "Assessment Method",
                    optionArr: assessedMethodAssignedList || [],
                    state: assessedMethodAssigned,
                    setState: setAssessedMethodAssigned,
                    renderLabelFunction: RenderCategoriesType,
                    uniqueId: "assignedAssessedMethod"
                  }
                ]}
              />
            </> : ""}

            <hr />
            <div className="edit-icon new-card-header">
              <div className="card-header">Assessment Conditions</div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="d-flex w-100">
                  <div className="form-group-blk mb-3 mr-2">
                    <label>&nbsp;</label>
                    <div className="custom-check-group">
                      <div
                        className="custom-check custom-control custom-checkbox mt-2"
                        style={{ width: "100px" }}
                      >
                        <input
                          disabled={isDurationDisabled}
                          type="checkbox"
                          className="custom-control-input"
                          id="durationCheckbox"
                          name="select"
                          checked={formik.values.isDuration}
                          onChange={(e) =>
                            formik.setFieldValue("isDuration", e.target.checked)
                          }
                        />

                        <label
                          className="custom-control-label"
                          htmlFor="durationCheckbox"
                        >
                          <i className="fal fa-clock"></i> Duration
                        </label>
                      </div>
                    </div>
                  </div>
                  {formik.values.isDuration && (
                    <div className="form-group-blk mb-3 w-100">
                      <label>Time Limit</label>
                      {/* <div className="form-grp-dates time-select">
                        <DatePicker
                          className={"form-control cursor-pointer" + (formik.errors.TimeLimitHours && formik.touched.TimeLimitHours ? " is-invalid" : "")}
                          selected={formik.values.TimeLimitHours}
                          onChange={(date) => { formik.setFieldValue("TimeLimitHours", date) }}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Time"
                          title="To"
                          onBlur={formik.handleBlur}
                          placeholderText="Select To Time"
                          showTime={{ use12Hours: true, format: TABLE_TIME_FORMAT }}
                        />
                        </div> */}
                      <div className="row form-grp-dates time-select">
                        <Select
                          isDisabled={
                            (!isEditable && type === "open") ||
                            formik.values.isDuration == false
                          }
                          className={
                            "form-control custom-select-box" +
                            (formik.errors.TimeLimitHours &&
                              formik.touched.TimeLimitHours
                              ? " is-invalid"
                              : "")
                          }
                          name="Hour"
                          title="Time Limit Hour"
                          placeholder={
                            formik.values.TimeLimitHours
                              ? formik.values.TimeLimitHours
                              : "Hours"
                          }
                          options={hourHandle()}
                          onBlur={formik.handleBlur}
                          onChange={(value) => {
                            if (value) {
                              formik.setFieldValue(
                                "TimeLimitHours",
                                value.value
                              );
                            } else {
                              formik.setFieldValue("TimeLimitHours", "");
                            }
                          }}
                          value={hourHandle().filter((val) => {
                            return (
                              val.value == formik.values.TimeLimitHours
                              // Number(formik.values.TimeLimitHours).toLocaleString(
                              //   "en-US",
                              //   { minimumIntegerDigits: 2, useGrouping: false }
                              // )
                            );
                          })}
                          isClearable
                        />
                        <Select
                          isDisabled={
                            (!isEditable && type === "open") ||
                            formik.values.isDuration == false
                          }
                          className={
                            "form-control custom-select-box" +
                            (formik.errors.TimeLimitMinutes &&
                              formik.touched.TimeLimitMinutes
                              ? " is-invalid"
                              : "")
                          }
                          name="Minute"
                          title="Time Limit Minute"
                          placeholder={
                            formik.values.TimeLimitMinutes
                              ? formik.values.TimeLimitMinutes
                              : "Minutes"
                          }
                          options={MinutesHandle()}
                          onBlur={formik.handleBlur}
                          onChange={(value) => {
                            if (value) {
                              formik.setFieldValue(
                                "TimeLimitMinutes",
                                value.value
                              );
                            } else {
                              formik.setFieldValue("TimeLimitMinutes", "");
                            }
                          }}
                          value={MinutesHandle().filter((val) => {
                            return (
                              val.value == formik.values.TimeLimitMinutes
                              // Number(
                              //   formik.values.TimeLimitMinutes
                              // ).toLocaleString("en-US", {
                              //   minimumIntegerDigits: 2,
                              //   useGrouping: false,
                              // })
                            );
                          })}
                          isClearable
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-12 col-lg-6 ml-auto d-flex justify-content-end">
                <div className="form-group-blk mb-3">
                  <label>&nbsp;</label>
                  <div className="custom-check-group">
                    <div className="custom-check custom-control custom-checkbox mb-2">
                      <input
                        disabled={!isEditable && type === "open"}
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck1"
                        name="select"
                        checked={formik.values.Identification || false}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "Identification",
                            e.target.checked
                          )
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck1"
                      >
                        <i className="fal fa-portrait"></i> Identification
                      </label>
                    </div>
                    <div className="custom-check custom-control custom-checkbox mb-2">
                      <input
                        disabled={!isEditable && type === "open"}
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck2"
                        name="select"
                        checked={formik.values.Camera || false}
                        onChange={(e) =>
                          formik.setFieldValue("Camera", e.target.checked)
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck2"
                      >
                        <i className="fal fa-webcam"></i> Camera
                      </label>
                    </div>
                    <div className="custom-check custom-control custom-checkbox mt-2 mb-2">
                      <input
                        disabled={!isEditable && type === "open"}
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck3"
                        name="select"
                        checked={formik.values.ScreenShare || false}
                        onChange={(e) =>
                          formik.setFieldValue("ScreenShare", e.target.checked)
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck3"
                      >
                        <i className="fal fa-desktop"></i> Screenshare
                      </label>
                    </div>
                    <div className="custom-check custom-control custom-checkbox mb-2">
                      <input
                        disabled={!isEditable && type === "open"}
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck4"
                        name="select"
                        checked={formik.values.Audio || false}
                        onChange={(e) =>
                          formik.setFieldValue("Audio", e.target.checked)
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck4"
                      >
                        <i className="fal fa-microphone"></i> Audio
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {formik.values.quizCheck == true && !(formik.values.AssessmentType == 13 || formik.values.AssessmentType == 14) ? (
                <div className="col-md-6 col-lg-3">
                  <div className="form-group-blk mb-3">
                    <label htmlFor="maximum_attempts">Maximum Attempts *</label>
                    <input
                      type="number"
                      title="Maximum Attempts"
                      className={
                        "form-control" +
                        (formik.errors.maximum_attempts &&
                          formik.touched.maximum_attempts
                          ? " is-invalid"
                          : "")
                      }
                      placeholder="Enter Maximum Attempts"
                      name="maximum_attempts"
                      id="maximum_attempts"
                      value={formik.values.maximum_attempts || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="form-group form-group-save-cancel mt-3 mb-0">
              <div>
                <PermissionsGate scopes={type === "open" ? ["castedit"] : ["castadd"]} errorProps={{disabled: true}}>
                  <button
                    className="btn btn-save btn-success"
                    onClick={formik.handleSubmit}
                    type="submit"
                    title="Save"
                    disabled={assessmentLoad ? true : false}
                  >
                    {assessmentLoad === false ? (
                      <i className="fal fa-save"></i>
                    ) : (
                      <i className="fas fa-cog fa-spin"></i>
                    )}
                    Save
                  </button>
                </PermissionsGate>
                <button
                  className="btn btn-close btn-danger"
                  type="button"
                  title="Cancel"
                  onClick={() => {
                    if (!isEditable || !formik.dirty) {
                      history.goBack();
                    } else {
                      Swal.fire({
                        title: "Are you sure?",
                        text: "Your work will not be saved",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Go Back!",
                      })
                        .then((result) => {
                          if (result.isConfirmed) {
                            history.goBack();
                          }
                        })
                        .catch((error) => {
                          console.log("error: ", error);
                        });
                    }
                  }}
                >
                  <i className="fal fa-times"></i>Cancel
                </button>
              </div>
              {Object.keys(formik.values).map((key) => {
                if (formik.touched[key] && formik.errors[key]) {
                  if (key == "gradeMix") {
                    if (tempCheckedAllData.length) {
                      return gradeMixError.map((item) => {

                        if (!item) {
                          return <></>
                        }
                        return item.errorMessage.map((error) => (
                          <div className="invalid-feedback d-block">
                            {error}
                          </div>
                        ))
                      }
                      );
                    } else {
                      return (
                        <div className="invalid-feedback d-block">
                          {formik.errors[key]}
                        </div>
                      );
                    }
                  }
                  return (
                    <div className="invalid-feedback d-block">
                      {formik.errors[key]}
                    </div>
                  );
                }
              })}
              {/* {formik.touched.TimeLimitMinutes && formik.errors.TimeLimitMinutes ? <div className="invalid-feedback d-block">{formik.errors.TimeLimitMinutes}</div> : null} */}
            </div>
          </form>
        </div>
      </div>
      <div
        className="topic-add-modal modal able_modal01 fade"
        id="quiz"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="modal-header modal-header-custom">
                <h5 className="modal-title">
                  <i className="fal fa-plus"></i> {formik.values.AssessmentType != 13 ? "Add Quiz" : "Add Assessment"}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {/* <div className="t p-30px">
                {showModal && (
                  <form onSubmit={quizFormik.handleSubmit}>
                    <div className="form-group-blk mb-3">
                      <Select
                        className={
                          "form-control custom-select-box" +
                          (quizFormik.errors.quiz_id &&
                            quizFormik.touched.quiz_id
                            ? " is-invalid"
                            : "")
                        }
                        name="quiz_id"
                        id="quiz_id"
                        title="Add Quiz"
                        // placeholder={
                        //   quizFormik.values.quiz_id
                        //     ? quizFormik.values.quiz_id
                        //     : "Select Quiz"
                        // }
                        options={quizList.sort(
                          sort_by("label", false, (a) => a.toUpperCase())
                        )}
                        onBlur={quizFormik.handleBlur}
                        onChange={(value) => {
                          if (value) {
                            quizFormik.setFieldValue("quiz_id", value.value);
                            formik.setFieldValue("quiz_id",value.value)
                            setQuizLabel(value.label);
                          } else {
                            quizFormik.setFieldValue("quiz_id", "");
                            formik.setFieldValue("quiz_id","")
                            setQuizLabel("");
                          }                         
                        }}
                        value={quizList.filter(function (option) {
                          return option.value == quizFormik.values.quiz_id;
                        })}
                        isClearable
                      />
                    </div>
                    <div className="form-group form-group-save-cancel mt-3 mb-0">
                      <div>
                        <button
                          className="btn btn-save btn-success"
                          onClick={quizFormik.handleSubmit}
                          type="submit"
                          title="Save"
                          disabled={quizLoad ? true : false}
                        >
                            {loading === false ? (
                              <i className="fal fa-save"></i>
                            ) : (
                              <i className="fas fa-cog fa-spin"></i>
                            )} Save
                        </button>
                        <button
                          className="btn btn-close btn-danger"
                          type="button"
                          title="Cancel"
                          onClick={() => {
                            setShowModal(false);
                            quizFormik.setFieldValue("quiz_id", "");
                            setQuizLabel("");
                            $("#quiz").modal("hide");
                          }}
                        >
                          <i className="fal fa-times"></i>Cancel
                        </button>
                      </div>
                    </div>
                    {quizFormik.touched.quiz_id && quizFormik.errors.quiz_id ? (
                      <div className="invalid-feedback d-block">
                        {quizFormik.errors.quiz_id}
                      </div>
                    ) : null}
                  </form>
                )}
              </div> */}
              <AddQuizzTable addQuizzHandler={addQuizzHandler} AssessmentType={formik.values.AssessmentType} selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
            </div>
          </div>
        </div>
      </div>
      <ListOfTemplateModal
        setSelectedTemplate={setSelectedTemplate}
        selectedTemplate={selectedTemplate}
        setSelectedGradeMixTemplate={setSelectedGradeMixTemplate}
      />
      <ProgrammeGradeMixModal
        setTempCheckedAllData={setTempCheckedAllData}
        tempCheckedAllData={tempCheckedAllData}
        setCheckedAllRows={setCheckedAllRows}
        checkedAllRows={checkedAllRows}
        courseId={id}
        setSelectedGradeMixTemplate={setSelectedGradeMixTemplate}
        quizTotal={quizTotal}
        type={type}
      />
      <PopupComponent
        id="availableStudents"
        header={{
          iconName: "fa-user-plus",
          heading: "Assign Student"
        }}
        tableData={{
          exportData: exportDataAvailableStd,
          exportFileName: "Assigned_Students",
          data: dataToRenderAvailableStd(),
          columns: studentColumns,
          setSearch: setAvailableSearch,
          search: availableSearch,
          loading: availableLoading,
          filters: [
            {
              filterName: "Status",
              optionArr: statusAvailableList || [],
              state: statusAvailable,
              setState: setStatusAvailable,
              renderLabelFunction: RenderStudentStatus,
              uniqueId: "availableStatus"
            },
            {
              filterName: "Course Result",
              optionArr: courseResultAvailableList || [],
              state: courseResultAvailable,
              setState: setCourseResultAvailable,
              renderLabelFunction: RenderQuizAttemptResultStatus,
              uniqueId: "available"
            }
          ]
        }}
      />
      <PopupComponent
        id="assessmentStudents"
        type="assessment"
        header={{
          iconName: "fa-user-plus",
          heading: "Assign Student"
        }}
      tableData={{
        exportData:exportDataAvailableAssessment,
        exportFileName:"Assigned_Students",
        data: dataToRenderAvailableAss(),
        // columns: formik.values.AssessmentType == 13 ? selfAssessdColumns : lecturerAvailableColumns,
        columns: selfAssessdColumns,
        setSearch: setAvailableSearch,
        search: availableSearch,
        loading: availableLoading,
        filters:[
          {
            filterName: "Status",
            optionArr: statusAvailableAssessmentList || [],
            state: statusAvailable,
            setState: setStatusAvailable,
            renderLabelFunction: RenderStudentStatus,
            uniqueId: "availableStatusAssessment"
          },
          ...(formik.values.AssessmentType == 14 && assessedMethodAvailableList ? [{
            filterName: "Assessment Method",
            optionArr: assessedMethodAvailableList || [],
            state: assessedMethodAvailable,
            setState: setAssessedMethodAvailable,
            renderLabelFunction: RenderCategoriesType,
            uniqueId: "availableAssessedMethod"
          }] : [])
        ]
      }}
        tableButton={[
          <button className="btn btn-primary" title="Add All Students" type="button" onClick={handleAssignAllStudents}>
            <i className="fal fa-plus"></i>Add All Students
          </button>
        ]}
      />
      <PopupComponent
        id="overrideMarkFor"
        header={{
          iconName: "fa-exchange",
          heading: "Override Mark For"
        }}
        tableData={{
        exportData:exportDataOverrideFor,
        exportFileName:"Available_assessment",
          data: dataToRenderOverrideAssess(),
          columns: overrideAssessColumns,
          setSearch: setOverrideSearch,
          search: overrideSearch,
          loading: overrideLoading,
        filters:[
            {
              filterName: "Status",
              optionArr: overrideStatusList || [],
              state: overrideStatus,
              setState: setOverrideStatus,
              renderLabelFunction: RenderAssessmentStatus
            },
            {
              filterName: "Assessment Type",
              optionArr: overrideAssessTypeList || [],
              state: overrideAssessType,
              setState: setOverrideAssessType,
              renderLabelFunction: RenderAssessmentType
            }
          ]
        }}
      />
      <PopupComponent
        id="availableStudents"
        header={{
          iconName: "fa-user-plus",
          heading: "Assign Student"
        }}
        tableData={{
        exportData:exportDataAvailableStd,
        exportFileName:"Assigned_Students",
          data: dataToRenderAvailableStd(),
          columns: studentColumns,
          setSearch: setAvailableSearch,
          search: availableSearch,
          loading: availableLoading,
        filters:[
            {
              filterName: "Status",
              optionArr: statusAvailableList || [],
              state: statusAvailable,
              setState: setStatusAvailable,
              renderLabelFunction: RenderStudentStatus,
              uniqueId: "availableStatus"
            },
            {
              filterName: "Course Result",
              optionArr: courseResultAvailableList || [],
              state: courseResultAvailable,
              setState: setCourseResultAvailable,
              renderLabelFunction: RenderQuizAttemptResultStatus,
              uniqueId: "available"
            }
          ]
        }}
      />
      <PopupComponent
        id="overrideMarkFor"
        header={{
          iconName: "fa-exchange",
          heading: "Override Mark For"
        }}
        tableData={{
        exportData:exportDataOverrideFor,
        exportFileName:"Available_assessment",
          data: dataToRenderOverrideAssess(),
          columns: overrideAssessColumns,
          setSearch: setOverrideSearch,
          search: overrideSearch,
          loading: overrideLoading,
        filters:[
            {
              filterName: "Status",
              optionArr: overrideStatusList || [],
              state: overrideStatus,
              setState: setOverrideStatus,
              renderLabelFunction: RenderAssessmentStatus
            },
            {
              filterName: "Assessment Type",
              optionArr: overrideAssessTypeList || [],
              state: overrideAssessType,
              setState: setOverrideAssessType,
              renderLabelFunction: RenderAssessmentType
            }
          ]
        }}
      />
      <PopupComponent
        id="availableStudents"
        header={{
          iconName: "fa-user-plus",
          heading: "Assign Student"
        }}
        tableData={{
        exportData:exportDataAvailableStd,
        exportFileName:"Assigned_Students",
          data: dataToRenderAvailableStd(),
          columns: studentColumns,
          setSearch: setAvailableSearch,
          search: availableSearch,
          loading: availableLoading,
        filters:[
            {
              filterName: "Status",
              optionArr: statusAvailableList || [],
              state: statusAvailable,
              setState: setStatusAvailable,
              renderLabelFunction: RenderStudentStatus,
              uniqueId: "availableStatus"
            },
            {
              filterName: "Course Result",
              optionArr: courseResultAvailableList || [],
              state: courseResultAvailable,
              setState: setCourseResultAvailable,
              renderLabelFunction: RenderQuizAttemptResultStatus,
              uniqueId: "available"
            }
          ]
        }}
      />
      <PopupComponent
        id="overrideMarkFor"
        header={{
          iconName: "fa-exchange",
          heading: "Override Mark For"
        }}
        tableData={{
          exportData:exportDataOverrideFor,
          exportFileName:"Available_assessment",
          data: dataToRenderOverrideAssess(),
          columns: overrideAssessColumns,
          setSearch: setOverrideSearch,
          search: overrideSearch,
          loading: overrideLoading,
          filters:[
            {
              filterName: "Status",
              optionArr: overrideStatusList || [],
              state: overrideStatus,
              setState: setOverrideStatus,
              renderLabelFunction: RenderAssessmentStatus
            },
            {
              filterName: "Assessment Type",
              optionArr: overrideAssessTypeList || [],
              state: overrideAssessType,
              setState: setOverrideAssessType,
              renderLabelFunction: RenderAssessmentType
            }
          ]
        }}
      />
      <PopupComponent
        closeFunction={closeFunction}
        id="assignStudent"
        type="multiple_details_blocks"
        header={{
          iconName: "fa-comment",
          heading: "Appeals"
        }}
        detailsBlocks={[
          {
            heading: "Registered Appeal",
            columns: [
              <div className="col-md-12">
                <div className="basic-info-box-list modal-info-list">
                  <ul>
                    <li>
                      <span className="title popup-detail-block">
                        Reason for not submitting assessment:{" "}
                      </span>
                      <span className="popup-detail-block">
                        <select
                          className="form-control"
                          name="reason"
                          id="reason"
                          value={appealReason}
                          onChange={e => setAppealReason(e.target.value)}
                        >
                          <option value="Sick" >Sick</option>
                          <option value="Family Responsibility">Family Responsibility</option>
                          <option value="Pre-Arranged Appeal">Pre-Arranged Appeal</option>
                          <option value="Portal Difficulties">Portal Difficulties</option>
                          <option value="Other">Other</option>
                        </select>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>,
              <div className="col-md-12">
                <div className="basic-info-box-list modal-info-list">
                  <ul>
                    <li>
                      <span className="title popup-detail-block">Description: </span>
                      <span className="text-break popup-detail-block">
                        <div className="fmInput">
                          <textarea className="form-control"
                            onChange={e => setStudentMessage(e.target.value)}
                            value={studentMessage}
                            placeholder="Description"
                            name="description"
                            id="description" />
                        </div>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>,
              <div className="col-md-12">
                <div className="basic-info-box-list modal-info-list">
                  <ul className="appeal-submission-list">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <li>
                            <span className="title popup-detail-block">
                              Supporting File :
                            </span>
                            <span className="d-block popup-detail-block">
                              <div className="fmInput">
                                <Dropzone onDrop={acceptedFiles => {
                                  setSelectedFileForAppeal([...selectedFileForAppeal, acceptedFiles[0]])
                                }} accept=".zip,.jpg,.png,.jpeg,.pdf,.doc,.docx,.txt">
                                  {({ getRootProps, getInputProps }) => (
                                    <section>
                                      <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p>Drag & drop files here, or click to select files</p>
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>
                                {selectedFileForAppeal.map((item, index) => {
                                  return <span key={index}>
                                    <span>
                                      <i className="fal fa-file"></i>
                                      &nbsp;{item.name}
                                    </span>
                                    <button className="btn btn-danger rounded-circle" onClick={() => handleRemoveFile(item)} type="button" title="Delete">
                                      <i className="fal fa-trash-alt"></i>
                                    </button>
                                  </span>
                                })}
                              </div>
                            </span>
                          </li>
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            ]
          },
        ]}
        bottomButton={[
          <button
            type="button"
            className="btn btn-success"
            title="Save"
            onClick={handleSaveAppeal}
          >
            <i className="fal fa-save"></i> Save
          </button>,
          <button
            type="button"
            className="btn btn-danger"
            title="Cancel"
            onClick={closeFunction}
            data-target="#assignStudent"
            data-toggle="modal"
          >
            <i className="fal fa-times"></i> Cancel
          </button>
        ]}
        validationErrorMessage={[
          appealError
        ]}
        otherProps={
          {
            secondPopupCss: "zindexadd appeal-by-admin"
          }
        }
      />
    </div>
  );
};

export default AssessmentForm;

import React, { useEffect } from "react";

export const VideoIframe = ({ node, index }) => {
    const videoId = `video-${index}-${Math.random()}`;

    useEffect(() => {
        // Load YouTube API if not already loaded
        if (!window.YT) {
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }

        // Load Vimeo Player SDK if not already loaded
        if (!window.Vimeo) {
            const vimeoScript = document.createElement('script');
            vimeoScript.src = 'https://player.vimeo.com/api/player.js';
            document.body.appendChild(vimeoScript);
        }
    }, []);

    // Function to pause all videos except the current one
    const pauseOtherVideos = (currentId) => {
        // Pause other YouTube videos
        document.querySelectorAll('iframe').forEach(otherIframe => {
            if (otherIframe.id !== currentId) {
                try {
                    // Handle YouTube videos
                    if (otherIframe.src.includes('youtube.com')) {
                        otherIframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                    }
                    // Handle Vimeo videos
                    else if (otherIframe.src.includes('vimeo.com') && window.Vimeo) {
                        const player = new window.Vimeo.Player(otherIframe);
                        player.pause();
                    }
                } catch (err) {
                    console.warn('Failed to pause video:', err);
                }
            }
        });

        // Pause HTML5 videos
        document.querySelectorAll('video').forEach(otherVideo => {
            if (otherVideo.id !== currentId) {
                otherVideo.pause();
            }
        });
    };

    // console.log("node: ", node, "node.attribs: ", node.attribs);
    // Prepare props
    const props = {
        ...node.attribs,
        id: videoId,
        width: node.attribs.width || 'auto',
        height: node.attribs.height || 'auto',
        allow: 'accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen',
        frameBorder: '0',
        onLoad: (e) => {
            const iframe = e.target;

            // Handle YouTube videos
            if (iframe.src.includes('youtube.com')) {
                if (window.YT && window.YT.Player) {
                    new window.YT.Player(videoId, {
                        events: {
                            onStateChange: (event) => {
                                if (event.data === 1) { // Playing
                                    console.log("iframe.id: ", iframe.id, "videoId: ", videoId);
                                    pauseOtherVideos(iframe.id);
                                }
                            }
                        }
                    });
                }
            } 
            // Handle Vimeo videos
            else if (iframe.src.includes('vimeo.com')) {
                if (window.Vimeo) {
                    const player = new window.Vimeo.Player(iframe);
                    player.on('play', () => {
                        pauseOtherVideos(iframe.id);
                    });
                }
            } 
            // Handle HTML5 videos
            else {
                iframe.contentWindow.document.addEventListener('load', () => {
                    const videoElement = iframe.contentDocument.querySelector('video');
                    if (videoElement) {
                        videoElement.addEventListener('play', () => {
                            pauseOtherVideos(videoId);
                        });
                    }
                });
            }
        }
    };

    // Handle YouTube and Vimeo URLs
    if (props.src.includes('youtube.com') || props.src.includes('vimeo.com')) {
        // Add enablejsapi=1 for YouTube
        if (props.src.includes('youtube.com')) {
            props.src = props.src.includes('?')
                ? `${props.src}&enablejsapi=1`
                : `${props.src}?enablejsapi=1`;
        }

        // Ensure HTTPS
        props.src = props.src.replace(/^\/\//, 'https://');

        return <iframe {...props} />;
    } else {
        // For non-YouTube and non-Vimeo videos, use video tag
        return (
            <video 
                {...props} 
                controls 
                height={props.height && props.height !== 'auto' ? props.height : 177} 
                width={props.width}
                onPlay={() => pauseOtherVideos(videoId)}
            >
                <source src={props.src} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        );
    }
};
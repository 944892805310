import axiosInstance from "../utils/axios";

export const AddProgram = async (values) => {
  return await axiosInstance().post(`/addProgramme`, values);
};

export const GetListofProgram = async () => {
  return await axiosInstance().post(`/listProgramme`, {});
};

export const getAssignedTextbooks = async (id, cancelToken) => {
  return await axiosInstance().get(`/getAssignedTextbooks?id=${id}`, { cancelToken });
}

export const addAssignedTextBook = async (data) => {
  return await axiosInstance().post(`/addAssignedTextBook`, data);
}
export const removeAssignedTextBook = async (data) => {
  return await axiosInstance().post(`/removeAssignedTextBook`, data);
}

export const getAvailableTextbooks = async (payload, cancelToken) => {
  return await axiosInstance().post(`/getAvailableTextbooks`,payload, { cancelToken });
}

export const GetListofProgramCancelToken = async (payload, cancelToken) => {
  return await axiosInstance().post(`/listProgramme`, payload, {cancelToken});
};

export const AddAndUpdateProgrammePageCancelToken = async (payload, cancelToken) => {
  return await axiosInstance().post(`/addAndUpdateProgrammePage`, payload, {cancelToken});
};
export const AddAndUpdateProgrammePageContentCancelToken = async (payload, cancelToken) => {
  return await axiosInstance().post(`/addAndUpdateProgrammePageContent`, payload, {cancelToken});
};


export const GetListOfPMCAuditTrail = async (values, cancelToken) => {
  return await axiosInstance().post(`/listPMCAuditTrail`, values, {
    cancelToken: cancelToken
  });
};
export const GetSchoolList = async () => {
  return await axiosInstance().get(`/getSchoolList`);
};
export const GetProgramDetail = async (values) => {
  return await axiosInstance().post(`/getPMCDetail`, values);
};

export const GetProgramDetailCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getPMCDetail`, values, {cancelToken});
};
export const GetProgrammePageDetailCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getProgrammePageDetail`, values, {cancelToken});
}
export const GetListofModule = async () => {
  return await axiosInstance().post(`/listModules`, {});
};

export const GetListofModuleCancelToken = async (cancelToken) => {
  return await axiosInstance().post(`/listModules`, {}, {
    cancelToken: cancelToken
  });
};

export const GetProgramAssignedOrNotAssignedList = async (values) => {
  return await axiosInstance().post(`/getProgramAssignedOrNotAssignedList`, values);
};
export const GetProgramAssignedOrNotAssignedListCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getProgramAssignedOrNotAssignedList`, values, {cancelToken});
};

export const AddModuleToProgramme = async (values) => {
  return await axiosInstance().post(`/addModuleToProgramme`, values);
};

export const RemoveModuleFromProgramme = async (values) => {
  return await axiosInstance().post(`/removeModuleFromProgramme`, values);
};

export const AddModule = async (values) => {
  return await axiosInstance().post(`/addModule`, values);
};

export const UpdateProgramme = async (values) => {
  return await axiosInstance().post(`/updateProgramme`, values);
};

export const GetModuleDetail = async (values) => {
  return await axiosInstance().post(`/getModuleDetail`, values);
};

export const GetModuleDetailCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getModuleDetail`, values, {cancelToken});
};

export const UpdateModule = async (values) => {
  return await axiosInstance().post(`/updateModule`, values);
};

export const CreateTopic = async (values) => {
  return await axiosInstance().post(`/createTopic`, values);
};

export const CreateShortCourseTopic = async (values) => {
  return await axiosInstance().post(`/addAndUpdateShortcourseTopic`, values);
};

export const CreateFeaturesBenefits = async (values) => {
  return await axiosInstance().post(`/addEditBenefitAndFeature`, values);
};

export const addEditBenefitAndFeature = async (values) => {
  return await axiosInstance().post(`/addEditBenefitAndFeature`, values);
};

export const ListTopics = async (values) => {
  return await axiosInstance().post(`/listTopics`, values);
};

export const ListTopicsCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/listTopics`, values, {cancelToken});
};

export const ListFeaturesBenefits = async (values,availble) => {
  return await axiosInstance().get(`/getBenefitAndFeatureList?programme_id=${values}&available=${availble}`);
};

export const ListFeaturesBenefitsCancelToken = async (values,availble,cancelToken) => {
  return await axiosInstance().get(`/getBenefitAndFeatureList?programme_id=${values}&available=${availble}`, {
    cancelToken: cancelToken
  });
};

export const ListFeaturesBenefitsShortCourseCancelToken = async (params,cancelToken) => {
  return await axiosInstance().get(`/getBenefitAndFeatureList`,{params, cancelToken});
};

export const ListShortCourseTopics = async (params,cancelToken) => {
  return await axiosInstance().get(`/listShortCourseTopics`,{params, cancelToken});
};

export const getWebsiteListByBrand = async (params) => {
  return await axiosInstance().get(`/getWebsiteListByBrand`,{params});
};

export const DeleteTopic = async (values) => {
  return await axiosInstance().post(`/deleteTopic`, values);
};

export const DeleteShortCourseTopic = async (params) => {
  return await axiosInstance().get(`/deleteShortCourseTopic`, {params});
};

export const DeleteProgramme = async (values) => {
  return await axiosInstance().post(`/deleteModule`, values);
};

export const getCommonContent = async (cancelToken) => {
  return await axiosInstance().get("/getCommonContent", {cancelToken});
};

export const GetModuleAudit = async (values, cancelToken) => {
  return await axiosInstance().post(`/getModuleAudit`, values, {
    cancelToken: cancelToken
  });
};

export const GetModuleAuditFilters = async (values,cancelToken) => {
  return await axiosInstance().get(`/getModuleAuditFilters`, { params: values, cancelToken: cancelToken });
};

export const GetModuleOutcomesList = async (id) => {
  return await axiosInstance().get(`/getModuleOutcomesList?module_id=${id}`);
};

export const GetModuleOutcomesListCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get(`/getModuleOutcomesList?module_id=${id}`, {cancelToken});
};

export const GetShortCourseOutcomeList = async (params, cancelToken) => {
  return await axiosInstance().get("/getShortCourseOutcomesList", {params,cancelToken});
};

export const AddEditModuleOutcomes = async (data) => {
  return await axiosInstance().post(`/addEditModuleOutcomes`, data);
};

export const AddUpdatesShortCourseOutcomes = async (data) => {
  return await axiosInstance().post(`/addAndUpdateShortCourseOutcomes`, data);
};

export const DeleteModuleOutcomes = async (data) => {
  return await axiosInstance().post(`/deleteModuleOutcomes`, data);
};

export const DeleteShortCourseOutcomes = async (params) => {
  return await axiosInstance().get(`/deleteShortCourseOutcomes`, {params});
};


export const DeleteBenefitAndFeature = async (data) => {
  return await axiosInstance().post(`/deleteBenefitAndFeature`, data);
};

export const DeletePmc  = async (data) => {
  return await axiosInstance().post(`/deletePmc`, data);
};
export const deleteProgrammePage  = async (data) => {
  return await axiosInstance().post(`/deleteProgrammePage`, data);
};


export const deleteShortCourse  = async (data) => {
  return await axiosInstance().get(`/deleteShortCourse`, {params : data});
};

export const GetPMCPdf  = async (data) => {
  return await axiosInstance().post(`/getPMCPdf`, data);
};

export const GetProgrammeResourcesList = async (id) => {
  return await axiosInstance().get(`/getModuleResourcesList?module_id=${id}`);
};

export const GetProgrammeResourcesListCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get(`/getModuleResourcesList?module_id=${id}`, {cancelToken});
};

export const getProgrammeResourcesListCancelToken = async (params, cancelToken) => {
  return await axiosInstance().post(`/getProgrammeResourcesList`,params, {cancelToken});
};

export const getShortCourseResourceDetailsCancelToken = async (params, cancelToken) => {
  return await axiosInstance().post(`/getShortCourseResourcesList`,params, {cancelToken});
};


export const DeleteProgrammeResources  = async (data) => {
  return await axiosInstance().post(`/deleteModuleResources`, data);
};

export const deleteProgrammeResources  = async (data) => {
  return await axiosInstance().post(`/deleteProgrammeResources`, data);
};

export const deleteShortCourseResources  = async (data) => {
  return await axiosInstance().post(`/deleteShortCourseResources`, data);
};

export const AddEditModuleResources  = async (data) => {
  return await axiosInstance().post(`/addEditModuleResources`, data);
};

export const addEditProgrammeResources  = async (data) => {
  return await axiosInstance().post(`/addEditProgrammeResources`, data);
};

export const addEditShortCourseResources = async (data) => {
  return await axiosInstance().post(`/addEditShortCourseResources`, data);
};

export const AssignedFeatureAndBenefit  = async (data) => {
  return await axiosInstance().post(`/assignedFeatureAndBenefit`, data);
};

export const shortCourseAssignedFeatureAndBenefit  = async (data) => {
  return await axiosInstance().post(`/shortCourseAssignedFeatureAndBenefit`, data);
};

export const UnlinkFeatureAndBenefit = async (data) => {
  return await axiosInstance().post(`/unlinkFeatureAndBenefit`, data);
};

export const UnlinkShortCourseFeatureAndBenefit = async (params,cancelToken) => {
  return await axiosInstance().get(`/unlinkShortCourseFeatureAndBenefit`, {params,cancelToken});
};

export const ListModulesFilters = async () => {
  return await axiosInstance().get("/listModulesFilters");
};

export const getProgramAssignedOrNotAssignedListFilter = async (params,cancelToken) => {
  return await axiosInstance().get("/getProgramAssignedOrNotAssignedListFilter", {params,cancelToken});
};

export const ListModulesFiltersCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/listModulesFilters`, {
    cancelToken: cancelToken
  });
};

export const ListProgrammeFilters = async (cancelToken) => {
  return await axiosInstance().get("/listProgrammeFilters",{cancelToken});
};

export const LinkedModuleNetsuiteProductCourseList = async (data,cancelToken) => {
  return await axiosInstance().post(`/linkedModuleNetsuiteProductCourseList`, data,{cancelToken});
};
export const LinkedModuleNetsuiteProductCourseListFilters = async (module_id,netsuite_product_id,isAssigned,cancelToken) => {
  return await axiosInstance().get(`/linkedModuleNetsuiteProductCourseListFilters?module_id=${module_id}&netsuite_product_id=${netsuite_product_id}&isAssigned=${isAssigned}`,{cancelToken});
};

export const GetProgrammeStudyPathList = async (data) => {
  return await axiosInstance().post("/getProgrammeStudyPathList" ,data);
};
export const LinkInatkeToModule = async (data) => {
  return await axiosInstance().post(`/linkInatkeToModule`,data);
};

export const UnlinkInatkeToModule = async (data) => {
  return await axiosInstance().post("/unlinkInatkeToModule" ,data);
};

export const LinkedNetsuiteProductCourseList = async (data) => {
  return await axiosInstance().post("/linkedNetsuiteProductCourseList" ,data);
};
export const LinkedNetsuiteProductCourseListFilters = async (programme_id,netsuite_product_id,isAssigned,cancelToken) => {
  return await axiosInstance().get(`/linkedNetsuiteProductCourseListFilters?programme_id=${programme_id}&netsuite_product_id=${netsuite_product_id}&isAssigned=${isAssigned}`,{cancelToken});
};

export const UnlinkedIntakeToProgramme = async (data) => {
  return await axiosInstance().post("/unlinkedIntakeToProgramme" ,data);
};
export const LinkedIntakeToProgramme = async (data) => {
  return await axiosInstance().post("/linkedIntakeToProgramme" ,data);
};

export const ReArrangeStudyPathPhase = async (data) => {
  return await axiosInstance().post("/reArrangeStudyPathPhase" ,data);
};

export const getProgrammeStudyPathModuleList = async (data) => {
  return await axiosInstance().post("/getProgrammeStudyPathModuleList" ,data);
}
export const createProgrammeStudyPath = async (data) => {
  return await axiosInstance().post("/createProgrammeStudyPath", data);
};
export const updateProgrammeStudyPath = async (data) => {
  return await axiosInstance().post("/updateProgrammeStudyPath", data);
};
export const DeleteProgrammeStudyPath = async (data) => {
  return await axiosInstance().post("/deleteProgrammeStudyPath" ,data);
};
export const getProgrammeStudyPathDetails = async (id) => {
  return await axiosInstance().get(`/getProgrammeStudyPathDetails?id=${id}`);
};
export const GetCampusLocationDropdown = async (cancelToken) => {
  return await axiosInstance().get("/getCampusLocationDropdown",{cancelToken});
};

export const getAllPmcProgrammesDropdown = async (cancelToken) => {
  return await axiosInstance().get("/getAllPmcProgrammesDropdown",{cancelToken});
};

export const GetBrandDropdown = async (cancelToken) => {
  return await axiosInstance().get("/getBrandDropdown",{cancelToken});
};

export const getAllPmcShortcourseDropdownCancelToken = async (cancelToken) => {
  return await axiosInstance().get("/getAllPmcShortcourseDropdown",{cancelToken});
};

export const getBrandTemplateList = async (cancelToken) => {
  return await axiosInstance().get("/getBrandTemplateList", { cancelToken });
};

export const getPagesListForPmcFilters = async (params, cancelToken) => {
  return await axiosInstance().get("/getPagesListForPmcFilters",{params, cancelToken});
};

export const GetStatusDropDownList = async (cancelToken) => {
  return await axiosInstance().get("/getStatusDropDownList",{cancelToken});
};

export const GetPopUpListStatic = async (cancelToken) => {
  return await axiosInstance().get("/getPopUpListStatic",{cancelToken});
};

export const GetCategoryListStatic = async (cancelToken) => {
  return await axiosInstance().get("/getCatgoryListStatic",{cancelToken});
};

export const GetPagesList = async (values, cancelToken) => {
  return await axiosInstance().post("/getPagesListForPmc", values, {cancelToken});
};

export const GetAssignedProgrammeAndShortCourseList = async (values, cancelToken) => {
  return await axiosInstance().post("/getAssignedProgrammeAndShortcourseList", values, {cancelToken});
};
export const GetPageAssignedProgrammeAndShortCourseList = async (values, cancelToken) => {
  return await axiosInstance().post("/getAssignedPageProgrammeAndShortcourseList", values, {cancelToken});
};

export const GetAssignedDropdownForPmc = async (cancelToken) => {
  return await axiosInstance().get("/getAssignedDropdownForPmc",{cancelToken});
};

export const CreateSoftware = async (values) => {
  return await axiosInstance().post(`/createSoftwareUsed`, values);
};

export const DeleteSoftware = async (values) => {
  return await axiosInstance().post(`/deleteSoftwareUsed`, values);
};

export const ListSoftwareCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/listSoftwareUses`, values, {cancelToken});
}; 

export const GetListProgrammeAndShortCourse = async (values, cancelToken) => {
  return await axiosInstance().post("/getListProgrammeAndShortcourse", values, {cancelToken});
};

export const GetListProgrammeAndShortCourseWebPage = async (values, cancelToken) => {
  return await axiosInstance().post("/getListProgrammeAndShortcourseWebPage", values, {cancelToken});
};

export const GetDropdownForProgrammeAndShortCourse = async (cancelToken) => {
  return await axiosInstance().get("/getDropdownForProgrammeAndShortcourse",{cancelToken});
};

export const getAssignedDropdownForPmc = async (params,cancelToken) => {
  return await axiosInstance().get("/getAssignedDropdownForPmc",{params,cancelToken});
};
export const getAssignedDropdownForPage = async (params,cancelToken) => {
  return await axiosInstance().get("/getAssignedDropdownForPage",{params,cancelToken});
};
export const AddAndUpdateProgrammeDates = async (values) => {
  return await axiosInstance().post("/addAndUpdateProgrammeDates", values);
};

export const GetProgrammesDatesListCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post("/getProgrammesDatesList", values, {cancelToken});
};

export const GetProgramDateFilter = async (params, cancelToken) => {
  return await axiosInstance().get("/getProgrammeDatesDropdown",{params, cancelToken});
};

export const DeleteProgrammeDates = async (params) => {
  return await axiosInstance().get("/deleteProgrammeDates",{params});
};

export const GetLevelsList = async (cancelToken) => {
  return await axiosInstance().get("/getlevelsList",{cancelToken});
};

export const AddShortCourse = async (values) => {
  return await axiosInstance().post("/addShortcourse", values);
};
export const UpdateShortCourse = async (values) => {
  return await axiosInstance().post("/updateShortcourse", values);
};

export const GetShortCourseList = async (values, cancelToken) => {
  return await axiosInstance().post("/getShortcourseList", values, {cancelToken});
};
export const ListShortCourseFilters = async (cancelToken) => {
  return await axiosInstance().get("/getShortCourseDropdown",{cancelToken});
};

export const GetShortCourseData = async (params, cancelToken) => {
  return await axiosInstance().get("/getShortCourseData", {params, cancelToken});
};

export const AddAndUpdateShortCourseDates = async (values) => {
  return await axiosInstance().post("/addAndUpdateShortCourseDates", values);
};

export const DeleteShortCourseDates = async (params) => {
  return await axiosInstance().get("/deleteShortCourseDates", {params});
};

export const GetShortCourseDatesList = async (values, cancelToken) => {
  return await axiosInstance().post("/getShortCourseDatesList", values, {cancelToken});
};

export const GetShortCourseDatesFilter = async (params, cancelToken) => {
  return await axiosInstance().get("/getShortCourseDatesDropdown",{params, cancelToken});
};

export const GetGraduatesStudentsList = async (values, cancelToken) => {
  return await axiosInstance().post("/getGraduatesStudentsList", values, {cancelToken});
};

export const GetGraduatesStudentsListFilters = async (netsuite_product_id,cancelToken) => {
  return await axiosInstance().get(`/getGraduatesStudentsListFilters?netsuite_product_id=${netsuite_product_id}`,{cancelToken});
};

export const GetProgramAssignedOrNotAssignedListFilter = async (PId,type,cancelToken) => {
  return await axiosInstance().get(`/getProgramAssignedOrNotAssignedListFilter?PId=${PId}&type=${type}`,{cancelToken});
}

export const GetListOfPMCAuditTrailFilters = async (values,cancelToken) => {
  return await axiosInstance().get(`/listAuditTrailDataFilters`, { params: values, cancelToken: cancelToken });
};

export const ProgrammesPageListCancelToken = async (payload, cancelToken) => {
  return await axiosInstance().post(`/programmesPageList`, payload, {cancelToken});
};
export const ProgrammesPageListFilters = async (params, cancelToken) => {
  return await axiosInstance().get("/programmesPageListFilters",{params, cancelToken});
};

export const GetProgrammesPageDatesListFilters = async (params, cancelToken) => {
  return await axiosInstance().get("/getProgrammesPageDatesListFilters",{params, cancelToken});
};
export const GetProgrammesPageDatesList = async (values, cancelToken) => {
  return await axiosInstance().post("/getProgrammesPageDatesList", values, {cancelToken});
};
export const AddAndUpdateProgrammePageDates = async (values) => {
  return await axiosInstance().post("/addAndUpdateProgrammePageDates", values);
};

export const DeleteProgrammePageDates = async (params) => {
  return await axiosInstance().get("/deleteProgrammePageDates",{params});
};
export const AddAndUpdateProgrammePageContentAndCustomisation = async (values) => {
  return await axiosInstance().post(`/addAndUpdateProgrammePageContentAndCustomisation`, values);
};

export const GenerateFactSheet = async (params) => {
  return await axiosInstance().get("/generateFactSheetforAdmin",{params});
};

export const ProgrameReArrangeFandB = async (values) => {
  return await axiosInstance().post(`/programeReArrangeFandB`, values);
};

export const ShortCourseReArrangeFandB = async (values) => {
  return await axiosInstance().post(`/shortCourseReArrangeFandB`, values);
};
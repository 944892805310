import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import {
  changeStatusEmail,
  emailPendingList,
} from "../../../services/EmailServices";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Str from "../../common/Str";

import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from "../../../utils/permissionGate";
import HtmlParser from "react-html-parser";
import moment from "moment";
import hasPermission from "../../../utils/hasMultiplePermission";
import {
  IMAGE_URL,
  TABLE_DATE_FORMAT,
  TABLE_DATE_TIME_FORMAT,
  TABLE_ROWS_PER_PAGE,
  TABLE_TIME_FORMAT,
} from "../../../utils/Constants";
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";
const PendingList = () => {
  const loc = useLocation();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState([]);
  const [searchStatusCheck, setSearchStatusCheck] = useState({});
  const [role, setRole] = useState(
    loc && loc.state && loc.state.name ? [loc.state.name] : []
  );
  const [searchRoleCheck, setSearchRoleCheck] = useState(
    loc && loc.state && loc.state.role_name ? loc.state.role_name : {}
  );
  const [company, setCompany] = useState([]);
  const [searchCompanyCheck, setSearchCompanyCheck] = useState({});
  const [location, setLocation] = useState([]);
  const [searchLocationCheck, setSearchLocationCheck] = useState({});

  const [report, setReport] = useState([]);
  const [searchReportCheck, setSearchReportCheck] = useState({});
  const [userData, setUserData] = useState([]);
  const [loading, setloading] = useState(false);
  const [isStopped, setIsStopped] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortkey, setSortKey] = useState("created_date");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [pendingData, setPendingData] = useState({});
  const [filterCheck, setFilterCheck] = useState("true");

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    handleTableScroll()
  }, [loading])
  // TODO Api Call

  useEffect(() => {
    let response = hasPermission({
      scopes: ["bnpeview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);
  useEffect(() => {
    setPage(1);
  }, [location]);

  useEffect(() => {
    const cancelTokenSources = [];
    const getList = async () =>{
      setloading(true);
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();      
      cancelTokenSources.push(source);
      
      const data = {
        page: page,
        limit: perPage,
        viaSearch: search ? search : "",
        exportStatus: false,
        key: sortkey,
        sort: sortOrder,
        dynamicFilter: filterCheck,
      };

      try {
        const res = await emailPendingList(data, source.token);
        if(res.status==200){
          setUserData(res?.data?.data_list?.data);
          setTotalRows(res?.data?.data_list?.total);
          setloading(false);
          setIsStopped(false);
          setFilterCheck("false");
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }
    }
    getList();
    return () => {
      cancelTokenSources.forEach(source => {
          source.cancel('Component unmounted');
      });
    };
  }, [search, page, perPage, sortkey, sortOrder, isStopped]);

  // TODO Searching
  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column?.selector);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  // TODO Reset Filter

  const resetFilter = () => {
    setStatus([]);
    setSearchStatusCheck({});
    setRole([]);
    setSearchRoleCheck({});
    setCompany([]);
    setSearchCompanyCheck({});
    setLocation([]);
    setSearchLocationCheck({});
    setReport([]);
    setSearchReportCheck({});
    setSearch("");
  };

  // TODO Data

  const setPenData = (row) => {
    handlePendingView(row.id);
    setPenData(row);
  };

  const stopEmail = (row) => {
    const data = {
      id: row.id,
      status: "stopped",
    };
    changeStatusEmail(data)
      .then((res) => {
        setIsStopped(true);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleReturningIcon = (row) => {
    return (
      <>
        {row.isEmailActive ? (
          <>
            <i class="fal fa-envelope"></i>&nbsp;
          </>
        ) : (
          ""
        )}
        {row.isSmsActive ? (
          <>
            <i class="fal fa-comment"></i>&nbsp;
          </>
        ) : (
          ""
        )}
        {row.isPushnotificationActive ? <i class="fal fa-mobile"></i> : ""}

        {!row.isPushnotificationActive && !row.isSmsActive && !row.isEmailActive
          ? "-"
          : ""}
      </>
    );
  };

  const sortingUser = (rowA, rowB) => {
    const name1 = rowA?.sendByCourse?.log.toString()
      ? rowA?.sendByCourse?.log.toString()
      : "-";
    const name2 = rowB?.sendByCourse?.log.toString()
      ? rowB?.sendByCourse?.log.toString()
      : "-";
    return name1.localeCompare(name2);
  };

  const columns = useMemo(() => [
    {
      name: "Subject",
      selector: "subject",
      sortable: true,
      // cell: (row) => (row.subject ? TrimText(row.subject, 20) : "NA"),
      cell: (row) =>
        row?.subject ? (
          <p
            className="as-text-blue curser textLimit100"
            title={row?.subject}
            onClick={() => setPendingData(row)}
            data-toggle="modal"
            data-target="#notifications"
          >
            {row?.subject}
          </p>
        ) : (
          "-"
        ),
    },
    {
      name: "To",
      selector: "to",
      sortable: true,
      cell: (row) => (row?.to ? row?.to : "-"),
    },
    {
      name: "Sent Via",
      selector: "sendVia",
      sortable: false,
      cell: (row) => (
        <div className="ticket-id">
          <span className="overflow-ellipsis2">
            {row.draft_notification ? <div className="as-green-bg as-widget">{"Draft Saved On " + moment.unix(row.start).format(TABLE_DATE_TIME_FORMAT)}</div> : handleReturningIcon(row)}
          </span>
        </div>
      ),
    },
    {
      name: "Sent By",
      selector: "log",
      sortable: false,
      wrap: true,
      // sortFunction: sortingUser,
      cell: (row) =>
        row.sendByCourse && row.sendByCourse.log ?
          <span className="feature-name" title={row.sendByCourse && row.sendByCourse.log}>
            <span className="textLimit100">{row.sendByCourse && row.sendByCourse.log}</span>
          </span>
          : (
            <p>-</p>
          ),
    },
    {
      name: "Created Date",
      selector: "created_date",
      sortable: true,
      // cell: (row) => (row?.created_date ? row?.created_date : "NA"),
      cell: (row) =>
        row?.created_date && row?.created_date != 0
          ? `${moment(row?.created_date).format(TABLE_DATE_FORMAT)} ${moment(
              row?.created_date
            ).format(TABLE_TIME_FORMAT)} `
          : "-",
    },

    {
      name: "Action",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <PermissionsGate scopes={["umedit"]}>
              <button
                title="View"
                className="btn btn-primary rounded-circle"
                onClick={() => setPendingData(row)}
                data-toggle="modal"
                data-target="#notifications"
              >
                <i className="fal fa-eye"></i>
              </button>
            </PermissionsGate>
            <PermissionsGate scopes={["bnpeedit"]}>
              <button
                title="Stop Email"
                className="btn btn-primary rounded-circle"
                onClick={() => stopEmail(row)}
              >
                <i class="fal fa-ban"></i>
              </button>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  const handlePendingView = (ID) => {
    history.push(`/notification/email/pending/view/${ID}`);
    // const result = checkRole(user.role_name, row.role_name);
    // // console.log(user.role_name, row.role_name, result);
    // if (result === "enabled") {
    //   history.push(`/systemAdministration/userManagement/open/${ID}/aboutYou`);
    // } else {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Not authorised",
    //     text: "You dont have the permission to Edit ",
    //   }).then((result) => {});
    // }
  };

  // TODO Excel
  // TODO Csv
  // TODO Pdf

  const exportData = (fileType, fileName) => {
    let data;
    const header = [
      "Subject",
      "To",
      "Sent Via",
      "Sent By",
      "Created Date"
    ];

    // data = data?.map((row) => ({
    //   ...row,
    //   // ID: row?.id,
    //   Subject: row?.subject ? row?.subject : "NA",
    //   Status: row?.is_active ? "Active" : "Inactive",
    //   TO: row?.to ? row?.to : "NA",
    //   Created_Date: row?.created_date ? row?.created_date : "NA",
    // }));

    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    emailPendingList({
      page: page,
      limit: perPage,
      viaSearch: search ? search : "",
      exportStatus: true,
    }).then((res) => {
      data = res?.data?.data_list.map((row) => ({
        Subject: row?.subject ? row?.subject : "-",

        // Status: row.status ? row.status : "-",
        To: row.to ? row.to : "-",
        "Sent Via": row.draft_notification
          ? "Draft Saved On " +
            moment.unix(row.start).format(TABLE_DATE_TIME_FORMAT)
          : (row.isEmailActive ? "Email " : "") +
            (row.isPushnotificationActive ? " Push" : ""),
        ["Sent By"]:
          row.sendByCourse && row.sendByCourse ? row.sendByCourse.log : "-",
        "Created Date":
          row?.created_date && row?.created_date != 0
            ? `${moment(row?.created_date).format(TABLE_DATE_FORMAT)} ${moment(
                row?.created_date
              ).format(TABLE_TIME_FORMAT)}`
            : "-",
      }));

      if (fileType === "csv") {
        const csvString = Papa.unparse({ fields: header, data });

        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

        const blobURL = window.URL.createObjectURL(blob);

        const anchor = document.createElement("a");
        anchor.download = fileName;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = [
          "text/csv",
          anchor.download,
          anchor.href,
        ].join(":");
        anchor.click();

        setTimeout(() => {
          URL.revokeObjectURL(blobURL);
        }, 1000);
        Swal.close();
      } else if (fileType === "xlsx") {
        const compatibleData = data.map((row) => {
          const obj = {};
          header.map((col, index) => {
            obj[col] = row[col];
          });
          return obj;
        });

        let wb = XLSX.utils.book_new();
        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
          header,
        });
        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        XLSX.writeFile(wb, `${fileName}.xlsx`);
        Swal.close();
        return false;
      }
      if (fileType === "pdf") {
        const compatibleData = data.map((row) => {
          return [
            row["Subject"],
            // row["Status"],
            row["To"],

            row["Sent Via"],
            row["Sent By"],
            row["Created Date"],
          ];
        });
        const doc = new JsPDF();
        doc.autoTable({
          head: [header],
          body: compatibleData,
          styles: {
            minCellHeight: 10,
            minCellWidth: 5,
            halign: "left",
            fontSize: 8,
          },
        });
        doc.save(`${fileName}.pdf`);
        Swal.close();
        return false;
      }
    });

    // if (fileType === "csv") {
    //   const csvString = Papa.unparse({ fields: header, data });
    //   console.log(csvString);
    //   const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

    //   const blobURL = window.URL.createObjectURL(blob);

    //   // Create new tag for download file
    //   const anchor = document.createElement("a");
    //   anchor.download = fileName;
    //   anchor.href = blobURL;
    //   anchor.dataset.downloadurl = [
    //     "text/csv",
    //     anchor.download,
    //     anchor.href,
    //   ].join(":");
    //   anchor.click();

    //   // Remove URL.createObjectURL. The browser should not save the reference to the file.
    //   setTimeout(() => {
    //     // For Firefox it is necessary to delay revoking the ObjectURL
    //     URL.revokeObjectURL(blobURL);
    //   }, 1000);
    // } else if (fileType === "xlsx") {
    //   const compatibleData = data.map((row) => {
    //     const obj = {};
    //     header.map((col, index) => {
    //       obj[col] = row[col];
    //     });
    //     return obj;
    //   });

    //   let wb = XLSX.utils.book_new();
    //   let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
    //     header,
    //   });
    //   XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
    //   XLSX.writeFile(wb, `${fileName}.xlsx`);

    //   // Returning false as downloading of file is already taken care of
    //   return false;
    // }
    // if (fileType === "pdf") {
    //   const compatibleData = data.map((row) => {
    //     return [row.id, row.to, row.subject, row.created_date, row.status];
    //   });
    //   const doc = new JsPDF();
    //   doc.autoTable({
    //     head: [header],
    //     body: compatibleData,
    //     styles: {
    //       minCellHeight: 10,
    //       minCellWidth: 5,
    //       halign: "left",
    //       // valign: "center",
    //       fontSize: 8,
    //     },
    //   });
    //   doc.save(`${fileName}.pdf`);

    //   return false;
    // }
  };

  const customSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
      // use the selector function to resolve your field names by passing the sort comparitors
      const aField = rowA[selector]?.toString().toLowerCase();
      const bField = rowB[selector]?.toString()?.toLowerCase();

      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }

      return direction === "desc" ? comparison * -1 : comparison;
    });
  };

  return (
    <>
      <div className="my-tickets-info-list Tickets-main-wrap">
        <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
          <div className="search-filter-div">
            <div className="search-filter-div-left">
              <div className="system-administration-table table-responsive">
                <div className="table-responsive-div">
                  <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      id="assessment-table-main_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          className=""
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={handleSearchFilter}
                          value={search}
                        />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filter-button-group">
                  {/* <div className="assessment-table-filter">
<div className="btn-type-filter dropdown-comman">
  <div className="dropdown multiselect">
    <button
      className={`btn btn-default dropdown-toggle ${
        status.length ? "btn-selected" : ""
      }`}
      type="button"
      id="dropdownMenu1"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      title="Status"
    >
      <span>
        Status <i className="fal fa-angle-down"></i>
      </span>
    </button>
    <form className="dropdown-menu remove-list-style">
      <ul aria-labelledby="dropdownMenu1">
        <li className="item-text-green">
          <input
            type="checkbox"
            id="action1"
            value="1"
            onClick={handleStatusFilter}
            checked={searchStatusCheck.action1}
          />
          <label htmlFor="action1">
            <span className="as-green-bg as-widget">ACTIVE</span>
          </label>
        </li>

        <li className="item-text-red">
          <input
            type="checkbox"
            id="action2"
            value="0"
            onClick={handleStatusFilter}
            checked={searchStatusCheck.action2}
          />
          <label htmlFor="action2">
            <span className="as-red-bg as-widget">INACTIVE</span>
          </label>
        </li>
      </ul>
    </form>
  </div>
</div>
</div> */}
                  <div className="reset-btn-group">
                    <div className="button-reset dropdown-comman">
                      <button
                        className="btn btn-primary"
                        onClick={resetFilter}
                        title="Reset"
                      >
                        <i className="fal fa-redo"></i>Reset
                      </button>
                    </div>
                    <div className="files-export-group">
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("xlsx", "Pending Notifications");
                        }}
                        title="Export spreadsheet"
                      >
                        <i className="fal fa-file-excel icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("csv", "Pending Notifications");
                        }}
                        title="Export CSV"
                      >
                        <i className="fal fa-file-csv icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("pdf", "Pending Notifications");
                        }}
                        title="Export PDF"
                      >
                        <i className="fal fa-file-pdf icon"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DataTable
            data={userData}
            defaultSortField="created_date"
            defaultSortAsc={false}
            columns={columns}
            pagination={true}
            noDataComponent={Str.noRecord}
            paginationDefaultPage={page}
            progressPending={loading}
            progressComponent={<SkeletonTicketList />}
            onSort={handleSort}
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={false}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
            sortFunction={customSort}
          />
        </div>
      </div>
      <div
        className="modal fade"
        id="notifications"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="modal-header modal-header-custom">
                <h5 className="modal-title">
                  <i className="fas fa-bars"></i> Generated Details
                </h5>
                <button
                  type="button"
                  data-dismiss="modal"
                  class="close"
                  title="Close"
                >
                  <span aria-hidden="true">×</span>
                  <span class="sr-only">Close</span>
                </button>
              </div>
              <div className="p-30px">
                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <div className="basic-info-box-list">
                      <ul className="d-flex flex-column p-0">
                        <li className="d-flex">
                          <span className="title mw-0">To Mail:&nbsp;</span>
                          <span className="word-break-all">
                            {pendingData?.to ? pendingData?.to : "NA"}
                          </span>
                        </li>
                        <li className="d-flex">
                          <span className="title mw-0">Sent:&nbsp;</span>
                          <span className="word-break-all">
                            {pendingData?.sent == 0
                              ? "Pending"
                              : `${moment(pendingData.sent).format(
                                  "DD MMMM YYYY"
                                )}@${moment(pendingData.sent).format("HH:mm")}`}
                          </span>
                        </li>
                        <li className="d-flex">
                          <span className="title mw-0">Subject:&nbsp;</span>
                          <span className="word-break-all">
                            {pendingData?.subject ? pendingData?.subject : "NA"}
                          </span>
                        </li>
                      </ul>
                    </div>

                    <span className="d-flex" style={{ fontWeight: "600" }}>
                      Content:&nbsp;
                    </span>
                    <span className="word-break-all">
                      {pendingData?.body ? HtmlParser(pendingData?.body) : "NA"}
                    </span>
                    <div className="basic-info-box-list">
                      <ul className="d-flex flex-column p-0">
                        <li className="d-flex">
                          <span className="">Attachment:&nbsp;</span>
                          {/* <span>{generatedData && generatedData.subject && generatedData.subject}</span> */}
                          <span>
                            {pendingData?.attachment &&
                            pendingData?.attachmentArr.length ? (
                              <>
                                <a
                                  className="as-text-blue curser"
                                  target={"_blank"}
                                  href={`${IMAGE_URL}/${encodeURI(pendingData?.attachment
                                    .replace("home/myaie/public_html/", "")
                                    .replace("public/", ""))}`}
                                >
                                  {
                                    pendingData?.attachment?.split("/")[
                                      pendingData?.attachment?.split("/")
                                        .length - 1
                                    ]
                                  }
                                </a>
                                <br />
                                {pendingData.attachmentArr.map(
                                  (item, index) => (
                                    <React.Fragment key={index}>
                                      <a
                                        className="as-text-blue curser"
                                        target={"_blank"}
                                        href={`${IMAGE_URL}/${encodeURI(item?.attachment
                                          .replace(
                                            "home/myaie/public_html/",
                                            ""
                                          )
                                          .replace("public/", ""))}`}
                                      >
                                        {
                                          item?.attachment?.split("/")[
                                            item?.attachment?.split("/")
                                              .length - 1
                                          ]
                                        }
                                      </a>
                                      {index <
                                      pendingData.attachmentArr.length - 1 ? (
                                        <br />
                                      ) : (
                                        ""
                                      )}
                                    </React.Fragment>
                                  )
                                )}
                              </>
                            ) : pendingData?.attachment ? (
                              <a
                                className="as-text-blue curser"
                                target={"_blank"}
                                href={`${IMAGE_URL}/${encodeURI(pendingData?.attachment
                                  .replace("home/myaie/public_html/", "")
                                  .replace("public/", ""))}`}
                              >
                                {
                                  pendingData?.attachment?.split("/")[
                                    pendingData?.attachment?.split("/").length -
                                      1
                                  ]
                                }
                              </a>
                            ) : pendingData?.attachmentArr?.length ? (
                              pendingData.attachmentArr.map((item, index) => (
                                <React.Fragment key={index}>
                                  <a
                                    className="as-text-blue curser"
                                    target={"_blank"}
                                    href={`${IMAGE_URL}/${encodeURI(item?.attachment
                                      .replace("home/myaie/public_html/", "")
                                      .replace("public/", ""))}`}
                                  >
                                    {
                                      item?.attachment?.split("/")[
                                        item?.attachment?.split("/").length - 1
                                      ]
                                    }
                                  </a>
                                </React.Fragment>
                              ))
                            ) : (
                              "NA"
                            )}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="form-group form-group-save-cancel mt-3">
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                    title="Close"
                  >
                    <i className="fal fa-times"></i>
                    Close
                  </button>
                </div>
              </div>
            </div>

            {/* <div className="modal-footer"> */}
            {/* <a href={!notificationData || !notificationData.attachment ? "" : IMAGE_URL + "/" + (notificationData.attachment).replace("/home/myaie/public_html/", "").replace("public/", "")} target="_blank" download>
                  <button className="btn btn-primary" disabled={notificationData.attachment == "" || notificationData.attachment == null || !notificationData.attachment} title="Download Document">
                    <i className="fal fa-arrow-down"></i>
                    Download Document
                  </button>
                </a> */}

            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PendingList;
